import { query, collection, getDocs, where } from "firebase/firestore/lite";
import { FirebaseDB } from "../firebase/config";


export const loadCategories = async (uid = '') => {
    if (!uid) throw new Error('El UID del usuario no existe');

    const q = query(
        collection(FirebaseDB, 'categorie')
    );

    const docs = await getDocs(q);

    const categories = [];
    docs.forEach(doc => {
        categories.push({ id: doc.id, ...doc.data() });
    });
    return categories;
}

export const loadCategorie = async (uid = '') => {
    if (!uid) throw new Error('El UID del usuario no existe');

    const q = query(
        collection(FirebaseDB, 'categorie'),
        where('uid', '==', uid)
    );

    const docs = await getDocs(q);

    const categorie = [];
    docs.forEach(doc => {
        categorie.push({ id: doc.id, ...doc.data() });
    })

    return categorie;
}

export const loadAllCategories = async () => {

    const q = query(
        collection(FirebaseDB, 'categorie'),
    );

    const docs = await getDocs(q);

    const categorie = [];
    docs.forEach(doc => {
        categorie.push({ id: doc.id, ...doc.data() });
    })

    return categorie;
}