import { collection, deleteDoc, doc, setDoc } from 'firebase/firestore/lite';
import { FirebaseDB } from "../../firebase/config";
import { addNewEmptyCompanie, deleteCompanieById, savingNewCompanie, setActiveCompanie, setCompanie, setCompanies, setSaving, updateCompanie } from "./companiesSlice"
import { loadAllCompanies, loadCompanie, loadCompanies } from '../../helpers/loadCompanies';


export const startNewClearcompanie = (companie) => {
    return async (dispatch, getState) => {
        dispatch(savingNewCompanie());
        const { uid } = getState().auth;

        const {
            name,
            description,
            urlImage,
            urlWebsite,
            email,
            merchantId,
            token,
            category
        } = companie

        const newCompanie = {
            uid,
            name,
            description,
            category,
            urlImage,
            urlWebsite,
            email,
            merchantId,
            token,
            creationDate: new Date().getTime(),
            updateDate: new Date().getTime()
        }

        const newDoc = doc(collection(FirebaseDB, `companie`))
        await setDoc(newDoc, newCompanie);
        newCompanie.id = newDoc.id;
        dispatch(addNewEmptyCompanie(newCompanie));
        dispatch(setActiveCompanie(newCompanie));

    }
}

export const startNewCompanie = (companie) => {

    return async (dispatch, getState) => {
        dispatch(savingNewCompanie());

        const { uid } = getState().auth;
        const {
            name,
            description,
            urlImage,
            urlWebsite,
            email,
            merchantId,
            token,
            category,
            country
        } = companie;

        const newCompanie = {
            uid: uid,
            name,
            description,
            category,
            urlImage,
            urlWebsite,
            email,
            merchantId,
            token,
            country,
            creationDate: new Date().getTime(),
            updateDate: new Date().getTime()
        }

        const newDoc = doc(collection(FirebaseDB, `companie`))
        await setDoc(newDoc, newCompanie);
        newCompanie.id = newDoc.id;

        dispatch(addNewEmptyCompanie(newCompanie));
        dispatch(setActiveCompanie(newCompanie));

    }
}

export const startLoadingCompanies = () => {
    return async (dispatch, getState) => {
        const { uid } = getState().auth;

        if (!uid) throw new Error('El UID del usuario no existe');
        const companies = await loadCompanies(uid);
        dispatch(setCompanies(companies));
    }
}

export const startLoadingAllCompanies = () => {
    return async (dispatch, getState) => {
        // const { uid } = getState().auth;

        // if (!uid) throw new Error('El UID del usuario no existe');
        const companies = await loadAllCompanies();
        dispatch(setCompanies(companies));
    }
}

export const startLoadingCompanie = () => {
    return async (dispatch, getState) => {
        const { uid } = getState().auth;

        if (!uid) throw new Error("El UID del usuario no existe");
        const companie = await loadCompanie(uid);
        dispatch(setCompanie(companie));
    }
}

export const startDeletingCompanie = () => {
    return async (dispatch, getState) => {
        const { uid } = getState().auth;
        const { active: companie } = getState().companie;

        const docRef = doc(FirebaseDB, `companie/${companie.id}`);
        await deleteDoc(docRef);

        dispatch(deleteCompanieById(companie.id));
    }
}

export const startSaveCompanie = () => {
    return async (dispatch, getState) => {

        dispatch(setSaving());

        const { uid } = getState().auth;
        const { active: companie } = getState().companie;

        const companieToFireStore = { ...companie };
        delete companieToFireStore.id;

        const docRef = doc(FirebaseDB, `companie/${companie.id}`)
        await setDoc(docRef, companieToFireStore, { merge: true });

        dispatch(updateCompanie(companie));
    }
}