import React, { useEffect, useState } from 'react'
import Swal from 'sweetalert2';
import { useForm } from 'react-hook-form';
import { FormControl, InputLabel, MenuItem, Select, TextField } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { DataGrid } from '@mui/x-data-grid';
import { setActiveSeller } from '../store/seller/sellerSlice';
import { startDeletingSeller, startLoadingSellers, startNewSeller, startSaveSeller } from '../store/seller/thunks';

const state = [
    { name: 'Activo' },
    { name: 'Inactivo' }
]

export const RepatriationSeller = () => {
    const dispatch = useDispatch();
    const [status, setStatus] = useState('');
    const [selectedCompanie, setSelectedCompanie] = useState('');
    const { sellers } = useSelector(state => state.seller);
    const { companies } = useSelector(state => state.companie);
    const { repCountries } = useSelector(state => state.repCountry)
    const { errorMessage } = useSelector(state => state.auth);
    const [edit, setEdit] = useState(false);
    const [sellerId, setSellerId] = useState('');
    const [updateSellers, setUpdateSellers] = useState(false);
    const [deleteSeller, setDeleteSeller] = useState(false);

    const { register, handleSubmit, setValue, control, formState: { errors } } = useForm({
        defaultValues: {
            sellerName: "",
            state: ""
        }
    });

    const columns = [
        { field: 'sellerName', headerName: 'Nombre', width: 200 },
        { field: 'companyName', headerName: 'Comercio', width: 200 },
        { field: 'status', headerName: 'Estado', width: 200 },
        { field: 'id', headerName: 'Opciones', width: 250, renderCell: (params) => <><button className='w-16 h-8 bg-blue-800 text-white rounded-full' onClick={() => onEdit(params.value)}>Editar</button><button className='w-16 h-8 bg-red-800 text-white ml-2 rounded-full' onClick={() => onDelete(params.value)}>Eliminar</button></> }
    ]

    const onEdit = (idSeller) => {
        setEdit(true);
        setEdit((state) => {
            return state;
        })

        let tmpSeller = sellers.filter(x => x.id === idSeller);
        const { id, sellerName, companyId, status } = tmpSeller[0];
        dispatch(setActiveSeller({ id, sellerName, companyId, status }));
        setSellerId(id);
        setSellerId((state) => {
            return state;
        });
        setValue('sellerName', sellerName);
        setStatus(status);
        setSelectedCompanie(companyId);
    }

    useEffect(() => {
        if (deleteSeller) {
            dispatch(startLoadingSellers());
        }
    }, [deleteSeller])

    const onDelete = (idSeller) => {
        let tmpSeller = sellers.filter(x => x.id === idSeller);
        const { id, sellerName, status } = tmpSeller[0];
        dispatch(setActiveSeller({ id, sellerName, status }));
        setDeleteSeller(true);
        dispatch(startDeletingSeller(id));
        if (!errorMessage) {
            showDeleteSuccess();
            onClear();
        }
    }

    const onSubmit = (data) => {
        let tmpCompany = companies.filter((x) => x.id === selectedCompanie);

        if (edit) {
            const newData = {
                id: sellerId,
                sellerName: data.sellerName,
                companyId: selectedCompanie,
                companyName: tmpCompany[0].name,
                status: status
            }
            dispatch(setActiveSeller(newData));
            if (newData) {
                onUpdateRegister(newData);
                if (!errorMessage) {
                    showSuccess();
                    onClear();
                    setUpdateSellers(true);
                }
            }

        } else {
            const newData = {
                sellerName: data.sellerName,
                companyId: selectedCompanie,
                companyName: tmpCompany[0].name,
                status: status
            }

            dispatch(setActiveSeller(newData));
            if (newData) {
                onSaveNewSeller(newData);
            }
        }
    }

    const onUpdateRegister = () => {
        dispatch(startSaveSeller());
    }

    const onSaveNewSeller = (data) => {
        dispatch(startNewSeller(data));
        if (!errorMessage) {
            showSuccess();
            onClear();
        } else {
            showError();
        }
    }

    const showDeleteSuccess = () => {
        Swal.fire("Eliminado", 'El vendedor fué eliminado correctamente', 'success');
    }

    const showSuccess = () => {
        Swal.fire('Guardado', 'Vendedor creado correctamente', 'success');
    };

    const showError = () => {
        Swal.fire('Error', 'El Vendedor no fué creado', 'error');
    };

    const handleChangeStatus = (event) => {
        const {
            target: { value },
        } = event;
        setStatus(
            value,
        );
    }

    const handleChangeCompanie = (event) => {
        const {
            target: { value },
        } = event;
        setSelectedCompanie(
            value,
        );
    }

    const onClear = () => {
        setValue('sellerName', "");
        setStatus('');
        setSelectedCompanie('');
    }

    return (
        <div className='flex flex-col bg-white w-full place-items-center h-[870px]'>
            <div className='w-full pt-10 pl-32 pr-32'>
                <form onSubmit={handleSubmit(onSubmit)}>
                    <div className='flex flex-row w-full '>
                        <div className='w-full'>
                            <TextField
                                id='sellerName'
                                className='w-full'
                                placeholder='Nombre Vendedor'
                                {...register("sellerName")}
                            />
                        </div>
                    </div>
                    <div className='flex flex-row w-full pt-4'>
                        <FormControl className='w-full'>
                            <InputLabel>Comercio</InputLabel>
                            <Select
                                placeholder='Comercio'
                                label='Comercio'
                                value={selectedCompanie}
                                onChange={handleChangeCompanie}
                            >
                                {repCountries.map((item) => (
                                    <MenuItem key={item.id} value={item.companyId}>
                                        {item.companyName}
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    </div>
                    <div className='flex flex-row w-full pt-4'>
                        <FormControl className='w-full'>
                            <InputLabel>Estado</InputLabel>
                            <Select
                                placeholder='Estado'
                                label='Estado'
                                value={status}
                                onChange={handleChangeStatus}
                            >
                                {state.map((item) => (
                                    <MenuItem key={item.name} value={item.name}>
                                        {item.name}
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    </div>
                    <div className='flex flex-row pt-10 justify-end w-full'>
                        <div className='w-1/4'><button type="button" className='w-full h-10 bg-red-700 text-white rounded-full font-medium' onClick={() => onClear()}>Limpiar</button></div>
                        <div className='w-1/4 pl-2'><button type="submit" className='w-full h-10 bg-sky-900 text-white rounded-full font-medium'>Guardar</button></div>

                    </div>
                </form>
            </div>

            <div className='flex flex-row w-full pl-32 pr-32 pt-10'>
                <DataGrid
                    rows={sellers}
                    columns={columns}
                    initialState={{
                        pagination: {
                            paginationModel: { page: 0, pageSize: 5 },
                        },
                    }}
                    pageSizeOptions={[5, 10]}
                />
            </div>

        </div>
    )
}
