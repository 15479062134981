import React, { useState } from 'react'
import { Alert, TextField } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from "react-redux";
import { useForm } from 'react-hook-form';
import { startCreatingUserWithEmailPassword } from '../../store/auth/thunks';

export const RegisterPage = () => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    // const { status, errorMessage } = useSelector(state => state.auth)
    const [errorForm, setErrorForm] = useState(false);

    const { register, handleSubmit, setValue, control, formState: { errors } } = useForm({
        defaultValues: {
            email: "",
            displayName: "",
            password: "",
            confirmPassword: "",
            photoURL: ""
        }
    })

    const onSubmit = (data) => {
        setErrorForm(false);

        if (data.secret !== 'comercial&procesos') {
            setErrorForm(true);
            return;
        }

        if (data.password != data.confirmPassword) {
            setErrorForm(true);
            return;
        }
        dispatch(startCreatingUserWithEmailPassword(data));

    }

    return (
        <>

            <div className='flex w-full bg-white mb-6 ml-6 mt-6'>
                <div className='flex flex-1 w-full'>

                    <div className='w-full ml-20 mr-20'>
                        <div className='flex flex-col mr-28 mt-32 justify-center items-center text-center'>
                            <div className='ml-32 mr-28 w-full'>
                                <div className='pl-32 pr-32 w-full'>
                                    <div className="flex flex-row text-center justify-center w-full font-['Poppins'] text-gray-900 text-2xl font-bold">{t('register.create')}</div>
                                    <div className='flex flex-col w-full mt-8 justify-center items-center'>
                                        <TextField
                                            id='displayName'
                                            label={t('register.name')}
                                            className='w-7/12 rounded-lg indent-3'
                                            placeholder={t('register.name')}
                                            {...register('displayName', { required: true, minLength: 6 })}
                                        />
                                        {
                                            errors.displayName && errors.displayName.type === "required" &&
                                            <p className="mt-2 text-sm text-red-600 dark:text-red-500">Display Name is required</p>
                                        }
                                        {
                                            errors.displayName && errors.displayName.type === "minLength" &&
                                            <p className="mt-2 text-sm text-red-600 dark:text-red-500">Must have at least 6 characters</p>
                                        }
                                    </div>
                                    <div className='flex flex-col w-full mt-8 justify-center items-center'>
                                        <TextField
                                            id='email'
                                            className='w-7/12 rounded-lg indent-3'
                                            label={t('register.email')}
                                            placeholder={t('register.email')}
                                            {...register('email', { required: true, minLength: 6 })}
                                        />
                                        {errors.email && errors.email.type === "required" &&
                                            <p className="mt-2 text-sm text-red-600 dark:text-red-500">Email is required</p>
                                        }
                                    </div>
                                    <div className='flex flex-col w-full mt-8 justify-center items-center'>
                                        <TextField
                                            id='secret'
                                            className='w-7/12 rounded-lg indent-3'
                                            label={t('register.secret')}
                                            type='password'
                                            {...register('secret', { required: true })}
                                        />
                                        {errors.secret && errors.secret.type === "required" &&
                                            <p className="mt-2 text-sm text-red-600 dark:text-red-500">Secret word is required</p>
                                        }
                                    </div>
                                    <div className='flex flex-col w-full mt-8 justify-center items-center'>
                                        <TextField
                                            id='password'
                                            type='password'
                                            className='w-7/12 rounded-lg indent-3'
                                            label={t('register.password')}
                                            placeholder={t('register.password')}
                                            {...register('password', { required: true, minLength: 6 })}
                                        />
                                        {
                                            errors.password && errors.password.type === "required" &&
                                            <p className="mt-2 text-sm text-red-600 dark:text-red-500">Password is required</p>
                                        }
                                    </div>
                                    <div className='flex flex-col w-full mt-8 justify-center items-center'>
                                        <TextField
                                            id='confirmPassword'
                                            type='password'
                                            className='w-7/12 rounded-lg indent-3'
                                            placeholder={t('register.confirm')}
                                            label={t('register.confirm')}
                                            {...register('confirmPassword', { required: true, minLength: 6 })}
                                        />
                                        {
                                            errors.confirmPassword && errors.confirmPassword.type === "required" &&
                                            <p className="mt-2 text-sm text-red-600 dark:text-red-500">Confirm password is required</p>
                                        }
                                    </div>
                                    <div className='w-full mt-12 pl-32 pr-32'>
                                        <button className='w-full h-14 bg-[#0D497D] rounded-full text-white' type='button' onClick={handleSubmit(onSubmit)} >{t('register.started')}</button>
                                    </div>
                                    {/* {errorMessage &&
                                        <div className='pt-4'>
                                            <Alert severity="error">{t('login.errorMessage')}</Alert>
                                        </div>
                                    } */}
                                    {errorForm &&
                                        <div className='pt-4'>
                                            <Alert severity="error">{t('login.errorMessage')}</Alert>
                                        </div>
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
