import { checkingCredentials, login, logout } from "./authSlice"
import { signInWithGoogle, registerUserWithEmailPassword, loginWithEmailPassword, logoutFirebase } from "../../firebase/providers";

export const checkingAuthentication = (email, password) => {
    return async (dispatch) => {
        dispatch(checkingCredentials());
    }
}

export const startGoogleSignIn = () => {
    return async (dispatch) => {
        dispatch(checkingCredentials());
        const result = await signInWithGoogle();

        if (!result.ok) dispatch(logout(result.errorMessage))

        dispatch(login(result))
    }
}

export const startCreatingUserWithEmailPassword = ({ email, password, displayName }) => {
    return async (dispatch) => {
        dispatch(checkingCredentials());
        let userType = sessionStorage.getItem('userType');
        const { ok, uid, photoURL, errorMessage } = await registerUserWithEmailPassword({ email, password, displayName, photoURL: userType });

        if (!ok) return dispatch(logout({ errorMessage }));

        dispatch(login({ uid, displayName, email, photoURL }));
    }
}

export const startLoginWithEmailPassword = ({ email, password }) => {
    return async (dispatch) => {
        dispatch(checkingCredentials());

        const result = await loginWithEmailPassword({ email, password });
        if (!result.ok) return dispatch(logout(result));
        dispatch(login(result));
    }

}


export const startLogout = () => {
    return async (dispatch) => {
        await logoutFirebase();

        dispatch(logout({}));
    }
}
