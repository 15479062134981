import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    page: 0,
    isLoading: false,
    messageSaved: '',
    isSaving: false,
    companies: [],
    companie: [],
    active: null
}

export const companieSlice = createSlice({
    name: 'companie',
    initialState,
    reducers: {
        savingNewCompanie: (state) => {
            state.isSaving = true;
        },
        addNewEmptyCompanie: (state, action) => {
            const newCompanie = action.payload;
            state.companies.push(newCompanie);
            state.isSaving = false;
        },
        setActiveCompanie: (state, action) => {
            state.active = action.payload;
            state.messageSaved = '';
        },
        setCompanies: (state, action) => {
            state.companies = action.payload;
        },
        setCompanie: (state, action) => {
            state.companie = action.payload;
        },
        setSaving: (state) => {
            state.isSaving = true;
            state.messageSaved = '';
        },
        updateCompanie: (state, action) => {
            state.isSaving = false;
            state.companies = state.companies.map(companie => {
                if (companie.id === action.payload.id) {
                    return action.payload;
                }
                return companie;
            })
            state.messageSaved = `${action.payload.title}, actualizada correctamente`
        },
        deleteCompanieById: (state, action) => {

        }
    }
})

export const {
    savingNewCompanie,
    addNewEmptyCompanie,
    setActiveCompanie,
    setCompanies,
    setCompanie,
    setSaving,
    updateCompanie,
    deleteCompanieById
} = companieSlice.actions