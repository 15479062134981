import React, { useState } from 'react'
import { Alert } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { startLoginWithEmailPassword } from '../../store/auth/thunks';

export const LoginPage = () => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');

    const submit = (event) => {
        // event.PreventDefault();
        dispatch(startLoginWithEmailPassword({ email, password }))
    }

    return (
        <>
            <div>
                <div className='flex w-full bg-white mb-6 ml-6 mt-6'>
                    <div className='flex flex-1 w-full justify-center'>
                        <div className='w-7/12 ml-20 mr-20'>
                            <div className='flex flex-col mr-28 mt-28 justify-center items-center text-center'>
                                <div className='ml-32 mr-28 w-full'>
                                    <div className='pl-32 pr-32 w-full'>
                                        {/* <div className='w-full flex flex-row justify-center'>
                                            <img src="../assets/logo.png" className="relative min-w-0 mb-px mt-14" alt='' />
                                        </div> */}
                                        <div className="flex pt-4 flex-row text-center justify-center w-full font-['Poppins'] text-gray-900 text-2xl font-bold">{t('login.login')}</div>
                                        <div className='flex flex-col w-full mt-12 justify-center items-center'>
                                            <input className='w-full rounded-lg indent-3 h-14' type='text' value={email} onChange={(e) => setEmail(e.target.value)} placeholder={t('login.email')} style={{ border: '1px solid #D7DEE1' }} />
                                        </div>
                                        <div className='flex flex-col w-full mt-8 justify-center items-center'>
                                            <input className='w-full rounded-lg indent-3 h-14' type='password' value={password} onChange={(e) => setPassword(e.target.value)} placeholder={t('login.password')} style={{ border: '1px solid #D7DEE1' }} />
                                        </div>
                                        <div className='flex mt-4 ml-0 text-left w-full'>
                                            <div className="ForgotYourPassword text-sky-900 text-[12px] font-medium">Forgot your password?</div>
                                        </div>
                                        <div className='w-full mt-12'>
                                            <button className='w-full h-14 bg-[#0D497D] rounded-full text-white' type='submit' onClick={() => submit()} >{t('login.getStarted')}</button>
                                        </div>
                                        {/* {errorMessage &&
                                            <div className='pt-4'>
                                                <Alert severity="error">{t('login.errorMessage')}</Alert>
                                            </div>
                                        } */}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
