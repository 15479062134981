import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    page: 0,
    isLoading: false,
    messageSaved: '',
    isSaving: false,
    videos: [],
    video: [],
    active: null
}

export const videoSlice = createSlice({
    name: 'video',
    initialState,
    reducers: {
        savingNewVideo: (state) => {
            state.isSaving = true;
        },
        addNewEmptyVideo: (state, action) => {
            const newVideo = action.payload;
            state.videos.push(newVideo);
            state.isSaving = false;
        },
        setActiveVideo: (state, action) => {
            state.active = action.payload;
            state.messageSaved = '';
        },
        setVideos: (state, action) => {
            state.videos = action.payload;
        },
        setVideo: (state, action) => {
            state.video = action.payload;
        },
        setSaving: (state) => {
            state.isSaving = true;
            state.messageSaved = '';
        },
        updateVideo: (state, action) => {
            state.isSaving = false;
            state.videos = state.videos.map(video => {
                if (video.id === action.payload.id) {
                    return action.payload;
                }
                return video;
            })
            state.messageSaved = `${action.payload.title}, actualizada correctamente`
        },
        deleteVideoById: (state, action) => {

        }
    }
})

export const {
    savingNewVideo,
    addNewEmptyVideo,
    setActiveVideo,
    setVideos,
    setVideo,
    setSaving,
    updateVideo,
    deleteVideoById
} = videoSlice.actions