import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { useForm } from 'react-hook-form';
import { DataGrid } from '@mui/x-data-grid';
import { FormControl, InputLabel, MenuItem, Select } from '@mui/material';
import Swal from 'sweetalert2';
import { startDeletingReferred, startLoadingReferreds, startNewReferred, startSaveReferred } from '../store/Referred/thunks';
import { setActiveReferred } from '../store/Referred/referredSlice';

export const Referred = () => {
    const dispatch = useDispatch();
    const { referreds } = useSelector(state => state.referred)
    const { repCountries } = useSelector(state => state.repCountry)
    const { companies } = useSelector(state => state.companie);
    const [selectedCompanie, setSelectedCompanie] = useState('');
    const [selectedReferred, setSelectedReferred] = useState('');
    const [edit, setEdit] = useState(false);
    const [updateReferreds, setUpdateReferreds] = useState(false);
    const [deleteReferred, setDeleteReferred] = useState(false);
    const [referredId, setReferredId] = useState('');
    const { errorMessage } = useSelector(state => state.auth);

    const { register, handleSubmit, setValue, control, formState: { errors } } = useForm({
        defaultValues: {
            companyName: "",
            description: "",
            state: ""
        }
    });

    useEffect(() => {
        dispatch(startLoadingReferreds());
    }, [])

    useEffect(() => {
        if (updateReferreds) {
            dispatch(startLoadingReferreds());
        }
    }, [updateReferreds])

    const columns = [
        { field: 'companyName', headerName: 'Referido De', width: 200 },
        // { field: 'companyId', headerName: 'CompanyId', width: 200 },
        { field: 'refCompanyName', headerName: 'Comercio', width: 200 },
        { field: 'id', headerName: 'Opciones', width: 250, renderCell: (params) => <><button className='w-16 h-8 bg-blue-800 text-white rounded-full' onClick={() => onEdit(params.value)}>Editar</button><button className='w-16 h-8 bg-red-800 text-white ml-2 rounded-full' onClick={() => onDelete(params.value)}>Eliminar</button></> }
    ]

    const onSubmit = (data) => {

        let tmpCompany = companies.filter((x) => x.id === selectedCompanie);
        let tmpReferredCompany = companies.filter((x) => x.id === selectedReferred);
        const { name } = tmpCompany[0];
        const { name: refName } = tmpReferredCompany[0];

        if (edit) {
            const newData = {
                id: referredId,
                companyId: selectedCompanie,
                companyName: name,
                refCompanyId: selectedReferred,
                refCompanyName: refName
            }

            dispatch(setActiveReferred(newData));
            if (newData) {
                onUpdateRegister(newData);
                if (!errorMessage) {
                    showUpdateSuccess();
                    onClear();
                    setUpdateReferreds(true);
                }
            }
        } else {
            const newData = {
                companyId: selectedCompanie,
                companyName: name,
                refCompanyId: selectedReferred,
                refCompanyName: refName
            }
            dispatch(setActiveReferred(newData));
            if (newData) {
                onSaveNewReferred(newData);
            }
        }
    }

    const onUpdateRegister = () => {
        dispatch(startSaveReferred());
    }

    const onSaveNewReferred = (data) => {
        dispatch(startNewReferred(data));
        if (!errorMessage) {
            showSuccess();
            onClear();
            setUpdateReferreds(true);
        } else {
            showError();
        }
    }

    const onEdit = (idReferred) => {
        setEdit(true);
        setEdit((state) => {
            return state;
        });

        let tmpReferreds = referreds.filter(x => x.id === idReferred);
        const { id, companyId, companyName, refCompanyId, refCompanyName } = tmpReferreds[0];
        dispatch(setActiveReferred({ id, companyId, companyName, refCompanyId, refCompanyName }));
        setReferredId(id);
        setReferredId((state) => {
            return state;
        })
        setSelectedCompanie(companyId)
        setSelectedReferred(refCompanyId);
    }

    useEffect(() => {
        if (deleteReferred) {
            dispatch(startLoadingReferreds());
        }
    }, [deleteReferred])

    const onDelete = (idCompany) => {
        let tmpReferred = referreds.filter(x => x.id === idCompany);
        const { id, companyId, companyName, refCompanyId, refCompanyName } = tmpReferred[0];
        dispatch(setActiveReferred({ id, companyId, companyName, refCompanyId, refCompanyName }))
        setDeleteReferred(true);
        dispatch(startDeletingReferred(id));
        if (!errorMessage) {
            showDeleteSuccess();
            onClear();
        }
    }

    const showDeleteSuccess = () => {
        Swal.fire('El referido fué eliminado correctamente', "Eliminado", 'success');
    }

    const showSuccess = () => {
        Swal.fire('Referido creado correctamente', 'Guardado', 'success');
    };

    const showUpdateSuccess = () => {
        Swal.fire('Referido actualizado correctamente', 'Guardado', 'success');
    };

    const showError = () => {
        Swal.fire('El Referido no fué creado', 'Error', 'error');
    };


    const handleChangeCompanie = (event) => {
        const {
            target: { value },
        } = event;
        setSelectedCompanie(
            value,
        );
    }

    const handleChangeRefered = (event) => {
        const {
            target: { value },
        } = event;
        setSelectedReferred(
            value,
        );
    }

    const onClear = () => {
        setValue('companyName', "");
        setSelectedCompanie('');
        setSelectedReferred('');
    }

    return (
        <div className='flex flex-col bg-white w-full place-items-center h-[870px]'>
            <div className='w-full pt-10 pl-32 pr-32'>
                <form onSubmit={handleSubmit(onSubmit)}>
                    <div className='flex flex-row w-full pt-4'>
                        <FormControl className='w-full'>
                            <InputLabel>Referido De:</InputLabel>
                            <Select
                                placeholder='Referido De:'
                                label='Referido De:'
                                value={selectedCompanie}
                                onChange={handleChangeCompanie}
                            >
                                {companies.map((item) => (
                                    <MenuItem key={item.id} value={item.id}>
                                        {item.name}
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    </div>
                    <div className='flex flex-row w-full pt-4'>
                        <FormControl className='w-full'>
                            <InputLabel>Comercio</InputLabel>
                            <Select
                                placeholder='Comercio'
                                label='Comercio'
                                value={selectedReferred}
                                onChange={handleChangeRefered}
                            >
                                {repCountries.map((item) => (
                                    <MenuItem key={item.companyName} value={item.companyId}>
                                        {item.companyName}
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    </div>
                    <div className='flex flex-row pt-10 justify-end w-full'>
                        <div className='w-1/4'><button type="button" className='w-full h-10 bg-red-700 text-white rounded-full font-medium' onClick={() => onClear()}>Limpiar</button></div>
                        <div className='w-1/4 pl-2'><button type="submit" className='w-full h-10 bg-sky-900 text-white rounded-full font-medium'>Guardar</button></div>
                    </div>
                </form>
            </div>

            <div className='flex flex-row w-full pl-32 pr-32 pt-10'>
                <DataGrid
                    rows={referreds}
                    columns={columns}
                    initialState={{
                        pagination: {
                            paginationModel: { page: 0, pageSize: 5 },
                        },
                    }}
                    pageSizeOptions={[5, 10]}
                />
            </div>

        </div>
    )
}
