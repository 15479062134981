import React, { useEffect, useState } from 'react'
import { CarouselPage } from './CarouselPage'
import { DownloadApp } from './DownloadApp'
import { References } from './References'
import { Products } from './Products'
import { WouldAffiliate } from './WouldAffiliate'
import { useSelector, useDispatch } from 'react-redux'
import { Box, Button, FormControl, IconButton, InputLabel, MenuItem, Modal, Select, Typography } from '@mui/material'
import { setActiveCountry } from '../store/countries/countrySlice'
import { useTranslation } from 'react-i18next'
import { startLoadingAllCountries } from '../store/countries/thunks'
import { CloseOutlined } from '@mui/icons-material'
import { Services } from './Services'
import { Connect } from './Connect'

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    // width: 900,
    bgcolor: 'background.paper',
    // border: '2px solid #000',
    // boxShadow: 12,
};

export const Home = () => {
    const { t } = useTranslation();
    const { active, countries } = useSelector(state => state.country);
    const dispatch = useDispatch();
    const [selectedCountry, setSelectedCountry] = useState('');
    const [open, setOpen] = React.useState(false);

    useEffect(() => {
        dispatch(startLoadingAllCountries());
    }, [])

    useEffect(() => {
        if (active == null) {
            handleOpen();
        }
    }, [active]);

    const handleOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
        handleIgnoreCountry();
    };

    const handleIgnoreCountry = () => {

        if (countries) {
            let tmpCountry = countries.filter((item) => item.countryName == '🇬🇹 Guatemala')
            if (tmpCountry.length > 0) {
                const { id, countryName, status } = tmpCountry[0];
                dispatch(setActiveCountry({ id, countryName, status }));
            }
            // handleClose();
            setOpen(false);
        }
    }

    const onSubmit = () => {
        if (countries) {
            let tmpCountry = countries.filter((item) => item.id === selectedCountry);
            if (tmpCountry.length > 0) {
                const { id, countryName, status } = tmpCountry[0];
                dispatch(setActiveCountry({ id, countryName, status }));
            }
            handleClose();
        }
    }

    const handleChangeCountry = (event) => {
        const {
            target: { value },
        } = event;
        setSelectedCountry(
            value,
        );
        setSelectedCountry((state) => {
            return state;
        })
    }

    return (
        <>
            <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={style} className='w-[370px] md:w-[400px] lg:w-[900px] h-[400px]'>
                    <div className='w-full h-full flex flex-row'>
                        <IconButton aria-label="close"
                            onClick={handleIgnoreCountry}
                            sx={{
                                position: "absolute",
                                right: 8,
                                top: 8,
                                color: (theme) => theme.palette.grey[500]
                            }}>
                            <CloseOutlined />
                        </IconButton>
                        <div className='lg:w-3/5 w-full'>
                            <div className='w-full pt-14 md:pt-20 pl-2 pr-2 md:pl-0 md:pr-0'>
                                <div className='w-full flex flex-row text-center justify-center pt-4'>
                                    <Typography gutterBottom variant="h5" component="div" fontSize='1.25rem' className='text-center'>
                                        {t('country.welcome')}
                                    </Typography>
                                </div>
                                <div className='w-full flex flex-row text-center justify-center'>
                                    <Typography variant="body2" color="text.secondary" fontSize='0.813rem'>
                                        {t('country.description')}
                                    </Typography>
                                </div>
                                <div className='w-full pt-4 place-content-center text-center'>
                                    <FormControl className='w-1/2'>
                                        <InputLabel>País</InputLabel>
                                        <Select
                                            label='País'
                                            placeholder='País'
                                            value={selectedCountry}
                                            onChange={handleChangeCountry}
                                        >
                                            {countries.map((item) => (
                                                <MenuItem key={item.countryName} value={item.id}>{item.countryName}</MenuItem>
                                            ))}
                                        </Select>
                                    </FormControl>
                                </div>
                                <div className='w-full place-content-center text-center items-center align-middle pt-4'>
                                    <div className='pt-4 w-full items-center text-center '>
                                        <button onClick={() => onSubmit()} className='bg-blue-950 text-white text-medium font-medium rounded-full w-48 h-14 opacity-100 hover:opacity-70 hover:rounded-full'>Seleccionar</button>
                                    </div>

                                </div>
                            </div>
                        </div>
                        <div className='w-2/5 hidden lg:block' style={{ backgroundImage: 'url("../assets/carousel/Rectangle189.png")' }}>

                        </div>

                    </div>
                </Box>
            </Modal>
            <CarouselPage />
            <Services />
            <Connect />
            <DownloadApp />
            <References />
            <WouldAffiliate />
            {/* <SelectCountry /> */}
            {/* <Products /> */}
            {/* <CarouselVideo /> */}
        </>
    )
}
