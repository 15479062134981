import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    page: 0,
    isLoading: false,
    messageSaved: '',
    isSaving: false,
    payments: [],
    payment: [],
    active: null
}

export const paymentSlice = createSlice({
    name: 'payment',
    initialState,
    reducers: {
        savingNewPayment: (state) => {
            state.isSaving = true;
        },
        addNewEmptyPayment: (state, action) => {
            const newPayment = action.payload;
            state.payments.push(newPayment);
            state.isSaving = false;
        },
        setActivePayment: (state, action) => {
            state.active = action.payload;
            state.messageSaved = '';
        },
        setPayments: (state, action) => {
            state.payments = action.payload;
        },
        setPayment: (state, action) => {
            state.payment = action.payload;
        },
        setSaving: (state) => {
            state.isSaving = true;
            state.messageSaved = '';
        },
        updatePayment: (state, action) => {
            state.isSaving = false;
            state.payments = state.payments.map(payment => {
                if (payment.id === action.payload.id) {
                    return action.payload;
                }
                return payment;
            })
            state.messageSaved = `${action.payload.title}, actualizada correctamente`
        },
        deletePaymentById: (state, action) => {

        }
    }
})

export const {
    savingNewPayment,
    addNewEmptyPayment,
    setActivePayment,
    setPayments,
    setPayment,
    setSaving,
    updatePayment,
    deletePaymentById
} = paymentSlice.actions