import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { onAuthStateChanged } from 'firebase/auth';

import { FirebaseAuth } from '../firebase/config';
import { login, logout } from '../store/auth/authSlice';
import { startLoadingCategories } from '../store/Categories/thunks';
import { startLoadingCompanies } from '../store/Companies/thunks';
import { startLoadingCountries } from '../store/countries/thunks';
import { startLoadingSellers } from '../store/seller/thunks';
import { startLoadingVideos } from '../store/video/thunks';
import { startLoadingRepCountries } from '../store/RepatriationCountry/thunks';

export const useCheckAuth = () => {

    const { status } = useSelector(state => state.auth);
    const dispatch = useDispatch();

    useEffect(() => {

        onAuthStateChanged(FirebaseAuth, async (user) => {
            if (!user) return dispatch(logout());

            const { uid, email, displayName, photoURL } = user;
            dispatch(login({ uid, email, displayName, photoURL }));
            dispatch(startLoadingCategories());
            dispatch(startLoadingCompanies());
            dispatch(startLoadingCountries());
            dispatch(startLoadingSellers());
            dispatch(startLoadingVideos());
            dispatch(startLoadingRepCountries());
        })
    }, []);

    return status;
}