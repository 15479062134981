import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    page: 0,
    isLoading: false,
    messageSaved: '',
    isSaving: false,
    categories: [],
    categorie: [],
    active: null
}

export const categorieSlice = createSlice({
    name: 'categorie',
    initialState,
    reducers: {
        savingNewCategorie: (state) => {
            state.isSaving = true;
        },
        addNewEmptyCategorie: (state, action) => {
            const newCategorie = action.payload;
            state.categories.push(newCategorie);
            state.isSaving = false;
        },
        setActiveCategorie: (state, action) => {
            state.active = action.payload;
            state.messageSaved = '';
        },
        setCategories: (state, action) => {
            state.categories = action.payload;
        },
        setCategorie: (state, action) => {
            state.categorie = action.payload;
        },
        setSaving: (state) => {
            state.isSaving = true;
            state.messageSaved = '';
        },
        updateCategorie: (state, action) => {
            state.isSaving = false;
            state.categories = state.categories.map(categorie => {
                if (categorie.id === action.payload.id) {
                    return action.payload;
                }
                return categorie;
            })
            state.messageSaved = `${action.payload.title}, actualizada correctamente`
        },
        deleteCategorieById: (state, action) => {

        }
    }
})

export const {
    savingNewCategorie,
    addNewEmptyCategorie,
    setActiveCategorie,
    setCategories,
    setCategorie,
    setSaving,
    updateCategorie,
    deleteCategorieById
} = categorieSlice.actions