import React, { useEffect, useState } from 'react'
import { Link, useParams } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux';
import { startLoadingAllCompanies } from '../store/Companies/thunks';
import { FormControl, MenuItem, Select } from '@mui/material';
import { setActiveCountry } from '../store/countries/countrySlice';
import { startLoadingAllCountries } from '../store/countries/thunks';
import { useTranslation } from 'react-i18next'

export const Stores = () => {
    const { t } = useTranslation();
    const { id } = useParams();
    const dispatch = useDispatch();
    const { companies } = useSelector(state => state.companie);
    const { categories } = useSelector(state => state.categorie);
    const { active, countries } = useSelector(state => state.country);
    const [companys, setCompanys] = useState([]);
    const [companyName, setCompanyName] = useState('');
    const [selectedCountry, setSelectedCountry] = useState('');
    const [loadCompanies, setLoadCompanies] = useState(false);

    useEffect(() => {
        if (window.scrollY > 300) {
            window.scrollTo({ top: 0, behavior: 'smooth' });
        }
        dispatch(startLoadingAllCountries());
        dispatch(startLoadingAllCompanies());
    }, [])

    useEffect(() => {
        if (active) {
            setSelectedCountry(active.id);
            dispatch(startLoadingAllCompanies());
        }
    }, [active])

    useEffect(() => {
        if (countries) {
            let tmpCountry = countries.filter((item) => item.id === selectedCountry);
            if (tmpCountry.length > 0) {
                const { id, countryName, status } = tmpCountry[0];
                dispatch(setActiveCountry({ id, countryName, status }));
            }
        }
    }, [selectedCountry])

    const handleChangeCountry = (event) => {
        const {
            target: { value },
        } = event;
        setSelectedCountry(
            value,
        );
        setSelectedCountry((state) => {
            return state;
        })
    }

    useEffect(() => {
        if (companies.length > 0 && categories.length > 0) {
            let activeCountry = active;
            let tmpCategorie = categories.filter(x => x.id === id);
            let tmpCompanies = [];
            if (activeCountry) {
                tmpCompanies = companies.filter(x => x.category[0] == tmpCategorie[0].name && x.country === activeCountry.id);
            } else {
                tmpCompanies = companies.filter(x => x.category[0] === tmpCategorie[0].name && x.country === '23LNyXbCM0VdknwEFa9f');
            }

            setCompanyName(tmpCategorie[0].name);
            setCompanyName((state) => {
                return state;
            });
            if (tmpCompanies.length < 5) {
                let count = tmpCompanies.length;
                if (count === 0) {
                    tmpCompanies.push({ urlWebsite: '', name: tmpCategorie[0].name, urlImage: tmpCategorie[0].urlImage })
                    tmpCompanies.push({ urlWebsite: '', name: tmpCategorie[0].name, urlImage: tmpCategorie[0].urlImage })
                    tmpCompanies.push({ urlWebsite: '', name: tmpCategorie[0].name, urlImage: tmpCategorie[0].urlImage })
                    tmpCompanies.push({ urlWebsite: '', name: tmpCategorie[0].name, urlImage: tmpCategorie[0].urlImage })
                    tmpCompanies.push({ urlWebsite: '', name: tmpCategorie[0].name, urlImage: tmpCategorie[0].urlImage })
                    tmpCompanies.push({ urlWebsite: '', name: tmpCategorie[0].name, urlImage: tmpCategorie[0].urlImage })
                }
                if (count === 1) {
                    tmpCompanies.push({ urlWebsite: '', name: tmpCategorie[0].name, urlImage: tmpCategorie[0].urlImage })
                    tmpCompanies.push({ urlWebsite: '', name: tmpCategorie[0].name, urlImage: tmpCategorie[0].urlImage })
                    tmpCompanies.push({ urlWebsite: '', name: tmpCategorie[0].name, urlImage: tmpCategorie[0].urlImage })
                    tmpCompanies.push({ urlWebsite: '', name: tmpCategorie[0].name, urlImage: tmpCategorie[0].urlImage })
                    tmpCompanies.push({ urlWebsite: '', name: tmpCategorie[0].name, urlImage: tmpCategorie[0].urlImage })
                }
                if (count === 2) {
                    tmpCompanies.push({ urlWebsite: '', name: tmpCategorie[0].name, urlImage: tmpCategorie[0].urlImage })
                    tmpCompanies.push({ urlWebsite: '', name: tmpCategorie[0].name, urlImage: tmpCategorie[0].urlImage })
                    tmpCompanies.push({ urlWebsite: '', name: tmpCategorie[0].name, urlImage: tmpCategorie[0].urlImage })
                    tmpCompanies.push({ urlWebsite: '', name: tmpCategorie[0].name, urlImage: tmpCategorie[0].urlImage })
                }
                if (count === 3) {
                    tmpCompanies.push({ urlWebsite: '', name: tmpCategorie[0].name, urlImage: tmpCategorie[0].urlImage })
                    tmpCompanies.push({ urlWebsite: '', name: tmpCategorie[0].name, urlImage: tmpCategorie[0].urlImage })
                    tmpCompanies.push({ urlWebsite: '', name: tmpCategorie[0].name, urlImage: tmpCategorie[0].urlImage })
                }
                if (count === 4) {
                    tmpCompanies.push({ urlWebsite: '', name: tmpCategorie[0].name, urlImage: tmpCategorie[0].urlImage })
                    tmpCompanies.push({ urlWebsite: '', name: tmpCategorie[0].name, urlImage: tmpCategorie[0].urlImage })
                }
                if (count === 5) {
                    tmpCompanies.push({ urlWebsite: '', name: tmpCategorie[0].name, urlImage: tmpCategorie[0].urlImage })
                }

            }

            setCompanys(tmpCompanies);
        }
    }, [companies, id]);

    return (
        <>
            <div className='w-full bg-principal-blue min-h-[100px]'>
                <div className='w-full flex justify-end place-content-end items-end text-right  pt-8 pl-4 md:pl-40 pr-4 md:pr-44'>
                    <div className='w-44 pb-4 pr-2'>
                        <p className='text-white text-sm md:text-base'>{t('country.market')}</p>
                    </div>
                    <div className=' w-48'>
                        <FormControl className='w-full'>
                            {/* <InputLabel sx={{ color: 'white' }}>Pais</InputLabel> */}
                            <Select
                                // label='Pais'
                                placeholder='Pais'
                                value={selectedCountry}
                                onChange={handleChangeCountry}
                                sx={{ color: 'white', border: 'transparent', textAlign: 'center' }}
                            >
                                {countries.map((item) => (
                                    <MenuItem key={item.countryName} value={item.id}>{item.countryName}</MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    </div>
                </div>
                <div className='w-full flex flex-row'>
                    <div className='w-full'>
                        <div className='flex flex-row w-full pt-16 md:pt-40'>
                            <p className="w-full text-center text-white text-4xl md:text-6xl font-bold leading-normal">{companyName}</p>
                        </div>
                        <div className='flex flex-row w-full pt-4 pb-28 md:pb-36 text-center justify-center'>
                            <p className="text-center text-white text-2xl md:text-4xl font-light leading-normal">{t('stores.affiliates')}</p>
                        </div>
                    </div>
                </div>
            </div>
            <div className='w-full flex flex-wrap bg-white min-h-[100px] pl-4 md:pl-44 pr-4 md:pr-44 pt-12 md:pt-24 pb-28'>
                {companys.map((item, index) => (
                    <div key={index} className='w-full flex md:w-1/2 lg:w-1/3 justify-between pt-6'>
                        <a href={item.urlWebsite} target="_blank" className='w-full'>
                            <div className='w-full md:w-9/12 lg:w-9/12 '>
                                <div className='w-full flex flex-row  pt-2 items-center justify-center'>
                                    <img className=' w-52 h-52' src={item.urlImage} alt='' />

                                </div>
                                <div className='w-full flex flex-row pt-2'>
                                    <div className="w-full text-center text-indigo-600 text-3xl font-semibold leading-9">{item.name}</div>
                                </div>
                            </div>
                        </a>

                    </div>
                ))
                }
            </div>
        </>
    )
}
