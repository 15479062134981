import { query, collection, getDocs, where } from "firebase/firestore/lite";
import { FirebaseDB } from "../firebase/config";


export const loadRepCountries = async (uid = '') => {
    if (!uid) throw new Error('El UID del usuario no existe');

    const q = query(
        collection(FirebaseDB, 'repCountry')
    );

    const docs = await getDocs(q);
    const countries = [];
    docs.forEach(doc => {
        countries.push({ id: doc.id, ...doc.data() });
    });
    return countries;
}

export const loadAllRepCountries = async () => {

    const q = query(
        collection(FirebaseDB, 'repCountry')
    );

    const docs = await getDocs(q);
    const countries = [];
    docs.forEach(doc => {
        countries.push({ id: doc.id, ...doc.data() });
    });
    return countries;
}

export const loadRepCountry = async (uid = '') => {
    if (!uid) throw new Error('El UID del usuario no existe');

    const q = query(
        collection(FirebaseDB, 'repCountry'),
        where('uid', '==', uid)
    );

    const docs = await getDocs(q);

    const country = [];
    docs.forEach(doc => {
        country.push({ id: doc.id, ...doc.data() });
    })

    return country;
}

