import React, { useEffect } from 'react'
import { RegisterLlegaMarket } from './RegisterLlegaMarket'
import { useForm } from 'react-hook-form';
import emailjs from '@emailjs/browser';
import Swal from 'sweetalert2';
import { useTranslation } from 'react-i18next'
import { FormControl, InputLabel, MenuItem, Select } from '@mui/material';
import { useSearchParams } from 'react-router-dom';

export const Contact = () => {
    const { t } = useTranslation();
    const [queryParameters] = useSearchParams();

    const { register, handleSubmit, setValue, control, formState: { errors } } = useForm({
        defaultValues: {
            displayName: "",
            phone: "",
            email: "",
            subject: "",
            message: ""
        }
    })

    useEffect(() => {
        if (window.scrollY > 300) {
            window.scrollTo({ top: 0, behavior: 'smooth' });
        }
    }, [])

    useEffect(() => {
        let comercio = queryParameters.get("comercio");
        if (comercio) {
            setValue('message', "Me gustaría afiliar el comercio: " + comercio);
            setValue('subject', t('contact.option4'))
        }
    }, [])

    const onSubmit = (data) => {
        if (data) {
            sendEmail(data);
        }
    }

    function sendEmail(data) {
        emailjs.send('service_k1iecnp', 'template_5xho742', data, 'srjso40pBJ9jqwxHz')
            .then(function (response) {
                showSuccess();
                limpiar();
            }, function (error) {
                showError();
            });

    }

    const limpiar = () => {
        setValue('displayName', "");
        setValue('phone', "");
        setValue('email', "");
        setValue('subject', "");
        setValue('message', "");
    }

    const showSuccess = () => {
        Swal.fire('Correo enviado correctamente', 'Ok', 'success');
    };

    const showError = () => {
        Swal.fire('Error en el envío', 'Error', 'Error');
    };

    return (
        <>
            <div className='w-full mih-h-[100px] bg-principal-blue'>
                <div className='flex flex-row w-full text-center pt-20 md:pt-20'>
                    <p className="w-full text-white text-5xl font-bold leading-normal">{t('contact.welcome')}</p>
                </div>
                <div className='flex flex-row w-full text-center pt-3 pb-20'>
                    <p className="w-full text-center text-white text-5xl font-medium leading-normal">{t('contact.description')}</p>
                </div>
            </div>
            <div className='bg-white pl-2 md:pl-20 pr-2 md:pr-20 w-full pb-14'>
                <div className='w-full flex flex-wrap'>
                    <div className='w-full md:w-1/2'>
                        <div className='flex flex-row w-full pt-20 md:pt-60'>
                            <p className="text-black text-4xl font-bold leading-relaxed">{t('contact.contact')}</p>
                        </div>
                        <div className='flex flex-row w-full pt-16'>
                            <div className='pt-2'>
                                <img className='w-6 h-6' src='../assets/contact/mapa.png' alt='' />
                            </div>
                            <div>
                                <p className="w-full text-left text-blue-950 text-2xl pl-2 font-normal leading-relaxed">12 Calle 5-42 / 13 Calle 5-31 Zona 9, Edificio<br />Ascend 5to Nivel, Oficina 501 - 502.</p>
                            </div>
                        </div>
                        <div className='flex flex-row w-full pt-16'>
                            <div className='pt-2'>
                                <img className='w-6 h-6' src='../assets/contact/telefono.png' alt='' />
                            </div>
                            <div>
                                <p className="text-blue-950 text-2xl pl-2 font-normal leading-relaxed">(502) 2221-4775</p>
                            </div>
                        </div>
                        <div className='flex flex-row w-full pt-16'>
                            <div className='pt-2'>
                                <img className='w-6 h-6' src='../assets/contact/sobre.png' alt='' />
                            </div>
                            <div>
                                <p className="text-blue-950 text-2xl pl-2 font-normal leading-relaxed">comercial@llega.com</p>
                            </div>
                        </div>
                        <div className='w-full flex flex-row pt-12'>
                            <img className='w-[535px] h-[471px]' src='../assets/images/mapa.png' alt='' />
                        </div>
                    </div>
                    <div className='w-full md:w-1/2 pt-12'>
                        <div className='w-full bg-[#303030] rounded-3xl pl-2 md:pl-16 pr-2 md:pr-16'>
                            <div className='flex flex-row w-full pt-16'>
                                <p className="w-full text-center text-white text-3xl font-extrabold leading-10">{t('contact.request')}</p>
                            </div>
                            <div className='w-full flex flex-row pt-12'>
                                <div className="text-white text-2xl font-semibold leading-10">{t('contact.name')}</div>
                            </div>
                            <div className='w-full flex flex-row pt-2'>
                                <input name='name' className="w-full h-12 bg-white rounded-full px-4" {...register('displayName', { required: true })}></input>
                            </div>
                            <div className='w-full flex flex-row pt-12'>
                                <div className="text-white text-2xl font-semibold leading-10" >{t('contact.phone')}</div>
                            </div>
                            <div className='w-full flex flex-row pt-2'>
                                <input className="w-full h-12 bg-white rounded-full px-4" {...register('phone', { required: true })}></input>
                            </div>
                            <div className='w-full flex flex-row pt-12'>
                                <div className="text-white text-2xl font-semibold leading-10">{t('contact.email')}</div>
                            </div>
                            <div className='w-full flex flex-row pt-2'>
                                <input className="w-full h-12 bg-white rounded-full px-4" {...register('email', { required: true })}></input>
                            </div>
                            <div className='w-full flex flex-row pt-12'>
                                <div className="text-white text-2xl font-semibold leading-10">{t('contact.subject')}</div>
                            </div>
                            <div className='w-full flex flex-row pt-2'>
                                <select className="text-black px-4 text-[14px]  leading-10 w-full rounded-full h-12"
                                    {...register('subject', { required: true })}>
                                    <option value={t('contact.option1')} >{t('contact.option1')}</option>
                                    <option value={t('contact.option2')}>{t('contact.option2')}</option>
                                    <option value={t('contact.option3')}>{t('contact.option3')}</option>
                                    <option value={t('contact.option4')}>{t('contact.option4')}</option>
                                </select>
                                {/* <FormControl className='w-full'>
                                    <InputLabel id="">Asunto</InputLabel>
                                    <Select
                                        labelId='subject'
                                        id='subject'
                                        label={t('contact.subject')}
                                        placeholder={t('contact.subject')}
                                        defaultValue=''
                                        inputProps={{ 'aria-label': 'Without label' }}
                                        // value={subject}

                                        {...register('subject', { required: true })}
                                    >
                                        <MenuItem value="Como afiliarme" >Como afiliarme a LlegaMarket?</MenuItem>
                                        <MenuItem value="Quiero más información">Quiero más información</MenuItem>
                                        <MenuItem value="Quiero ser contactado">Quiero ser contactado</MenuItem>
                                    </Select>
                                </FormControl> */}
                            </div>
                            {/* <div className='w-full flex flex-row pt-2'>
                                <input className="w-full h-12 bg-white rounded-full px-4" {...register('subject', { required: true })}></input>
                            </div> */}
                            <div className='w-full flex flex-row pt-12'>
                                <div className="text-white text-2xl font-semibold leading-10">{t('contact.message')}</div>
                            </div>
                            <div className='w-full flex flex-row pt-2'>
                                <textarea className="w-full bg-white rounded-3xl px-4 py-2" rows="8" {...register('message', { required: true })}></textarea>
                            </div>
                            <div className='w-full flex flex-row pt-16 justify-center'>
                                <button type='button' className="w-80 h-12 bg-[#6EEBE6] rounded-full" onClick={handleSubmit(onSubmit)}>{t('contact.send')}</button>
                            </div>

                            <div className='w-full flex flex-row pb-14'>
                                <p className="w-full text-white pt-16 text-sm font-semibold leading-tight">{t('contact.data_1')}<br />{t('contact.data_2')}</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/* <RegisterLlegaMarket /> */}
        </>
    )
}
