import { Card, CardContent, CardMedia, Typography } from '@mui/material'
import React, { useEffect, useState } from 'react'
import ModalVideo from 'react-modal-video'
import { useTranslation } from 'react-i18next'
import VideoCard from '../components/VideoCard'
import { useDispatch, useSelector } from 'react-redux'
import { startLoadingVideos } from '../store/video/thunks'
import FileCard from '../components/FileCard'

export const MeetUs = () => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const { videos } = useSelector(state => state.video);
    const [isOpen, setIsOpen] = useState(false);

    const openModal = () => {
        setIsOpen(true);
    }

    useEffect(() => {

        dispatch(startLoadingVideos());

    }, [])

    return (
        <>
            {/* <div className='w-full mt-20'>
                <div className='w-full'>
                    <div className='flex flex-row w-full justify-center pt-10 md:pt-20'>
                        <p className='w-full md:w-4/6 h-32 text-center text-black text-4xl lg:text-5xl font-normal leading-20'>Conoce mas acerca de nosotros</p>
                    </div>
                </div>
                <div className='w-full'>
                    <p className='text-center'>Conoce mas acerca de nuestros servicios</p>
                </div>
            </div> */}
            <div className='w-full mih-h-[100px] bg-principal-blue'>
                <div className='flex flex-row w-full text-center pt-20 md:pt-20 pb-20'>
                    <p className="w-full text-white text-5xl font-bold leading-normal">{t('videosSection.aboutus')}</p>
                </div>
                {/* <div className='flex flex-row w-full text-center pt-4 pb-20'>
                    <p className="w-full text-center text-white text-5xl font-medium leading-normal">Conoce mas sobre nosotros</p>
                </div> */}
            </div>
            <div className='w-full flex flex-wrap mt-20 mb-20 pl-4 pr-4 sm:pl-28 sm:pr-28 md:pl-32 md:pr-32 place-content-center'>
                <div className='w-full flex flex-row text-center justify-center pt-4'>
                    <Typography gutterBottom variant="h5" component="div" fontSize='2.5rem' className='text-center'>
                        {t('videosSection.title1')}
                    </Typography>
                </div>
                {/* <div className='w-full flex flex-row text-center justify-center'>
                    <Typography variant="body2" color="text.secondary" fontSize='0.813rem'>
                        Conoce mas sobre nuestros servicios.
                    </Typography>
                </div> */}
                {videos.filter(item => item.type === 'Video').map((item) => (
                    <div key={item.id} className='w-full place-content-center md:w-1/2 lg:w-1/3 xl:w-1/3 pt-4 pl-2 pr-2 pb-4 max-w-[360px]'>
                        <VideoCard
                            urlImage={item.urlImage}
                            title={item.title}
                            description={item.description}
                            urlVideo={item.urlVideo}
                            type={item.type}
                        />
                    </div>
                ))}
            </div>
            <hr />
            <div className='w-full flex flex-wrap mt-10 mb-20  pl-10 pr-10 sm:pl-40 sm:pr-40 place-content-center'>
                <div className='w-full flex flex-row text-center justify-center pt-4'>
                    <Typography gutterBottom variant="h5" component="div" fontSize='2.5rem' className='text-center'>
                        {t('videosSection.title2')}
                    </Typography>
                </div>
                {/* <div className='w-full flex flex-row text-center justify-center'>
                    <Typography variant="body2" color="text.secondary" fontSize='0.813rem'>
                        Conoce mas sobre nuestros servicios.
                    </Typography>
                </div> */}
                {videos.filter(item => item.type === 'Archivo').map((item) => (
                    <div key={item.id} className='w-full  md:w-1/2 lg:w-1/2 xl:w-1/3 pt-4 pl-2 pr-2 pb-5 hover:transition ease-in-out delay-150 hover:-translate-y-1 hover:scale-105 duration-300 max-w-[360px]'>
                        <FileCard
                            urlImage={item.urlImage}
                            title={item.title}
                            description={item.description}
                            urlVideo={item.urlVideo}
                            type={item.type}
                        />
                    </div>
                ))}
            </div>
        </>
    )
}
