import React, { Suspense, useRef, useEffect, useState } from 'react'
import { useForm } from 'react-hook-form';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next'
import Swal from 'sweetalert2';
import { Box, Button, CircularProgress } from '@mui/material';
import { fetchData } from '../utils/fetchData';
import { useDispatch, useSelector } from 'react-redux';
import { setActivePayment } from '../store/Payment/paymentSlice';
import { startLoadingAllPayments, startNewPayment } from '../store/Payment/thunks';
import ReactToPrint from 'react-to-print';
import { PrintOutlined } from '@mui/icons-material';
import { startLoadingAllRepatriations } from '../store/Repatriations/thunks';
import { parse, add, isAfter, isEqual, isBefore } from 'date-fns';
import { startLoadingAllCompanies, startLoadingCompanies } from '../store/Companies/thunks';
import { startLoadingAllSellers } from '../store/seller/thunks';
import { apiInstance } from '../utils';
import emailjs from '@emailjs/browser';
import { startLoadingAllLlegaRepatriations } from '../store/llegaRepatriation/thunks';

// const apiData = fetchData("https://web.llega.com/spa/xpay/securew/ipay.asp?827|11a22b|IC1|101|contract|TestServiceDescription|8|B|")

export const RepatriationPayment = () => {
    const { t } = useTranslation();
    const ref = useRef();
    const dispatch = useDispatch();
    const { repatriations } = useSelector(state => state.repatriation);
    const { llegaRepatriations } = useSelector(state => state.llegaRepatriation);
    const { payments } = useSelector(state => state.payment);
    const { companies } = useSelector(state => state.companie);
    const { repCountries } = useSelector(state => state.repCountry);
    const { sellers } = useSelector(state => state.seller);
    // const data = apiData.read()
    // const { data, loading, error } = useFetch("https://web.llega.com/spa/xpay/securew/ipay.asp?827|11a22b|IC1|101|contract|TestServiceDescription|8|B|")
    const [loading, setLoading] = useState(false);
    const [consult, setConsult] = useState(true);
    const [paid, setPaid] = useState(false);
    const [voucher, setVoucher] = useState({});
    const [codigo, setCodigo] = useState('');
    const navigate = useNavigate();
    const [queryParameters] = useSearchParams();
    const [merchant, setMerchant] = useState();
    const [merchantCompanie, setMerchantCompanie] = useState('');
    const [fecha, setFecha] = useState(new Date());
    const [commerceName, setCommerceName] = useState('');
    const [sellerName, setSellerName] = useState('');
    const [token, setToken] = useState('');
    const [correoComercio, setCorreoComercio] = useState('');
    const [direccionRep, setDireccionRep] = useState('');
    const [direccionOgiginRep, setDireccionOriginRep] = useState('');
    const [nameRep, setNameRep] = useState('');
    const [loadingPromise, setLoadingPromise] = useState(false);

    useEffect(() => {
        dispatch(startLoadingAllRepatriations());
        dispatch(startLoadingAllPayments());
        dispatch(startLoadingAllCompanies());
        dispatch(startLoadingAllSellers());
        dispatch(startLoadingAllLlegaRepatriations());
    }, [])


    // useEffect(() => {
    //     if (companies) {
    //         let merchantCompanieTmp = companies.
    //     }
    // }, [companies])

    const { register, handleSubmit, setValue, control, formState: { errors } } = useForm({
        defaultValues: {
            userLlega: "",
            passLlega: "",
            contract: ""
        }
    })

    function sendEmail(data) {
        const {
            displayName,
            email,
            policyNo
        } = data;

        const newData = {
            displayName,
            email,
            policyNo
        }
        emailjs.send('service_k1iecnp', 'template_6ljwx0j', newData, 'srjso40pBJ9jqwxHz')
            .then(function (response) {
            }, function (error) {
            });

    }

    const handleCodigoChange = (event) => {
        const codigoIngresado = event.target.value;
        // Eliminar caracteres no numéricos y espacios
        const codigoLimpio = codigoIngresado.replace(/[^\d]/g, '');
        // Insertar un guion después de los primeros cuatro dígitos
        const codigoFormateado = codigoLimpio.replace(/^(\d{4})(\d*)$/, '$1-$2');
        setCodigo(codigoFormateado);
    };

    useEffect(() => {
        let userLlega = '';
        setFecha(new Date());

        if (repatriations && merchant) {

            const [
                ReferenceNo,
                ProductID,
                Total,
                Last4DigitCardNumber,
                ResponseCode,
                ResponseMsg,
                AuthNo
            ] = merchant.split('|');

            userLlega = repatriations.find(x => x.policyNo === ReferenceNo);

            if (userLlega && ResponseCode === 'APR') {
                setCodigo(ReferenceNo);

                // setCodigo(ProductID);
                const newData = {
                    ReferenceNo,
                    ProductID,
                    Total,
                    Last4DigitCardNumber,
                    ResponseCode,
                    ResponseMsg,
                    AuthNo,
                    userLlega: ''
                }

                setVoucher(newData);

                const newEmailData = {
                    displayName: nameRep,
                    email: correoComercio,
                    address: direccionRep,
                    addressOrigin: direccionOgiginRep,
                    policyNo: ProductID
                }
                // console.log('newEmailData', newEmailData);

                if (newEmailData) {
                    sendEmail(newEmailData);
                }

                let tmpPayment = payments.filter(x => x.ProductID === ProductID && x.AuthNo === AuthNo);

                if (tmpPayment?.length < 1) {
                    dispatch(setActivePayment({ newData }));
                    if (newData) {
                        onSaveNewPayment(newData);
                    }

                    setPaid(true);
                    showSuccess();
                } else {
                    setPaid(false);
                    showErrorAuthNo();
                }
            } else {

                if (ResponseMsg === 'no sufficient funds/decline') {
                    showErrorFounds();
                } else {
                    if (ResponseMsg === 'invalid cardnumber (no such number)') {
                        showErrorInvalidCard();
                    } else {
                        showErrorCallback();
                    }
                }
            }
        }
    }, [repatriations])

    useEffect(() => {
        const urlParams = new URLSearchParams(window.location.search);
        const parameters = urlParams.get('Params');
        // http://localhost:3000/#/payment?merchanturl=1234|2024-11660|8|1234|APR|Success|12345|
        // https://llegamarket.com/#/payment?Params=101|2024-11664|8|9448|APR|APPROVED|005464|pedro|
        // http://localhost:3000/#/payment?Params=101|2024-11664|8|9448|APR|APPROVED|005464|pedro|
        //https://llegamarket.com/#/payment?Params=101|2024-11664|8|6188|DEC|no%20sufficient%20funds/decline|||
        // https://llegamarket.com/#/payment?Params=101|2024-11664|8||DEC|invalid%20cardnumber%20(no%20such%20number)|||
        //https://llegamarket.com/#/payment?Params=101|2024-11664|8||DEC|no%20such%20issuer/decline|||

        //https://llegamarket.com/#/payment?Params=101|2024-11668|100|3608|APR|APPROVED|005467|Lhogan%20Jose|
        let userLlega = '';
        let param = queryParameters.get("Params");

        if (param) {
            const [
                ReferenceNo,
                ProductID,
                Total,
                Last4DigitCardNumber,
                ResponseCode,
                ResponseMsg,
                AuthNo
            ] = param.split('|');
            let userLlega = [];

            setMerchant(param);

        } else {
            console.log('no encontró el parametro')
            // showErrorPayment();
        }

    }, [navigate])

    const onSaveNewPayment = (data) => {
        dispatch(startNewPayment(data));
    }

    // const validateDate = (inputDate) => {
    //     console.log('inputDate', inputDate);
    //     const currentDate = new Date();
    //     // const initialDate = new Date(inputDate);
    //     // console.log('initialDate', initialDate);
    //     const inputParsed = parse(inputDate, 'dd/MM/yyyy', new Date());
    //     console.log('inputParsed', inputParsed);
    //     const maxAllowedDate = add(inputParsed, { months: 1, days: 3 });
    //     console.log('maxAllowedDate', maxAllowedDate);
    //     if (isAfter(currentDate, maxAllowedDate)) {
    //         return false;
    //     } else {
    //         return true;
    //     }
    // }

    const validateContractDateAnual = (inputDate, totalPayments) => {
        const currentDate = new Date();
        const inputParsed = parse(inputDate, 'dd/MM/yyyy', new Date());
        const yearhNextPayment = add(inputParsed, { years: totalPayments });
        const maxAllowedDate = add(inputParsed, { years: parseInt(totalPayments), days: 4 });

        if (isBefore(currentDate, yearhNextPayment) || isEqual(currentDate, yearhNextPayment)) {
            return true;
        }

        if (isAfter(currentDate, yearhNextPayment)
            && (isBefore(currentDate, maxAllowedDate) || (isEqual(currentDate, maxAllowedDate)))) {
            return true;
        } else {
            return false;
        }

    }

    const validateContractDate = (inputDate, totalPayments) => {
        const currentDate = new Date();
        const inputParsed = parse(inputDate, 'dd/MM/yyyy', new Date());
        // console.log('inputParsed', inputParsed);
        const monthNextPayment = add(inputParsed, { months: totalPayments });
        const maxAllowedDate = add(inputParsed, { months: parseInt(totalPayments), days: 4 });

        if (isBefore(currentDate, monthNextPayment) || isEqual(currentDate, monthNextPayment)) {
            return true;
        }

        if (isAfter(currentDate, monthNextPayment)
            && (isBefore(currentDate, maxAllowedDate) || (isEqual(currentDate, maxAllowedDate)))) {
            return true;
        } else {
            return false;
        }

        // if (isAfter(currentDate, maxAllowedDate)) {
        //     return false;
        // } else {
        //     return true;
        // }
    }

    const onConsult = (formData) => {
        console.log('entra a consulta');
        let repatriationTmp = repatriations.filter(x => x.policyNo == codigo);
        let llegaRepatriationTmp = llegaRepatriations.filter(x => x.ReferenceNo == codigo);
        console.log('llegaRepatriationTmp', llegaRepatriationTmp)
        // console.log('repatriationTmp', repatriationTmp);
        if (repatriationTmp.length < 1 && llegaRepatriationTmp.length < 1) {
            showErrorContractExist();
            return;
        }
        let CompanyInfo = '';
        let addressMail = '';
        let nameMail = '';
        let addressOriginMail = '';
        let companyTmp = '';
        let merchantId = '';
        let token = '';
        let correo = '';
        let sellerTmp = '';
        let sellerNameTmp = '';
        if (repatriationTmp.length >= 1) {
            CompanyInfo = repatriationTmp[0]?.companyName;
            addressMail = repatriationTmp[0]?.address;
            nameMail = repatriationTmp[0]?.displayName;
            addressOriginMail = repatriationTmp[0]?.addressOrigin;
            companyTmp = companies.filter(x => x.name === CompanyInfo);
            merchantId = companyTmp[0]?.merchantId;
            token = companyTmp[0]?.token;
            correo = companyTmp[0]?.email;
            sellerTmp = sellers.filter(x => x.id === repatriationTmp[0]?.seller[0]);
        }
        if (llegaRepatriationTmp.length >= 1) {
            CompanyInfo = 'Llega Express';
            addressMail = '';
            nameMail = '';
            addressOriginMail = '';
        }
        setDireccionRep(addressMail);
        setDireccionOriginRep(addressOriginMail);
        setNameRep(nameMail);
        setMerchantCompanie(merchantId);
        setToken(token);
        setCorreoComercio(correo);
        setCommerceName(CompanyInfo);
        if (repatriationTmp.length >= 1) {
            sellerNameTmp = sellerTmp[0].sellerName;
        }
        console.log('longitud', llegaRepatriationTmp.length);
        if (llegaRepatriationTmp.length >= 1) {
            sellerNameTmp = 'Llega Express';
        }
        console.log('asigna vendedor', sellerNameTmp);
        setSellerName(sellerNameTmp)
        setConsult(false);
        // let companyTmp = repCountries.filter(x => x.id === repatriationTmp[0].companyId[0]);
        // console.log('companyTmp', companyTmp);
    }

    const onSubmit = async (formData) => {
        // Este es el correcto de Llega Market
        setLoading(true);
        //PRODUCCION
        // const merchantId = '827';
        const merchantId = merchantCompanie;
        const password = '11a22b';

        //DESARROLLO
        // const merchantId = '154';
        // const password = '11a22b33';

        const pid = 'IC2';
        const referenceNo = '101';

        let repatriationTmp = repatriations.filter(x => x.policyNo == codigo);
        let llegaRepatriationTmp = llegaRepatriations.filter(x => x.ReferenceNo == codigo);

        // console.log('repatriationTmp', repatriationTmp);
        let amount = '';
        let plan = '';
        let creationDate = '';
        let comercio = '';

        // console.log('payments', payments);
        let paymentsTmp = payments.filter(pay => pay.ProductID === codigo);

        if (repatriationTmp.length > 0) {
            let companyTmp = companies.filter(x => x.id === repatriationTmp[0].companyId[0]);
            let companyId = companyTmp.id;
            let emailComercio = companyTmp.emailComercio;
            amount = repatriationTmp[0].amount;
            plan = repatriationTmp[0].plan;
            creationDate = repatriationTmp[0].creationDate;
            comercio = repatriationTmp[0].companyName;

            if (comercio === 'Llega Express') {
                showErrorLlegaExpress();
                setLoading(false);
                return;
            }

            let totalPayments = paymentsTmp.length;
            let api_url = 'llegamarket/consultarepatriacion';
            let body = {
                MerchantID: merchantCompanie,
                Token: token,
                PolicyNumber: codigo
            }
            // let body = {
            //     MerchantID: '154',
            //     Token: 'i0mQHBMqDxa0UNgx',
            //     PolicyNumber: codigo // '2024-11675'
            // }
            setLoadingPromise(true);
            let promise = new Promise((resolve, reject) => {

                const response = apiInstance.post(api_url, body).then((response) => {
                    resolve(response.data);
                }).catch((error) => {
                    console.log('error', error);
                    showErrorResponse();
                    setLoadingPromise(false);
                    setLoading(false);
                    return;
                });

            })

            let result = await promise;
            setLoadingPromise(false);
            let expirationDate = result.Expirationdate;
            let agreementStatus = result.AgreementStatus;

            let isValidDate = true;

            if (result.ErrorCode !== 0) {
                showErrorResponse();
                return;
            }


            if (agreementStatus === 'Cancelled') {
                isValidDate = false;
                Swal.fire({
                    title: `"Contrato ${plan} expirado, se recomienda crear nuevo contrato. ¿Deseas ser redirigido a la creación de contrato de LlegaMarket?"`,
                    showDenyButton: true,
                    confirmButtonText: "Sí",
                    denyButtonText: `No`
                }).then((result) => {
                    if (result.isConfirmed) {
                        if (plan === 'Anual Plus') {
                            navigate('/repatriationform?id=3');
                        } else {
                            if (plan === 'Anual') {
                                navigate('/repatriationform?id=1');
                            } else {
                                navigate('/repatriationform?id=1');
                            }
                        }

                    } else if (result.isDenied) {
                        setLoading(false);
                        return;
                    }
                });
                setLoading(false);
                return;
            }

            if (agreementStatus !== 'Active') {
                isValidDate = false;
            }

            // if (plan === 'Anual Plus' || plan === 'Anual') {
            //     isValidDate = validateContractDateAnual(creationDate, totalPayments);
            // } else {
            //     isValidDate = validateContractDate(creationDate, totalPayments);
            // }

            let planDescription = 'Servicio Repatriacion';

            if (plan) {
                planDescription = 'Repatriacion ' + plan;
            }

            const params = {
                MerchantID: merchantId,
                Password: password,
                PID: pid,
                ReferenceNo: codigo,
                ProductID: referenceNo,
                Description: planDescription,
                Total: amount,
                BackAction: 'B'
            };

            if (isValidDate) {

                const url = `https://web.llega.com/spa/xpay/securew/ipay.asp?${Object.entries(params).map(([key, value]) => `${value}`).join('|') + '|'}`;
                // const url = `https://test.bgpay.me/spa/xpay/securew/ipay.asp?${Object.entries(params).map(([key, value]) => `${value}`).join('|') + '|'}`;
                window.location.href = url;
            } else {
                Swal.fire({
                    title: `"Contrato ${plan} expirado, se recomienda crear nuevo contrato. ¿Deseas continuar con el pago?"`,
                    showDenyButton: true,
                    confirmButtonText: "Sí",
                    denyButtonText: `No`
                }).then((result) => {
                    if (result.isConfirmed) {
                        const url = `https://web.llega.com/spa/xpay/securew/ipay.asp?${Object.entries(params).map(([key, value]) => `${value}`).join('|') + '|'}`;
                        // const url = `https://test.bgpay.me/spa/xpay/securew/ipay.asp?${Object.entries(params).map(([key, value]) => `${value}`).join('|') + '|'}`;
                        window.location.href = url;
                    } else if (result.isDenied) {
                        if (plan === 'Anual Plus') {
                            navigate('/repatriationform?id=3');
                        } else {
                            if (plan === 'Anual') {
                                navigate('/repatriationform?id=1');
                            } else {
                                navigate('/repatriationform?id=1');
                            }
                        }

                    }
                });
                setLoading(false);
            }


        } else {
            if (llegaRepatriationTmp.length > 0) {
                // console.log('si llega a validacion llegaExpress');
                // showErrorLlegaExpress();
                // setLoading(false);
                // setConsult(true);
                // return;

                let api_url = 'llegamarket/consultarepatriacion';
                let body = {
                    MerchantID: '827',
                    Token: 'fKaDylJeBTi61v88',
                    PolicyNumber: codigo
                }
                // let body = {
                //     MerchantID: '154',
                //     Token: 'i0mQHBMqDxa0UNgx',
                //     PolicyNumber: codigo // '2024-11675'
                // }
                setLoadingPromise(true);
                let promise = new Promise((resolve, reject) => {

                    const response = apiInstance.post(api_url, body).then((response) => {
                        resolve(response.data);
                    }).catch((error) => {
                        console.log('error', error);
                        showErrorResponse();
                        setLoadingPromise(false);
                        setLoading(false);
                        return;
                    });

                })

                let result = await promise;

                if (result === null || result === undefined) {
                    setLoading(false);
                    return;
                }
                console.log('result', result);
                setLoadingPromise(false);
                let expirationDate = result.Expirationdate;
                let agreementStatus = result.AgreementStatus;
                console.log('estado poliza', agreementStatus);
                let isValidDate = true;

                if (result.ErrorCode !== 0) {
                    showErrorResponse();
                    setConsult(true);
                    setLoading(false);
                    return;
                }

                if (agreementStatus === 'Active') {
                    showErrorLlegaExpress();
                    setConsult(true);
                    setLoading(false);
                    return;
                }


                if (agreementStatus === 'Cancelled') {
                    isValidDate = false;
                    Swal.fire({
                        title: `"El Contrato  ${plan} fué creado por medio de Llega Express y ha expirado, se recomienda crear nuevo contrato. ¿Deseas ser redirigido a la creación de contrato de Llega Market?"`,
                        showDenyButton: true,
                        confirmButtonText: "Sí",
                        denyButtonText: `No`
                    }).then((result) => {
                        if (result.isConfirmed) {
                            if (plan === 'Anual Plus') {
                                navigate('/repatriationform?id=3');
                            } else {
                                if (plan === 'Anual') {
                                    navigate('/repatriationform?id=1');
                                } else {
                                    navigate('/repatriationform?id=1');
                                }
                            }
                        } else if (result.isDenied) {
                            setLoading(false);
                            return;
                        }
                    });
                    setLoading(false);
                    return;
                }
                if (agreementStatus === '') {
                    showErrorStatus();
                    setConsult(true);
                    setLoading(false);
                }
            } else {
                Swal.fire({
                    title: "El contrato ingresado no existe. ¿Deseas ser redirigido a la sección de creación de contrato?",
                    showDenyButton: true,
                    confirmButtonText: "Sí",
                    denyButtonText: `No`
                }).then((result) => {
                    if (result.isConfirmed) {
                        navigate('/repatriationform');
                    } else if (result.isDenied) {
                        console.log('Error');
                        setLoading(false);
                    }
                });
            }
        }
    }

    const showSuccess = () => {
        Swal.fire('Info', 'Información de Pago recibida correctamente, puedes guardar tu comprobante.', 'success');
    };

    const showErrorContractExist = () => {
        Swal.fire('Error', 'El contrato no existe.', 'error');
    };

    const showErrorCallback = () => {
        Swal.fire('Error', 'La operación de Pago no fué aprobada.', 'error');
    };

    const showErrorAuthNo = () => {
        Swal.fire('Error', 'El número de autorización ya existe para este pago.', 'error');
    };

    const showErrorConditions = () => {
        Swal.fire('Error', 'Debe ingresar todos los datos para continuar', 'error');
    };

    const showError = () => {
        Swal.fire('Error en el envío', 'Error', 'error');
    };

    const showErrorPayment = () => {
        Swal.fire('Error', 'Error al procesar el pago', 'error');
    };

    const showErrorLlegaExpress = () => {
        Swal.fire('Información', 'El pago debe realizarse desde la aplicación de Llega Express', 'info');
    };

    const showErrorFounds = () => {
        Swal.fire('Error', 'Fondos insuficientes para realizar la transacción.', 'error');
    };

    const showErrorInvalidCard = () => {
        Swal.fire('Error', 'La cuenta o teléfono ingresada no es válida.', 'error');
    };

    const showErrorResponse = () => {
        Swal.fire('Error', 'No se logró obtener el estado del contrato.', 'error');
    };

    const showErrorStatus = () => {
        Swal.fire('Error', 'No se logró obtener información del contrato.', 'error');
    }

    return (
        <>
            <div className='w-full min-h-[500px] pt-10 bg-white justify-center align-middle text-center pb-20'>

                {/* <Suspense fallback={<div>Loading...</div>}>
                    <ul>
                        {data?.map((item, index) => (
                            <li key={index}>{item}</li>
                        ))}
                    </ul>
                </Suspense> */}
                <div className='w-full flex flex-row justify-between align-middle text-center'>
                    <div className='w-full md:w-5/12 pt-12 pl-12 pb-20 pr-10'>
                        <div className='w-full bg-[#303030] rounded-3xl pl-2 md:pl-16 pr-2 md:pr-16'>
                            <div className='flex flex-row w-full pt-12'>
                                <p className="w-full text-center text-white text-3xl font-extrabold leading-10">{t('repatriation.payment')}</p>
                            </div>
                            {commerceName &&
                                <div className='flex flex-row w-full pt-8'>
                                    <p className="w-full text-left text-white text-lg font-extrabold leading-10">{t('repatriation.commerceSeller') + ': ' + commerceName}</p>
                                </div>
                            }
                            {/* {sellerName &&
                                <div className='flex flex-row w-full pt-4'>
                                    <p className="w-full text-left text-white text-lg font-extrabold leading-10">{t('repatriation.vendor') + ': ' + sellerName}</p>
                                </div>
                            } */}
                            <div className='w-full flex flex-row pt-4'>
                                <div className="text-white text-xl font-semibold leading-10">{t('repatriation.contract')}*</div>
                            </div>
                            <div className='w-full flex flex-row pt-2'>
                                <input name='name' className="w-full h-12 bg-white rounded-full px-4" placeholder='Ingresa no. de contrato, Ejemplo: 2024-11660' value={codigo} onChange={handleCodigoChange}></input>
                            </div>
                            {/* <hr className='mt-6' />

                            <div className='w-full flex flex-row'>
                                <div className='w-1/2'>
                                    <div className='w-full flex flex-row pt-12 pr-2'>
                                        <div className="text-white text-xl font-semibold leading-10">{t('repatriation.userLlega')}*</div>
                                    </div>
                                    <div className='w-full flex flex-row pt-2 pr-2'>
                                        <input name='email' className="w-full h-12 bg-white rounded-full px-4" {...register('userLlega', { required: true })}></input>
                                    </div>
                                </div>
                                <div className='w-1/2'>
                                    <div className='w-full flex flex-row pt-12 pr-2'>
                                        <div className="text-white text-xl font-semibold leading-10">{t('repatriation.passLlega')}*</div>
                                    </div>
                                    <div className='w-full flex flex-row pt-2 pr-2'>
                                        <input name='pass' type='password' className="w-full h-12 bg-white rounded-full px-4" {...register('passLlega', { required: true })}></input>
                                    </div>
                                </div>
                            </div> */}
                            {consult &&
                                <div className='w-full flex flex-row pt-12 justify-center pb-10'>
                                    <button type='button' className="w-80 h-12 bg-cyan-300 rounded-full" onClick={handleSubmit(onConsult)}>{t('repatriation.consult')}</button>
                                </div>
                            }
                            {!loading && !consult &&
                                <div className='w-full flex flex-row pt-12 justify-center pb-10'>
                                    <button type='button' className="w-80 h-12 bg-cyan-300 rounded-full" onClick={handleSubmit(onSubmit)} disabled={loadingPromise} loading={loadingPromise}>{t('repatriation.add')}</button>
                                </div>
                            }
                            {loading &&
                                <div className='pt-10 pb-10 place-content-center justify-center'>
                                    <Box sx={{ display: 'flex w-full' }}>
                                        <CircularProgress />
                                    </Box>
                                </div>
                            }
                        </div>
                    </div>

                    {paid && voucher &&
                        <>

                            {/* <div>
                                <Button type='' />
                            </div> */}
                            <div className='w-5/12 pt-8 mr-10 h-[675px] pl-20' >
                                <div className='w-full pb-10'>
                                    <ReactToPrint
                                        bodyClass='print-agreement'
                                        content={() => ref.current}
                                        trigger={() => (
                                            <Button type='button' variant='outlined' startIcon={<PrintOutlined />}>
                                                Print
                                            </Button>
                                        )}

                                    />
                                </div>

                                <div className='w-full border  border-lg' ref={ref}>
                                    <div className='w-full flex place-content-center justify-center items-center pt-2'>
                                        <img className='w-44 h-auto justify-center' src='../assets/llegamarket_black.png' alt='' />
                                    </div>
                                    <div className='w-full flex flex-row'>
                                        <p className='w-full text-center'>Información de Pago</p>
                                    </div>
                                    <div className='w-full flex flex-row pt-4 justify-start text-left pl-20'>
                                        <p className='w-1/2 flex justify-start'>Fecha/Hora:</p>
                                        <p className='w-1/2 flex justify-start text-md pl-4'>{fecha.toLocaleDateString() + fecha.toLocaleTimeString()}</p>
                                    </div>
                                    <div className='w-full flex flex-row pt-4 justify-start text-left pl-20'>
                                        <p className='w-1/2 flex justify-start'>yPayMe No:</p>
                                        <p className='w-1/2 flex justify-start text-md pl-4'>{'**** **** **** ' + voucher.Last4DigitCardNumber}</p>
                                    </div>
                                    <div className='w-full flex flex-row pt-4 justify-start text-left pl-20'>
                                        <p className='w-1/2 flex justify-start'>Transacción:</p>
                                        <p className='w-1/2 flex justify-start text-md pl-4'>Repatriacion Mensual</p>
                                    </div>
                                    <div className='w-full flex flex-row pt-4 justify-start text-left pl-20'>
                                        <p className='w-1/2 flex justify-start'>No Autorización:</p>
                                        <p className='w-1/2 flex justify-start text-md pl-4'>{voucher.AuthNo}</p>
                                    </div>
                                    <div className='w-full flex flex-row pt-4 justify-start text-left pl-20'>
                                        <p className='w-1/2 flex justify-start'>Contrato Número:</p>
                                        <p className='w-1/2 flex justify-start text-md pl-4'>{voucher.ReferenceNo}</p>
                                    </div>
                                    <div className='w-full flex flex-row pt-4 justify-start text-left pl-20 pb-10'>
                                        <p className='w-1/2 flex justify-start'>Monto:</p>
                                        <p className='w-1/2 flex justify-start text-md pl-4'>{voucher.Total}</p>
                                    </div>

                                    <hr />
                                    <p className='w-full text-center pt-10'>Recibido</p>
                                    <p className='w-full text-center pt-2 pb-10'>Llega Market</p>
                                    {/* <div className='w-full pt-4 pb-10'>
                                        <p className='w-full justify-center'>Llega Market</p>
                                    </div> */}

                                </div>
                            </div>

                            <div className='w-1/12'></div>
                        </>
                    }

                </div>
            </div>
        </>
    )
}
