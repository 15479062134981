import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    page: 0,
    isLoading: false,
    messageSaved: '',
    isSaving: false,
    repatriations: [],
    repatriation: [],
    active: null
}

export const repatriationSlice = createSlice({
    name: 'repatriation',
    initialState,
    reducers: {
        savingNewRepatriation: (state) => {
            state.isSaving = true;
        },
        addNewEmptyRepatriation: (state, action) => {
            const newRepatriation = action.payload;
            state.repatriations.push(newRepatriation);
            state.isSaving = false;
        },
        setActiveRepatriation: (state, action) => {
            state.active = action.payload;
            state.messageSaved = '';
        },
        setRepatriations: (state, action) => {
            state.repatriations = action.payload;
        },
        setRepatriation: (state, action) => {
            state.repatriation = action.payload;
        },
        setSaving: (state) => {
            state.isSaving = true;
            state.messageSaved = '';
        },
        updateRepatriation: (state, action) => {
            state.isSaving = false;
            state.repatriations = state.repatriations.map(repatriation => {
                if (repatriation.id === action.payload.id) {
                    return action.payload;
                }
                return repatriation;
            })
            state.messageSaved = `${action.payload.title}, actualizada correctamente`
        },
        deleteRepatriationById: (state, action) => {

        }
    }
})

export const {
    savingNewRepatriation,
    addNewEmptyRepatriation,
    setActiveRepatriation,
    setRepatriations,
    setRepatriation,
    setSaving,
    updateRepatriation,
    deleteRepatriationById
} = repatriationSlice.actions