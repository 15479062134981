import { collection, count, deleteDoc, doc, setDoc } from "firebase/firestore/lite";
import { FirebaseDB } from "../../firebase/config";
import { addNewEmptyCountry, deleteCountryById, savingNewCountry, setActiveCountry, setCountries, setCountry, setSaving, updateCountry } from "./countrySlice";
import { loadAllCountries, loadCountries, loadCountry } from "../../helpers/loadCountries";


export const startNewClearCountrie = (countrie) => {
    return async (dispatch, getState) => {
        dispatch(savingNewCountry());
        const { uid } = getState().auth;

        const {
            countryName,
            status
        } = countrie;

        const newCountry = {
            uid,
            countryName,
            status,
            creationDate: new Date().getTime(),
            updateDate: new Date().getTime()
        }

        const newDoc = doc(collection(FirebaseDB, `countrie`))
        await setDoc(newDoc, newCountry);
        newCountry.id = newDoc.id;
        dispatch(addNewEmptyCountry(newCountry));
        dispatch(setActiveCountry(newCountry));

    }
}

export const startNewCountry = (country) => {
    return async (dispatch, getState) => {
        dispatch(savingNewCountry());

        const { uid } = getState().auth;
        const {
            countryName,
            status,
        } = country;

        const newCountry = {
            uid,
            countryName,
            status,
            creationDate: new Date().getTime(),
            updateDate: new Date().getTime()
        }

        const newDoc = doc(collection(FirebaseDB, `country`));
        await setDoc(newDoc, newCountry);
        newCountry.id = newDoc.id;

        dispatch(addNewEmptyCountry(newCountry));
        dispatch(setActiveCountry(newCountry));

    }
}

export const startLoadingCountries = () => {
    return async (dispatch, getState) => {
        const { uid } = getState().auth;

        if (!uid) throw new Error('El UID del usuario no existe');
        const countries = await loadCountries(uid);
        dispatch(setCountries(countries));
    }
}

export const startLoadingAllCountries = () => {
    return async (dispatch, getState) => {
        // const { uid } = getState().auth;

        // if (!uid) throw new Error('El UID del usuario no existe');
        const countries = await loadAllCountries();
        dispatch(setCountries(countries));
    }
}

export const startLoadingCountry = () => {
    return async (dispatch, getState) => {
        const { uid } = getState().auth;

        if (!uid) throw new Error("El UID del usuario no existe");
        const country = await loadCountry(uid);
        dispatch(setCountry(country));
    }
}

export const startDeletingCountry = () => {
    return async (dispatch, getState) => {
        const { uid } = getState().auth;
        const { active: country } = getState().country;

        const docRef = doc(FirebaseDB, `country/${country.id}`);
        await deleteDoc(docRef);

        dispatch(deleteCountryById(country.id));
    }
}

export const startSaveCountry = () => {
    return async (dispatch, getState) => {

        dispatch(setSaving());

        const { uid } = getState().auth;
        const { active: country } = getState().country;

        const countryToFireStore = { ...country };
        delete countryToFireStore.id;

        const docRef = doc(FirebaseDB, `country/${country.id}`)
        await setDoc(docRef, countryToFireStore, { merge: true });

        dispatch(updateCountry(country));
    }
}