import React from 'react'
import { Link } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

export const Footer = () => {
    const { t } = useTranslation();

    return (
        <>
            <div className='w-full flex flex-row bg-[#4F4170] pt-16 pb-12 pl-4 md:pl-16 lg:pl-28'>
                <div className='w-full md:w-2/5'>
                    <div className='w-full flex flex-row h-16'>
                        <a href='/'><img src='../assets/logo_footer.png' alt='' /></a>
                    </div>
                    <div className='w-full flex flex-row pt-4'>
                        <ul className='w-full flex flex-wrap place-content-center justify-between text-white'>
                            <li className='hover:text-[#6EEBE6]'><Link to='/'>{t('header.begin')}</Link></li>
                            <li className='hover:text-[#6EEBE6]'><Link to='/market'>{t('header.market')}</Link></li>
                            <li className='hover:text-[#6EEBE6]'><Link to='/repatriation'>{t('header.repatriation')}</Link></li>
                            <li className='hover:text-[#6EEBE6]'><Link to="/meetus" >{t('header.meetus')}</Link></li>
                            <li className='hover:text-[#6EEBE6]'><Link to='/questions'>{t('header.question')}</Link></li>
                            <li className='hover:text-[#6EEBE6]'><Link to='/contact'>{t('header.contact')}</Link></li>
                        </ul>
                    </div>
                    <div className='flex flex-row w-full pt-4 h-20'>
                        <p className="text-gray-300 text-sm font-normal leading-normal">© 2023 Llega Market. Reservados todos los derechos.</p>
                    </div>
                </div>
                <div className='w-full md:w-1/5'></div>
                <div className='w-full md:w-2/5 mr-4 md:mr-20'>
                    <div className='flex flex-row w-full justify-start text-left text-white'>
                        <img className='w-6 h-6' src='../assets/icons/Pin_fill.png' alt='' />
                        <p className="pl-2 text-white text-[10px] md:text-base font-normal leading-relaxed">12 Calle 5-42 / 13 Calle 5-31 Zona 9, Edificio Ascend 5to Nivel, Oficina 501 - 502.</p>
                    </div>
                    <div className='flex flex-row w-full justify-start text-left text-white mt-4'>
                        <img className='w-6 h-6' src='../assets/icons/Phone_fill.png' alt='' />
                        <p className="pl-2 text-white text-[10px] md:text-base font-normal leading-relaxed">(502) 2221-4775</p>
                    </div>
                    <div className='flex flex-row w-full justify-start text-left text-white mt-6 ml-2 space-x-2'>
                        {/* <div className='flex w-full pt-1 justify-end pr-2 space-x-2'> */}
                        <a href='https://instagram.com/llegamarketgt502?igshid=MzRlODBiNWFlZA==' target="_blank"><img className='w-8 h-8' src='../assets/icons/instagram.png' alt='' /></a>
                        <a href='https://www.facebook.com/profile.php?id=100092551129689&mibextid=ZbWKwL' target="_blank"><img className='w-8 h-8' src='../assets/icons/facebook.png' alt='' /></a>
                        <a href='http://wa.me/+50230534328' target="_blank"><img className='w-8 h-8' src='../assets/icons/whatsapp.png' alt='' /></a>
                        {/* </div> */}
                    </div>
                </div>
            </div>
        </>
    )
}
