import { collection, deleteDoc, doc, setDoc } from 'firebase/firestore/lite';
import { FirebaseDB } from "../../firebase/config";
import { addNewEmptyCategorie, deleteCategorieById, savingNewCategorie, setActiveCategorie, setCategorie, setCategories, setSaving, updateCategorie } from "./categoriesSlice"
import { loadAllCategories, loadCategorie, loadCategories } from "../../helpers/loadCategories";


export const startNewClearcategorie = (categorie) => {
    return async (dispatch, getState) => {
        dispatch(savingNewCategorie());
        const { uid } = getState().auth;

        const {
            name,
            description,
            urlImage,
            urlPicture
        } = categorie

        const newCategorie = {
            uid,
            name,
            description,
            urlImage,
            urlPicture,
            serviceOrder: 20,
            creationDate: new Date().getTime(),
            updateDate: new Date().getTime()
        }

        const newDoc = doc(collection(FirebaseDB, `categorie`))
        await setDoc(newDoc, newCategorie);
        newCategorie.id = newDoc.id;
        dispatch(addNewEmptyCategorie(newCategorie));
        dispatch(setActiveCategorie(newCategorie));

    }
}

export const startNewCategorie = (categorie) => {

    return async (dispatch, getState) => {
        dispatch(savingNewCategorie());

        const { uid } = getState().auth;
        const {
            name,
            description,
            urlImage,
            urlPicture
        } = categorie;

        const newCategorie = {
            uid: uid,
            name,
            description,
            urlImage,
            urlPicture,
            serviceOrder: 20,
            creationDate: new Date().getTime(),
            updateDate: new Date().getTime()
        }

        const newDoc = doc(collection(FirebaseDB, `categorie`))
        await setDoc(newDoc, newCategorie);
        newCategorie.id = newDoc.id;

        dispatch(addNewEmptyCategorie(newCategorie));
        dispatch(setActiveCategorie(newCategorie));

    }
}

export const startLoadingCategories = () => {
    return async (dispatch, getState) => {
        const { uid } = getState().auth;

        if (!uid) throw new Error('El UID del usuario no existe');
        const categories = await loadCategories(uid);
        dispatch(setCategories(categories));
    }
}

export const startLoadingCategorie = () => {
    return async (dispatch, getState) => {
        const { uid } = getState().auth;

        if (!uid) throw new Error("El UID del usuario no existe");
        const categorie = await loadCategorie(uid);
        dispatch(setCategorie(categorie));
    }
}

export const startLoadingAllCategories = () => {
    return async (dispatch, getState) => {

        const categorie = await loadAllCategories();
        dispatch(setCategories(categorie));
    }
}

export const startDeletingCategorie = () => {
    return async (dispatch, getState) => {
        const { uid } = getState().auth;
        const { active: categorie } = getState().categorie;

        const docRef = doc(FirebaseDB, `categorie/${categorie.id}`);
        await deleteDoc(docRef);

        dispatch(deleteCategorieById(categorie.id));
    }
}

export const startSaveCategorie = () => {
    return async (dispatch, getState) => {

        dispatch(setSaving());

        const { uid } = getState().auth;
        const { active: categorie } = getState().categorie;

        const categorieToFireStore = { ...categorie };
        delete categorieToFireStore.id;

        const docRef = doc(FirebaseDB, `categorie/${categorie.id}`)
        await setDoc(docRef, categorieToFireStore, { merge: true });

        dispatch(updateCategorie(categorie));
    }
}