import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    page: 0,
    isLoading: false,
    messageSaved: '',
    isSaving: false,
    llegaRepatriations: [],
    llegaRepatriation: [],
    active: null
}

export const llegaRepatriationSlice = createSlice({
    name: 'llegaRepatriation',
    initialState,
    reducers: {
        savingNewLlegaRepatriation: (state) => {
            state.isSaving = true;
        },
        addNewEmptyLlegaRepatriation: (state, action) => {
            const newLlegaRepatriation = action.payload;
            state.llegaRepatriations.push(newLlegaRepatriation);
            state.isSaving = false;
        },
        setActiveLlegaRepatriation: (state, action) => {
            state.active = action.payload;
            state.messageSaved = '';
        },
        setLlegaRepatriations: (state, action) => {
            state.llegaRepatriations = action.payload;
        },
        setLlegaRepatriation: (state, action) => {
            state.llegaRepatriation = action.payload;
        },
        setSaving: (state) => {
            state.isSaving = true;
            state.messageSaved = '';
        },
        updateLlegaRepatriation: (state, action) => {
            state.isSaving = false;
            state.llegaRepatriations = state.llegaRepatriations.map(repatriation => {
                if (repatriation.id === action.payload.id) {
                    return action.payload;
                }
                return repatriation;
            })
            state.messageSaved = `${action.payload.title}, actualizada correctamente`
        },
        deleteLlegaRepatriationById: (state, action) => {

        }
    }
})

export const {
    savingNewLlegaRepatriation,
    addNewEmptyLlegaRepatriation,
    setActiveLlegaRepatriation,
    setLlegaRepatriations,
    setLlegaRepatriation,
    setSaving,
    updateLlegaRepatriation,
    deleteLlegaRepatriationById
} = llegaRepatriationSlice.actions