import { query, collection, getDocs, where } from "firebase/firestore/lite";
import { FirebaseDB } from "../firebase/config";

export const loadRepatriations = async (uid = '') => {
    if (!uid) throw new Error('El UID del usuario no existe');

    const q = query(
        collection(FirebaseDB, 'repatriation')
    );

    const docs = await getDocs(q);
    const repatriations = [];
    docs.forEach(doc => {
        repatriations.push({ id: doc.id, ...doc.data() });
    });
    return repatriations;
}

export const loadAllRepatriations = async () => {

    const q = query(
        collection(FirebaseDB, 'repatriation')
    );

    const docs = await getDocs(q);
    const repatriations = [];
    docs.forEach(doc => {
        repatriations.push({ id: doc.id, ...doc.data() });
    });
    return repatriations;
}

export const loadRepatriation = async (uid = '') => {
    if (!uid) throw new Error('El UID del usuario no existe');

    const q = query(
        collection(FirebaseDB, 'repatriation'),
        where('uid', '==', uid)
    );

    const docs = await getDocs(q);

    const repatriation = [];
    docs.forEach(doc => {
        repatriation.push({ id: doc.id, ...doc.data() });
    })

    return repatriation;
}

