import { FormControl, Box, CircularProgress, InputLabel, MenuItem, Select, TextField } from '@mui/material'
import React, { useState, useRef, useEffect } from 'react'
import { useForm } from 'react-hook-form';
import Swal from 'sweetalert2';
import { FirebaseStorage } from '../firebase/config';
import { getDownloadURL, ref, uploadBytes } from 'firebase/storage';
import { useDispatch, useSelector } from 'react-redux';
import { setActiveVideo } from '../store/video/videoSlice';
import { startDeletingVideo, startLoadingVideos, startNewVideo, startSaveVideo } from '../store/video/thunks';
import { DataGrid } from '@mui/x-data-grid';

const types = [
    { name: 'Video' },
    { name: 'Archivo' }
]

export const Video = () => {
    const dispatch = useDispatch();
    const { videos } = useSelector(state => state.video);
    const [edit, setEdit] = useState(false);
    const { errorMessage } = useSelector(state => state.auth);
    const [urlImage, setUrlImage] = useState('');
    const [urlVideo, setUrlVideo] = useState('');
    const [imageSaved, setImageSaved] = useState(false);
    const inputRef = useRef(null);
    const inputRef2 = useRef(null);
    const [file, setFile] = useState("");
    const [videoFile, setVideoFile] = useState("");
    const [saving, setSaving] = useState(false);
    const [deleteVideo, setDeleteVideo] = useState(false);
    const [loadVideos, setLoadVideos] = useState(false);
    const [type, setType] = useState('');
    const [sorting, setSorting] = useState('');

    const { register, handleSubmit, setValue, control, formState: { errors } } = useForm({
        defaultValues: {
            title: "",
            description: ""
        }
    });

    useEffect(() => {
        dispatch(startLoadingVideos());
    }, [loadVideos])

    const onDelete = (idVideo) => {

        let tmpVideo = videos.filter(x => x.id === idVideo);
        const { id, title, description, urlImage, urlVideo } = tmpVideo[0];
        dispatch(setActiveVideo({ id, title, description, urlImage, urlVideo }));
        setDeleteVideo(true);
        dispatch(startDeletingVideo(id));
        if (!errorMessage) {
            showDeleteSuccess();
            onClear();
        }
        setLoadVideos(true);
    }

    const columns = [
        { field: 'sorting', headerName: 'Orden', width: 50 },
        { field: 'title', headerName: 'Titulo', width: 200 },
        { field: 'description', headerName: 'Descripción', width: 400 },
        { field: 'type', headerName: 'Tipo', width: 100 },
        { field: 'urlImage', headerName: 'Imagen', width: 70, renderCell: (params) => <><img src={params.value} alt='' /></> },
        { field: 'id', headerName: 'Opciones', width: 150, renderCell: (params) => <><button className='w-16 h-8 bg-red-800 text-white ml-2 rounded-full' onClick={() => onDelete(params.value)}>Eliminar</button></> }
    ]

    const onEdit = () => {
        setEdit(true);
        setEdit((state) => {
            return state;
        })

    }

    const showDeleteSuccess = () => {
        Swal.fire('El video fué eliminado correctamente', "Eliminado", 'success');
    }

    const showSaveSuccess = () => {
        Swal.fire('Saved', 'Registro Creado satisfactoriamente.', 'success');
    };

    const showUpdatedSuccess = () => {
        Swal.fire('Saved', 'Registro actualizado corretamente.', 'success');
    };

    const showError = () => {
        Swal.fire('Error', 'Debes llenar todos los campos', 'error');
    };

    const showSaveError = () => {
        Swal.fire('Error', 'Error al guardar', 'error');
    };

    const showUpdateError = () => {
        Swal.fire('Error', 'Error al actualizar', 'error');
    };

    const validate = () => {
        if (!file) {
            return false;
        }
        if (!videoFile) {
            return false;
        }
        return true;
    }

    const onSubmit = async (data) => {

        const {
            title,
            description
        } = data;

        let urlTmpImage = '';
        let urlTmpVideo = '';

        if (validate()) {
            setSaving(true);
            if (file) {
                urlTmpImage = await saveImage();
            }

            if (videoFile) {
                urlTmpVideo = await saveVideo();
                // saveVideo();
            }

            if (edit) {

                setTimeout(() => {
                    const newData = {
                        // id,
                        title,
                        description,
                        type: type,
                        sorting: sorting,
                        urlImage: urlTmpImage,
                        urlVideo: urlTmpVideo
                    }

                    dispatch(setActiveVideo(newData));
                    if (newData) {
                        dispatch(startSaveVideo());
                        if (!errorMessage) {
                            showUpdatedSuccess();
                            onClear();
                        } else {
                            showUpdateError();
                        }
                    }
                    setSaving(false);
                }, 4000);

            } else {
                setTimeout(() => {
                    const newData = {
                        title,
                        description,
                        type: type,
                        sorting: sorting,
                        urlImage: urlTmpImage,
                        urlVideo: urlTmpVideo
                    }

                    dispatch(setActiveVideo(newData));
                    if (newData) {
                        dispatch(startNewVideo(newData));
                        if (!errorMessage) {
                            showSaveSuccess();
                            onClear();
                        } else {
                            showSaveError();
                        }
                    }
                    setSaving(false);
                }, 4000);
            }
            setLoadVideos(true);
        } else {
            showError();
        }
    }

    const onClear = () => {
        setValue('title', "");
        setValue('description', "");
        setFile("");
        setVideoFile("");
        setUrlImage("");
        setUrlVideo("");
        setEdit(false);
    }

    const handleChangeFile = (event) => {
        setFile(event.target.files[0]);
    }

    const handleChangeFileVideo = (event) => {
        setVideoFile(event.target.files[0]);
    }

    async function saveVideo() {
        const storageRef = ref(FirebaseStorage, `conocenos/videos/${Date.now()}-${videoFile.name}`);
        let promise = new Promise((resolve, reject) => {
            uploadBytes(storageRef, videoFile).then((snapshot) => {
                getDownloadURL(snapshot.ref).then((url) => {
                    resolve(url);
                })
            })
        })

        let result = await promise;
        setUrlVideo(result);
        setUrlVideo((state) => {
            return state;
        })

        return result;
    }

    async function saveImage() {
        const storageRef = ref(FirebaseStorage, `conocenos/images/${Date.now()}-${file.name}`);
        let promise = new Promise((resolve, reject) => {
            uploadBytes(storageRef, file).then((snapshot) => {
                getDownloadURL(snapshot.ref).then((url) => {
                    resolve(url);
                })
            })
        })
        let result = await promise;
        setUrlImage(result);
        setUrlImage((state) => {
            return state;
        })

        return result;
    }



    const handleChangeType = (event) => {
        const {
            target: { value },
        } = event;
        setType(
            value
        );
    }

    const handleChangeSorting = (event) => {
        const {
            target: { value },
        } = event;
        setSorting(
            value
        );
    }

    function SelectItemNumbers() {
        return (
            <>
                <FormControl className='w-full'>
                    <InputLabel>Orden</InputLabel>
                    <Select
                        placeholder='Orden'
                        label='Orden'
                        value={sorting}
                        onChange={handleChangeSorting}
                        className='w-full'
                    >
                        {[...Array(15)].map((x, i) =>
                            <MenuItem key={i} value={i + 1}>
                                {i + 1}
                            </MenuItem>
                        )}
                    </Select>
                </FormControl>
            </>
        )
    }



    return (
        <div className='flex flex-col bg-white w-full place-items-center min-h-[870px] pb-20'>
            <div className='w-full pt-10 pl-32 pr-32'>
                <form onSubmit={handleSubmit(onSubmit)}>
                    <div className='flex flex-row w-full '>
                        <TextField
                            id='title'
                            className='w-full'
                            placeholder='Titulo'
                            {...register("title", { required: true, minLength: 5 })}
                        />
                    </div>
                    <div className='w-full flex flex-row pt-0'>
                        {
                            errors.title && errors.title.type === "required" &&
                            <p className="mt-2 text-sm text-red-600 dark:text-red-500">El titulo es requerido</p>
                        }
                        {
                            errors.title && errors.title.type === "minLength" &&
                            <p className="mt-2 text-sm text-red-600 dark:text-red-500">Debe tener al menos 5 caracteres</p>
                        }
                    </div>
                    <div className='flex flex-row w-full pt-4'>
                        <TextField
                            id='description'
                            className='w-full'
                            multiline
                            rows={4}
                            placeholder='Descripción'
                            {...register("description", { required: true, minLength: 10 })}
                        />
                    </div>
                    <div className='flex flex-row w-full pt-4 space-x-4'>
                        <div className='w-2/3'>
                            <FormControl className='w-full'>
                                <InputLabel>Tipo</InputLabel>
                                <Select
                                    placeholder='Tipo'
                                    label='Tipo'
                                    value={type}
                                    onChange={handleChangeType}
                                    className='w-full'
                                >
                                    {types.map((item) => (
                                        <MenuItem key={item.name} value={item.name}>
                                            {item.name}
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        </div>
                        <div className='w-1/3'>
                            <SelectItemNumbers className='w-full' />
                        </div>
                    </div>
                    <div className='w-full flex flex-row pt-0'>
                        {
                            errors.description && errors.description.type === "required" &&
                            <p className="mt-2 text-sm text-red-600 dark:text-red-500">Description is required</p>
                        }
                        {
                            errors.description && errors.description.type === "minLength" &&
                            <p className="mt-2 text-sm text-red-600 dark:text-red-500">Debe tener al menos 10 caracteres</p>
                        }
                    </div>
                    <div className='w-full pt-4'>
                        <div className='pt-2 flex flex-row w-full'>
                            <p>Agregar Imagen</p>
                        </div>
                        <div className='pt-2 flex flex-row w-full'>
                            <input
                                className="block w-full text-sm text-gray-900 border border-gray-300 rounded-lg cursor-pointer bg-gray-50 dark:text-gray-400 focus:outline-none dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400" aria-describedby="file_input_help" id="file_input"
                                type="file" onChange={handleChangeFile} disabled={urlImage}
                                ref={inputRef}
                            />
                        </div>
                        {urlImage &&
                            <div className='pt-2 flex flex-row w-full justify-center'>
                                <img className='w-16 h-16' src={urlImage} alt='' />
                            </div>
                        }
                    </div>

                    <div className='w-full pt-4'>
                        <div className='pt-2 flex flex-row w-full'>
                            <p>Agregar Video</p>
                        </div>
                        <div className='pt-2 flex flex-row w-full'>
                            <input
                                className="block w-full text-sm text-gray-900 border border-gray-300 rounded-lg cursor-pointer bg-gray-50 dark:text-gray-400 focus:outline-none dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400" aria-describedby="file_input_help" id="file_input"
                                type="file" onChange={handleChangeFileVideo} disabled={urlVideo}
                                ref={inputRef2}
                            />
                        </div>
                        {urlVideo &&
                            <div className='pt-2 flex flex-row w-full justify-center'>
                                <img className='w-16 h-16' src={urlVideo} alt='' />
                            </div>
                        }
                    </div>

                    {!saving &&
                        <div className='flex flex-row pt-10 justify-end w-full'>
                            <div className='w-1/4'><button type="button" className='w-full h-10 bg-red-700 text-white rounded-full font-medium' onClick={() => onClear()}>Limpiar</button></div>
                            <div className='w-1/4 pl-2'><button type="submit" className='w-full h-10 bg-sky-900 text-white rounded-full font-medium'>Guardar</button></div>
                        </div>
                    }
                    {saving &&
                        <div className='pt-20 place-content-center justify-center'>
                            <Box sx={{ display: 'flex' }}>
                                <CircularProgress />
                            </Box>
                        </div>
                    }
                </form>
            </div>

            <div className='flex flex-row w-full pl-32 pr-32 pt-10'>
                <DataGrid
                    rows={videos}
                    columns={columns}
                    initialState={{
                        pagination: {
                            paginationModel: { page: 0, pageSize: 5 },
                        },
                    }}
                    pageSizeOptions={[5, 10]}
                />
            </div>

        </div>
    )
}
