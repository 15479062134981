import { loadAllVideos, loadVideo, loadVideos } from "../../helpers/loadVideos";
import { addNewEmptyVideo, deleteVideoById, savingNewVideo, setActiveVideo, setSaving, setVideo, setVideos, updateVideo } from "./videoSlice"
import { collection, count, deleteDoc, doc, setDoc } from "firebase/firestore/lite";
import { FirebaseDB } from "../../firebase/config";

export const startNewClearVideo = (video) => {
    return async (dispatch, getState) => {
        dispatch(savingNewVideo());
        const { uid } = getState().auth;

        const {
            title,
            description,
            urlImage,
            urlVideo,
            type,
            sorting,
        } = video;

        const newVideo = {
            uid,
            title,
            description,
            urlImage,
            urlVideo,
            type,
            sorting,
            creationDate: new Date().getTime(),
        }

        const newDoc = doc(collection(FirebaseDB, `video`))
        await setDoc(newDoc, newVideo);
        newVideo.id = newDoc.id;
        dispatch(addNewEmptyVideo(newVideo));
        dispatch(setActiveVideo(newVideo));
    }
}

export const startNewVideo = (video) => {
    return async (dispatch, getState) => {
        dispatch(savingNewVideo());

        const { uid } = getState().auth;

        const {
            title,
            description,
            urlImage,
            urlVideo,
            type,
            sorting
        } = video;

        const newVideo = {
            uid,
            title,
            description,
            urlImage,
            urlVideo,
            type,
            sorting,
            creationDate: new Date().getTime(),
        }

        const newDoc = doc(collection(FirebaseDB, `video`));
        await setDoc(newDoc, newVideo);
        newVideo.id = newDoc.id;

        dispatch(addNewEmptyVideo(newVideo));
        dispatch(setActiveVideo(newVideo));
    }
}

export const startLoadingVideos = () => {
    return async (dispatch, getState) => {
        const videos = await loadVideos();
        dispatch(setVideos(videos));
    }
}

export const startLoadingAllVideos = () => {
    return async (dispatch, getState) => {
        const videos = await loadAllVideos();
        dispatch(setVideos(videos));
    }
}

export const startLoadingVideo = () => {
    return async (dispatch, getState) => {
        const { uid } = getState().auth;

        if (!uid) throw new Error("El UID del usuario no existe");
        const video = await loadVideo(uid);
        dispatch(setVideo(video));
    }
}

export const startDeletingVideo = () => {
    return async (dispatch, getState) => {
        const { uid } = getState().auth;
        const { active: video } = getState().video;

        const docRef = doc(FirebaseDB, `video/${video.id}`);
        await deleteDoc(docRef);

        dispatch(deleteVideoById(video.id));
    }
}

export const startSaveVideo = () => {
    return async (dispatch, getState) => {

        dispatch(setSaving());

        const { uid } = getState().auth;
        const { active: video } = getState().video;

        const videoToFireStore = { ...video };
        delete videoToFireStore.id;

        const docRef = doc(FirebaseDB, `video/${video.id}`)
        await setDoc(docRef, videoToFireStore, { merge: true });

        dispatch(updateVideo(video));
    }
}