import { Button, FormControl, InputLabel, MenuItem, Paper, Select, Typography } from '@mui/material';
import React, { useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useForm } from 'react-hook-form';
import ReactPlayer from 'react-player';
import { RegisterLlegaMarket } from './RegisterLlegaMarket';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import Swal from 'sweetalert2';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import ModalVideo from 'react-modal-video'
import "react-modal-video/css/modal-video.css"
import { PlayCircle } from '@mui/icons-material';
import { RepatriationPayment } from '../components/RepatriationPayment';
import { RepatriationPricing } from '../components/pricing/RepatriationPricing';
const companyData = [
    'LlegaMarket',
    'Grupo Global',
    'MiShop'
]

const sellerData = [
    'Juanjo Martínez',
    'Fernando De la Oca',
    'Jose'
]

export const Repatriations = () => {
    const { t } = useTranslation();
    const [isOpen, setIsOpen] = useState(false);

    const openModal = () => {
        setIsOpen(true);
    }

    useEffect(() => {
        if (window.scrollY > 300) {
            window.scrollTo({ top: 0, behavior: 'smooth' });
        }
    }, [])

    return (
        <>
            <div className='w-full bg-principal-blue pb-20'>
                <div className=' pt-16 pl-4 md:pl-40 pr-4 md:pr-40'>
                    <div className='w-full flex flex-row'>
                        <p className="w-full text-center text-white text-xl md:text-6xl font-extrabold leading-10">{t('repatriation.title')}</p>
                    </div>
                </div>

                {/* <div className='w-full'>
                    <div className='w-1/2'>
                        <div className='w-full flex flex-row text-center justify-center pt-4'>
                            <Typography gutterBottom variant="h5" component="div" fontSize='2.5rem' className='text-center'>
                                {t('videosSection.title1')}
                            </Typography>
                        </div>
                        <div className='w-full flex flex-row text-center justify-center'>
                            <Typography variant="body2" color="text.secondary" fontSize='0.813rem'>
                                Conoce mas sobre nuestros servicios.
                            </Typography>
                        </div>
                    </div>
                    <div className='w-1/2'></div>
                </div> */}

                <div className='w-full flex flex-row place-content-center'>
                    <div className='w-full flex flex-wrap sm:pl-10 sm:pr-10  lg:pl-10 lg:pr-10 max-w-[1440px] pt-4 sm:pt-20'>
                        <div className='w-1/12'></div>
                        <div className='w-full md:w-6/12'>
                            <div className='pt-8 md:pt-32 '>
                                <p className="w-full text-justify text-white text-xl md:text-xl lg:text-2xl xl:text-2xl 2xl:text-3xl font-light leading-8 pl-8 pr-8 md:pl-0 md:pr-0">
                                    {t('repatriation.descriptionservice')}
                                </p>
                            </div>
                            <div className='flex flex-row w-full pt-10 md:pt-12 justify-center'>
                                <Link to='/meetus'><div className="w-48 h-12 px-5 py-3 rounded--md border border-white justify-start items-center gap-4 inline-flex">
                                    <div className="text-center text-white text-sm font-medium leading-normal">{t('repatriation.description_button')}</div>
                                    <img className="w-6 h-6 relative" src='../assets/icons/right-arrow-white.png' alt='' />
                                </div></Link>
                            </div>
                        </div>
                        <div className='w-1/12'></div>
                        <div className='w-full md:w-4/12 pt-10 sm:pt-20 md:pt-0 pl-8 pr-8'>
                            <div className=' relative overflow-hidden rounded-3xl bg-cover bg-no-repeat p-12 text-center'
                                style={{ backgroundImage: 'url(https://firebasestorage.googleapis.com/v0/b/llega-market.appspot.com/o/repatriation%2FRectangle188.png?alt=media&token=5c228402-c97b-42f7-8e9e-ad8c1f74c0cc)', height: '520px' }}>
                                <div className="absolute bottom-0 left-0 right-0 top-0 h-full w-full overflow-hidden bg-fixed"
                                // style={{ backgroundColor: 'rgba(0,0,0,0.6)' }}
                                >

                                    <div className="flex h-full items-center justify-center">
                                        <div className="text-white">
                                            <ModalVideo channel='custom' isOpen={isOpen} onClose={() => setIsOpen(false)} url='https://firebasestorage.googleapis.com/v0/b/llega-market.appspot.com/o/Temporal%20Videos%2FRepatriaciones%20Llega.mp4?alt=media&token=87f4d2ac-2750-4b7f-955c-c57a8d95e272' />
                                            <button className='' onClick={openModal}>
                                                <img className='w-32 h-32  hover:opacity-40 hover:rounded-full' src='../assets/images/play-video.png' />
                                            </button>
                                        </div>
                                    </div>

                                </div>

                            </div>
                        </div>
                    </div>
                </div>
                <div className='w-full flex flex-row place-content-center mt-20 md:mt-40'>
                    <div className='w-full flex flex-wrap sm:pl-10 sm:pr-10  lg:pl-10 lg:pr-10 max-w-[1440px] pt-4 sm:pt-20'>
                        <div className='w-1/12'></div>
                        <div className='w-full md:w-4/12 md:pl-0 md:pr-0 pl-8 pr-8'>
                            <div className=' relative overflow-hidden rounded-3xl bg-cover bg-no-repeat p-12 text-center'
                                style={{ backgroundImage: 'url(https://firebasestorage.googleapis.com/v0/b/llega-market.appspot.com/o/repatriation%2FRectangle189_1.png?alt=media&token=1afae83e-6682-4dd4-9ef7-1fd1ec96a214)', height: '520px' }}>
                                <div className="absolute bottom-0 left-0 right-0 top-0 h-full w-full overflow-hidden bg-fixed"
                                // style={{ backgroundColor: 'rgba(0,0,0,0.6)' }}
                                >

                                    <div className="flex h-full items-center justify-center">
                                        <div className="text-white">
                                            {/* <ModalVideo channel='custom' isOpen={isOpen} onClose={() => setIsOpen(false)} url='https://firebasestorage.googleapis.com/v0/b/llega-market.appspot.com/o/Videos%2Frepatriaciones%2FWhatsApp%20Video%202023-10-16%20at%2015.07.05.mp4?alt=media&token=4f9fe75a-d367-4f60-b452-e4a198a3fc35&_gl=1*1bbt16y*_ga*MTE5Mzk2MTkxMy4xNTk2NDI1NzQx*_ga_CW55HF8NVT*MTY5NzUxODgzNy4zNjIuMS4xNjk3NTE4ODQwLjU3LjAuMA..' />
                                            <button className='' onClick={openModal}>
                                                <img className='w-32 h-32  hover:opacity-40 hover:rounded-full' src='../assets/images/play-video.png' />
                                            </button> */}
                                        </div>
                                    </div>

                                </div>

                            </div>
                        </div>
                        <div className='w-1/12'></div>
                        <div className='w-full md:w-6/12 pt-10 sm:pt-20 md:pt-0 pl-8 pr-8'>
                            <div className='pt-8 md:pt-24 pl-6 pr-6 md:pl-10 md:pr-10'>
                                <p className="w-full text-center text-white text-3xl font-semibold leading-loose">{t('repatriation.service')}</p>
                                <p className="w-full text-justify text-white text-xl md:text-2xl font-light leading-loose pt-10">
                                    {t('repatriation.description_section2')}
                                </p>
                                <div className='w-full flex flex-row pt-16 justify-center pb-10'>
                                    <Link to='/payment'><button type='button' className="w-80 h-12 bg-blue-950 text-white rounded-full">{t('repatriation.add')}</button></Link>
                                </div>
                            </div>

                        </div>
                    </div>


                </div>

                {/* <div className='w-full flex flex-row place-content-center'>
                    <div className='w-full flex flex-wrap sm:pl-10 sm:pr-10  lg:pl-10 lg:pr-10 max-w-[1440px] pt-20'>
                        <div className='w-full md:w-1/2'>
                            <div className='pt-8 md:pt-32 pl-10 pr-10'>
                                <p className="w-full text-justify text-white md:text-xl lg:text-xl xl:text-2xl 2xl:text-3xl font-light leading-loose">
                                    {t('repatriation.descriptionservice')}
                                </p>
                            </div>
                            <div className='flex flex-row w-full pt-4 md:pt-12 justify-center'>
                                <a href='../assets/files/Repatriacion.pdf' target="_blank"><div className="w-48 h-12 px-5 py-3 rounded--md border border-white justify-start items-center gap-4 inline-flex">
                                    <div className="text-center text-white text-sm font-medium leading-normal">{t('repatriation.description_button')}</div>
                                    <img className="w-6 h-6 relative" src='../assets/icons/right-arrow-white.png' alt='' />
                                </div></a>
                            </div>
                        </div>
                        <div className='w-full md:w-1/2 pt-10 sm:pt-20 md:pt-0 pl-8 pr-8'>
                            <div className="flex items-center justify-center w-auto h-[680px] max-w-[545px] max-h-[680px] rounded-2xl bg-[url('https://firebasestorage.googleapis.com/v0/b/llega-market.appspot.com/o/repatriation%2FRectangle188.png?alt=media&token=5c228402-c97b-42f7-8e9e-ad8c1f74c0cc')]">
                                <ModalVideo channel='custom' isOpen={isOpen} onClose={() => setIsOpen(false)} url='https://firebasestorage.googleapis.com/v0/b/llega-market.appspot.com/o/Videos%2Frepatriaciones%2FWhatsApp%20Video%202023-10-16%20at%2015.07.05.mp4?alt=media&token=4f9fe75a-d367-4f60-b452-e4a198a3fc35&_gl=1*1bbt16y*_ga*MTE5Mzk2MTkxMy4xNTk2NDI1NzQx*_ga_CW55HF8NVT*MTY5NzUxODgzNy4zNjIuMS4xNjk3NTE4ODQwLjU3LjAuMA..' />
                                <button className='' onClick={openModal}>
                                    <img className='w-32 h-32  hover:opacity-40 hover:rounded-full' src='../assets/images/play-video.png' />
                                </button>
                            </div>
                        </div>
                    </div>
                </div> */}

                <div className='pl-8 md:pl-28 pr-8 md:pr-28 pt-4 md:pt-20 pb-20'>
                    {/* <div className='w-full flex flex-wrap justify-between lg:pl-12 lg:pr-12'>
                        <div className='w-full md:w-1/2 md:pr-10 md:pt-4 lg:pt-20'>
                            <p className="w-full text-justify text-white md:text-base lg:text-lg xl:text-2xl 2xl:text-3xl font-light leading-loose">
                                {t('repatriation.descriptionservice')}
                            </p>
                            <div className='flex flex-row w-full pt-4 md:pt-12 justify-center'>
                                <a href='../assets/files/Repatriacion.pdf' target="_blank"><div className="w-48 h-12 px-5 py-3 rounded--md border border-white justify-start items-center gap-4 inline-flex">
                                    <div className="text-center text-white text-sm font-medium leading-normal">{t('repatriation.description_button')}</div>
                                    <img className="w-6 h-6 relative" src='../assets/icons/right-arrow-white.png' alt='' />
                                </div></a>
                            </div>
                        </div>
                        <div className='w-full pt-10 sm:pt-10 md:w-1/2  relative place-items-center'>
                            <img className=' rounded-3xl' src='../assets/carousel/Rectangle188.png' alt='' />
                            <ModalVideo channel='custom' isOpen={isOpen} onClose={() => setIsOpen(false)} url='https://firebasestorage.googleapis.com/v0/b/llega-market.appspot.com/o/Videos%2Frepatriaciones%2FWhatsApp%20Video%202023-10-16%20at%2015.07.05.mp4?alt=media&token=4f9fe75a-d367-4f60-b452-e4a198a3fc35&_gl=1*1bbt16y*_ga*MTE5Mzk2MTkxMy4xNTk2NDI1NzQx*_ga_CW55HF8NVT*MTY5NzUxODgzNy4zNjIuMS4xNjk3NTE4ODQwLjU3LjAuMA..' />
                            <button className='absolute top-[35%] left-[30%] sm:top-[40%] sm:left-[35%] md:top-[30%] md:left-[30%] lg:top-[30%] lg:left-[35%] xl:top-[40%] xl:left-[40%] 2xl:top-[38%] 2xl:left-[35%]' onClick={openModal}>
                                <img className='w-32 h-32  hover:opacity-40 hover:rounded-full' src='../assets/images/play-video.png' />
                            </button>
                        </div>
                    </div> */}

                    {/* <div className='w-full flex flex-wrap pt-20 lg:pl-20 lg:pr-20'>
                        <div className='w-full md:w-1/2 md:pr-10'>
                            <img className='rounded-3xl' src='../assets/carousel/Rectangle189.png' alt='' />
                        </div>
                        <div className='w-full md:w-1/2 pt-20'>
                            <p className="w-full text-center text-white text-3xl font-semibold leading-loose">{t('repatriation.title_section2')}</p>
                            <p className="w-full text-justify text-white text-xl md:text-2xl font-light leading-loose pt-16">
                                {t('repatriation.description_section2')}
                            </p>
                            <div className='w-full flex flex-row pt-16 justify-center pb-10'>
                                <Link to='/repatriationform'><button type='button' className="w-80 h-12 bg-blue-950 text-white rounded-full">{t('repatriation.affiliate')}</button></Link>
                            </div>
                        </div>
                    </div> */}

                    <RepatriationPricing />
                </div>
            </div>
        </>
    )
}
