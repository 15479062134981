import React, { useState } from 'react'
import { useForm } from 'react-hook-form';
import emailjs from '@emailjs/browser';
import Swal from 'sweetalert2';
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom';

export const WouldAffiliate = () => {
    const { t } = useTranslation();
    const [comercio, setComercio] = useState('');

    const { register, handleSubmit, setValue, control, formState: { errors } } = useForm({
        defaultValues: {
            displayName: "",
            affiliate: "",
            phone: "",
            message: `Quisiera afiliar el comercio: `,
            subject: "Afiliar comercio"
        }
    })

    const onSubmit = (data) => {
        let tmpAffiliate = data.affiliate;
        const {
            displayName,
            affiliate,
            phone,
            message,
            subject
        } = data;

        const newData = {
            displayName,
            affiliate,
            phone,
            message: `Quisiera afiliar el comercio: ${tmpAffiliate}`,
            subject
        }

        if (data) {
            sendEmail(data);
        }
    }

    function sendEmail(data) {

        emailjs.send('service_k1iecnp', 'template_5xho742', data, 'srjso40pBJ9jqwxHz')
            .then(function (response) {
                showSuccess();
                limpiar();
            }, function (error) {
                showError();
            });
    }

    const limpiar = () => {
        setValue('email', "");
        setValue('affiliate', "");
    }

    const showSuccess = () => {
        Swal.fire('Gracias por tu seguerencia', 'Ok', 'success');
    };

    const showError = () => {
        Swal.fire('Error en el registro', 'Error', 'Error');
    };

    const handleComercioChange = (event) => {
        const comercioIngresado = event.target.value;
        setComercio(comercioIngresado);
    };

    return (
        <>
            <div className="w-full bg-[#645EC2] h-96">
                {/* <div className="w-full bg-[#645EC2] h-96 flex-col justify-start items-start gap-2.5 inline-flex mb-2 pt-0"> */}
                <div className='w-full relative '>
                    <img className='w-full h-96 left-0 top-0 absolute' src='../assets/images/Vector 1.png' alt='' />
                    <img className='w-full h-96 left-0 top-0 absolute ' src='../assets/images/Vector 2.png' alt='' />
                </div>
                <div className="w-full h-36 flex-col justify-start relative items-start gap-8 flex pt-8">
                    <div className="w-full pt-16 text-white text-2xl md:text-3xl font-extrabold leading-10">{t('affiliate.title')}</div>
                    <div className=" w-full justify-center items-center inline-flex">
                        <div className="">
                            <input className="px-4 py-3.5 bg-gray-100 rounded-tl rounded-bl justify-start items-center gap-2.5 w-52 text-zinc-600 text-sm font-normal leading-normal" placeholder={t('affiliate.email')} value={comercio} onChange={handleComercioChange} />
                        </div>
                        <div className="px-6 py-3.5 bg-blue-950 opacity-100 hover:opacity-70 rounded-tr rounded-br justify-start items-center gap-2.5 flex">
                            {/* <Link to={'/contact?comercio=' + comercio}>
                                <div type='button' className="text-white text-sm font-medium leading-tight">{t('affiliate.button')}</div>
                            </Link> */}
                            <Link to={'/contact?comercio=' + comercio}><input type='button' className="text-white text-sm font-medium leading-tight" value={t('affiliate.button')} /></Link>
                        </div>
                    </div>
                </div>
            </div>

        </>
    )
}
