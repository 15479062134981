import React, { useRef } from 'react'
import { Link, useNavigate } from 'react-router-dom';
import { Paper, Button } from '@mui/material'
import { ArrowLeft, ArrowRight, BorderClear } from '@mui/icons-material';
import { useTranslation } from 'react-i18next'
import { Carousel } from 'react-responsive-carousel';
import "react-responsive-carousel/lib/styles/carousel.min.css";

export const CarouselPage = () => {
    const { i18n, t } = useTranslation();
    const sliderRef = useRef(null);
    const navigate = useNavigate();

    const items = [
        {
            name: "Random Name #1",
            description: "Probably the most random thing you have ever seen!"
        },
        {
            name: "Random Name #2",
            description: "Hello World!"
        }
    ]

    const handlePrevSlide = (e) => {
        e.preventDefault();
        if (sliderRef?.current) {
            sliderRef.current.prev('prev');
        }
    }

    // const handleNextSlide = (e) => {
    //     let count = sliderRef.current;
    //     e.preventDefault();
    //     if (sliderRef?.current) {
    //         sliderRef.current = 'next';
    //     } else {
    //         sliderRef.current = 'next'
    //     }
    // }

    const handleNextSlide = () => {
        let count = 1;
        if (sliderRef.current === undefined) {
            sliderRef.current = 2;
        } else {

            sliderRef.current.next = count + 1;
        }
    }

    const sendToRepatriations = () => {
        navigate('/repatriation');
    }

    const sendToMarket = () => {
        navigate('/market');
    }

    return (
        <>
            <Carousel
                infiniteLoop
                autoPlay
                showStatus={false}
                showThumbs={false}
                showArrows={true}
                showIndicators={false}
                className='relative'
            >
                <div className='bg-[#4F4170] relative'>
                    <img src='https://firebasestorage.googleapis.com/v0/b/llega-market.appspot.com/o/LlegaMarket2.0%2FCarousel%2FDisen%CC%83o%20sin%20ti%CC%81tulo%20(4).svg?alt=media&token=69874bd9-445f-4eb7-ba47-817915ffb36e' alt='' />


                    <div className='absolute  w-full h-full top-[20%] left-[4%]
                        '>

                        <div className='w-full flex flex-row'>

                            <div className='w-1/2'>
                                <div className='w-full h-auto flex flex-row place-content-center'>

                                    <div className='w-1/2 object-cover ml-2 '>
                                        <Link to='/store/cfD1liQDKy21iKYtdnJb'><div className='hover:transition ease-in-out delay-150 hover:-translate-y-1 hover:scale-105 duration-300 ...
                    '>
                                            <img className='w-full object-cover rounded-xl' src='https://firebasestorage.googleapis.com/v0/b/llega-market.appspot.com/o/LlegaMarket2.0%2FMarket%2FFarmacias2.png?alt=media&token=f6d39d17-8914-406a-b63a-efc548c68c44' alt='' />
                                        </div></Link>
                                    </div>
                                    <div className='w-1/2 object-cover ml-2 '>
                                        <Link to='/store/hdAki79eDTLVuYCNrsen'><div className='hover:transition ease-in-out delay-150 hover:-translate-y-1 hover:scale-105 duration-300 ...'
                                        >
                                            <img className='w-full object-cover rounded-xl' src='https://firebasestorage.googleapis.com/v0/b/llega-market.appspot.com/o/LlegaMarket2.0%2FMarket%2Fcuidadopersonal.png?alt=media&token=4ab21493-9c44-443c-a5f6-dc69fb9537c0' alt='' />
                                        </div>
                                        </Link>
                                    </div>
                                </div>
                                <div className='w-full h-auto flex flex-row mt-2'>
                                    <div className='w-1/2 ml-2 rounded-xl'>
                                        <Link to='/store/WeKwmZGDCaeSMfOUyjr4'><div className='hover:transition ease-in-out delay-150 hover:-translate-y-1 hover:scale-105 duration-300 ...'
                                        >
                                            <img className='w-full object-cover rounded-xl' src='https://firebasestorage.googleapis.com/v0/b/llega-market.appspot.com/o/LlegaMarket2.0%2FMarket%2Feccomerce1.png?alt=media&token=2af1a4e7-c239-4b72-bfd5-3b9ea379d0eb' alt='' />
                                        </div></Link>
                                    </div>
                                    <div className='w-1/2 bg-secondary-green ml-2 rounded-xl hover:transition ease-in-out delay-150 hover:-translate-y-1 hover:scale-105 duration-300 ...'>
                                        <div className='w-full h-full flex items-center justify-center'>
                                            <Link to='/market'><div>
                                                <p className="                                                                                                                                    
                                                    text-sm sm:text-xl md:text-2xl lg:text-4xl
                                                    text-slate-600  font-extrabold font-['Inter']
                                                    "
                                                >{t('carouselPage.other')} <br />{t('carouselPage.services')}
                                                </p>
                                            </div></Link>
                                        </div>
                                    </div>
                                </div>
                            </div>


                            <div className='w-1/2 ml-8'>

                                <div className='w-full flex flex-row'>
                                    <div className='w-1/3'>
                                        <img className='w-full' src='../assets/logo.png' alt='' />
                                    </div>
                                    <div className='w-2/3'></div>
                                </div>

                                <div className='w-full flex flex-row mt-4'>
                                    <div className='w-2/12'>
                                        <img src='https://firebasestorage.googleapis.com/v0/b/llega-market.appspot.com/o/LlegaMarket2.0%2FHeader%2Flogo_llega.png?alt=media&token=494a6c60-7054-4de4-9bf7-61b5dd89a6f9' alt='' />
                                    </div>
                                    <div className='w-8/12 flex flex-wrap ml-4 mr-2'>
                                        <p className="text-white  text-[9px] sm:text-[12px] md:text-xl lg:text-3xl xl:text-4xl 3xl:text-5xl font-bold  text-left">{t('carouselPage.title')}</p>
                                        <p className=" md:pt-2 lg:pt-6 text-white text-[6px] sm:text-[11px] md:text-[16px] lg:text-[15px] xl:text-[18px] 2xl:text-[18px] 3xl:text-[22px] text-left font-normal ">{t('carouselPage.subtitle')}</p>
                                    </div>
                                    <div className='w-2/12'>

                                    </div>
                                </div>

                                <div className='w-full flex flex-row mt-1 md:mt-2'>
                                    <div className='w-10/12'>
                                        <p className="text-justify text-white text-[5px] xs:text-[7px] sm:text-[10px] md:text-[14px] lg:text-base xl:text-lg 3xl:text-[20px] font-normal">
                                            {t('carouselPage.description')}
                                        </p>
                                    </div>
                                </div>


                            </div>

                        </div>

                    </div>


                </div >

            </Carousel >
        </>
    )
}

