import React, { useState } from 'react'
import { NavLink } from 'react-router-dom'
import { useDispatch } from 'react-redux';

import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { startLogout } from '../store/auth/thunks';

export const AppMenu = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [open, setOpen] = useState(true);
    const [selectedOption, setSelectedOption] = useState(0);
    const { t } = useTranslation();

    const Menus = [
        { title: "menu.home", src: "apps", path: "/dashboard", index: "0" },
        { title: "menu.categories", src: "money-withdraw", path: "/categories", index: "1" },
        { title: "menu.stores", src: "money-withdraw", path: "/companies", index: "2" },
        { title: "menu.repatriationCompany", src: "money-withdraw", path: "/repatriationcompanies", index: "3" },
        { title: "menu.repatriationSeller", src: "money-withdraw", path: "/sellers", index: "4" },
        { title: "menu.repatriationadmin", src: "money-withdraw", path: "/repatriationadmin", index: "5" },
        { title: "menu.payment", src: "money-withdraw", path: "/repatriationpayment", index: "6" },
        { title: "menu.llegaExpress", src: "money-withdraw", path: "/repatriationllegaexpress", index: "7" },
        { title: "menu.countries", src: "money-withdraw", path: "/countries", index: "8" },
        { title: "menu.videos", src: "money-withdraw", path: "/videos", index: "9" }
    ]

    const onLogout = () => {
        dispatch(startLogout());
    }

    const setMenuOption = (option, index) => {
        setSelectedOption(index);
        navigate(option);
    }

    return (
        <>
            <div className='flex bg-[#6EEBE6]'>
                <div className={`${open ? " w-72" : "w-20"} duration-300 relative font-['Poppins'] text-sm bg-[#6EEBE6]`}>
                    <div className='bg-blue-950 w-full'>
                        <img src="../assets/icons/Frame.png"
                            className={`absolute cursor-pointer rounded-full -right-3 top-9 w-7 border-2 border-sidebar-color ${!open && "rotate-180"}`} alt=''
                            onClick={() => setOpen(!open)} />
                        <div className='flex gap-x-4 items-center border-blue-950 text-center justify-center w-30 h-20'>
                            <img src="../assets/logo.png" alt='' className={`cursor-pointer w-21 h-16 duration-500 ${open && "rotate-[360deg]"}`} />
                        </div>
                    </div>
                    {/* <hr className='mt-6' /> */}
                    <div className='bg-[#6EEBE6]'>
                        <ul className='pt-6  p-5'>
                            {Menus.map((menu, index) => (
                                <li key={index}
                                    className={` text-sm flex items-center gap-x-4 cursor-pointer p-2 hover:bg-[#0D497D] hover:text-white rounded-full h-14
                                ${menu.gap ? "mt-9" : "mt-2"} ${index === selectedOption && ('bg-[#0D497D]')}  ${index !== selectedOption && 'text-[#1F2A3B]'} ${index === selectedOption && ('text-white')}`} onClick={() => setMenuOption(menu.path, index)}>
                                    <img src={`${index === selectedOption ? `../assets/icons/${menu.src}-white.png` : `../assets/icons/${menu.src}.png`}`} />
                                    <span className={`${!open && 'hidden'} origin-left duration-200 `}>{t(menu.title)}</span>
                                </li>
                            ))}
                        </ul>
                    </div>
                    <br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br />
                    <br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br />
                    <div>
                        <hr />
                        <ul >
                            <li
                                className={`text-[#1F2A3B] text-sm flex items-center justify-center gap-x-4 cursor-pointer p-6 hover:bg-[#0D497D] hover:text-white rounded-full h-14`} onClick={onLogout}>
                                <img src={`../assets/icons/exit.png`} />
                                <span className={`${!open && 'hidden'} origin-left duration-200 `}>{t('menu.logout')}</span>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </>
    )
}
