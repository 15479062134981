import React, { useEffect, useState } from 'react'
import { ScrollMenu, VisibilityContext } from 'react-horizontal-scrolling-menu';
import 'react-horizontal-scrolling-menu/dist/styles.css';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';



const getItems = () =>
    Array(20)
        .fill(0)
        .map((_, ind) => ({ id: `element-${ind}` }));

function Card({ onClick, selected, title, itemId, image }) {
    const visibility = React.useContext(VisibilityContext);

    return (
        <div
            onClick={() => onClick(visibility)}
            tabIndex={0}
            className='
                pt-4 md:pt-10 pl-2
                w-[170px] h-[130px] 
                md:w-[320px] md:h-[280px]
                hover:transition ease-in-out delay-100 hover:-translate-y-1 hover:scale-102 duration-300 ...
                '
        >
            <div className=' rounded-lg md:rounded-xl bg-cover md:bg-auto bg-no-repeat md:bg-center w-full h-full' style={{ backgroundImage: `url(${image})` }}>
                {/* <img className='object-fill w-full h-full' src={image} alt='' /> */}
            </div>
        </div>
    );
}

export const Services = () => {
    const [itemCategorie, setItemCategorie] = useState([]);
    const [items, setItems] = useState([]);
    const { categories } = useSelector(state => state.categorie);
    const [selected, setSelected] = useState([]);
    const [position, setPosition] = useState(0);

    useEffect(() => {
        let itemCategorieTmp = [];
        if (categories.length > 0) {
            let arrayForSort = [...categories];
            let sortedCategories = arrayForSort.sort(
                (p1, p2) => (p1.serviceOrder > p2.serviceOrder) ? 1 : (p1.serviceOrder < p2.serviceOrder) ? -1 : 0);
            sortedCategories.map((item) => {
                if (item.serviceOrder != '100') {
                    itemCategorieTmp.push({ id: item.id, title: item.name, redirect: `/store/${item.id}`, image: item.urlPicture })
                }
            })
            setItems(itemCategorieTmp);
        }

    }, [categories])

    useEffect(() => {

    }, [items])

    const isItemSelected = (id) => !!selected.find((el) => el === id);

    const handleClick =
        (id) =>
            ({ getItemById, scrollToItem }) => {
                const itemSelected = isItemSelected(id);

                setSelected((currentSelected) =>
                    itemSelected
                        ? currentSelected.filter((el) => el !== id)
                        : currentSelected.concat(id)
                );
            };

    return (
        <>
            <div className='w-full h-36 md:h-80 bg-secondary-green pl-8 pr-8 md:pl-16 md:pr-16'>
                <ScrollMenu>
                    {items.map((item) => (
                        <Link to={item.redirect} key={item.id}><Card
                            itemId={item.id} // NOTE: itemId is required for track items
                            title={item.title}
                            key={item.id}
                            image={item.image}
                            onClick={handleClick(item.id)}
                            selected={isItemSelected(item.id)}
                        />
                        </Link>
                    ))}
                </ScrollMenu>
            </div>
        </>
    )
}
