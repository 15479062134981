import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { startLoadingAllLlegaRepatriations, startSaveLlegaRepatriation } from '../store/llegaRepatriation/thunks';
import { CSVLink } from 'react-csv';
import { DataGrid } from '@mui/x-data-grid';
import { Box, CircularProgress, FormControl, IconButton, InputLabel, MenuItem, Modal, Select, TextField, Typography } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { useForm } from 'react-hook-form';
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import Autocomplete from '@mui/material/Autocomplete';
import moment from 'moment';
import Swal from 'sweetalert2';
import { setActiveLlegaRepatriation } from '../store/llegaRepatriation/llegaRepatriationSlice';

const style = {
    position: 'absolute',
    top: '40%',
    left: '50%',
    transform: 'translate(-50%, -40%)',
    // height: 700,
    // width: 900,
    bgcolor: 'background.paper',
    overflow: 'scroll',
    display: 'block'
    // border: '2px solid #000',
    // boxShadow: 12,
};

const countries = [
    { code: 'AD', label: 'Andorra', phone: '376' },
    {
        code: 'AE',
        label: 'United Arab Emirates',
        phone: '971',
    },
    { code: 'AF', label: 'Afghanistan', phone: '93' },
    {
        code: 'AG',
        label: 'Antigua and Barbuda',
        phone: '1-268',
    },
    { code: 'AI', label: 'Anguilla', phone: '1-264' },
    { code: 'AL', label: 'Albania', phone: '355' },
    { code: 'AM', label: 'Armenia', phone: '374' },
    { code: 'AO', label: 'Angola', phone: '244' },
    { code: 'AQ', label: 'Antarctica', phone: '672' },
    { code: 'AR', label: 'Argentina', phone: '54' },
    { code: 'AS', label: 'American Samoa', phone: '1-684' },
    { code: 'AT', label: 'Austria', phone: '43' },
    {
        code: 'AU',
        label: 'Australia',
        phone: '61',
        suggested: true,
    },
    { code: 'AW', label: 'Aruba', phone: '297' },
    { code: 'AX', label: 'Alland Islands', phone: '358' },
    { code: 'AZ', label: 'Azerbaijan', phone: '994' },
    {
        code: 'BA',
        label: 'Bosnia and Herzegovina',
        phone: '387',
    },
    { code: 'BB', label: 'Barbados', phone: '1-246' },
    { code: 'BD', label: 'Bangladesh', phone: '880' },
    { code: 'BE', label: 'Belgium', phone: '32' },
    { code: 'BF', label: 'Burkina Faso', phone: '226' },
    { code: 'BG', label: 'Bulgaria', phone: '359' },
    { code: 'BH', label: 'Bahrain', phone: '973' },
    { code: 'BI', label: 'Burundi', phone: '257' },
    { code: 'BJ', label: 'Benin', phone: '229' },
    { code: 'BL', label: 'Saint Barthelemy', phone: '590' },
    { code: 'BM', label: 'Bermuda', phone: '1-441' },
    { code: 'BN', label: 'Brunei Darussalam', phone: '673' },
    { code: 'BO', label: 'Bolivia', phone: '591' },
    { code: 'BR', label: 'Brazil', phone: '55' },
    { code: 'BS', label: 'Bahamas', phone: '1-242' },
    { code: 'BT', label: 'Bhutan', phone: '975' },
    { code: 'BV', label: 'Bouvet Island', phone: '47' },
    { code: 'BW', label: 'Botswana', phone: '267' },
    { code: 'BY', label: 'Belarus', phone: '375' },
    { code: 'BZ', label: 'Belize', phone: '501' },
    {
        code: 'CA',
        label: 'Canada',
        phone: '1',
        suggested: true,
    },
    {
        code: 'CC',
        label: 'Cocos (Keeling) Islands',
        phone: '61',
    },
    {
        code: 'CD',
        label: 'Congo, Democratic Republic of the',
        phone: '243',
    },
    {
        code: 'CF',
        label: 'Central African Republic',
        phone: '236',
    },
    {
        code: 'CG',
        label: 'Congo, Republic of the',
        phone: '242',
    },
    { code: 'CH', label: 'Switzerland', phone: '41' },
    { code: 'CI', label: "Cote d'Ivoire", phone: '225' },
    { code: 'CK', label: 'Cook Islands', phone: '682' },
    { code: 'CL', label: 'Chile', phone: '56' },
    { code: 'CM', label: 'Cameroon', phone: '237' },
    { code: 'CN', label: 'China', phone: '86' },
    { code: 'CO', label: 'Colombia', phone: '57' },
    { code: 'CR', label: 'Costa Rica', phone: '506' },
    { code: 'CU', label: 'Cuba', phone: '53' },
    { code: 'CV', label: 'Cape Verde', phone: '238' },
    { code: 'CW', label: 'Curacao', phone: '599' },
    { code: 'CX', label: 'Christmas Island', phone: '61' },
    { code: 'CY', label: 'Cyprus', phone: '357' },
    { code: 'CZ', label: 'Czech Republic', phone: '420' },
    {
        code: 'DE',
        label: 'Germany',
        phone: '49',
        suggested: true,
    },
    { code: 'DJ', label: 'Djibouti', phone: '253' },
    { code: 'DK', label: 'Denmark', phone: '45' },
    { code: 'DM', label: 'Dominica', phone: '1-767' },
    {
        code: 'DO',
        label: 'Dominican Republic',
        phone: '1-809',
    },
    { code: 'DZ', label: 'Algeria', phone: '213' },
    { code: 'EC', label: 'Ecuador', phone: '593' },
    { code: 'EE', label: 'Estonia', phone: '372' },
    { code: 'EG', label: 'Egypt', phone: '20' },
    { code: 'EH', label: 'Western Sahara', phone: '212' },
    { code: 'ER', label: 'Eritrea', phone: '291' },
    { code: 'ES', label: 'Spain', phone: '34' },
    { code: 'ET', label: 'Ethiopia', phone: '251' },
    { code: 'FI', label: 'Finland', phone: '358' },
    { code: 'FJ', label: 'Fiji', phone: '679' },
    {
        code: 'FK',
        label: 'Falkland Islands (Malvinas)',
        phone: '500',
    },
    {
        code: 'FM',
        label: 'Micronesia, Federated States of',
        phone: '691',
    },
    { code: 'FO', label: 'Faroe Islands', phone: '298' },
    {
        code: 'FR',
        label: 'France',
        phone: '33',
        suggested: true,
    },
    { code: 'GA', label: 'Gabon', phone: '241' },
    { code: 'GB', label: 'United Kingdom', phone: '44' },
    { code: 'GD', label: 'Grenada', phone: '1-473' },
    { code: 'GE', label: 'Georgia', phone: '995' },
    { code: 'GF', label: 'French Guiana', phone: '594' },
    { code: 'GG', label: 'Guernsey', phone: '44' },
    { code: 'GH', label: 'Ghana', phone: '233' },
    { code: 'GI', label: 'Gibraltar', phone: '350' },
    { code: 'GL', label: 'Greenland', phone: '299' },
    { code: 'GM', label: 'Gambia', phone: '220' },
    { code: 'GN', label: 'Guinea', phone: '224' },
    { code: 'GP', label: 'Guadeloupe', phone: '590' },
    { code: 'GQ', label: 'Equatorial Guinea', phone: '240' },
    { code: 'GR', label: 'Greece', phone: '30' },
    {
        code: 'GS',
        label: 'South Georgia and the South Sandwich Islands',
        phone: '500',
    },
    { code: 'GT', label: 'Guatemala', phone: '502' },
    { code: 'GU', label: 'Guam', phone: '1-671' },
    { code: 'GW', label: 'Guinea-Bissau', phone: '245' },
    { code: 'GY', label: 'Guyana', phone: '592' },
    { code: 'HK', label: 'Hong Kong', phone: '852' },
    {
        code: 'HM',
        label: 'Heard Island and McDonald Islands',
        phone: '672',
    },
    { code: 'HN', label: 'Honduras', phone: '504' },
    { code: 'HR', label: 'Croatia', phone: '385' },
    { code: 'HT', label: 'Haiti', phone: '509' },
    { code: 'HU', label: 'Hungary', phone: '36' },
    { code: 'ID', label: 'Indonesia', phone: '62' },
    { code: 'IE', label: 'Ireland', phone: '353' },
    { code: 'IL', label: 'Israel', phone: '972' },
    { code: 'IM', label: 'Isle of Man', phone: '44' },
    { code: 'IN', label: 'India', phone: '91' },
    {
        code: 'IO',
        label: 'British Indian Ocean Territory',
        phone: '246',
    },
    { code: 'IQ', label: 'Iraq', phone: '964' },
    {
        code: 'IR',
        label: 'Iran, Islamic Republic of',
        phone: '98',
    },
    { code: 'IS', label: 'Iceland', phone: '354' },
    { code: 'IT', label: 'Italy', phone: '39' },
    { code: 'JE', label: 'Jersey', phone: '44' },
    { code: 'JM', label: 'Jamaica', phone: '1-876' },
    { code: 'JO', label: 'Jordan', phone: '962' },
    {
        code: 'JP',
        label: 'Japan',
        phone: '81',
        suggested: true,
    },
    { code: 'KE', label: 'Kenya', phone: '254' },
    { code: 'KG', label: 'Kyrgyzstan', phone: '996' },
    { code: 'KH', label: 'Cambodia', phone: '855' },
    { code: 'KI', label: 'Kiribati', phone: '686' },
    { code: 'KM', label: 'Comoros', phone: '269' },
    {
        code: 'KN',
        label: 'Saint Kitts and Nevis',
        phone: '1-869',
    },
    {
        code: 'KP',
        label: "Korea, Democratic People's Republic of",
        phone: '850',
    },
    { code: 'KR', label: 'Korea, Republic of', phone: '82' },
    { code: 'KW', label: 'Kuwait', phone: '965' },
    { code: 'KY', label: 'Cayman Islands', phone: '1-345' },
    { code: 'KZ', label: 'Kazakhstan', phone: '7' },
    {
        code: 'LA',
        label: "Lao People's Democratic Republic",
        phone: '856',
    },
    { code: 'LB', label: 'Lebanon', phone: '961' },
    { code: 'LC', label: 'Saint Lucia', phone: '1-758' },
    { code: 'LI', label: 'Liechtenstein', phone: '423' },
    { code: 'LK', label: 'Sri Lanka', phone: '94' },
    { code: 'LR', label: 'Liberia', phone: '231' },
    { code: 'LS', label: 'Lesotho', phone: '266' },
    { code: 'LT', label: 'Lithuania', phone: '370' },
    { code: 'LU', label: 'Luxembourg', phone: '352' },
    { code: 'LV', label: 'Latvia', phone: '371' },
    { code: 'LY', label: 'Libya', phone: '218' },
    { code: 'MA', label: 'Morocco', phone: '212' },
    { code: 'MC', label: 'Monaco', phone: '377' },
    {
        code: 'MD',
        label: 'Moldova, Republic of',
        phone: '373',
    },
    { code: 'ME', label: 'Montenegro', phone: '382' },
    {
        code: 'MF',
        label: 'Saint Martin (French part)',
        phone: '590',
    },
    { code: 'MG', label: 'Madagascar', phone: '261' },
    { code: 'MH', label: 'Marshall Islands', phone: '692' },
    {
        code: 'MK',
        label: 'Macedonia, the Former Yugoslav Republic of',
        phone: '389',
    },
    { code: 'ML', label: 'Mali', phone: '223' },
    { code: 'MM', label: 'Myanmar', phone: '95' },
    { code: 'MN', label: 'Mongolia', phone: '976' },
    { code: 'MO', label: 'Macao', phone: '853' },
    {
        code: 'MP',
        label: 'Northern Mariana Islands',
        phone: '1-670',
    },
    { code: 'MQ', label: 'Martinique', phone: '596' },
    { code: 'MR', label: 'Mauritania', phone: '222' },
    { code: 'MS', label: 'Montserrat', phone: '1-664' },
    { code: 'MT', label: 'Malta', phone: '356' },
    { code: 'MU', label: 'Mauritius', phone: '230' },
    { code: 'MV', label: 'Maldives', phone: '960' },
    { code: 'MW', label: 'Malawi', phone: '265' },
    { code: 'MX', label: 'Mexico', phone: '52' },
    { code: 'MY', label: 'Malaysia', phone: '60' },
    { code: 'MZ', label: 'Mozambique', phone: '258' },
    { code: 'NA', label: 'Namibia', phone: '264' },
    { code: 'NC', label: 'New Caledonia', phone: '687' },
    { code: 'NE', label: 'Niger', phone: '227' },
    { code: 'NF', label: 'Norfolk Island', phone: '672' },
    { code: 'NG', label: 'Nigeria', phone: '234' },
    { code: 'NI', label: 'Nicaragua', phone: '505' },
    { code: 'NL', label: 'Netherlands', phone: '31' },
    { code: 'NO', label: 'Norway', phone: '47' },
    { code: 'NP', label: 'Nepal', phone: '977' },
    { code: 'NR', label: 'Nauru', phone: '674' },
    { code: 'NU', label: 'Niue', phone: '683' },
    { code: 'NZ', label: 'New Zealand', phone: '64' },
    { code: 'OM', label: 'Oman', phone: '968' },
    { code: 'PA', label: 'Panama', phone: '507' },
    { code: 'PE', label: 'Peru', phone: '51' },
    { code: 'PF', label: 'French Polynesia', phone: '689' },
    { code: 'PG', label: 'Papua New Guinea', phone: '675' },
    { code: 'PH', label: 'Philippines', phone: '63' },
    { code: 'PK', label: 'Pakistan', phone: '92' },
    { code: 'PL', label: 'Poland', phone: '48' },
    {
        code: 'PM',
        label: 'Saint Pierre and Miquelon',
        phone: '508',
    },
    { code: 'PN', label: 'Pitcairn', phone: '870' },
    { code: 'PR', label: 'Puerto Rico', phone: '1' },
    {
        code: 'PS',
        label: 'Palestine, State of',
        phone: '970',
    },
    { code: 'PT', label: 'Portugal', phone: '351' },
    { code: 'PW', label: 'Palau', phone: '680' },
    { code: 'PY', label: 'Paraguay', phone: '595' },
    { code: 'QA', label: 'Qatar', phone: '974' },
    { code: 'RE', label: 'Reunion', phone: '262' },
    { code: 'RO', label: 'Romania', phone: '40' },
    { code: 'RS', label: 'Serbia', phone: '381' },
    { code: 'RU', label: 'Russian Federation', phone: '7' },
    { code: 'RW', label: 'Rwanda', phone: '250' },
    { code: 'SA', label: 'Saudi Arabia', phone: '966' },
    { code: 'SB', label: 'Solomon Islands', phone: '677' },
    { code: 'SC', label: 'Seychelles', phone: '248' },
    { code: 'SD', label: 'Sudan', phone: '249' },
    { code: 'SE', label: 'Sweden', phone: '46' },
    { code: 'SG', label: 'Singapore', phone: '65' },
    { code: 'SH', label: 'Saint Helena', phone: '290' },
    { code: 'SI', label: 'Slovenia', phone: '386' },
    {
        code: 'SJ',
        label: 'Svalbard and Jan Mayen',
        phone: '47',
    },
    { code: 'SK', label: 'Slovakia', phone: '421' },
    { code: 'SL', label: 'Sierra Leone', phone: '232' },
    { code: 'SM', label: 'San Marino', phone: '378' },
    { code: 'SN', label: 'Senegal', phone: '221' },
    { code: 'SO', label: 'Somalia', phone: '252' },
    { code: 'SR', label: 'Suriname', phone: '597' },
    { code: 'SS', label: 'South Sudan', phone: '211' },
    {
        code: 'ST',
        label: 'Sao Tome and Principe',
        phone: '239',
    },
    { code: 'SV', label: 'El Salvador', phone: '503' },
    {
        code: 'SX',
        label: 'Sint Maarten (Dutch part)',
        phone: '1-721',
    },
    {
        code: 'SY',
        label: 'Syrian Arab Republic',
        phone: '963',
    },
    { code: 'SZ', label: 'Swaziland', phone: '268' },
    {
        code: 'TC',
        label: 'Turks and Caicos Islands',
        phone: '1-649',
    },
    { code: 'TD', label: 'Chad', phone: '235' },
    {
        code: 'TF',
        label: 'French Southern Territories',
        phone: '262',
    },
    { code: 'TG', label: 'Togo', phone: '228' },
    { code: 'TH', label: 'Thailand', phone: '66' },
    { code: 'TJ', label: 'Tajikistan', phone: '992' },
    { code: 'TK', label: 'Tokelau', phone: '690' },
    { code: 'TL', label: 'Timor-Leste', phone: '670' },
    { code: 'TM', label: 'Turkmenistan', phone: '993' },
    { code: 'TN', label: 'Tunisia', phone: '216' },
    { code: 'TO', label: 'Tonga', phone: '676' },
    { code: 'TR', label: 'Turkey', phone: '90' },
    {
        code: 'TT',
        label: 'Trinidad and Tobago',
        phone: '1-868',
    },
    { code: 'TV', label: 'Tuvalu', phone: '688' },
    {
        code: 'TW',
        label: 'Taiwan',
        phone: '886',
    },
    {
        code: 'TZ',
        label: 'United Republic of Tanzania',
        phone: '255',
    },
    { code: 'UA', label: 'Ukraine', phone: '380' },
    { code: 'UG', label: 'Uganda', phone: '256' },
    {
        code: 'US',
        label: 'United States',
        phone: '1',
        suggested: true,
    },
    { code: 'UY', label: 'Uruguay', phone: '598' },
    { code: 'UZ', label: 'Uzbekistan', phone: '998' },
    {
        code: 'VA',
        label: 'Holy See (Vatican City State)',
        phone: '379',
    },
    {
        code: 'VC',
        label: 'Saint Vincent and the Grenadines',
        phone: '1-784',
    },
    { code: 'VE', label: 'Venezuela', phone: '58' },
    {
        code: 'VG',
        label: 'British Virgin Islands',
        phone: '1-284',
    },
    {
        code: 'VI',
        label: 'US Virgin Islands',
        phone: '1-340',
    },
    { code: 'VN', label: 'Vietnam', phone: '84' },
    { code: 'VU', label: 'Vanuatu', phone: '678' },
    { code: 'WF', label: 'Wallis and Futuna', phone: '681' },
    { code: 'WS', label: 'Samoa', phone: '685' },
    { code: 'XK', label: 'Kosovo', phone: '383' },
    { code: 'YE', label: 'Yemen', phone: '967' },
    { code: 'YT', label: 'Mayotte', phone: '262' },
    { code: 'ZA', label: 'South Africa', phone: '27' },
    { code: 'ZM', label: 'Zambia', phone: '260' },
    { code: 'ZW', label: 'Zimbabwe', phone: '263' },
];

export const RepatriationLlegaExpress = () => {
    const dispatch = useDispatch();
    const { t } = useTranslation();
    const { active, llegaRepatriations } = useSelector(state => state.llegaRepatriation);
    const [filterRepatriations, setFilterRepatriations] = useState([]);
    const [open, setOpen] = React.useState(false);
    const [birthday, setBirthday] = React.useState('');
    const [company, setCompany] = useState(1);
    const [refered, setRefered] = useState(1);
    const [seller, setSeller] = useState([]);
    const [disableCompanie, setDisableCompanie] = useState(false);
    const [selectedOriginCountry, setSelectedOriginCountry] = useState(countries[230]);
    const [selectedDestinationCountry, setSelectedDestinationCountry] = useState(countries[89])
    const [plan, setPlan] = useState(1);
    const [loading, setLoading] = useState(false);

    const { register, handleSubmit, setValue, control, formState: { errors } } = useForm({
        defaultValues: {
            displayName: "",
            phone: "",
            areaCode: "",
            email: "",
            identification: "",
            birthday: "",
            addressOrigin: "",
            cityOrigin: "",
            stateOrigin: "",
            countryOrigin: "",
            contactOrigin: "",
            areaCodeOrigin: "",
            phoneContactOrigin: "",
            address: "",
            cityholdDestination: "",
            departmentDestination: "",
            contactDestination: "",
            areaCodeDestination: "",
            phoneContactDestination: "",
            seller: "",
            userLlega: ""
        }
    })

    useEffect(() => {
        dispatch(startLoadingAllLlegaRepatriations());
        setValue('countryOrigin', countries[230])
        setValue('countryDestination', countries[89])
    }, [])

    useEffect(() => {
        let tmpData = [];
        if (llegaRepatriations) {
            llegaRepatriations.map((item) => {
                const {
                    id,
                    AuthNo,
                    ReferenceNo,
                    Total,
                    companyName,
                    correo,
                    dpi,
                    nombre,
                    telefono,
                    timestamp,
                    userLlega,
                    displayName,
                    identification,
                    email,
                    phone,
                    areaCode,
                    creationDate,
                    address,
                    countryDestination,
                    cityholdDestination,
                    departmentDestination,
                    contactDestination,
                    areaCodeDestination,
                    phoneContactDestination,
                    addressOrigin,
                    cityOrigin,
                    stateOrigin,
                    countryOrigin,
                    contactOrigin,
                    areaCodeOrigin,
                    phoneContactOrigin,
                    birthday,
                    sellerName
                } = item;

                const newData = {
                    id,
                    authNo: AuthNo,
                    ReferenceNo,
                    Total,
                    companyName,
                    correo,
                    dpi,
                    timestamp,
                    userLlega: 'Llega Express',
                    amount: Total,
                    plan: Total === 8 ? 'Mensual' : Total === 10 ? 'Menusal' : Total === 90 ? 'Anual' : Total === 100 ? 'Anual Plus' : '',
                    displayName: nombre,
                    identification: dpi,
                    email: correo,
                    CodigoArea: areaCode ? areaCode : '',
                    phone: telefono ? telefono : '',
                    // creationDate: moment(timestamp).format("DD/MM/YYYY") ? timestamp : '',
                    creationDate: moment(new Date(timestamp).getTime()).format("DD/MM/YYYY"),
                    DirecciónDestino: address,
                    address: address,
                    countryDestination: countryDestination,
                    cityholdDestination: cityholdDestination,
                    departmentDestination: departmentDestination,
                    contactDestination: contactDestination,
                    areaCodeDestination: areaCodeDestination,
                    phoneContactDestination: phoneContactDestination,
                    addressOrigin: addressOrigin,
                    cityOrigin: cityOrigin,
                    stateOrigin: stateOrigin,
                    countryOrigin: countryOrigin,
                    contactOrigin: contactOrigin,
                    areaCodeOrigin: areaCodeOrigin,
                    phoneContactOrigin: phoneContactOrigin,
                    birthday: birthday,
                    companyName: companyName,
                    sellerName: sellerName
                }
                tmpData.push(newData);

            }
            )
            setFilterRepatriations(tmpData);

        }
    }, [llegaRepatriations])

    useEffect(() => {

    }, [selectedOriginCountry])

    useEffect(() => {

    }, [birthday])

    useEffect(() => {

    }, [selectedDestinationCountry])

    const onEdit = (idRepatriation) => {
        setLoading(false);
        console.log("Entra onEdit", idRepatriation);
        let tmpLlegaRepatriation = llegaRepatriations.filter(x => x.id === idRepatriation);
        console.log('tmpLlegaRepatriation', tmpLlegaRepatriation);
        const {
            id,
            nombre,
            correo,
            dpi,
            telefono,
            AuthNo,
            ReferenceNo,
            Total,
            companyName,
            timestamp,
            displayName,
            identification,
            email,
            phone,
            areaCode,
            address,
            countryDestination,
            cityholdDestination,
            departmentDestination,
            contactDestination,
            areaCodeDestination,
            phoneContactDestination,
            addressOrigin,
            cityOrigin,
            stateOrigin,
            countryOrigin,
            contactOrigin,
            areaCodeOrigin,
            phoneContactOrigin
        } = tmpLlegaRepatriation[0];

        setValue('displayName', nombre);
        setValue('email', correo);
        setValue('identification', dpi);
        setValue('phone', telefono);
        setValue('areaCode', areaCode);
        setValue('addressOrigin', addressOrigin);
        setValue('cityOrigin', cityOrigin);
        setValue('stateOrigin', stateOrigin);
        setValue('contactOrigin', contactOrigin);
        setValue('areaCodeOrigin', areaCodeOrigin);
        setValue('phoneContactOrigin', phoneContactOrigin);
        setValue('address', address);
        setValue('cityholdDestination', cityholdDestination);
        setValue('departmentDestination', departmentDestination);
        setValue('contactDestination', contactDestination);
        setValue('areaCodeDestination', areaCodeDestination);
        setValue('phoneContactDestination', phoneContactDestination);

        console.log('birthday', birthday);
        // console.log('savedBirthday', savedBirthday);
        // moment(birthday.$d).format("DD/MM/YYYY")
        setBirthday(moment(tmpLlegaRepatriation[0].birthday).format("DD/MM/YYYY"));
        let countryOriginTmp = countries.filter(x => x.label === countryOrigin);
        console.log('countryOriginTmp', countryOriginTmp);
        let countryDestinationTmp = countries.filter(x => x.label === countryDestination);
        console.log('countryDestinationTmp', countryDestinationTmp);
        if (countryOriginTmp.length > 0) {
            setSelectedOriginCountry(countryOriginTmp[0]);
        }
        if (countryDestinationTmp.length > 0) {
            setSelectedDestinationCountry(countryDestinationTmp[0]);
        }
        // const newData = {
        //     nombre,
        //     correo,
        //     dpi,
        //     telefono,
        //     AuthNo,
        //     ReferenceNo,
        //     Total,
        //     companyName,
        //     timestamp
        // }
        dispatch(setActiveLlegaRepatriation({ id, nombre, correo, dpi, telefono, AuthNo, ReferenceNo, Total, companyName, timestamp }))
        setOpen(true);
    }

    const onSubmit = (data) => {
        console.log('data onSubmit', data);
        console.log('active', active);
        setLoading(true);

        const {
            id,
            AuthNo,
            ReferenceNo,
            Total,
            companyName,
            correo,
            dpi,
            nombre,
            telefono,
            timestamp
        } = active

        const {
            displayName,
            identification,
            email,
            phone,
            areaCode,
            address,
            addressOrigin,
            cityOrigin,
            stateOrigin,
            contactOrigin,
            areaCodeOrigin,
            phoneContactOrigin,
            countryDestination,
            cityholdDestination,
            departmentDestination,
            contactDestination,
            areaCodeDestination,
            phoneContactDestination,
        } = data;


        const newData = {
            id,
            AuthNo,
            ReferenceNo,
            Total,
            companyName,
            correo,
            dpi,
            nombre: displayName,
            telefono,
            timestamp,
            displayName,
            identification,
            email,
            phone,
            areaCode,
            address,
            countryDestination: selectedDestinationCountry.label,
            cityholdDestination,
            departmentDestination,
            contactDestination,
            areaCodeDestination,
            phoneContactDestination,
            addressOrigin,
            cityOrigin,
            stateOrigin,
            countryOrigin: selectedOriginCountry.label,
            contactOrigin,
            areaCodeOrigin,
            phoneContactOrigin,
            birthday: moment(birthday.$d).format("DD/MM/YYYY"),
        }
        console.log('newData', newData);
        dispatch(setActiveLlegaRepatriation(newData))
        if (newData) {
            dispatch(startSaveLlegaRepatriation());
            handleClose();
            onClear();
            showSuccess();
            dispatch(startLoadingAllLlegaRepatriations())
        }
        setLoading(false);
    }

    const showSuccess = () => {
        Swal.fire('Datos actualizados correctamente', 'Guardado', 'success');
    };

    const onClear = () => {
        setValue('', '')
        setValue('displayName', '')
        setValue('phone', '')
        setValue('areaCode', '')
        setValue('email', '')
        setValue('identification', '')
        // birthday: "",
        setValue('addressOrigin', '')
        setValue('cityOrigin', '')
        setValue('stateOrigin', '')
        setValue('countryOrigin', '')
        setValue('contactOrigin', '')
        setValue('areaCodeOrigin', '')
        setValue('phoneContactOrigin', '')
        setValue('address', '')
        setValue('cityholdDestination', '')
        setValue('departmentDestination', '')
        setValue('contactDestination', '')
        setValue('areaCodeDestination', '')
        setValue('phoneContactDestination', '')
    }

    const handleClose = () => {
        setOpen(false);
    };

    const handleKeyDown = (event) => {
        // Permitir solo números (0-9), retroceso y borrar
        if (
            !(
                event.key === 'Backspace' ||
                event.key === 'Delete' ||
                event.key === 'ArrowLeft' ||
                event.key === 'ArrowRight' ||
                event.key === 'ArrowUp' ||
                event.key === 'ArrowDown' ||
                (event.key >= '0' && event.key <= '9')
            )
        ) {
            event.preventDefault();
        }
    };

    const handleChangeSeller = (event) => {
        const {
            target: { value },
        } = event;
        setSeller(
            typeof value === 'string' ? value.split(',') : value,
        );
    };

    const handleChangePlan = (event) => {
        const {
            target: { value },
        } = event;
        setPlan(value);
    };

    const handleChangeOriginCountry = (newValue) => {
        setSelectedOriginCountry(newValue);
    };

    const handleChangeDestinationCountry = (newValue) => {
        setSelectedDestinationCountry(newValue);
    };

    // const handleChangeCompany = (event) => {


    //     const {
    //         target: { value },
    //     } = event;

    //     let tmpSellers = [];
    //     let tmpCompanie = repCountries.filter(x => x.id === value);

    //     if (value === 1) {
    //         setShowSellers(false);
    //     } else {
    //         if (tmpCompanie?.length > 0) {
    //             tmpSellers = sellers.filter(x => x.companyId === tmpCompanie[0].companyId);
    //         }

    //         if (tmpSellers?.length > 0) {
    //             setSellersByCompany(tmpSellers);
    //             setShowSellers(true);
    //         } else {
    //             setSellersByCompany([]);
    //             setShowSellers(false);
    //         }

    //     }
    //     setCompany(
    //         typeof value === 'string' ? value.split(',') : value,
    //     );
    // };

    const columns = [
        { field: 'userLlega', headerName: 'Usuario Llega', width: 100 },
        { field: 'ReferenceNo', headerName: 'Contrato', width: 100 },
        { field: 'authNo', headerName: 'Auth Number', width: 100 },
        { field: 'amount', headerName: 'Monto', width: 100 },
        { field: 'plan', headerName: 'Plan', width: 100 },
        { field: 'displayName', headerName: 'Nombre', width: 200 },
        { field: 'identification', headerName: 'Identificación', width: 150 },
        { field: 'email', headerName: 'Correo', width: 250 },
        { field: 'phone', headerName: 'Teléfono', width: 100 },
        { field: 'creationDate', headerName: 'Fecha', width: 100 },
        { field: 'address', headerName: 'Dirección Destino', width: 300 },
        { field: 'countryDestination', headerName: 'Pais Destino', width: 300 },
        { field: 'cityholdDestination', headerName: 'Municipio Destino', width: 300 },
        { field: 'departmentDestination', headerName: 'Departamento Destino', width: 300 },
        { field: 'contactDestination', headerName: 'Contacto Destino', width: 250 },
        { field: 'areaCodeDestination', headerName: 'Codigo Area Destino', width: 150 },
        { field: 'phoneContactDestination', headerName: 'Telefono Contacto Destino', width: 200 },
        { field: 'addressOrigin', headerName: 'Dirección Origen', width: 300 },
        { field: 'cityOrigin', headerName: 'Ciudad Origen', width: 300 },
        { field: 'stateOrigin', headerName: 'Estado Origen', width: 300 },
        { field: 'countryOrigin', headerName: 'Pais Origen', width: 300 },
        { field: 'contactOrigin', headerName: 'Contacto Origen', width: 200 },
        { field: 'areaCodeOrigin', headerName: 'Codigo Area Origen', width: 150 },
        { field: 'phoneContactOrigin', headerName: 'Telefono Contacto Origen', width: 200 },
        { field: 'birthday', headerName: 'Fecha Nacimiento', width: 150 },
        { field: 'companyName', headerName: 'Compañía', width: 150 },
        { field: 'sellerName', headerName: 'Vendedor', width: 150 },
        { field: 'id', headerName: 'Opciones', width: 250, renderCell: (params) => <><button className='w-16 h-8 bg-blue-800 text-white ml-2 rounded-full' onClick={() => onEdit(params.value)}>Editar</button></> }
    ]

    return (
        <>
            <div className='flex flex-col bg-white w-full place-items-center h-[870px] pt-20 pb-20'>
                <div className='w-32 h-8 bg-blue-800 text-white rounded-2xl'>
                    <div className='pt-1'>
                        <CSVLink data={filterRepatriations}>Exportar</CSVLink>
                    </div>
                </div>

                <div className=' overflow-scroll w-full pt-2 pl-4 pr-4'>
                    <DataGrid
                        rows={filterRepatriations}
                        columns={columns}
                        initialState={{
                            pagination: {
                                paginationModel: { page: 0, pageSize: 5 },
                            },
                        }}
                        pageSizeOptions={[5, 10]}

                    />
                </div>
                <Modal
                    open={open}
                    onClose={handleClose}
                    aria-labelledby="modal-modal-title"
                    aria-describedby="modal-modal-description"

                >
                    <Box sx={style} className='w-[370px] md:w-[400px] lg:w-[800px] h-[800px]'>
                        <div className='w-full min-h-[500px] pt-10 bg-white pb-12'>
                            <div className='flex justify-center place-content-center items-center'>
                                <div className='lg:w-10/12 bg-[#303030] rounded-3xl ml-4 mr-4'>
                                    <div className='w-full flex flex-row pt-8 pl-4 pr-4'>
                                        <p className="w-full text-center text-white text-2xl lg:text-3xl font-extrabold leading-10">Actualizar datos Repatriación Llega</p>
                                    </div>

                                    <div className='w-full lg:flex lg:flex-row pt-4 pl-4 lg:pl-8 pr-4 lg:pr-8'>

                                        <div className='w-full lg:w-1/2 lg:pr-2'>
                                            <div className='w-full'>
                                                <p className="text-white text-left text-xl font-semibold leading-10">{t('repatriation.name')}*</p>
                                            </div>
                                            <div className='w-full pt-2'>
                                                <input name='name' className="w-full h-12 bg-white rounded-full px-4" {...register('displayName', { required: true })}></input>
                                            </div>
                                        </div>

                                        <div className='w-full lg:w-1/2 lg:pl-2 pt-2'>
                                            <div className='w-full'>
                                                <p className="text-white text-left text-xl font-semibold leading-10">{t('repatriation.email')}*</p>
                                            </div>
                                            <div className='w-full '>
                                                <input name='email' className="w-full h-12 bg-white rounded-full px-4" {...register('email', { required: true })}></input>
                                            </div>
                                        </div>
                                    </div>

                                    <div className='w-full lg:flex lg:flex-row pt-4 pl-4 lg:pl-8 pr-4 lg:pr-8'>

                                        <div className='w-full lg:w-1/2 lg:pr-2 '>
                                            <div className='w-full'>
                                                <p className="text-white text-left text-xl font-semibold leading-10">{t('repatriation.identification')}*</p>
                                            </div>
                                            <div className='w-full pt-2'>
                                                <input name='identification' className="w-full h-14 bg-white rounded-full px-4" {...register('identification', { required: true })}></input>
                                            </div>
                                        </div>

                                        <div className='w-full lg:w-1/2 lg:pl-2'>
                                            <div className='w-full'>
                                                <p className="text-white text-left text-xl font-semibold leading-10">{t('repatriation.birthday')}*</p>
                                            </div>
                                            <div className='w-full '>
                                                <LocalizationProvider dateAdapter={AdapterDayjs} className='rounded-full ' >
                                                    <DemoContainer components={['DatePicker']} sx={{ border: 'none' }}>
                                                        <DatePicker value={birthday} onChange={(newValue) => setBirthday(newValue)} className=" w-full bg-white rounded-full"
                                                            sx={{
                                                                '& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline': { border: 'none' }
                                                                // '& .MuiOutlinedInput-root:hoover .MuiOutlinedInput-notchedOutline': { border: 'none' },
                                                                // '& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': { border: 'none' }
                                                            }}

                                                        />
                                                    </DemoContainer>
                                                </LocalizationProvider>
                                            </div>
                                        </div>
                                    </div>

                                    <div className='w-full lg:flex lg:flex-row pt-4 pl-4 lg:pl-8 pr-4 lg:pr-8'>

                                        <div className='w-full lg:w-2/6 lg:pr-2'>
                                            <div className='w-full'>
                                                <p className="text-white text-left text-xl font-semibold leading-10">{t('repatriation.areaCode')}*</p>
                                            </div>
                                            <div className='w-full'>
                                                <input name='areaCode' maxLength={5} className="w-full h-12 bg-white rounded-full px-4" {...register('areaCode', { required: true })} onKeyDown={handleKeyDown}></input>
                                            </div>
                                        </div>

                                        <div className='w-full lg:w-4/6 lg:pl-2'>
                                            <div className='w-full'>
                                                <p className="text-white text-left text-xl font-semibold leading-10">{t('repatriation.phone')}*</p>
                                            </div>
                                            <div className='w-full '>
                                                <input name='phone' maxLength={12} className="w-full h-12 bg-white rounded-full px-4" {...register('phone', { required: true })} onKeyDown={handleKeyDown}></input>
                                            </div>
                                        </div>

                                    </div>

                                    <hr className='mt-6 ml-4 lg:ml-8 mr-4 lg:mr-8' />

                                    <div className='w-full pt-4 pl-4 lg:pl-8 pr-4 lg:pr-8'>
                                        <div className='w-full'>
                                            <p className="text-white text-left text-xl font-semibold leading-10">{t('repatriation.addressOrigin')}*</p>
                                        </div>
                                        <div className='w-full'>
                                            <input name='addressOrigin' className="w-full h-12 bg-white rounded-full px-4" {...register('addressOrigin', { required: true })}></input>
                                        </div>
                                    </div>

                                    <div className='w-full lg:flex lg:flex-row pt-4 pl-4 lg:pl-8 pr-4 lg:pr-8'>

                                        <div className='w-full lg:w-1/2 lg:pr-2'>
                                            <div className='w-full'>
                                                <p className="text-white text-left text-xl font-semibold leading-10">{t('repatriation.city')}*</p>
                                            </div>
                                            <div className='w-full'>
                                                <input name='cityOrigin' className="w-full h-12 bg-white rounded-full px-4" {...register('cityOrigin', { required: true })}></input>
                                            </div>
                                        </div>

                                        <div className='w-full lg:w-1/2 lg:pl-2'>
                                            <div className='w-full'>
                                                <p className="text-white text-left text-xl font-semibold leading-10">{t('repatriation.state')}*</p>
                                            </div>
                                            <div className='w-full '>
                                                <input name='stateOrigin' className="w-full h-12 bg-white rounded-full px-4" {...register('stateOrigin', { required: true })}></input>
                                            </div>
                                        </div>

                                    </div>

                                    <div className='w-full lg:flex lg:flex-row pt-4 pl-4 lg:pl-8 pr-4 lg:pr-8'>

                                        <div className='w-full lg:w-1/2 lg:pr-2'>
                                            <div className='w-full'>
                                                <p className="text-white text-left text-xl font-semibold leading-10">{t('repatriation.countryOrigin')}*</p>
                                            </div>
                                            <div className='w-full'>
                                                {/* <input name='countryOrigin' className="w-full h-12 bg-white rounded-full px-4" {...register('countryOrigin', { required: true })}></input> */}
                                                <Autocomplete
                                                    id="country-select-demo"
                                                    // sx={{ width: 300 }}
                                                    value={selectedOriginCountry}
                                                    onChange={handleChangeOriginCountry}
                                                    options={countries}
                                                    autoHighlight
                                                    getOptionLabel={(option) => option.label}
                                                    className="w-full h-12 bg-white"
                                                    sx={{ borderRadius: 10, '& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline': { border: 'none' } }}
                                                    renderOption={(props, option) => (
                                                        <Box component="li" sx={{ '& > img': { mr: 2, flexShrink: 0 } }} {...props}>
                                                            <img
                                                                loading="lazy"
                                                                width="20"
                                                                srcSet={`https://flagcdn.com/w40/${option.code.toLowerCase()}.png 2x`}
                                                                src={`https://flagcdn.com/w20/${option.code.toLowerCase()}.png`}
                                                                alt=""
                                                            />
                                                            {/* {option.label} */}
                                                            {option.label} ({option.code}) +{option.phone}
                                                        </Box>
                                                    )}
                                                    renderInput={(params) => (
                                                        <TextField
                                                            {...params}

                                                            // label="Choose a country"                                                        
                                                            inputProps={{
                                                                ...params.inputProps,
                                                                autoComplete: 'new-password', // disable autocomplete and autofill
                                                            }}
                                                            {...register('countryOrigin', { required: true })}
                                                        />
                                                    )}
                                                />
                                            </div>
                                        </div>

                                        <div className='w-full lg:w-1/2 lg:pl-2'>
                                            <div className='w-full'>
                                                <p className="text-white text-left text-xl font-semibold leading-10">{t('repatriation.contact')}*</p>
                                            </div>
                                            <div className='w-full '>
                                                <input name='contactOrigin' className="w-full h-12 bg-white rounded-full px-4" {...register('contactOrigin', { required: true })}></input>
                                            </div>
                                        </div>

                                    </div>

                                    <div className='w-full lg:flex lg:flex-row pt-4 pl-4 lg:pl-8 pr-4 lg:pr-8'>

                                        <div className='w-full lg:w-2/6 lg:pr-2'>
                                            <div className='w-full'>
                                                <p className="text-white text-left text-xl font-semibold leading-10">{t('repatriation.areaCodeOrigin')}*</p>
                                            </div>
                                            <div className='w-full'>
                                                <input name='areaCodeOrigin' maxLength={5} className="w-full h-12 bg-white rounded-full px-4" {...register('areaCodeOrigin', { required: true })} onKeyDown={handleKeyDown}></input>
                                            </div>
                                        </div>

                                        <div className='w-full lg:w-4/6 lg:pl-2'>
                                            <div className='w-full'>
                                                <p className="text-white text-left text-xl font-semibold leading-10">{t('repatriation.phoneContactOrigin')}*</p>
                                            </div>
                                            <div className='w-full '>
                                                <input name='phoneContactOrigin' maxLength={12} className="w-full h-12 bg-white rounded-full px-4" {...register('phoneContactOrigin', { required: true })} onKeyDown={handleKeyDown}></input>
                                            </div>
                                        </div>

                                    </div>


                                    <hr className='mt-6 ml-4 lg:ml-8 mr-4 lg:mr-8' />

                                    <div className='w-full pt-4 pl-4 lg:pl-8 pr-4 lg:pr-8'>
                                        <div className='w-full'>
                                            <p className="text-white text-left text-xl font-semibold leading-10">{t('repatriation.addressDestiny')}*</p>
                                        </div>
                                        <div className='w-full'>
                                            <input name='address' className="w-full h-12 bg-white rounded-full px-4" {...register('address', { required: true })}></input>
                                        </div>
                                    </div>

                                    <div className='w-full lg:flex lg:flex-row pt-4 pl-4 lg:pl-8 pr-4 lg:pr-8'>

                                        <div className='w-full lg:w-1/2 lg:pr-2'>
                                            <div className='w-full'>
                                                <p className="text-white text-left text-xl font-semibold leading-10">{t('repatriation.cityholdDestination')}*</p>
                                            </div>
                                            <div className='w-full'>
                                                <input name='cityholdDestination' className="w-full h-12 bg-white rounded-full px-4" {...register('cityholdDestination', { required: true })}></input>
                                            </div>
                                        </div>

                                        <div className='w-full lg:w-1/2 lg:pl-2'>
                                            <div className='w-full'>
                                                <p className="text-white text-left text-xl font-semibold leading-10">{t('repatriation.departmentDestination')}*</p>
                                            </div>
                                            <div className='w-full '>
                                                <input name='departmentDestination' className="w-full h-12 bg-white rounded-full px-4" {...register('departmentDestination', { required: true })}></input>
                                            </div>
                                        </div>

                                    </div>

                                    <div className='w-full lg:flex lg:flex-row pt-4 pl-4 lg:pl-8 pr-4 lg:pr-8'>

                                        <div className='w-full lg:w-1/2 lg:pr-2'>
                                            <div className='w-full'>
                                                <p className="text-white text-left text-xl font-semibold leading-10">{t('repatriation.countryDestination')}*</p>
                                            </div>
                                            <div className='w-full'>
                                                {/* <input name='countryOrigin' className="w-full h-12 bg-white rounded-full px-4" {...register('countryOrigin', { required: true })}></input> */}
                                                <Autocomplete
                                                    id="country-select-demo"
                                                    // sx={{ width: 300 }}
                                                    value={selectedDestinationCountry}
                                                    onChange={handleChangeDestinationCountry}
                                                    options={countries}
                                                    autoHighlight
                                                    getOptionLabel={(option) => option.label}
                                                    // defaultValue='US'
                                                    className="w-full h-12 bg-white"
                                                    sx={{ borderRadius: 10, '& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline': { border: 'none' } }}
                                                    renderOption={(props, option) => (
                                                        <Box component="li" sx={{ '& > img': { mr: 2, flexShrink: 0 } }} {...props}>
                                                            <img
                                                                loading="lazy"
                                                                width="20"
                                                                srcSet={`https://flagcdn.com/w40/${option.code.toLowerCase()}.png 2x`}
                                                                src={`https://flagcdn.com/w20/${option.code.toLowerCase()}.png`}
                                                                alt=""
                                                            />
                                                            {option.label} ({option.code}) +{option.phone}
                                                        </Box>
                                                    )}
                                                    renderInput={(params) => (
                                                        <TextField
                                                            {...params}
                                                            // label="Choose a country"
                                                            inputProps={{
                                                                ...params.inputProps,
                                                                autoComplete: 'new-password', // disable autocomplete and autofill
                                                            }}
                                                            {...register('countryDestination', { required: true })}
                                                        />
                                                    )}
                                                />
                                            </div>
                                        </div>

                                        <div className='w-full lg:w-1/2 lg:pl-2'>
                                            <div className='w-full'>
                                                <p className="text-white text-left text-xl font-semibold leading-10">{t('repatriation.contactDestination')}*</p>
                                            </div>
                                            <div className='w-full '>
                                                <input name='contactDestination' className="w-full h-12 bg-white rounded-full px-4" {...register('contactDestination', { required: true })}></input>
                                            </div>
                                        </div>

                                    </div>

                                    <div className='w-full lg:flex lg:flex-row pt-4 pl-4 lg:pl-8 pr-4 lg:pr-8'>

                                        <div className='w-full lg:w-2/6 lg:pr-2'>
                                            <div className='w-full'>
                                                <p className="text-white text-left text-xl font-semibold leading-10">{t('repatriation.areaCodeDestination')}*</p>
                                            </div>
                                            <div className='w-full'>
                                                <input name='areaCodeDestination' maxLength={5} className="w-full h-12 bg-white rounded-full px-4" {...register('areaCodeDestination', { required: true })} onKeyDown={handleKeyDown}></input>
                                            </div>
                                        </div>

                                        <div className='w-full lg:w-4/6 lg:pl-2'>
                                            <div className='w-full'>
                                                <p className="text-white text-left text-xl font-semibold leading-10">{t('repatriation.phoneContactDestination')}*</p>
                                            </div>
                                            <div className='w-full '>
                                                <input name='phoneContactDestination' maxLength={12} className="w-full h-12 bg-white rounded-full px-4" {...register('phoneContactDestination', { required: true })} onKeyDown={handleKeyDown}></input>
                                            </div>
                                        </div>

                                    </div>

                                    {!loading &&
                                        <div className='w-full flex flex-row pt-12 justify-center pb-10'>
                                            <button type='button' className="w-80 h-12 bg-cyan-300 rounded-full" onClick={handleSubmit(onSubmit)}>Guardar</button>
                                        </div>
                                    }
                                    {loading &&
                                        <div className='pt-10 pb-10 place-content-center justify-center'>
                                            <Box sx={{ display: 'flex w-full' }}>
                                                <CircularProgress />
                                            </Box>
                                        </div>
                                    }


                                </div>

                            </div>
                        </div>

                    </Box>
                </Modal>
            </div>
        </>
    )
}
