import { collection, count, deleteDoc, doc, setDoc } from "firebase/firestore/lite";
import { FirebaseDB } from "../../firebase/config";
import { addNewEmptyRepCountry, deleteRepCountryById, savingNewRepCountry, setActiveRepCountry, setRepCountries, setSaving, setRepCountry, updateRepCountry } from "./repatriationCountrySlice";
import { loadAllRepCountries, loadRepCountries, loadRepCountry } from "../../helpers/loadRepCountries";


export const startNewClearRepCountrie = (repCountrie) => {
    return async (dispatch, getState) => {
        dispatch(savingNewRepCountry());
        const { uid } = getState().auth;

        const {
            companyId,
            companyName,
            referredby,
            referredName,
            status
        } = repCountrie;

        const newRepCountry = {
            uid,
            companyId,
            companyName,
            referredby,
            referredName,
            status,
            creationDate: new Date().getTime(),
            updateDate: new Date().getTime()
        }

        const newDoc = doc(collection(FirebaseDB, `repCountrie`))
        await setDoc(newDoc, newRepCountry);
        newRepCountry.id = newDoc.id;
        dispatch(addNewEmptyRepCountry(newRepCountry));
        dispatch(setActiveRepCountry(newRepCountry));

    }
}

export const startNewRepCountry = (repCountry) => {
    return async (dispatch, getState) => {
        dispatch(savingNewRepCountry());

        const { uid } = getState().auth;
        const {
            companyId,
            companyName,
            referredby,
            referredName,
            status,
        } = repCountry;

        const newRepCountry = {
            uid,
            companyId,
            companyName,
            referredby,
            referredName,
            status,
            creationDate: new Date().getTime(),
            updateDate: new Date().getTime()
        }

        const newDoc = doc(collection(FirebaseDB, `repCountry`));
        await setDoc(newDoc, newRepCountry);
        newRepCountry.id = newDoc.id;

        dispatch(addNewEmptyRepCountry(newRepCountry));
        dispatch(setActiveRepCountry(newRepCountry));

    }
}

export const startLoadingRepCountries = () => {
    return async (dispatch, getState) => {
        const { uid } = getState().auth;

        if (!uid) throw new Error('El UID del usuario no existe');
        const repCountries = await loadRepCountries(uid);
        dispatch(setRepCountries(repCountries));
    }
}

export const startLoadingAllRepCountries = () => {
    return async (dispatch, getState) => {
        // const { uid } = getState().auth;

        // if (!uid) throw new Error('El UID del usuario no existe');
        const repCountries = await loadAllRepCountries();
        dispatch(setRepCountries(repCountries));
    }
}

export const startLoadingRepCountry = () => {
    return async (dispatch, getState) => {
        const { uid } = getState().auth;

        if (!uid) throw new Error("El UID del usuario no existe");
        const repCountry = await loadRepCountry(uid);
        dispatch(setRepCountry(repCountry));
    }
}

export const startDeletingRepCountry = () => {
    return async (dispatch, getState) => {
        const { uid } = getState().auth;
        const { active: repCountry } = getState().repCountry;

        const docRef = doc(FirebaseDB, `repCountry/${repCountry.id}`);
        await deleteDoc(docRef);

        dispatch(deleteRepCountryById(repCountry.id));
    }
}

export const startSaveRepCountry = () => {
    return async (dispatch, getState) => {

        dispatch(setSaving());

        const { uid } = getState().auth;
        const { active: repCountry } = getState().repCountry;

        const repCountryToFireStore = { ...repCountry };
        delete repCountryToFireStore.id;

        const docRef = doc(FirebaseDB, `repCountry/${repCountry.id}`)
        await setDoc(docRef, repCountryToFireStore, { merge: true });

        dispatch(updateRepCountry(repCountry));
    }
}