import './App.css';
import { Header } from './pages/Header';
import { CarouselPage } from './pages/CarouselPage';
import { CarouselVideo } from './pages/CarouselVideo';
import { DownloadApp } from './pages/DownloadApp';
import { References } from './pages/References';
import { Products } from './pages/Products';
import { WarningPage } from './pages/WarningPage';
import { RegisterLlegaMarket } from './pages/RegisterLlegaMarket';
import { Footer } from './pages/Footer';
import { Route, Routes, useNavigate, useLocation } from 'react-router-dom';
import { Home } from './pages/Home';
import { Market } from './pages/Market';
import { Stores } from './pages/Stores';
import { Questions } from './pages/Questions';
import { Contact } from './pages/Contact';
import { useState, useEffect } from 'react';
import { LoginPage } from './auth/pages/LoginPage';
import { RegisterPage } from './auth/pages/RegisterPage';
import { Categories } from './pages/Categories';
import { Companies } from './pages/Companies';

import { useCheckAuth } from './hooks/useCheckAuth';
import { AppTopbar } from './pages/AppTopbar';
import { AppMenu } from './pages/AppMenu';
import { Dashboard } from './pages/Dashboard';
import { LlegaExpress } from './pages/LlegaExpress';
import { Repatriations } from './pages/Repatriations';
import { RepatriationCompanies } from './pages/RepatriationCompanies';
import { Country } from './pages/Country';
import { RepatriationSeller } from './pages/RepatriationSeller';
import { RepatriationForm } from './components/RepatriationForm';
import { MeetUs } from './pages/MeetUs';
import { Video } from './pages/Video';
import { RepatriationAdmin } from './pages/RepatriationAdmin';
import "@fontsource/inter"; // Defaults to weight 400
import { Referred } from './pages/Referred';
import { RepatriationPayment } from './components/RepatriationPayment';
import { RepatriationPayments } from './pages/RepatriationPayments';
import { RepatriationLlegaExpress } from './pages/RepatriationLlegaExpress';
import { RepatriationFormSimple } from './components/RepatriationFormSimple';

function App() {
  const status = useCheckAuth();
  const navigate = useNavigate();

  useEffect(() => {
    if (status === 'authenticated') {
      navigate('/')
    }
  }, [status])

  return (
    <div className="App">
      {status === 'not-authenticated' &&
        <>
          <Header />

          <Routes>
            <Route path="/" Component={Home} />
            <Route path="/market" Component={Market} />
            <Route path='/store/:id' Component={Stores} />
            <Route path="/questions" Component={Questions} />
            <Route path="/contact" Component={Contact} />
            <Route path='/login' Component={LoginPage} />
            <Route path='/register' Component={RegisterPage} />
            <Route path='/llegaexpress' Component={LlegaExpress} />
            <Route path='/repatriation' Component={Repatriations} />
            {/* <Route path='/repatriationform' Component={RepatriationForm} /> */}
            <Route path='/repatriationform' Component={RepatriationFormSimple} />
            <Route path='/payment' Component={RepatriationPayment} />
            <Route path='/meetus' Component={MeetUs} />
          </Routes>
          <Footer />
        </>
      }

      {status === 'authenticated' &&
        <>
          <div className="bg-[#D7DEE1] flex flex-row justify-center gap-8 relative items-start px-2 py-12" >
            <AppMenu />
            <div className="flex flex-col justify-start gap-8 relative w-3/4 items-center" >
              <AppTopbar />
              <Routes>
                <Route path="/" Component={Dashboard} />
                <Route path='/dashboard' Component={Dashboard} />
                <Route path='/categories' Component={Categories} />
                <Route path='/companies' Component={Companies} />
                <Route path='/repatriationcompanies' Component={RepatriationCompanies} />
                <Route path='/repatriationadmin' Component={RepatriationAdmin} />
                <Route path='/repatriationpayment' Component={RepatriationPayments} />
                <Route path='/repatriationllegaexpress' Component={RepatriationLlegaExpress} />
                <Route path='/sellers' Component={RepatriationSeller} />
                <Route path='/referred' Component={Referred} />
                <Route path='/countries' Component={Country} />
                <Route path='/videos' Component={Video} />
              </Routes>
            </div>
          </div>
        </>
      }
    </div>
  );
}

export default App;
