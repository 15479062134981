import { FormControl, InputLabel, MenuItem, Select } from '@mui/material';
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import Swal from 'sweetalert2';
import { useForm } from 'react-hook-form';
import { DataGrid } from '@mui/x-data-grid';
import { setActiveRepatriation } from '../store/Repatriations/repatriationSlice';
import { startDeletingRepCountry, startLoadingRepCountries, startNewRepCountry, startSaveRepCountry } from '../store/RepatriationCountry/thunks';
import { setActiveRepCountry } from '../store/RepatriationCountry/repatriationCountrySlice';

const state = [
    { name: 'Activo' },
    { name: 'Inactivo' }
]

export const RepatriationCompanies = () => {
    const dispatch = useDispatch();
    const [status, setStatus] = useState('');
    const { companies } = useSelector(state => state.companie);
    const { repCountries } = useSelector(state => state.repCountry)
    const { errorMessage } = useSelector(state => state.auth);
    const { partners } = useSelector(state => state.partner);
    const [selectedCompanie, setSelectedCompanie] = useState('');
    const [edit, setEdit] = useState(false);
    const [companyId, setCompanyId] = useState('');
    const [updateRepCountries, setUpdateRepCountries] = useState(false);
    const [deleteRepCountry, setDeleteRepCountry] = useState(false);
    const [selectedReferred, setSelectedReferred] = useState('');

    const { register, handleSubmit, setValue, control, formState: { errors } } = useForm({
        defaultValues: {
            companyName: "",
            description: "",
            state: ""
        }
    });

    const columns = [
        { field: 'companyName', headerName: 'Nombre', width: 200 },
        { field: 'referredName', headerName: 'Referred by', width: 200 },
        { field: 'status', headerName: 'Estado', width: 200 },
        { field: 'id', headerName: 'Opciones', width: 250, renderCell: (params) => <><button className='w-16 h-8 bg-blue-800 text-white rounded-full' onClick={() => onEdit(params.value)}>Editar</button><button className='w-16 h-8 bg-red-800 text-white ml-2 rounded-full' onClick={() => onDelete(params.value)}>Eliminar</button></> }
    ]

    useEffect(() => {
        if (updateRepCountries) {
            dispatch(startLoadingRepCountries());
        }
    }, [updateRepCountries])

    const onSubmit = (data) => {
        let tmpCompany = companies.filter((x) => x.id === selectedCompanie);
        let tmpCompanyReferred = companies.filter((x) => x.id === selectedReferred);
        const { id, name } = tmpCompany[0];
        const { id: referredId, name: referredName } = tmpCompanyReferred[0];
        if (edit) {
            const newData = {
                id: companyId,
                companyName: name,
                companyId: id,
                referredby: selectedReferred,
                referredName: referredName,
                status: status
            }
            dispatch(setActiveRepCountry(newData));
            if (newData) {
                onUpdateRegister(newData);
                if (!errorMessage) {
                    showUpdateSuccess();
                    onClear();
                    setUpdateRepCountries(true);
                }
            }
        } else {
            const newData = {
                companyName: name,
                companyId: id,
                referredby: selectedReferred,
                referredName: referredName,
                status: status
            }
            dispatch(setActiveRepatriation(newData));
            if (newData) {
                // showSuccess();
                onSaveNewRepatriationCompany(newData);
            }
        }

    }

    const onUpdateRegister = () => {
        dispatch(startSaveRepCountry());
    }

    const handleChangeRefered = (event) => {
        const {
            target: { value },
        } = event;

        setSelectedReferred(
            value,
        );
    }

    const onSaveNewRepatriationCompany = (data) => {
        dispatch(startNewRepCountry(data));
        if (!errorMessage) {
            showSuccess();
            onClear();
        } else {
            showError();
        }
    }

    const onEdit = (idCompany) => {
        setEdit(true);
        setEdit((state) => {
            return state;
        });

        let tmpRepCountries = repCountries.filter(x => x.id === idCompany);
        const { id, companyId, referredby, referredName, companyName, status } = tmpRepCountries[0];
        dispatch(setActiveRepCountry({ id, companyId, referredby, referredName, companyName, status }));
        setCompanyId(id);
        setCompanyId((state) => {
            return state;
        })
        setSelectedCompanie(companyId);
        setSelectedReferred(referredby);
        setStatus(status);

    }

    useEffect(() => {
        if (deleteRepCountry) {
            dispatch(startLoadingRepCountries());
        }
    }, [deleteRepCountry])

    const onDelete = (idCompany) => {
        let tmpRepCountry = repCountries.filter(x => x.id === idCompany);
        const { id, companyId, referredby, referredName, companyName, status } = tmpRepCountry[0];
        dispatch(setActiveRepCountry({ id, companyId, referredby, referredName, companyName, status }))
        setDeleteRepCountry(true);
        dispatch(startDeletingRepCountry(id));
        if (!errorMessage) {
            showDeleteSuccess();
            onClear();
        }

    }

    const handleChangeStatus = (event) => {
        const {
            target: { value },
        } = event;
        setStatus(
            value,
        );
    }

    const handleChangeCompanie = (event) => {
        const {
            target: { value },
        } = event;
        setSelectedCompanie(
            value,
        );
    }

    const onClear = () => {
        setValue('companyName', "");
        setStatus('');
        setSelectedCompanie('');
        setSelectedReferred('');
    }

    const showDeleteSuccess = () => {
        Swal.fire('El comercio fué eliminado correctamente', "Eliminado", 'success');
    }

    const showSuccess = () => {
        Swal.fire('Comercio creado correctamente', 'Guardado', 'success');
    };

    const showUpdateSuccess = () => {
        Swal.fire('Comercio actualizado correctamente', 'Guardado', 'success');
    };

    const showError = () => {
        Swal.fire('El Comercio no fué creado', 'Error', 'error');
    };


    return (
        <div className='flex flex-col bg-white w-full place-items-center h-[870px]'>
            <div className='w-full pt-10 pl-32 pr-32'>
                <form onSubmit={handleSubmit(onSubmit)}>
                    <div className='flex flex-row w-full pt-4'>
                        <FormControl className='w-full'>
                            <InputLabel>Comercio</InputLabel>
                            <Select
                                placeholder='Comercio'
                                label='Comercio'
                                value={selectedCompanie}
                                onChange={handleChangeCompanie}
                            >
                                {companies.filter(x => x.name !== 'Llega Market').map((item) => (
                                    <MenuItem key={item.id} value={item.id}>
                                        {item.name}
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    </div>
                    <div className='flex flex-row w-full pt-4'>
                        <FormControl className='w-full'>
                            <InputLabel>Estado</InputLabel>
                            <Select
                                placeholder='Estado'
                                label='Estado'
                                value={status}
                                onChange={handleChangeStatus}
                            >
                                {state.map((item) => (
                                    <MenuItem key={item.name} value={item.name}>
                                        {item.name}
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    </div>
                    <div className='flex flex-row w-full pt-4'>
                        <FormControl className='w-full'>
                            <InputLabel>Referido De:</InputLabel>
                            <Select
                                placeholder='Comercio'
                                label='Comercio'
                                value={selectedReferred}
                                onChange={handleChangeRefered}
                            >
                                {repCountries.map((item) => (
                                    <MenuItem key={item.companyName} value={item.companyId}>
                                        {item.companyName}
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    </div>
                    <div className='flex flex-row pt-10 justify-end w-full'>
                        <div className='w-1/4'><button type="button" className='w-full h-10 bg-red-700 text-white rounded-full font-medium' onClick={() => onClear()}>Limpiar</button></div>
                        <div className='w-1/4 pl-2'><button type="submit" className='w-full h-10 bg-sky-900 text-white rounded-full font-medium'>Guardar</button></div>
                    </div>
                </form>
            </div>
            <div className='flex flex-row w-full pl-32 pr-32 pt-10'>
                <DataGrid
                    rows={repCountries}
                    columns={columns}
                    initialState={{
                        pagination: {
                            paginationModel: { page: 0, pageSize: 5 },
                        },
                    }}
                    pageSizeOptions={[5, 10]}
                />
            </div>
        </div>
    )
}
