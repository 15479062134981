// import React from 'react'
// import { Carousel } from 'react-responsive-carousel';

// export const Connect = () => {
//     return (
//         <>
//             <Carousel
//                 infiniteLoop
//                 autoPlay
//                 showStatus={false}
//                 showThumbs={false}
//                 showArrows={true}
//                 showIndicators={false}
//                 className='relative'
//             >
//                 <div className='relative bg-[#4F4170]'>
//                     <img src='https://firebasestorage.googleapis.com/v0/b/llega-market.appspot.com/o/Services%2FRectangle%20179.png?alt=media&token=fa10c901-8df0-4cee-8632-c24e8638905c' alt='' />

//                     <div className='absolute'>
//                         <div>

//                         </div>
//                     </div>
//                 </div>

//             </Carousel>
//         </>
//     )
// }

import React, { useRef } from 'react'
import { useNavigate } from 'react-router-dom';
import { Paper, Button } from '@mui/material'
import { ArrowLeft, ArrowRight, BorderClear } from '@mui/icons-material';
import { useTranslation } from 'react-i18next'
import { Carousel } from 'react-responsive-carousel';
import "react-responsive-carousel/lib/styles/carousel.min.css";

export const Connect = () => {
    const { i18n, t } = useTranslation();
    const sliderRef = useRef(null);
    const navigate = useNavigate();

    const items = [
        {
            name: "Random Name #1",
            description: "Probably the most random thing you have ever seen!"
        },
        {
            name: "Random Name #2",
            description: "Hello World!"
        }
    ]

    const handlePrevSlide = (e) => {
        e.preventDefault();
        if (sliderRef?.current) {
            sliderRef.current.prev('prev');
        }
    }

    // const handleNextSlide = (e) => {
    //     let count = sliderRef.current;
    //     e.preventDefault();
    //     if (sliderRef?.current) {
    //         sliderRef.current = 'next';
    //     } else {
    //         sliderRef.current = 'next'
    //     }
    // }

    const handleNextSlide = () => {
        let count = 1;
        if (sliderRef.current === undefined) {
            sliderRef.current = 2;
        } else {

            sliderRef.current.next = count + 1;
        }
    }

    const sendToRepatriations = () => {
        navigate('/repatriation');
    }

    const sendToMarket = () => {
        navigate('/market');
    }

    return (
        <>
            <Carousel
                infiniteLoop
                autoPlay
                showStatus={false}
                showThumbs={false}
                showArrows={true}
                showIndicators={false}
                className='relative'
                renderArrowPrev={(clickHandler, hasPrev) => {
                    return (
                        <div
                            className={`${hasPrev ? "absolute" : "hidden"
                                } top-0 bottom-0 left-0 flex justify-center items-center p-3 opacity-60 hover:opacity-100 cursor-pointer z-20 w-16 md:w-32`}
                            onClick={clickHandler}
                        >
                            <img src='../assets/icons/Frame2.png' />
                        </div>
                    );
                }}
                renderArrowNext={(clickHandler, hasNext) => {
                    return (
                        <div
                            className={`${hasNext ? "absolute" : "hidden"
                                // } top-0 bottom-0 right-[71%] sm:right-[80%] md:right-[70%] lg:right-[75%] xl:right-[82%] 2xl:right-[85%] flex justify-center items-center p-3 opacity-60 hover:opacity-100 cursor-pointer z-20 w-16 md:w-32`}
                                } top-0 bottom-0 right-[1%] flex justify-center items-center p-3 opacity-60 hover:opacity-100 cursor-pointer z-20 w-16 md:w-32`}
                            onClick={clickHandler}
                        >
                            <img src='../assets/icons/Frame.png' />
                        </div>
                    );
                }}
            >
                <div className='relative'>
                    <img src="https://firebasestorage.googleapis.com/v0/b/llega-market.appspot.com/o/carousel%2FDisen%CC%83o%20sin%20ti%CC%81tulo%20(1).jpg?alt=media&token=28fb3c43-531a-4f0f-8c2c-4e27973552fd" alt="" />
                </div>

                {/* <div className='relative'>
                    <img src="https://firebasestorage.googleapis.com/v0/b/llega-market.appspot.com/o/carousel%2F1.png?alt=media&token=9bf13930-9d0b-4e29-8a5f-6356c0b90ed1" alt="" />
                    <div className="absolute 
                        2xl:top-[22%] 2xl:left-[65%] 2xl:w-[530px] 2xl:h-[340px]
                        xl:top-[18%] xl:left-[60%] xl:w-[490px] xl:h-[280px]
                        lg:top-[7%] lg:left-[50%] lg:w-[490px] lg:h-[280px]
                        md:top-[8%] md:left-[50%] md:w-[380px] md:h-[180px] 
                        sm:top-[9%] sm:left-[56%] sm:w-[270px] sm:h-[130px]
                        top-[8%] left-[55%] w-[170px] h-[140px]
                        bg-slate-600 bg-opacity-70 rounded-[21px] sm:rounded-[41px] shadow
                        ">
                        <p className="w-full pt-1 sm:pt-6 md:pt-4 lg:pt-12 xl:pt-10 2xl:pt-16 pl-4 pr-4 md:pl-10 md:pr-10 text-left items-center">
                            <span className="text-white text-[10px] sm:text-[24px] md:text-[32px] lg:text-[45px] font-extrabold space-y-0 leading-none sm:leading-6 md:leading-10">{t('carousel.c4_text1')} </span>
                            <span className="text-white text-[10px] sm:text-[24px] md:text-[32px] lg:text-[45px] font-extrabold space-y-0 leading-none sm:leading-6 md:leading-10">{t('carousel.c4_text2')} </span>
                            <span className="text-white text-[10px] sm:text-[24px] md:text-[32px] lg:text-[45px] font-extrabold space-y-0 leading-none sm:leading-6 md:leading-10">{t('carousel.c4_text3')} </span>
                            <span className="text-cyan-300 text-[11px] sm:text-[30px] md:text-[40px] lg:text-[52px] font-extrabold leading-none sm:leading-6 md:leading-10">{t('carousel.c4_text4')}</span>
                        </p>
                    </div>
                </div> */}

                <div className='relative'>
                    <img src="https://firebasestorage.googleapis.com/v0/b/llega-market.appspot.com/o/carousel%2F1.png?alt=media&token=9bf13930-9d0b-4e29-8a5f-6356c0b90ed1" alt="" />
                    <div className="absolute  
                         top-[58%] left-[10%] w-[123px] h-[50px]
                         md:top-[58%] md:left-[10%] md:w-[480px] md:h-[206px]
                         pt-6 pb-8 bg-slate-700 bg-opacity-70 rounded-xl shadow justify-center items-center gap-11 inline-flex pl-2 pr-2">
                        <p className="text-center w-full text-white text-[10px] md:text-3xl font-extrabold font-['Inter'] pt-2 leading-none md:leading-10">{t('connect.carousel1')}</p>
                    </div>
                </div>


                {/* <div className='relative'>
                    <img src="https://firebasestorage.googleapis.com/v0/b/llega-market.appspot.com/o/carousel%2FDisen%CC%83o%20sin%20ti%CC%81tulo%20(2).jpg?alt=media&token=a1e1573d-2439-4361-96ca-cb891b88659d" alt="" />
                    <div className="absolute 
                         2xl:top-[28%] 2xl:left-[65%]
                         xl:top-[18%] xl:left-[60%]
                         lg:top-[15%] lg:left-[52%] lg:w-[490px] lg:h-[270px]
                         md:top-[15%] md:left-[50%] md:w-[370px] md:h-[210px] 
                         sm:top-[17%] sm:left-[58%] sm:w-[260px] sm:h-[130px]
                         top-[14%] left-[53%] w-[170px] h-[120px]
                         opacity-80 bg-blue-950 rounded-[21px] sm:rounded-[30px] md:rounded-[41px] shadow
                    ">
                        <p className="w-full pt-1 sm:pt-6 md:pt-4 lg:pt-16 xl:pt-16 2xl:pt-16 pl-4 pr-4 md:pl-10 md:pr-10 text-left items-center">
                            <span className="text-white text-[14px] sm:text-[24px] md:text-[32px] lg:text-[45px] font-extrabold space-y-0 leading-none sm:leading-6 md:leading-10">{t('carousel.c2_text1')} </span>
                            <span className="text-white text-[14px] sm:text-[24px] md:text-[32px] lg:text-[45px] font-extrabold space-y-0 leading-none sm:leading-6 md:leading-10">{t('carousel.c2_text2')} </span>
                            <span className="text-white text-[14px] sm:text-[24px] md:text-[32px] lg:text-[45px] font-extrabold space-y-0 leading-none sm:leading-6 md:leading-10">{t('carousel.c2_text3')} </span>
                            <span className="text-cyan-300 text-[15px] sm:text-[30px] md:text-[40px] lg:text-[52px] font-extrabold leading-none sm:leading-6 md:leading-10">{t('carousel.c2_text4')}</span>
                        </p>
                    </div>
                </div>*/}

                <div className='relative'>
                    <img src="https://firebasestorage.googleapis.com/v0/b/llega-market.appspot.com/o/carousel%2FDisen%CC%83o%20sin%20ti%CC%81tulo%20(2).jpg?alt=media&token=a1e1573d-2439-4361-96ca-cb891b88659d" alt="" />
                    <div className="absolute  
                         top-[58%] left-[10%] w-[123px] h-[50px]
                         md:top-[58%] md:left-[10%] md:w-[428px] md:h-[206px]
                         pt-6 pb-8 bg-slate-700 bg-opacity-70 rounded-xl shadow justify-center items-center gap-11 inline-flex pl-2 pr-2">
                        <p className="text-center w-full text-white text-[10px] md:text-3xl font-extrabold font-['Inter'] pt-2 leading-none md:leading-10">{t('connect.carousel2')}</p>
                    </div>
                </div>

                {/* <div>
                    <img src="https://firebasestorage.googleapis.com/v0/b/llega-market.appspot.com/o/carousel%2F3.png?alt=media&token=7f8e6a7c-a708-4c87-8503-2b371b47d237" alt="" />
                    <div className="absolute 
                        2xl:top-[28%] 2xl:left-[65%] xl:w-[530px] 2xl:h-[220px]
                        xl:top-[18%] xl:left-[60%] xl:h-[370px]
                        lg:top-[15%] lg:left-[48%] lg:w-[530px] lg:h-[220px]
                        md:top-[10%] md:left-[48%] md:w-[430px] md:h-[150px] 
                        sm:top-[17%] sm:left-[56%] sm:w-[270px] sm:h-[100px]
                        top-[4%] left-[50%] w-[180px] h-[140px]
                        opacity-80 bg-blue-950 rounded-[18px] sm:rounded-[34px] shadow                  
                        ">
                        <p className="w-full pt-1 sm:pt-4 md:pt-4 lg:pt-8 xl:pt-8 2xl:pt-6 pl-4 pr-4 md:pl-10 md:pr-10 text-left items-center">
                            <span className="text-white text-[14px] sm:text-[24px] md:text-[32px] lg:text-[45px] font-extrabold space-y-0 leading-none sm:leading-6 md:leading-10">{t('carousel.c1_text1')} </span>
                            <span className="text-white text-[14px] sm:text-[24px] md:text-[32px] lg:text-[45px] font-extrabold space-y-0 leading-none sm:leading-6 md:leading-10">{t('carousel.c1_text2')} </span>
                            <span className="text-white text-[14px] sm:text-[24px] md:text-[32px] lg:text-[45px] font-extrabold space-y-0 leading-none sm:leading-6 md:leading-10">{t('carousel.c1_text3')} </span>
                            <span className="text-white text-[14px] sm:text-[24px] md:text-[32px] lg:text-[45px] font-extrabold space-y-0 leading-none sm:leading-6 md:leading-10">{t('carousel.c1_text4')} </span>
                            <span className="text-cyan-300 text-[15px] sm:text-[30px] md:text-[40px] lg:text-[52px] font-extrabold leading-none sm:leading-6 md:leading-10">{t('carousel.c1_text5')}</span>
                        </p>
                    </div>
                </div>*/}

                <div className='relative'>
                    <img src="https://firebasestorage.googleapis.com/v0/b/llega-market.appspot.com/o/carousel%2F3.png?alt=media&token=7f8e6a7c-a708-4c87-8503-2b371b47d237" alt="" />
                    <div className="absolute  
                         top-[15%] left-[15%] w-[123px] h-[50px]
                         md:top-[15%] md:left-[15%] md:w-[552px] md:h-[206px]
                         pt-6 pb-8 bg-slate-700 bg-opacity-70 rounded-xl shadow justify-center items-center gap-11 inline-flex pl-2 pr-2">
                        <p className="text-center w-full text-white text-[10px] md:text-3xl font-extrabold font-['Inter'] pt-2 leading-none md:leading-10">{t('connect.carousel3')}</p>
                    </div>
                </div>

                {/* <div>
                    <img src="https://firebasestorage.googleapis.com/v0/b/llega-market.appspot.com/o/carousel%2F4.png?alt=media&token=85da14af-b42b-4274-aa4d-32c99269c1a4" alt="" />
                    <div className="absolute 
                        2xl:top-[28%] 2xl:left-[65%] xl:w-[530px]
                        xl:top-[18%] xl:left-[60%] xl:h-[370px]
                        lg:top-[4%] lg:left-[48%] lg:w-[530px] lg:h-[220px]
                        md:top-[6%] md:left-[48%] md:w-[400px] md:h-[200px] 
                        sm:top-[9%] sm:left-[56%] sm:w-[270px] sm:h-[110px]
                        top-[4%] left-[51%] w-[190px] h-[140px]
                        opacity-80 bg-blue-950 rounded-[18px] sm:rounded-[34px] shadow
                        ">
                        <p className="w-full pt-1 sm:pt-4 md:pt-4 lg:pt-8 xl:pt-8 2xl:pt-8 pl-4 pr-4 md:pl-10 md:pr-10 text-left items-center">
                            <span className="text-white text-[14px] sm:text-[24px] md:text-[32px] lg:text-[45px] font-extrabold space-y-0 leading-none sm:leading-6 md:leading-10">{t('carousel.c3_text1')} </span>
                            <span className="text-white text-[14px] sm:text-[24px] md:text-[32px] lg:text-[45px] font-extrabold space-y-0 leading-none sm:leading-6 md:leading-10">{t('carousel.c3_text2')} </span>
                            <span className="text-white text-[14px] sm:text-[24px] md:text-[32px] lg:text-[45px] font-extrabold space-y-0 leading-none sm:leading-6 md:leading-10">{t('carousel.c3_text3')} </span>
                            <span className="text-white text-[14px] sm:text-[24px] md:text-[32px] lg:text-[45px] font-extrabold space-y-0 leading-none sm:leading-6 md:leading-10">{t('carousel.c3_text4')} </span>
                            <span className="text-cyan-300 text-[15px] sm:text-[30px] md:text-[40px] lg:text-[52px] font-extrabold leading-none sm:leading-6 md:leading-10">{t('carousel.c3_text5')}</span>
                        </p>
                    </div>
                </div> */}

                <div className='relative'>
                    <img src="https://firebasestorage.googleapis.com/v0/b/llega-market.appspot.com/o/carousel%2F4.png?alt=media&token=85da14af-b42b-4274-aa4d-32c99269c1a4" alt="" />
                    <div className="absolute  
                         top-[58%] left-[50%] w-[123px] h-[50px]
                         md:top-[58%] md:left-[50%] md:w-[615px] md:h-[206px]
                         pt-6 pb-8 bg-slate-700 bg-opacity-70 rounded-xl shadow justify-center items-center gap-11 inline-flex pl-2 pr-2">
                        <p className="text-center w-full text-white text-[10px] md:text-3xl font-extrabold font-['Inter'] pt-2 leading-none md:leading-10">{t('connect.carousel4')}</p>
                    </div>
                </div>


                <div className='relative'>
                    <img src="https://firebasestorage.googleapis.com/v0/b/llega-market.appspot.com/o/carousel%2FDisen%CC%83o%20sin%20ti%CC%81tulo%20(9).png?alt=media&token=8924716b-7ecf-4b7c-b8d2-b3f32225167e" alt="" />
                    <div className="absolute  
                         top-[58%] left-[10%] w-[123px] h-[50px]
                         md:top-[58%] md:left-[10%] md:w-[546px] md:h-[206px]
                         pt-6 pb-8 bg-slate-700 bg-opacity-70 rounded-xl shadow justify-center items-center gap-11 inline-flex">
                        <p className="text-center w-full text-white text-[10px] md:text-3xl font-extrabold font-['Inter'] pt-2 leading-none md:leading-10">{t('connect.carousel5')}</p>
                    </div>
                </div>

            </Carousel>
        </>
    )
}

