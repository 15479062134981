import { TextField } from '@mui/material'
import { getDownloadURL, ref, uploadBytes } from 'firebase/storage';
import React, { useState, useEffect, useRef } from 'react'
import { useForm } from 'react-hook-form';
import { FirebaseStorage } from '../firebase/config';
import { useDispatch, useSelector } from 'react-redux';
import { setActiveCategorie } from '../store/Categories/categoriesSlice';
import Swal from 'sweetalert2';
import { startDeletingCategorie, startLoadingCategories, startNewCategorie, startSaveCategorie } from '../store/Categories/thunks';
import { DataGrid } from '@mui/x-data-grid';


export const Categories = () => {
    const dispatch = useDispatch();
    const inputRef = useRef(null);
    const inputRefPic = useRef(null);
    const { categories } = useSelector(state => state.categorie);
    const { status, errorMessage } = useSelector(state => state.auth);
    const [urlImage, setUrlImage] = useState('');
    const [urlPicture, setUrlPicture] = useState('');
    const [file, setFile] = useState("");
    const [filePicture, setFilePicture] = useState("");
    const [imageSaved, setImageSaved] = useState(false);
    const [edit, setEdit] = useState(false);
    const [deleteCategorie, setDeleteCategorie] = useState(false);
    const [idCategorie, setIdCategorie] = useState('');
    const [updateCategories, setUpdateCategories] = useState(false);

    const columns = [
        { field: 'urlImage', headerName: 'Imagen', width: 70, renderCell: (params) => <img className='w-8 h-8' src={params.value} /> },
        { field: 'name', headerName: 'Nombre', width: 150 },
        { field: 'description', headerName: 'Descripción', width: 320 },
        { field: 'id', headerName: 'Opciones', width: 250, renderCell: (params) => <><button className='w-16 h-8 bg-blue-800 text-white rounded-full' onClick={() => onEdit(params.value)}>Editar</button><button className='w-16 h-8 bg-red-800 text-white ml-2 rounded-full' onClick={() => onDelete(params.value)}>Eliminar</button></> },
        { field: 'urlPicture', headerName: 'Foto', width: 70, renderCell: (params) => <img className='w-8 h-8' src={params.value} /> }
    ]

    const { register, handleSubmit, setValue, control, formState: { errors } } = useForm({
        defaultValues: {
            name: "",
            description: "",
        }
    });

    const onEdit = (idCategorie) => {
        setEdit(true);
        setEdit((state) => {
            return state;
        })

        let tmpCategorie = categories.filter(x => x.id === idCategorie);
        const { id, name, description, uid, urlImage } = tmpCategorie[0];

        dispatch(setActiveCategorie({ id, name, description, uid, urlImage }));
        setIdCategorie(id);
        setIdCategorie((state) => {
            return state;
        })
        setValue('name', name);
        setValue('description', description);
        setUrlImage(urlImage);
        setUrlPicture(urlPicture)
    }

    const onDelete = (idCategorie) => {
        setDeleteCategorie(true);
        dispatch(startDeletingCategorie(idCategorie));
        if (!errorMessage) {
            showDeleteSuccess();
            onClear();
        }
    }

    useEffect(() => {
        if (deleteCategorie) {
            dispatch(startLoadingCategories());
        }
    }, [deleteCategorie])

    useEffect(() => {
        if (updateCategories) {
            dispatch(startLoadingCategories());
        }
    }, [updateCategories])

    const onClear = () => {
        setValue('name', "");
        setValue('description', "");
        setUrlImage('');
        setFile('');
        inputRef.current.value = null;
        inputRefPic.current.value = null;
    }

    const showDeleteSuccess = () => {
        Swal.fire('La categoría fué eliminada correctamente', "Eliminado", 'success');
    }

    const showSuccess = () => {
        Swal.fire('Categoria creada correctamente', 'Guardado', 'success');
    };

    const onSubmit = (data) => {
        let id = '';

        const {
            name,
            description
        } = data;

        if (edit) {

            const newData = {
                id: idCategorie,
                name: name,
                description: description,
                urlImage: urlImage,
                urlPicture: urlPicture
            }
            dispatch(setActiveCategorie(newData));
            if (newData) {
                onUpdateRegister(newData);
                if (!errorMessage) {
                    showSuccess();
                    onClear();
                    setUpdateCategories(true);
                }
            }

        } else {

            const newData = {
                name: name,
                description: description,
                urlImage: urlImage,
                urlPicture: urlPicture
            }

            dispatch(setActiveCategorie(newData));
            if (newData) {
                onUpdateCategorie(newData);
            }
        }
    }

    const onUpdateRegister = () => {
        dispatch(startSaveCategorie());
    }

    const onUpdateCategorie = (data) => {
        if (imageSaved) {
            setTimeout(() => {
                dispatch(startNewCategorie(data));
            }, 3000)
            if (!errorMessage) {
                showSuccess();
                onClear();
                setUpdateCategories(true);
            }
        }
    }

    useEffect(() => {
        saveVideo();
    }, [file])

    useEffect(() => {
        saveVideoPicture();
    }, [filePicture])

    useEffect(() => {
        setImageSaved(true);
    }, [urlImage])

    const handleChangeFile = (event) => {
        setFile(event.target.files[0]);
    }

    const handleChangeFilePicture = (event) => {
        setFilePicture(event.target.files[0]);
    }

    const saveVideo = () => {
        if (file) {
            const storageRef = ref(FirebaseStorage, `images/categories/${file.name}`);

            uploadBytes(storageRef, file).then((snapshot) => {
                getDownloadURL(snapshot.ref).then((url) => {
                    setUrlImage(url);
                    setUrlImage((state) => {
                        return state;
                    })
                })
            })
        }
    }

    const saveVideoPicture = () => {
        if (filePicture) {
            const storageRef = ref(FirebaseStorage, `images/categories/pictures/${filePicture.name}`);

            uploadBytes(storageRef, filePicture).then((snapshot) => {
                getDownloadURL(snapshot.ref).then((url) => {
                    setUrlPicture(url);
                    setUrlPicture((state) => {
                        return state;
                    })
                })
            })
        }
    }

    return (
        <div className='flex flex-col bg-white w-full place-items-center h-[870px]'>
            <div className='w-full pt-10 pl-32 pr-32'>
                <form onSubmit={handleSubmit(onSubmit)}>
                    <div className='flex flex-row w-full '>
                        <div className='w-full'>
                            <TextField
                                id='name'
                                className='w-full'
                                placeholder='Nombre'
                                {...register("name")}
                            />
                        </div>
                    </div>
                    <div className='flex flex-row w-full pt-4'>
                        <div className='w-full'>
                            <TextField
                                multiline
                                rows={4}
                                placeholder='Descripción'
                                className='w-full'
                                {...register("description")}
                            />
                        </div>
                    </div>
                    <div className='w-full pt-4'>
                        <label>Icono Categoria</label>
                        <div className='pt-2 flex flex-row w-full'>
                            <input
                                className="block w-full text-sm text-gray-900 border border-gray-300 rounded-lg cursor-pointer bg-gray-50 dark:text-gray-400 focus:outline-none dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400" aria-describedby="file_input_help" id="file_input"
                                type="file" onChange={handleChangeFile} disabled={urlImage}
                                ref={inputRef}
                            />
                        </div>
                        {urlImage &&
                            <div className='pt-2 flex flex-row w-full justify-center'>
                                <img className='w-16 h-16' src={urlImage} alt='' />
                            </div>
                        }
                    </div>

                    <div className='w-full pt-4'>
                        <label>Foto Categoría</label>
                        <div className='pt-2 flex flex-row w-full'>
                            <input
                                className="block w-full text-sm text-gray-900 border border-gray-300 rounded-lg cursor-pointer bg-gray-50 dark:text-gray-400 focus:outline-none dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400" aria-describedby="file_input_help" id="file_input"
                                type="file" onChange={handleChangeFilePicture} disabled={urlPicture}
                                ref={inputRefPic}
                            />
                        </div>
                        {urlImage &&
                            <div className='pt-2 flex flex-row w-full justify-center'>
                                <img className='w-16 h-16' src={urlPicture} alt='' />
                            </div>
                        }
                    </div>

                    <div className='flex flex-row pt-10 justify-end'>
                        <div className='w-1/4'><button type="button" className='w-full h-10 bg-red-700 text-white rounded-full font-medium' onClick={() => onClear()}>Limpiar</button></div>
                        <div className='w-1/4 pl-2'><button type="submit" className='w-full h-10 bg-sky-900 text-white rounded-full font-medium'>Guardar</button></div>
                    </div>
                </form>
            </div>
            <div className='flex flex-row w-full pl-32 pr-32 pt-10'>
                <DataGrid
                    rows={categories}
                    columns={columns}
                    initialState={{
                        pagination: {
                            paginationModel: { page: 0, pageSize: 5 },
                        },
                    }}
                    pageSizeOptions={[5, 10]}
                />
            </div>
        </div>
    )
}
