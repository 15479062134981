import React from 'react'
import { useTranslation } from 'react-i18next'

export const DownloadApp = () => {
    const { t } = useTranslation();

    return (
        // <div className="w-full bg-cyan-300 
        //         md:h-[318px] lg:h-[318px] xl:h-[318px] sm:h-[218px]  mt-2">
        //     <div className="left-[89px] top-[92px] xl:left-[5%] lg:left-[1%]  absolute text-indigo-600 text-[64px] lg:text-[52px] sm:collapse md:collapse lg:visible xl:visible font-extrabold leading-10">
        //         DESCARGA <br /><br />NUESTRA APP
        //     </div>
        //     <a href='https://play.google.com/store/apps/details?id=com.ypayme.appllegagt' target="_blank">
        //     <img className="w-[347px] h-[134px] lg:w-[327px] lg:h-[114px] md:w-[317px] md:h-[104px] sm:w-[230px] sm:h-[84px] left-[649px] md:left-[149px] sm:left-[49px] xl:left-[40%] lg:left-[38%] top-[92px] absolute" src="../assets/images/googleplay.png" />
        // </a>
        //     <a href='https://apps.apple.com/gt/app/llega/id1604889620' target="_blank"><img className="w-[311px] h-[103px] lg:w-[291px] lg:h-[83px] md:w-[281px] md:h-[73px] sm:w-[230px] sm:h-[55px] left-[1042px] md:left-[500px] sm:left-[400px] xl:left-[70%] lg:left-[70%] top-[106px] absolute" src="../assets/images/appstore.png" /></a>
        // </div>

        <div className='w-full bg-cyan-300 min-h-[218px]'>
            <div className='flex w-full flex-wrap'>
                <div className='w-full md:w-1/2 pt-8 md:pt-10'>
                    <p className='text-indigo-600 text-[34px] md:text-[44px] lg:text-[64px] font-extrabold leading-10'>{t('download_app.download_1')} <br className='hidden md:block' /><br /> {t('download_app.download_2')}</p>
                </div>
                <div className='w-full md:w-1/2 flex'>
                    <div className='w-1/2 pt-6 md:pt-8'>
                        <a href='https://play.google.com/store/apps/details?id=com.ypayme.appllegagt' target="_blank">
                            <img className="w-[230px] md:w-[317px] lg:w-[327px]
                                            h-[84px]  md:h-[104px] lg:h-[114px] 
                                left-[649px] md:left-[149px] sm:left-[49px] xl:left-[40%] lg:left-[38%] top-[92px]" src="../assets/images/googleplay.png" />
                        </a>
                    </div>
                    <div className='w-1/2 pt-10 md:pt-12 pl-1'>
                        <a href='https://apps.apple.com/gt/app/llega/id1604889620' target="_blank">
                            <img className="w-[230px] sm:w-[230px] md:w-[281px] lg:w-[291px] 
                                            h-[55px] sm:h-[55px] md:h-[73px] lg:h-[83px]   
                                            left-[1042px] md:left-[500px] sm:left-[400px] xl:left-[70%] lg:left-[70%] top-[106px]" src="../assets/images/appstore.png" />
                        </a>
                    </div>
                </div>
            </div>
        </div>
    )
}
