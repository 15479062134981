import React, { useEffect, useState } from 'react'
import { useForm } from 'react-hook-form';
import emailjs from '@emailjs/browser';
import Swal from 'sweetalert2';
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom';

export const RegisterLlegaMarket = () => {
    const { t } = useTranslation();
    const [comercio, setComercio] = useState('');

    const { register, handleSubmit, setValue, control, formState: { errors } } = useForm({
        defaultValues: {
            displayName: "",
            email: "",
            phone: "",
            message: "Quiero unirme a Llega Market",
            subject: t('contact.option1')
        }
    })

    const onSubmit = (data) => {
        if (data) {
            // sendEmail(data);
        }
    }


    function sendEmail(data) {

        emailjs.send('service_k1iecnp', 'template_5xho742', data, 'srjso40pBJ9jqwxHz')
            .then(function (response) {
                showSuccess();
                limpiar();
            }, function (error) {
                showError();
            });
    }

    const limpiar = () => {
        setValue('email', "");
    }

    const showSuccess = () => {
        Swal.fire('Gracias por registrarte', 'Ok', 'success');
    };

    const showError = () => {
        Swal.fire('Error en el registro', 'Error', 'Error');
    };

    const handleComercioChange = (event) => {
        const comercioIngresado = event.target.value;
        setComercio(comercioIngresado);
    };

    return (
        <>
            <div className="w-full bg-[#645EC2] h-96 flex-col justify-start items-start gap-2.5 inline-flex">
                <div className='w-full relative '>
                    <img className='w-full h-96 left-0 top-0 absolute' src='../assets/images/Vector 1.png' alt='' />
                    <img className='w-full h-96 left-0 top-0 absolute ' src='../assets/images/Vector 2.png' alt='' />
                </div>
                <div className="w-full h-36 flex-col justify-start relative items-start gap-8 flex pt-8">
                    <div className="w-full pt-16 text-white text-3xl font-extrabold leading-10">{t('asociate.title')}</div>
                    <div className=" w-full justify-center items-center inline-flex">
                        <div className="">
                            <input className="px-4 py-3.5 bg-gray-100 rounded-tl rounded-bl justify-start items-center gap-2.5 w-52 text-zinc-600 text-sm font-normal leading-normal" placeholder={t('affiliate.email')} value={comercio} onChange={handleComercioChange} />
                        </div>
                        <div className="px-6 py-3.5 bg-blue-950 rounded-tr rounded-br justify-start items-center gap-2.5 flex">
                            {/* <button className="text-white text-sm font-medium leading-tight" onClick={handleSubmit(onSubmit)}>{t('asociate.button')}</button> */}
                            <Link to={'/contact?comercio=' + comercio}><input type='button' className="text-white text-sm font-medium leading-tight" value={t('asociate.button')} /></Link>
                        </div>
                    </div>
                </div>
            </div>

        </>
    )
}
