import React, { useState, useEffect } from 'react'
import { MagnifyingGlassIcon } from '@heroicons/react/24/outline'
import { useTranslation } from 'react-i18next'
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { Autocomplete } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { startLoadingAllCategories } from '../store/Categories/thunks';


export const Header = () => {
    const { i18n, t } = useTranslation();
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const location = useLocation();
    const [language, setLanguage] = useState('es');
    const { categories } = useSelector(state => state.categorie);
    const [categorieOption, setCategorieOptions] = useState([]);
    const [selectedCategorie, setSelectedCategorie] = useState('');
    const nav = document.querySelector("#nav");

    useEffect(() => {
        dispatch(startLoadingAllCategories());
    }, [])

    useEffect(() => {
        let tmpCategorie = [];
        if (categories) {
            categories.forEach(element => {
                tmpCategorie.push(element.name)
            });
            setCategorieOptions(tmpCategorie);
            setCategorieOptions((state) => {
                return state;
            });
        }
    }, [categories])

    useEffect(() => {
        i18n.changeLanguage('es');
        onClose();
    }, [])

    useEffect(() => {
        let tmpCategorie = [];
        if (categories) {
            categories.forEach(element => {
                tmpCategorie.push(element.name)
            });
            setCategorieOptions(tmpCategorie);
            setCategorieOptions((state) => {
                return state;
            });
        }
    }, [location])

    const handleChange = (lan) => {
        setLanguage(lan);
        i18n.changeLanguage(lan);
        onClose();
    }

    useEffect(() => {
        if (selectedCategorie) {
            let tmpName = categories.filter((item) => item.name === selectedCategorie);
            if (tmpName[0].id) {
                navigate('/store/' + tmpName[0].id)
                setSelectedCategorie('');
                setSelectedCategorie((state) => {
                    return state;
                })
            }
        }
    }, [selectedCategorie])

    const handleCategorie = (name) => {
        if (name) {
            setSelectedCategorie(name);
            setSelectedCategorie((state) => {
                return state;
            })
            let tmpName = categories.filter((item) => item.name === name);
            if (tmpName[0].id) {
                setSelectedCategorie('');
                setSelectedCategorie((state) => {
                    return state;
                })
                navigate('/store/' + tmpName[0].id)
            }
        }
    };

    const onOpen = () => {
        nav.classList.add("visible");
    }

    const onClose = () => {

        if (typeof (nav) != 'undefined' && nav != null) {
            nav.classList.remove("visible");
        }
    }

    return (
        <>
            <header className='mb-[0.5px]'>
                <div className='w-full flex place-content-center justify-between'>
                    <div className='w-1/3 md:w-2/12'>
                        <a className="site-logo" href="/"><img className='logo' src="../assets/logo.png" alt='Logo' /></a>
                    </div>
                    <div className='w-3/12 hidden
                            lg:block xl:block 2xl:block pt-2 pl-4 justify-start sm:hidden'>
                        <form action='relative w-full'>
                            <div className='flex w-full items-center'>
                                <MagnifyingGlassIcon className='w-5 h-5 absolute ml-3 pointer-events-none' />
                                <Autocomplete
                                    sx={{
                                    }}
                                    value={selectedCategorie}
                                    id='search'
                                    placeholder='Buscar ...'
                                    options={categorieOption}
                                    renderInput={(params) => (
                                        <div ref={params.InputProps.ref}>
                                            <input type="text" {...params.inputProps} className="w-[100px] md:w-[200px] lg:w-[230px] xl:w-[320px] 2xl:w-[320px] h-9 rounded-full pr-3 pl-10 py-2 text-slate-600 text-opacity-40 text-sm font-medium leading-tight font-['Inter']" placeholder={t('header.search')} />
                                        </div>
                                    )}
                                    onChange={(event, newValue) => {
                                        setSelectedCategorie(newValue)
                                    }}
                                />
                            </div>
                        </form>
                    </div>
                    <div className='w-5/12 pt-3'>
                        <nav id="nav" className='nav'>
                            <button id="cerrar" className='cerrar-menu' onClick={onClose}><i className="bi bi-x-lg"></i></button>
                            {/* <ul className="w-full justify-start items-center gap-2.5 inline-flex text-white text-sm font-medium font-['Inter'] leading-tight"> */}
                            <ul className='nav-list'>
                                <li onClick={onClose} className=' hover: text-[#6EEBE6]'><a href="#" className=' hover: text-[#6EEBE6]'>{t('header.begin')}</a></li>
                                <li onClick={onClose}><Link to='/market'>{t('header.market')}</Link></li>
                                <li onClick={onClose}><Link to="/repatriation" >{t('header.repatriation')}</Link></li>
                                <li onClick={onClose}><Link to="/meetus" >{t('header.meetus')}</Link></li>
                                <li onClick={onClose}><Link to='/questions'>{t('header.question')}</Link></li>
                                <li onClick={onClose}><Link to="/contact" >{t('header.contact')}</Link></li>
                                <li>
                                    <select name='languages' id='languages' onChange={e => handleChange(e.target.value)} className=' bg-transparent text-white'>
                                        <option className='text-white md:text-black' disabled>{t('header.language')}</option>
                                        <option className='text-white md:text-black' value='es'>{t('header.spanish')}</option>
                                        <option className='text-white md:text-black' value='en'>{t('header.english')}</option>
                                        <option className='text-white md:text-black' value='fr'>{t('header.kiche')}</option>
                                        <option className='text-white md:text-black' value='de'>{t('header.qeqchi')}</option>
                                        <option className='text-white md:text-black' value='mx'>{t('header.kaqchiquel')}</option>
                                    </select>
                                </li>
                            </ul>
                        </nav>
                    </div>
                    <div className='w-1/12'>
                        <div className='flex justify-end pl-2
                                w-1/2 sm:w-1/2 md:w-1/2 lg:w-2/12 xl:w-2/12 2xl:w-2/12'>
                            <button id="abrir" className='abrir-menu' onClick={onOpen}><i className="bi bi-list"></i></button>
                        </div>
                    </div>
                </div>
            </header>
        </>

    )

}
