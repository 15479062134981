import { FormControl, InputLabel, MenuItem, OutlinedInput, Select } from '@mui/material'
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux';

export const AppTopbar = () => {
    const { i18n, t } = useTranslation();
    const [language, setLanguage] = useState('es');
    const { displayName } = useSelector(state => state.auth);

    const handleChange = (lan) => {
        setLanguage(lan);
        i18n.changeLanguage(lan);
    }

    return (
        <>
            <div className="bg-white flex flex-row justify-between relative w-full items-center pl-10 pr-8 rounded-lg" >
                <div className="whitespace-nowrap font-['Poppins'] text-gray-400 relative text-base" >

                </div>
                <div className="bg-white flex flex-col justify-center mb-px relative w-5/12 items-center px-8 py-4 border-gray-900 rounded-[32px]" >
                    <div className="flex text-center items-start whitespace-nowrap font-['Poppins'] font-medium text-gray-900 relative w-4/12" >
                        <FormControl fullWidth>
                            <Select
                                id="language-dropdown"
                                value={language}
                                label='language'
                                defaultValue={language}
                                inputProps={{ 'aria-label': 'Without label' }}
                                input={<OutlinedInput />}
                                displayEmpty
                            >
                                <MenuItem value="es" onClick={() => handleChange('es')} >🇪🇸</MenuItem>
                                <MenuItem value="en" onClick={() => handleChange('en')}>🇺🇸</MenuItem>
                            </Select>
                        </FormControl>
                        <div className="relative" >
                            {/* <p className="absolute text-xs text-center text-gray-500 p-16 pt-2">{t('homepage.welcome')}</p> */}
                            <p className="absolute text-base font-semibold text-center text-gray-500 pl-2 pt-6" >{displayName}</p>
                            {/* <img className="w-14 ml-2 top-10 pt-2" src="../assets/images/profile.png" /> */}
                        </div>
                    </div>
                </div>
            </div >
        </>
    )
}
