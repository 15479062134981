import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import { RegisterLlegaMarket } from './RegisterLlegaMarket'
import { useDispatch, useSelector } from 'react-redux';
import { startLoadingAllCategories, startLoadingCategorie } from '../store/Categories/thunks';
import { useTranslation } from 'react-i18next'
import { WouldAffiliate } from './WouldAffiliate';
import { FormControl, InputLabel, MenuItem, Select } from '@mui/material';
import { Label } from '@mui/icons-material';
import { setActiveCountry } from '../store/countries/countrySlice';
import { startLoadingAllCountries, startLoadingCountries } from '../store/countries/thunks';
import { DownloadApp } from './DownloadApp';

export const Market = () => {
    const dispatch = useDispatch();
    const { categories } = useSelector(state => state.categorie);
    const { active, countries } = useSelector(state => state.country);
    const [showCategories, setShowCategories] = useState(false);
    const [selectedCountry, setSelectedCountry] = useState('');
    const { t } = useTranslation();

    useEffect(() => {
        if (window.scrollY > 300) {
            window.scrollTo({ top: 0, behavior: 'smooth' });
        }
    }, [])

    useEffect(() => {
        dispatch(startLoadingAllCategories());
        dispatch(startLoadingAllCountries());
    }, [])

    useEffect(() => {
        if (active) {
            setSelectedCountry(active.id);
        }
    }, [active])

    useEffect(() => {
        if (categories.length > 0) {
            setShowCategories(true);
            setShowCategories((state) => {
                return state;
            })
        }
    }, [categories]);

    // const order = (data) => {
    //     let tmpData = [];
    //     if (data) {
    //         tmpData = data.sort(function (a, b) {
    //             let x = a.name.toLowerCase();
    //             let y = b.name.toLowerCase();
    //             if (x < y) { return -1; }
    //             if (x > y) { return 1; }
    //             return 0;
    //         })
    //     }
    //     return tmpData;
    // }

    useEffect(() => {
        if (countries) {
            let tmpCountry = countries.filter((item) => item.id === selectedCountry);
            if (tmpCountry.length > 0) {
                const { id, countryName, status } = tmpCountry[0];
                dispatch(setActiveCountry({ id, countryName, status }));
            }
        }
    }, [selectedCountry])

    const handleChangeCountry = (event) => {
        const {
            target: { value },
        } = event;
        setSelectedCountry(
            value,
        );
        setSelectedCountry((state) => {
            return state;
        })
    }

    return (
        <>
            <div className='w-full bg-principal-blue min-h-[500px] '>
                <div className='w-full flex justify-end place-content-end items-end text-right  pt-8 pl-4 md:pl-40 pr-4 md:pr-44'>
                    <div className=' w-44 pb-4 pr-2'>
                        <p className='text-white text-sm md:text-base w-full'>{t('country.market')}</p>
                    </div>
                    <div className=' w-48'>
                        <FormControl className='w-full'>
                            {/* <InputLabel sx={{ color: 'white' }}></InputLabel> */}
                            <Select
                                // label='País'
                                placeholder='País'
                                value={selectedCountry}
                                onChange={handleChangeCountry}
                                sx={{ color: 'white', border: 'transparent', textAlign: 'center' }}
                            >
                                {countries.map((item) => (
                                    <MenuItem key={item.countryName} value={item.id}>{item.countryName}</MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    </div>
                </div>
                <div className=' pt-8 pl-4 md:pl-20 lg:pl-32 xl:pl-40 pr-4 md:pr-20 lg:pr-32 xl:pr-40 2xl:pl-52 2xl:pr-52 3xl:pl-60 3xl:pr-60'>
                    <div className='w-full flex flex-row'>
                        <p className="w-full text-center text-white text-6xl font-extrabold leading-10">{t('market.title')}</p>
                    </div>
                    <div className='w-full flex flex-row pt-5'>
                        <p className="w-full text-center text-white text-3xl font-semibold leading-loose">{t('market.description')}</p>
                    </div>
                    <div className='w-full flex flex-row pt-5'>
                        <p className="w-full text-center text-white text-2xl font-medium leading-loose">{t('market.message')}</p>
                    </div>

                    <div className='w-full flex flex-wrap place-content-center pb-20'>
                        {showCategories &&
                            categories.filter(x => x.serviceOrder != '100').map((item, index) => (
                                <div key={index} className='w-full pt-4 pl-2 place-content-center md:w-1/2 lg:w-1/3 xl:w-1/3 max-w-[348px]
                                    hover:transition ease-in-out delay-150 hover:-translate-y-1 hover:scale-105 duration-300 ...
                                    '>
                                    <Link to={'/store/' + item.id}><div>
                                        <img className='w-full object-cover rounded-xl' src={item.urlPicture} alt='' />
                                    </div></Link>
                                </div>
                            ))}
                    </div>

                    {/* <div className='w-full flex flex-wrap  pt-11'>
                        {showCategories &&
                            categories.map((item, index) => (
                                <div key={index}
                                    className='w-full sm:w-1/2 md:w-1/2 lg:w-1/3 justify-between pl-4 pr-4 md:pl-0 md:pr-0 pb-16 
                                     hover:transition ease-in-out delay-150 hover:-translate-y-1 hover:scale-110 duration-300 ...'>
                                    <Link to={'/store/' + item.id}>
                                        <div className='w-full bg-cover bg-no-repeat bg-center h-[262px] sm:h-[175px] md:h-[190px] lg:h-[170px] xl:h-[190px] 2xl:h-[250px] 3xl:h-[410px]' style={{ backgroundImage: `url(${item.urlPicture})` }}>
                                        </div></Link>
                                </div>
                            ))}
                    </div> */}
                </div>
            </div>
            <DownloadApp />
            <WouldAffiliate />
        </>
    )
}
