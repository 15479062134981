import React from 'react'
import { useTranslation } from 'react-i18next'

export const References = () => {
    const { t } = useTranslation();

    return (
        <>
            <div className='w-full h-[586] mt-2 
                    pl-4 md:pl-36 
                    pr-4 md:pr-36 pb-32'>
                <div className='flex flex-row w-full pt-36'>
                    <p className='w-full text-center text-zinc-900 font-extrabold leading-10
                        text-[40px] md:text-[48px]'>{t('references.title')}</p>
                </div>
                <div className='flex flex-row w-full pt-4 md:pt-2'>
                    <p className='w-full text-center text-zinc-900 
                    text-[18px] font-normal leading-loose"'>{t('references.description')}</p>
                </div>
                <div className='flex flex-wrap w-full justify-between pt-14 md:pt-20'>
                    <div className='w-full md:w-2/4 lg:w-1/3 xl:w-1/3 2xl:w-1/3 pt-4'>
                        {/* <div className='flex flex-row w-full md:w-11/12 bg-[#645EC2] h-48 rounded-xl 
                            pt-8 md:pt-4 lg:pt-6 xl:pt-6 2xl:pt-8 pl-2 pr-2'>
                            <p className='w-full flex-wrapper text-white text-center font-light leading-none
                                text-[12px] md:text-[12px] lg:text-[12px] xl:text-[13px] 2xl:text-[14px]'>
                                “Estoy muy impresionada con la calidad del servicio de esta empresa. He utilizado sus servicios para enviar comida y regalos a mi familia en Guatemala, y siempre han llegado en perfecto estado y a tiempo. También me gusta que ofrecen opciones para diferentes presupuestos y tipos de productos. Definitivamente seguiré utilizando sus servicios en el futuro y se los recomendaré a mis amgiso y familiares.”
                            </p>
                        </div> */}
                        <div className="chat chat-start ">
                            <div className="chat-bubble bg-[#645EC2] w-full md:w-11/12 md:min-h-[85px] lg:min-h-[85px] xl:min-h-[85px] 2xl:min-h-[85px] flex items-center justify-center">
                                <p className='w-full flex-wrapper text-white text-center font-light leading-none
                                text-[14px] md:text-[14px] lg:text-[15px] xl:text-[15px] 2xl:text-[15px]'>
                                    {t('references.message_1')}
                                </p>
                            </div>
                        </div>
                        <div className='flex flex-row w-full md:w-11/12 pt-10 justify-center'>
                            <div className='w-14 h-14'>
                                <img className="w-14 h-14 rounded-full" src="../assets/images/EmiliRodriguez.png" />
                            </div>
                        </div>
                        <div className='flex flex-row w-full md:w-11/12 pt-2 justify-center'>
                            <p className='text-center text-zinc-900 text-lg font-bold leading-7'>{t('references.name_1')}</p>
                        </div>
                        <div className='flex flex-row w-full md:w-11/12 justify-center'>
                            <p className='text-center text-zinc-600 text-sm font-normal leading-normal'>{t('references.country_1')}</p>
                        </div>
                    </div>
                    <div className='sm:w-full md:w-2/4 lg:w-1/3 pt-4'>
                        {/* <div className='w-full md:w-11/12 bg-[#645EC2] h-48 rounded-xl 
                            pt-8 md:pt-4 lg:pt-6 xl:pt-6 2xl:pt-8 pl-2 pr-2'>
                            <p className='w-full flex-wrapper text-white text-center font-light leading-none
                                text-[12px] md:text-[12px] lg:text-[12px] xl:text-[13px] 2xl:text-[14px]'>
                                “Desde que empecé a utilizar los servicios de esta empresa para enviar regalos y compras a mi familia en Guatejala, todo ha sido muy fácil y cómodo. Puedo elegir entre una gran variedad de tiendas y productos, y siempre llegan a tiempo y en perfectas condiciones. ¡Incluso me han ayudado a sorprender a mi abuela en sus cumpleaños con un pastel de una panadería local!Definitivamente recomiendo sus servicios.”
                            </p>
                        </div> */}
                        <div className="chat chat-start ">
                            <div className="chat-bubble bg-[#645EC2] w-full md:w-11/12 md:min-h-[85px] lg:min-h-[85px] xl:min-h-[85px] 2xl:min-h-[85px] flex items-center justify-center">
                                <p className='w-full flex-wrapper text-white text-center font-light leading-none
                                text-[14px] md:text-[14px] lg:text-[15px] xl:text-[15px] 2xl:text-[15px]'>
                                    {t('references.message_2')}
                                </p>
                            </div>
                        </div>
                        <div className='flex flex-row w-full md:w-11/12 pt-10 justify-center'>
                            <img className="w-14 h-14 rounded-full" src="../assets/images/JuanMorales.png" />
                        </div>
                        <div className='flex flex-row w-full md:w-11/12 pt-2 justify-center'>
                            <p className='text-center text-zinc-900 text-lg font-bold leading-7'>{t('references.name_2')}</p>
                        </div>
                        <div className='flex flex-row w-full md:w-11/12 justify-center'>
                            <p className='text-center text-zinc-600 text-sm font-normal leading-normal'>{t('references.country_2')}</p>
                        </div>
                    </div>
                    <div className='sm:w-full md:w-2/4 lg:w-1/3 pt-4'>
                        {/* <div className='w-full md:w-11/12 bg-[#645EC2] h-48 rounded-xl 
                            pt-8 md:pt-4 lg:pt-6 xl:pt-6 2xl:pt-8 pl-2 pr-2'>
                            <p className='w-full flex-wrapper text-white text-center font-light leading-none
                                text-[12px] md:text-[12px] lg:text-[12px] xl:text-[13px] 2xl:text-[14px]'>“Trabajo en Estados Unidos y siempre he tenido difucultades para enviar productos específicos a mi familia en Guatemala. Pero desde que encontré esta empresa, todo ha sido muy sencillo. Puedo hacer mis compras en línea y ellos se encargan de todo, desde la recolección hasta la entrega. Incluso me han ayudado a enviar medicamentos a mi abuelo que vive en una zon rural. Estoy muy agradecida por su servicio.”</p>
                        </div> */}
                        <div className="chat chat-start ">
                            <div className="chat-bubble bg-[#645EC2] w-full md:w-11/12 md:min-h-[85px] lg:min-h-[85px] xl:min-h-[85px] 2xl:min-h-[85px] flex items-center justify-center">
                                <p className='w-full flex-wrapper text-white text-center font-light leading-none
                                text-[14px] md:text-[14px] lg:text-[15px] xl:text-[15px] 2xl:text-[15px]'>{t('references.message_3')}
                                </p>
                            </div>
                        </div>
                        <div className='flex flex-row w-full md:w-11/12 pt-10 justify-center'>
                            <img className="w-14 h-14 rounded-full" src="../assets/images/SaraGonzalez.jpg" />
                        </div>
                        <div className='flex flex-row w-full md:w-11/12 pt-2 justify-center'>
                            <p className='text-center text-zinc-900 text-lg font-bold leading-7'>{t('references.name_3')}</p>
                        </div>
                        <div className='flex flex-row w-full md:w-11/12 justify-center'>
                            <p className='text-center text-zinc-600 text-sm font-normal leading-normal'>{t('references.country_3')}</p>
                            {/* <div className="w-64 text-center text-zinc-600 text-sm font-normal leading-normal">Estados Unidos</div> */}
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
