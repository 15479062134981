import React, { useState } from 'react'
import "./style.css"
import { useTranslation } from 'react-i18next'
import { Helmet } from 'react-helmet';
import { Switch } from '@mui/material';
import { Link } from 'react-router-dom';

export const RepatriationPricing = () => {
    const { t } = useTranslation();
    const [checked, setChecked] = useState();

    const handleChange = () => {
        setChecked(!checked);
    }

    return (
        <>
            <div className='w-full align-middle justify-center items-center md:pt-20 bg-principal-blue'>
                <main className="main flow">
                    {/* <Helmet>
                    <script src="./payment.js" type="text/javascript" />
                    </Helmet> */}
                    <h1 className="main__heading">{t('repatriation.title_section2')}</h1>
                    <p className="w-full text-center text-white text-lg font-light leading-loose pt-2">
                        {t('repatriation.description')}
                    </p>
                    <div className='w-full flex flex-row'>
                        <div className='w-full text-white'>
                            {t('repatriation.dolar')}
                            <Switch
                                checked={checked}
                                onChange={handleChange}
                                color='default'
                                inputProps={{ 'aria-label': 'controlled' }}
                            />
                            {t('repatriation.quetzal')}
                        </div>
                    </div>

                    <div className="main__cards cards">
                        <div className="cards__inner w-4/12 justify-center place-content-center">
                            {/* <div className="cards__card card">
                                <h2 className="card__heading">{t('repatriation.monthly')}</h2>
                                {!checked &&
                                    <p className="card__price">$10.00</p>
                                }
                                {checked &&
                                    <p className="card__price">Q. 80.00</p>
                                }

                                <ul role="list" className="card__bullets flow">
                                    <li>{t('repatriation.repatriationService')}</li>
                                </ul>
                                <br />
                                {!checked &&
                                    <Link to='/repatriationform?id=1&currency=D'><input type='button' className='w-full block self-end mt-36 ml-0 mr-0 mb-2 decoration-0 text-white bg-[#0d0d0d]  text-center p-2 rounded-xl text-base font-[600]' value={t('repatriation.affiliate')} /></Link>
                                }
                                {checked &&
                                    <Link to='/repatriationform?id=1&currency=Q'><input type='button' className='w-full block self-end mt-36 ml-0 mr-0 mb-2 decoration-0 text-white bg-[#0d0d0d]  text-center p-2 rounded-xl text-base font-[600]' value={t('repatriation.affiliate')} /></Link>
                                }
                                 {!checked &&
                                    <form action='https://web.llega.com/spa/xpay/securew/ipay.asp' method='post' name='form1' target='_blank'>
                                        <input name='MerchantID' type='hidden' id='MerchantID' value='827' />
                                        <input name='Password' id='Password' type='hidden' value='11a22b' />
                                        <input name='PID' id='PID' type='hidden' value='IC1' />
                                        <input name='ProductID' id='ProductID' type='hidden' value='Repatriaciones USD' />
                                        <input name='BackAction' id='BackAction' type='hidden' value='B' />
                                        <input name='ReferenceNo' id='ReferenceNo' type='hidden' />
                                        <input name='Total' id='Total' value='8' type='hidden' />
                                        <input name='Description' id='Description' type='hidden' value='Repatriaciones USD' />
                                        <input type='submit' className='w-full block self-end mt-36 ml-0 mr-0 mb-2 decoration-0 text-white bg-[#0d0d0d]  text-center p-2 rounded-xl text-base font-[600]' value={t('repatriation.add')} />
                                    </form>
                                }
                                {checked &&
                                    <form action='https://host2.ypayme.com/spa/xpay/securew/ipay.asp' method='post' name='form1' target='_blank'>
                                        <input name='MerchantID' type='hidden' id='MerchantID' value='280' />
                                        <input name='Password' id='Password' type='hidden' value='11a22b33' />
                                        <input name='PID' id='PID' type='hidden' value='IC1' />
                                        <input name='ProductID' id='ProductID' type='hidden' value='Repatriaciones Q' />
                                        <input name='BackAction' id='BackAction' type='hidden' value='B' />
                                        <input name='ReferenceNo' id='ReferenceNo' type='hidden' />
                                        <input name='Total' id='Total' value='64' type='hidden' />
                                        <input name='Description' id='Description' type='hidden' value='Repatriaciones Q' />
                                        <input type='submit' className='w-full block self-end mt-36 ml-0 mr-0 mb-2 decoration-0 text-white bg-[#0d0d0d]  text-center p-2 rounded-xl text-base font-[600]' value={t('repatriation.add')} />
                                    </form>
                                }
                            </div> */}

                            {/* <div className="cards__card card">
                                <h2 className="card__heading">{t('repatriation.anual')}</h2>
                                {!checked &&
                                    <p className="card__price">$90.00</p>
                                }
                                {checked &&
                                    <p className="card__price">Q. 720.00</p>
                                }
                                <ul role="list" className="card__bullets flow">
                                    <li>{t('repatriation.repatriationService')}</li>
                                    <li hidden></li>
                                </ul>
                                <br />
                                <Link to='/repatriationform?id=2'><input type='button' className='w-full block self-end mt-36 ml-0 mr-0 mb-2 decoration-0 text-white bg-[#0d0d0d]  text-center p-2 rounded-xl text-base font-[600]' value={t('repatriation.affiliate')} /></Link>

                            </div> */}

                            <div className="cards__card card">
                                <h2 className="card__heading">{t('repatriation.plus')}</h2>
                                {!checked &&
                                    <p className="card__price">$100.00</p>
                                }
                                {checked &&
                                    <p className="card__price">Q. 800.00</p>
                                }
                                <ul role="list" className="card__bullets flow">
                                    <li>{t('repatriation.repatriationService')}</li>
                                    <li>{t('repatriation.funeralService')}</li>
                                </ul>
                                {checked &&
                                    <Link to='/repatriationform?id=3&currency=Q'><input type='button' className='w-full block self-end mt-36 ml-0 mr-0 mb-2 decoration-0 text-white bg-[#0d0d0d]  text-center p-2 rounded-xl text-base font-[600]' value={t('repatriation.affiliate')} /></Link>
                                }
                                {!checked &&
                                    <Link to='/repatriationform?id=3&currency=D'><input type='button' className='w-full block self-end mt-36 ml-0 mr-0 mb-2 decoration-0 text-white bg-[#0d0d0d]  text-center p-2 rounded-xl text-base font-[600]' value={t('repatriation.affiliate')} /></Link>
                                }
                                {/* {!checked &&
                                    <form action='https://web.llega.com/spa/xpay/securew/ipay.asp' method='post' name='form1' target='_blank'>
                                        <input name='MerchantID' type='hidden' id='MerchantID' value='827' />
                                        <input name='Password' id='Password' type='hidden' value='11a22b' />
                                        <input name='PID' id='PID' type='hidden' value='IC1' />
                                        <input name='ProductID' id='ProductID' type='hidden' value='Repatriaciones USD' />
                                        <input name='BackAction' id='BackAction' type='hidden' value='B' />
                                        <input name='ReferenceNo' id='ReferenceNo' type='hidden' />
                                        <input name='Total' id='Total' value='100' type='hidden' />
                                        <input name='Description' id='Description' type='hidden' value='Repatriaciones USD' />
                                        <input type='submit' className='w-full block self-end mt-20 ml-0 mr-0 mb-2 decoration-0 text-white bg-[#0d0d0d]  text-center p-2 rounded-xl text-base font-[600]' value={t('repatriation.add')} />
                                    </form>
                                }
                                {checked &&
                                    <form action='https://host2.ypayme.com/spa/xpay/securew/ipay.asp' method='post' name='form1' target='_blank'>
                                        <input name='MerchantID' type='hidden' id='MerchantID' value='280' />
                                        <input name='Password' id='Password' type='hidden' value='11a22b33' />
                                        <input name='PID' id='PID' type='hidden' value='IC1' />
                                        <input name='ProductID' id='ProductID' type='hidden' value='Repatriaciones Q' />
                                        <input name='BackAction' id='BackAction' type='hidden' value='B' />
                                        <input name='ReferenceNo' id='ReferenceNo' type='hidden' />
                                        <input name='Total' id='Total' value='800' type='hidden' />
                                        <input name='Description' id='Description' type='hidden' value='Repatriaciones Q' />
                                        <input type='submit' className='w-full block self-end mt-20 ml-0 mr-0 mb-2 decoration-0 text-white bg-[#0d0d0d]  text-center p-2 rounded-xl text-base font-[600]' value={t('repatriation.add')} />
                                    </form>
                                } */}
                            </div>
                        </div>

                        <div className="overlay cards__inner"></div>
                    </div>
                </main>
            </div>
        </>
    )
}
