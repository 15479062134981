import { Button } from '@mui/material';
import { DataGrid } from '@mui/x-data-grid'
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { startDeletingRepatriation, startLoadingAllRepatriations, startLoadingRepatriations } from '../store/Repatriations/thunks';
import { CSVLink, CSVDownload } from "react-csv";
import Swal from 'sweetalert2';
import { setActiveRepatriation } from '../store/Repatriations/repatriationSlice';

export const RepatriationAdmin = () => {
    const { repatriations } = useSelector(state => state.repatriation);
    const [filterRepatriations, setFilterRepatriations] = useState([]);
    const [deleteRepatriation, setDeleteRepatriation] = useState(false);
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(startLoadingAllRepatriations());
    }, [])

    useEffect(() => {
        if (deleteRepatriation) {
            dispatch(startLoadingRepatriations());
        }
    }, [deleteRepatriation])

    useEffect(() => {
        let tmpData = [];
        if (repatriations) {
            repatriations.map((item) => {
                const {
                    userLlega,
                    displayName,
                    identification,
                    policyNo,
                    plan,
                    authNo,
                    amount,
                    email,
                    phone,
                    areaCode,
                    creationDate,
                    address,
                    countryDestination,
                    cityholdDestination,
                    departmentDestination,
                    contactDestination,
                    areaCodeDestination,
                    phoneContactDestination,
                    addressOrigin,
                    cityOrigin,
                    stateOrigin,
                    countryOrigin,
                    contactOrigin,
                    areaCodeOrigin,
                    phoneContactOrigin,
                    birthday,
                    companyName,
                    sellerName
                } = item;

                const newData = {
                    Usuario: userLlega,
                    Póliza: policyNo,
                    Plan: plan,
                    Autorizacion: authNo,
                    Monto: amount,
                    FechaCreación: creationDate,
                    Nombre: displayName,
                    Correo: email,
                    Identificación: identification,
                    FechaNacimiento: birthday,
                    CodigoArea: areaCode,
                    Teléfono: phone,
                    Compañia: companyName,
                    Vendedor: sellerName,
                    DirecciónOrigen: addressOrigin,
                    CiudadOrigen: cityOrigin,
                    EstadoOrigen: stateOrigin,
                    PaisOrigen: countryOrigin,
                    ContactoOrigen: contactOrigin,
                    CodigoAreaOrigen: areaCodeOrigin,
                    TelefonoContactoOrigen: phoneContactOrigin,
                    DirecciónDestino: address,
                    MunicipioDestino: cityholdDestination,
                    DepartamentoDestino: departmentDestination,
                    PaisDestino: countryDestination,
                    ContactoDestino: contactDestination,
                    CodigoAreaDestino: areaCodeDestination,
                    TelefonoContactoDestino: phoneContactDestination
                }
                tmpData.push(newData);

            }
            )
            setFilterRepatriations(tmpData);

        }
    }, [repatriations])



    const columns = [
        { field: 'userLlega', headerName: 'Usuario Llega', width: 100 },
        { field: 'policyNo', headerName: 'Contrato', width: 100 },
        { field: 'authNo', headerName: 'Auth Number', width: 100 },
        { field: 'amount', headerName: 'Monto', width: 100 },
        { field: 'plan', headerName: 'Plan', width: 100 },
        { field: 'displayName', headerName: 'Nombre', width: 200 },
        { field: 'identification', headerName: 'Identificación', width: 150 },
        { field: 'email', headerName: 'Correo', width: 250 },
        { field: 'phone', headerName: 'Teléfono', width: 100 },
        { field: 'creationDate', headerName: 'Fecha', width: 100 },
        { field: 'address', headerName: 'Dirección Destino', width: 300 },
        { field: 'countryDestination', headerName: 'Pais Destino', width: 300 },
        { field: 'cityholdDestination', headerName: 'Municipio Destino', width: 300 },
        { field: 'departmentDestination', headerName: 'Departamento Destino', width: 300 },
        { field: 'contactDestination', headerName: 'Contacto Destino', width: 250 },
        { field: 'areaCodeDestination', headerName: 'Codigo Area Destino', width: 150 },
        { field: 'phoneContactDestination', headerName: 'Telefono Contacto Destino', width: 200 },
        { field: 'addressOrigin', headerName: 'Dirección Origen', width: 300 },
        { field: 'cityOrigin', headerName: 'Ciudad Origen', width: 300 },
        { field: 'stateOrigin', headerName: 'Estado Origen', width: 300 },
        { field: 'countryOrigin', headerName: 'Pais Origen', width: 300 },
        { field: 'contactOrigin', headerName: 'Contacto Origen', width: 200 },
        { field: 'areaCodeOrigin', headerName: 'Codigo Area Origen', width: 150 },
        { field: 'phoneContactOrigin', headerName: 'Telefono Contacto Origen', width: 200 },
        { field: 'birthday', headerName: 'Fecha Nacimiento', width: 150 },
        { field: 'companyName', headerName: 'Compañía', width: 150 },
        { field: 'sellerName', headerName: 'Vendedor', width: 150 },
        // { field: 'id', headerName: 'Opciones', width: 250, renderCell: (params) => <></> }
        // { field: 'id', headerName: 'Opciones', width: 250, renderCell: (params) => <><button className='w-16 h-8 bg-red-800 text-white ml-2 rounded-full' onClick={() => onDelete(params.value)}>Eliminar</button></> }
    ]

    const onDelete = (idRep) => {
        Swal.fire({
            title: "Estas seguro que deseas eliminar?",
            showDenyButton: true,
            // showCancelButton: true,
            confirmButtonText: "Sí",
            denyButtonText: `No`
        }).then((result) => {
            /* Read more about isConfirmed, isDenied below */
            if (result.isConfirmed) {
                let tmpRep = repatriations.filter(x => x.id === idRep);
                if (tmpRep.length > 0) {
                    const {
                        id, identification, displayName, address, addressOrigin, birthday, companyId, creationDate, email, phone, refered, seller, sellerName, userLlega, updateDate
                    } = tmpRep[0];
                    dispatch(setActiveRepatriation({ id, identification, displayName, address, addressOrigin, birthday, companyId, creationDate, email, phone, refered, seller, sellerName, userLlega, updateDate }))
                    setDeleteRepatriation(true);
                    dispatch(startDeletingRepatriation(id));
                    Swal.fire("Eliminado!", "", "success");
                } else {
                    Swal.fire("No se ha podido eliminar el registro", "", "error");
                }
            } else if (result.isDenied) {
                Swal.fire("No ha sido eliminado", "", "info");
            }
        });
        // let tmpCountry = countries.filter(x => x.id === idCountry);
        // const { id, countryName, status } = tmpCountry[0];
        // dispatch(setActiveCountry({ id, countryName, status }))
        // setDeleteCountry(true);
        // dispatch(startDeletingCountry(id));
        // if (!errorMessage) {
        //     showDeleteSuccess();
        //     onClear();
        // }
    }

    const exportToCSV = () => {
        let csv = arrayToCsv(repatriations);
        if (csv) {
            // downloadBlob(csv, 'repatriaciones.csv', 'text/csv;charset=utf-8;');
            // downloadBlob(csv, 'repatriaciones.csv', 'text/csv;encoding:utf-8');
            downloadBlob(csv, 'repatriaciones.csv', 'text/plain');
        }
    }

    function downloadBlob(content, filename, contentType) {
        // Create a blob
        var blob = new Blob([content], { type: contentType });
        var url = URL.createObjectURL(blob);

        // Create a link to download it
        var pom = document.createElement('a');
        pom.href = url;
        pom.setAttribute('download', filename);
        pom.click();
    }

    function arrayToCsv(data) {
        let tmpData = data.map((item) => {
            return Object.values(item).join(",");
        })
        let tmpData2 = tmpData.map((item) => {
            return item.replaceAll('"', '""');
        })
        let tmpData3 = tmpData2.map((item) => {
            return `"${item}"`;
        })
        let tmpData4 = Object.values(tmpData3).join(",\r\n");
        return tmpData4;
    }

    return (
        <>
            <div className='flex flex-col bg-white w-full place-items-center h-[870px] pt-20 pb-20'>
                <div className='w-32 h-8 bg-blue-800 text-white rounded-2xl'>
                    {/* <Button className='w-16 h-8 bg-blue-800 text-white rounded-full' type='button' onClick={() => exportToCSV()}>Exportar</Button> */}
                    {/* <CSVDownload data={repatriations} target="_blank" />; */}
                    <div className='pt-1'>
                        <CSVLink data={filterRepatriations}>Exportar</CSVLink>
                    </div>
                </div>
                {/* <button className='w-16 h-8 bg-blue-800 text-white rounded-full' onClick={() => onEdit(params.value)}>Editar</button> */}
                <div className=' overflow-scroll w-full pt-2 pl-4 pr-4'>
                    <DataGrid
                        rows={repatriations}
                        columns={columns}
                        initialState={{
                            pagination: {
                                paginationModel: { page: 0, pageSize: 5 },
                            },
                        }}
                        pageSizeOptions={[5, 10]}

                    />
                </div>
            </div>
        </>

    )
}
