import axios from 'axios';

export const apiInstance = axios.create({
    // baseURL: process.env.NEXT_PUBLIC_BACKEND_URL,
    baseURL: 'https://payment-service-bhud6f5q3q-uc.a.run.app/api/'
    // baseURL: 'http://localhost:5000/api/'
    // headers: {
    //     // source: '/:path*',
    //     // headers: false
    //     // headers: {
    //     // 'X-Requested-With': 'XMLHttpRequest',
    //     // "Access-Control-Allow-Methods": "GET,PUT,POST,DELETE,PATCH,OPTIONS",
    //     'Content-Type': 'application/json',
    //     'Access-Control-Allow-Origin': '*',
    //     'Accept': 'application/json'
    //     // 'Access-Control-Allow-Credentials': true,
    //     //'Access-Control-Allos-Origin': 'www.web.llega.com',
    //     // 'Access-Control-Allow-Methods': 'GET, OPTIONS, PATCH, DELETE, POST, PUT',
    //     // 'Access-Control-Allow-Headers': false, //'X-CSRF-Token, X-Requested-With, Accept, Accept Version, Content-Length, Content-MD5, Content-Type, Date, X-Api-Version, Authorization'
    // },
    // mode: 'no-cors'
});

// export const apiInstance = axios.create({
//     baseURL: 'https://cors-anywhere.herokuapp.com/' + process.env.NEXT_PUBLIC_BACKEND_URL,
//     headers: {
//         'Content-Type': 'application/json',
//     },
// });