import { configureStore } from '@reduxjs/toolkit'
import { authSlice } from './auth/authSlice'
import { categorieSlice } from './Categories/categoriesSlice'
import { companieSlice } from './Companies/companiesSlice'
import { repatriationSlice } from './Repatriations/repatriationSlice'
import { countrySlice } from './countries/countrySlice'
import { sellerSlice } from './seller/sellerSlice'
import { partnerSlice } from './partner/partnerSlice'
import { videoSlice } from './video/videoSlice'
import { repCountrySlice } from './RepatriationCountry/repatriationCountrySlice'
import { referredSlice } from './Referred/referredSlice'
import { paymentSlice } from './Payment/paymentSlice'
import { llegaRepatriationSlice } from './llegaRepatriation/llegaRepatriationSlice'


export const store = configureStore({
    reducer: {
        auth: authSlice.reducer,
        categorie: categorieSlice.reducer,
        companie: companieSlice.reducer,
        repatriation: repatriationSlice.reducer,
        repCountry: repCountrySlice.reducer,
        country: countrySlice.reducer,
        seller: sellerSlice.reducer,
        partner: partnerSlice.reducer,
        video: videoSlice.reducer,
        referred: referredSlice.reducer,
        payment: paymentSlice.reducer,
        llegaRepatriation: llegaRepatriationSlice.reducer
    },
})
