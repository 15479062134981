import { Typography } from '@mui/material';
import React from 'react'
import { Link } from 'react-router-dom';

function FileCard({ title, description, type, urlImage, urlVideo }) {
    return (
        <>
            <a href={urlVideo} target='_blank'>
                <div className='w-full'>
                    <div className='w-full rounded-2xl'
                        style={{ backgroundImage: `url("${urlImage}")`, height: '300px' }}>
                    </div>
                    <div className='max-w-[360px]'>
                        <div className='w-full flex flex-row text-center justify-center pt-4'>
                            <Typography gutterBottom variant="h5" component="div" fontSize='1.25rem' className='text-center'>
                                {title}
                            </Typography>
                        </div>
                        <div className='w-full flex flex-row text-center justify-center'>
                            <Typography variant="body2" color="text.secondary" fontSize='0.813rem'>
                                {description}
                            </Typography>
                        </div>
                    </div>
                </div>
            </a >
        </>
    )
}

export default FileCard;