import { addNewEmptyRepatriation, deleteRepatriationById, savingNewRepatriation, setActiveRepatriation, setRepatriation, setRepatriations, setSaving, updateRepatriation } from "./repatriationSlice";
import { collection, count, deleteDoc, doc, setDoc } from "firebase/firestore/lite";
import { FirebaseDB } from "../../firebase/config";
import { loadAllRepatriations, loadRepatriation, loadRepatriations } from "../../helpers/loadRepatriations";
import moment from 'moment';

export const startNewClearRepatriation = (repatriation) => {
    return async (dispatch, getState) => {
        dispatch(savingNewRepatriation());

        const {
            displayName,
            identification,
            email,
            phone,
            areaCode,
            address,
            addressOrigin,
            birthday,
            companyId,
            companyName,
            refered,
            seller,
            sellerName,
            userLlega,
            policyNo,
            authNo,
            plan,
            amount,
            acceptTerms,
        } = repatriation

        const newRepatriation = {
            displayName,
            identification,
            email,
            phone,
            areaCode,
            address,
            addressOrigin,
            birthday,
            companyId,
            companyName,
            refered,
            seller,
            sellerName,
            userLlega,
            policyNo,
            authNo,
            plan,
            amount,
            acceptTerms,
            creationDate: moment(new Date().getTime()).format("DD/MM/YYYY"),
            updateDate: moment(new Date().getTime()).format("DD/MM/YYYY")
        }

        const newDoc = doc(collection(FirebaseDB, `repatriation`))
        await setDoc(newDoc, newRepatriation);
        newRepatriation.id = newDoc.id;
        dispatch(addNewEmptyRepatriation(newRepatriation));
        dispatch(setActiveRepatriation(newRepatriation));
    }
}

export const startNewRepatriation = (repatriation) => {
    return async (dispatch, getState) => {
        dispatch(savingNewRepatriation());

        const { uid } = getState().auth;
        const {
            displayName,
            identification,
            email,
            phone,
            areaCode,
            address,
            cityholdDestination,
            countryDestination,
            departmentDestination,
            contactDestination,
            areaCodeDestination,
            phoneContactDestination,
            addressOrigin,
            cityOrigin,
            stateOrigin,
            countryOrigin,
            contactOrigin,
            areaCodeOrigin,
            phoneContactOrigin,
            birthday,
            companyId,
            companyName,
            refered,
            seller,
            sellerName,
            userLlega,
            policyNo,
            authNo,
            plan,
            amount,
            acceptTerms
        } = repatriation

        const newRepatriation = {
            displayName,
            identification,
            email,
            phone,
            areaCode,
            address,
            cityholdDestination,
            countryDestination,
            departmentDestination,
            contactDestination,
            areaCodeDestination,
            phoneContactDestination,
            addressOrigin,
            cityOrigin,
            stateOrigin,
            countryOrigin,
            contactOrigin,
            areaCodeOrigin,
            phoneContactOrigin,
            birthday,
            companyId,
            companyName,
            refered,
            seller,
            sellerName,
            userLlega,
            policyNo,
            authNo,
            plan,
            amount,
            acceptTerms,
            creationDate: moment(new Date().getTime()).format("DD/MM/YYYY"),
            updateDate: moment(new Date().getTime()).format("DD/MM/YYYY")
        }

        const newDoc = doc(collection(FirebaseDB, `repatriation`));
        await setDoc(newDoc, newRepatriation);
        newRepatriation.id = newDoc.id;

        dispatch(addNewEmptyRepatriation(newRepatriation));
        dispatch(setActiveRepatriation(newRepatriation));
    }
}

export const startLoadingRepatriations = () => {
    return async (dispatch, getState) => {
        const { uid } = getState().auth;

        if (!uid) throw new Error('El UID del usuario no existe');
        const repatriations = await loadRepatriations(uid);
        dispatch(setRepatriations(repatriations));
    }
}

export const startLoadingAllRepatriations = () => {
    return async (dispatch, getState) => {

        const repatriations = await loadAllRepatriations();
        dispatch(setRepatriations(repatriations));
    }
}

export const startLoadingRepatriation = () => {
    return async (dispatch, getState) => {
        const { uid } = getState().auth;

        if (!uid) throw new Error("El UID del usuario no existe");
        const repatriation = await loadRepatriation(uid);
        dispatch(setRepatriation(repatriation));
    }
}

export const startDeletingRepatriation = () => {
    return async (dispatch, getState) => {
        const { uid } = getState().auth;
        const { active: repatriation } = getState().repatriation;

        const docRef = doc(FirebaseDB, `repatriation/${repatriation.id}`);
        await deleteDoc(docRef);

        dispatch(deleteRepatriationById(repatriation.id));
    }
}

export const startSaveRepatriation = () => {
    return async (dispatch, getState) => {

        dispatch(setSaving());

        const { uid } = getState().auth;
        const { active: repatriation } = getState().repatriation;

        const repatriationToFireStore = { ...repatriation };
        delete repatriationToFireStore.id;

        const docRef = doc(FirebaseDB, `repatriation/${repatriation.id}`)
        await setDoc(docRef, repatriationToFireStore, { merge: true });

        dispatch(updateRepatriation(repatriation));
    }
}