import { Button } from '@mui/material';
import { DataGrid } from '@mui/x-data-grid'
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { startDeletingRepatriation, startLoadingAllRepatriations, startLoadingRepatriations } from '../store/Repatriations/thunks';
import { CSVLink, CSVDownload } from "react-csv";
import Swal from 'sweetalert2';
import { setActiveRepatriation } from '../store/Repatriations/repatriationSlice';
import { startLoadingAllPayments } from '../store/Payment/thunks';

export const RepatriationPayments = () => {
    const { repatriations } = useSelector(state => state.repatriation);
    const [dataRepatriations, setDataRepatriations] = useState([]);
    const [filterRepatriations, setFilterRepatriations] = useState([]);
    const { payments } = useSelector(state => state.payment);
    const [deleteRepatriation, setDeleteRepatriation] = useState(false);
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(startLoadingAllRepatriations());
        dispatch(startLoadingAllPayments());
    }, [])

    useEffect(() => {
        let tmpData = [];

        if (payments) {
            payments.map((item, index) => {
                const {
                    AuthNo,
                    Last4DigitCardNumber,
                    ProductID,
                    ReferenceNo,
                    ResponseCode,
                    ResponseMsg,
                    Total,
                    creationDate,
                    updateDate,
                    userLlega
                } = item;

                const tmpRepatriation = repatriations.filter(x => x.policyNo === item.ReferenceNo);

                const newData = {
                    id: index,
                    Autorizacion: AuthNo,
                    TerminacionTarjeta: Last4DigitCardNumber,
                    Poliza: ProductID,
                    ReferenceNo,
                    ResponseCode,
                    ResponseMsg,
                    Total,
                    creationDate,
                    updateDate,
                    // userLlega, // se comenta el usuario con que se realizó el pago por el usuario de creación de contrato.
                    userLlega: tmpRepatriation[0]?.userLlega,
                    displayName: tmpRepatriation[0]?.displayName,
                    plan: tmpRepatriation[0]?.plan,
                    identification: tmpRepatriation[0]?.identification,
                    email: tmpRepatriation[0]?.email,
                    phone: tmpRepatriation[0]?.phone,
                    address: tmpRepatriation[0]?.address,
                    countryDestination: tmpRepatriation[0]?.countryDestination,
                    cityholdDestination: tmpRepatriation[0]?.cityholdDestination,
                    departmentDestination: tmpRepatriation[0]?.departmentDestination,
                    contactDestination: tmpRepatriation[0]?.contactDestination,
                    areaCodeDestination: tmpRepatriation[0]?.areaCodeDestination,
                    phoneContactDestination: tmpRepatriation[0]?.phoneContactDestination,
                    addressOrigin: tmpRepatriation[0]?.addressOrigin,
                    cityOrigin: tmpRepatriation[0]?.cityOrigin,
                    stateOrigin: tmpRepatriation[0]?.stateOrigin,
                    countryOrigin: tmpRepatriation[0]?.countryOrigin,
                    contactOrigin: tmpRepatriation[0]?.contactOrigin,
                    areaCodeOrigin: tmpRepatriation[0]?.areaCodeOrigin,
                    phoneContactOrigin: tmpRepatriation[0]?.phoneContactOrigin,
                    birthday: tmpRepatriation[0]?.birthday,
                    companyName: tmpRepatriation[0]?.companyName,
                    sellerName: tmpRepatriation[0]?.sellerName,
                    type: Last4DigitCardNumber === '0000' ? 'Creación' : 'Pago',
                }
                tmpData.push(newData);
            })
        }
        // setRepatriations([
        //     { id: '1', userLlega: 'bgp369', policyNo: '2014-12345', authNo: '12345', creationDate: '16/02/2024' }
        // ])
        setFilterRepatriations(tmpData);
    }, [payments])

    const columns = [
        { field: 'userLlega', headerName: 'Usuario Llega', width: 100 },
        { field: 'Poliza', headerName: 'Contrato', width: 100 },
        { field: 'ReferenceNo', headerName: 'ReferenceNo', width: 100 },
        { field: 'type', headerName: 'Tipo', width: 100 },
        { field: 'Autorizacion', headerName: 'Auth Number', width: 100 },
        { field: 'Total', headerName: 'Total', width: 100 },
        { field: 'plan', headerName: 'Plan', width: 100 },
        { field: 'displayName', headerName: 'Nombre', width: 200 },
        { field: 'identification', headerName: 'Identificación', width: 150 },
        { field: 'email', headerName: 'Correo', width: 250 },
        { field: 'phone', headerName: 'Teléfono', width: 100 },
        { field: 'creationDate', headerName: 'Fecha', width: 100 },
        { field: 'address', headerName: 'Dirección Destino', width: 300 },
        { field: 'countryDestination', headerName: 'Pais Destino', width: 300 },
        { field: 'cityholdDestination', headerName: 'Municipio Destino', width: 300 },
        { field: 'departmentDestination', headerName: 'Departamento Destino', width: 300 },
        { field: 'contactDestination', headerName: 'Contacto Destino', width: 300 },
        { field: 'areaCodeDestination', headerName: 'Codigo Area Destino', width: 100 },
        { field: 'phoneContactDestination', headerName: 'Telefono Contacto Destino', width: 300 },
        { field: 'addressOrigin', headerName: 'Dirección Origen', width: 300 },
        { field: 'cityOrigin', headerName: 'Ciudad Origen', width: 300 },
        { field: 'stateOrigin', headerName: 'Estado Origen', width: 300 },
        { field: 'countryOrigin', headerName: 'Pais Origen', width: 300 },
        { field: 'contactOrigin', headerName: 'Contacto Origen', width: 300 },
        { field: 'areaCodeOrigin', headerName: 'Codigo Area Origen', width: 300 },
        { field: 'phoneContactOrigin', headerName: 'Telefono Contacto Origen', width: 300 },
        { field: 'birthday', headerName: 'Fecha Nacimiento', width: 150 },
        { field: 'companyName', headerName: 'Compañía', width: 150 },
        { field: 'sellerName', headerName: 'Vendedor', width: 150 },
        { field: 'TerminacionTarjeta', headerName: 'Terminacion Tarjeta', width: 100 },

        { field: 'ResponseMsg', headerName: 'ResponseMsg', width: 100 },


        // { field: 'id', headerName: 'Opciones', width: 250, renderCell: (params) => <></> }
    ]

    const onDelete = (idRep) => {

    }

    return (
        <div className='flex flex-col bg-white w-full place-items-center h-[870px] pt-20 pb-20'>
            <div className='w-32 h-8 bg-blue-800 text-white rounded-2xl'>
                {/* <Button className='w-16 h-8 bg-blue-800 text-white rounded-full' type='button' onClick={() => exportToCSV()}>Exportar</Button> */}
                {/* <CSVDownload data={repatriations} target="_blank" />; */}
                <div className='pt-1'>
                    <CSVLink data={filterRepatriations}>Exportar</CSVLink>
                </div>
            </div>
            {/* <button className='w-16 h-8 bg-blue-800 text-white rounded-full' onClick={() => onEdit(params.value)}>Editar</button> */}
            <div className=' overflow-scroll w-full pt-2 pl-4 pr-4'>
                <DataGrid
                    rows={filterRepatriations}
                    columns={columns}
                    initialState={{
                        pagination: {
                            paginationModel: { page: 0, pageSize: 5 },
                        },
                    }}
                    pageSizeOptions={[5, 10]}

                />
            </div>
        </div>
    )
}
