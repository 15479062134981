// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAuth } from 'firebase/auth';
import { getFirestore } from 'firebase/firestore/lite';
import { getStorage } from 'firebase/storage';
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
const firebaseConfig = {
    apiKey: "AIzaSyCcH5PozKNIyzMez2tlJ_JvdLBS1OioABQ",
    authDomain: "llega-market.firebaseapp.com",
    projectId: "llega-market",
    storageBucket: "llega-market.appspot.com",
    messagingSenderId: "916273192861",
    appId: "1:916273192861:web:be0d1c8a61551a4729d974"
};

// Initialize Firebase
export const FirebaseApp = initializeApp(firebaseConfig);
export const FirebaseAuth = getAuth(FirebaseApp);
export const FirebaseDB = getFirestore(FirebaseApp)
export const FirebaseStorage = getStorage(FirebaseApp);