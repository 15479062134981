import { query, collection, getDocs, where } from "firebase/firestore/lite";
import { FirebaseDB } from "../firebase/config";


export const loadReferreds = async (uid = '') => {
    if (!uid) throw new Error('El UID del usuario no existe');

    const q = query(
        collection(FirebaseDB, 'referred')
    );

    const docs = await getDocs(q);
    const referreds = [];
    docs.forEach(doc => {
        referreds.push({ id: doc.id, ...doc.data() });
    });
    return referreds;
}

export const loadAllReferreds = async () => {

    const q = query(
        collection(FirebaseDB, 'referred')
    );

    const docs = await getDocs(q);
    const referreds = [];
    docs.forEach(doc => {
        referreds.push({ id: doc.id, ...doc.data() });
    });
    return referreds;
}

export const loadReferred = async (uid = '') => {
    if (!uid) throw new Error('El UID del usuario no existe');

    const q = query(
        collection(FirebaseDB, 'referred'),
        where('uid', '==', uid)
    );

    const docs = await getDocs(q);

    const referred = [];
    docs.forEach(doc => {
        referred.push({ id: doc.id, ...doc.data() });
    })

    return referred;
}

