import { Box, Checkbox, CircularProgress, FormControl, MenuItem, Select } from '@mui/material';
import React, { useEffect, useState, useRef } from 'react'
import { useTranslation } from 'react-i18next'
import { useForm } from 'react-hook-form';
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import Swal from 'sweetalert2';
import { useDispatch, useSelector } from 'react-redux';
import { startLoadingAllCompanies, startLoadingCompanies } from '../store/Companies/thunks';
import { RepatriationPayment } from './RepatriationPayment';
import { startLoadingAllSellers } from '../store/seller/thunks';
import { RepatriationPricing } from './pricing/RepatriationPricing';
import { startLoadingAllRepatriations, startNewRepatriation } from '../store/Repatriations/thunks';
import { setActiveRepatriation } from '../store/Repatriations/repatriationSlice';
import emailjs from '@emailjs/browser';
import { startLoadingAllRepCountries } from '../store/RepatriationCountry/thunks';
import moment from 'moment';
import axios from 'axios';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { apiInstance } from '../utils';
import ModalVideo from 'react-modal-video';
import { setActivePayment } from '../store/Payment/paymentSlice';
import { startNewPayment } from '../store/Payment/thunks';
import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import { startLoadingAllLlegaRepatriations } from '../store/llegaRepatriation/thunks';
import { v4 as uuidv4 } from 'uuid';
import { PDFDownloadLink, Page, Text, View, Document, StyleSheet, pdf, Image, Font, PDFDocument, pdfjs } from '@react-pdf/renderer';
import ReactPDF from '@react-pdf/renderer';

const plans = [
    // { name: 'Mensual - $10.00', nameQ: 'Mensual - Q.80.00', value: 1, amount: 10, amountQ: 80 },
    // { name: 'Anual - $90.00', value: 2, amount: 90 },
    { name: 'Anual Plus - $100.00', nameQ: 'Anual Plus - Q.800.00', value: 3, amount: 100, amountQ: 800 }
]

// Registrar la fuente
Font.register({
    family: 'Roboto',
    src: 'https://fonts.gstatic.com/s/roboto/v19/KFOmCnqEu92Fr1Mu4mxK.woff2',
});

const styles = StyleSheet.create({
    page: {
        padding: 30,
        //   fontFamily: 'Roboto',
    },
    logo: {
        width: 140,
        height: 40,
        marginBottom: 15,
        alignSelf: 'flex-end',
    },
    header: {
        flexDirection: 'row',
        justifyContent: 'space-between',
        marginBottom: 20,
        fontSize: 12
    },
    text: {
        marginBottom: 14,
        fontSize: 12, // Tamaño del texto
    },
    textTitle: {
        marginBottom: 4,
        fontSize: 12, // Tamaño del texto
    },
    boldText: {
        fontWeight: 'bold',
        fontSize: 12, // Tamaño del texto
    },
    footer: {
        flexDirection: 'row',
        justifyContent: 'space-around',
        marginTop: 20,
    },
    cardImage: {
        position: 'relative',
        width: 250, // Ancho de una tarjeta de crédito
        height: 140, // Altura de una tarjeta de crédito
    },
    // cardText: {
    //     position: 'absolute',
    //     left: 10,
    //     top: 60,
    //     color: '#FFFFFF',
    //     fontSize: 9,
    //     textTransform: 'uppercase',
    // },
    cardText: {
        position: 'absolute',
        left: 10,
        top: 40,
        color: '#FFFFFF',
        fontSize: 9,
        textTransform: 'uppercase',
        width: '40%',
        height: 'auto'
    },
    agreementText: {
        position: 'absolute',
        left: 10,
        top: 90,
        color: '#FFFFFF',
        fontSize: 12,
    },
    divider: {
        borderBottomWidth: 1,
        borderBottomColor: '#000000',
        marginTop: 20,
        marginBottom: 20,
    },
});

const countries = [
    { code: 'AD', label: 'Andorra', phone: '376' },
    {
        code: 'AE',
        label: 'United Arab Emirates',
        phone: '971',
    },
    { code: 'AF', label: 'Afghanistan', phone: '93' },
    {
        code: 'AG',
        label: 'Antigua and Barbuda',
        phone: '1-268',
    },
    { code: 'AI', label: 'Anguilla', phone: '1-264' },
    { code: 'AL', label: 'Albania', phone: '355' },
    { code: 'AM', label: 'Armenia', phone: '374' },
    { code: 'AO', label: 'Angola', phone: '244' },
    { code: 'AQ', label: 'Antarctica', phone: '672' },
    { code: 'AR', label: 'Argentina', phone: '54' },
    { code: 'AS', label: 'American Samoa', phone: '1-684' },
    { code: 'AT', label: 'Austria', phone: '43' },
    {
        code: 'AU',
        label: 'Australia',
        phone: '61',
        suggested: true,
    },
    { code: 'AW', label: 'Aruba', phone: '297' },
    { code: 'AX', label: 'Alland Islands', phone: '358' },
    { code: 'AZ', label: 'Azerbaijan', phone: '994' },
    {
        code: 'BA',
        label: 'Bosnia and Herzegovina',
        phone: '387',
    },
    { code: 'BB', label: 'Barbados', phone: '1-246' },
    { code: 'BD', label: 'Bangladesh', phone: '880' },
    { code: 'BE', label: 'Belgium', phone: '32' },
    { code: 'BF', label: 'Burkina Faso', phone: '226' },
    { code: 'BG', label: 'Bulgaria', phone: '359' },
    { code: 'BH', label: 'Bahrain', phone: '973' },
    { code: 'BI', label: 'Burundi', phone: '257' },
    { code: 'BJ', label: 'Benin', phone: '229' },
    { code: 'BL', label: 'Saint Barthelemy', phone: '590' },
    { code: 'BM', label: 'Bermuda', phone: '1-441' },
    { code: 'BN', label: 'Brunei Darussalam', phone: '673' },
    { code: 'BO', label: 'Bolivia', phone: '591' },
    { code: 'BR', label: 'Brazil', phone: '55' },
    { code: 'BS', label: 'Bahamas', phone: '1-242' },
    { code: 'BT', label: 'Bhutan', phone: '975' },
    { code: 'BV', label: 'Bouvet Island', phone: '47' },
    { code: 'BW', label: 'Botswana', phone: '267' },
    { code: 'BY', label: 'Belarus', phone: '375' },
    { code: 'BZ', label: 'Belize', phone: '501' },
    {
        code: 'CA',
        label: 'Canada',
        phone: '1',
        suggested: true,
    },
    {
        code: 'CC',
        label: 'Cocos (Keeling) Islands',
        phone: '61',
    },
    {
        code: 'CD',
        label: 'Congo, Democratic Republic of the',
        phone: '243',
    },
    {
        code: 'CF',
        label: 'Central African Republic',
        phone: '236',
    },
    {
        code: 'CG',
        label: 'Congo, Republic of the',
        phone: '242',
    },
    { code: 'CH', label: 'Switzerland', phone: '41' },
    { code: 'CI', label: "Cote d'Ivoire", phone: '225' },
    { code: 'CK', label: 'Cook Islands', phone: '682' },
    { code: 'CL', label: 'Chile', phone: '56' },
    { code: 'CM', label: 'Cameroon', phone: '237' },
    { code: 'CN', label: 'China', phone: '86' },
    { code: 'CO', label: 'Colombia', phone: '57' },
    { code: 'CR', label: 'Costa Rica', phone: '506' },
    { code: 'CU', label: 'Cuba', phone: '53' },
    { code: 'CV', label: 'Cape Verde', phone: '238' },
    { code: 'CW', label: 'Curacao', phone: '599' },
    { code: 'CX', label: 'Christmas Island', phone: '61' },
    { code: 'CY', label: 'Cyprus', phone: '357' },
    { code: 'CZ', label: 'Czech Republic', phone: '420' },
    {
        code: 'DE',
        label: 'Germany',
        phone: '49',
        suggested: true,
    },
    { code: 'DJ', label: 'Djibouti', phone: '253' },
    { code: 'DK', label: 'Denmark', phone: '45' },
    { code: 'DM', label: 'Dominica', phone: '1-767' },
    {
        code: 'DO',
        label: 'Dominican Republic',
        phone: '1-809',
    },
    { code: 'DZ', label: 'Algeria', phone: '213' },
    { code: 'EC', label: 'Ecuador', phone: '593' },
    { code: 'EE', label: 'Estonia', phone: '372' },
    { code: 'EG', label: 'Egypt', phone: '20' },
    { code: 'EH', label: 'Western Sahara', phone: '212' },
    { code: 'ER', label: 'Eritrea', phone: '291' },
    { code: 'ES', label: 'Spain', phone: '34' },
    { code: 'ET', label: 'Ethiopia', phone: '251' },
    { code: 'FI', label: 'Finland', phone: '358' },
    { code: 'FJ', label: 'Fiji', phone: '679' },
    {
        code: 'FK',
        label: 'Falkland Islands (Malvinas)',
        phone: '500',
    },
    {
        code: 'FM',
        label: 'Micronesia, Federated States of',
        phone: '691',
    },
    { code: 'FO', label: 'Faroe Islands', phone: '298' },
    {
        code: 'FR',
        label: 'France',
        phone: '33',
        suggested: true,
    },
    { code: 'GA', label: 'Gabon', phone: '241' },
    { code: 'GB', label: 'United Kingdom', phone: '44' },
    { code: 'GD', label: 'Grenada', phone: '1-473' },
    { code: 'GE', label: 'Georgia', phone: '995' },
    { code: 'GF', label: 'French Guiana', phone: '594' },
    { code: 'GG', label: 'Guernsey', phone: '44' },
    { code: 'GH', label: 'Ghana', phone: '233' },
    { code: 'GI', label: 'Gibraltar', phone: '350' },
    { code: 'GL', label: 'Greenland', phone: '299' },
    { code: 'GM', label: 'Gambia', phone: '220' },
    { code: 'GN', label: 'Guinea', phone: '224' },
    { code: 'GP', label: 'Guadeloupe', phone: '590' },
    { code: 'GQ', label: 'Equatorial Guinea', phone: '240' },
    { code: 'GR', label: 'Greece', phone: '30' },
    {
        code: 'GS',
        label: 'South Georgia and the South Sandwich Islands',
        phone: '500',
    },
    { code: 'GT', label: 'Guatemala', phone: '502' },
    { code: 'GU', label: 'Guam', phone: '1-671' },
    { code: 'GW', label: 'Guinea-Bissau', phone: '245' },
    { code: 'GY', label: 'Guyana', phone: '592' },
    { code: 'HK', label: 'Hong Kong', phone: '852' },
    {
        code: 'HM',
        label: 'Heard Island and McDonald Islands',
        phone: '672',
    },
    { code: 'HN', label: 'Honduras', phone: '504' },
    { code: 'HR', label: 'Croatia', phone: '385' },
    { code: 'HT', label: 'Haiti', phone: '509' },
    { code: 'HU', label: 'Hungary', phone: '36' },
    { code: 'ID', label: 'Indonesia', phone: '62' },
    { code: 'IE', label: 'Ireland', phone: '353' },
    { code: 'IL', label: 'Israel', phone: '972' },
    { code: 'IM', label: 'Isle of Man', phone: '44' },
    { code: 'IN', label: 'India', phone: '91' },
    {
        code: 'IO',
        label: 'British Indian Ocean Territory',
        phone: '246',
    },
    { code: 'IQ', label: 'Iraq', phone: '964' },
    {
        code: 'IR',
        label: 'Iran, Islamic Republic of',
        phone: '98',
    },
    { code: 'IS', label: 'Iceland', phone: '354' },
    { code: 'IT', label: 'Italy', phone: '39' },
    { code: 'JE', label: 'Jersey', phone: '44' },
    { code: 'JM', label: 'Jamaica', phone: '1-876' },
    { code: 'JO', label: 'Jordan', phone: '962' },
    {
        code: 'JP',
        label: 'Japan',
        phone: '81',
        suggested: true,
    },
    { code: 'KE', label: 'Kenya', phone: '254' },
    { code: 'KG', label: 'Kyrgyzstan', phone: '996' },
    { code: 'KH', label: 'Cambodia', phone: '855' },
    { code: 'KI', label: 'Kiribati', phone: '686' },
    { code: 'KM', label: 'Comoros', phone: '269' },
    {
        code: 'KN',
        label: 'Saint Kitts and Nevis',
        phone: '1-869',
    },
    {
        code: 'KP',
        label: "Korea, Democratic People's Republic of",
        phone: '850',
    },
    { code: 'KR', label: 'Korea, Republic of', phone: '82' },
    { code: 'KW', label: 'Kuwait', phone: '965' },
    { code: 'KY', label: 'Cayman Islands', phone: '1-345' },
    { code: 'KZ', label: 'Kazakhstan', phone: '7' },
    {
        code: 'LA',
        label: "Lao People's Democratic Republic",
        phone: '856',
    },
    { code: 'LB', label: 'Lebanon', phone: '961' },
    { code: 'LC', label: 'Saint Lucia', phone: '1-758' },
    { code: 'LI', label: 'Liechtenstein', phone: '423' },
    { code: 'LK', label: 'Sri Lanka', phone: '94' },
    { code: 'LR', label: 'Liberia', phone: '231' },
    { code: 'LS', label: 'Lesotho', phone: '266' },
    { code: 'LT', label: 'Lithuania', phone: '370' },
    { code: 'LU', label: 'Luxembourg', phone: '352' },
    { code: 'LV', label: 'Latvia', phone: '371' },
    { code: 'LY', label: 'Libya', phone: '218' },
    { code: 'MA', label: 'Morocco', phone: '212' },
    { code: 'MC', label: 'Monaco', phone: '377' },
    {
        code: 'MD',
        label: 'Moldova, Republic of',
        phone: '373',
    },
    { code: 'ME', label: 'Montenegro', phone: '382' },
    {
        code: 'MF',
        label: 'Saint Martin (French part)',
        phone: '590',
    },
    { code: 'MG', label: 'Madagascar', phone: '261' },
    { code: 'MH', label: 'Marshall Islands', phone: '692' },
    {
        code: 'MK',
        label: 'Macedonia, the Former Yugoslav Republic of',
        phone: '389',
    },
    { code: 'ML', label: 'Mali', phone: '223' },
    { code: 'MM', label: 'Myanmar', phone: '95' },
    { code: 'MN', label: 'Mongolia', phone: '976' },
    { code: 'MO', label: 'Macao', phone: '853' },
    {
        code: 'MP',
        label: 'Northern Mariana Islands',
        phone: '1-670',
    },
    { code: 'MQ', label: 'Martinique', phone: '596' },
    { code: 'MR', label: 'Mauritania', phone: '222' },
    { code: 'MS', label: 'Montserrat', phone: '1-664' },
    { code: 'MT', label: 'Malta', phone: '356' },
    { code: 'MU', label: 'Mauritius', phone: '230' },
    { code: 'MV', label: 'Maldives', phone: '960' },
    { code: 'MW', label: 'Malawi', phone: '265' },
    { code: 'MX', label: 'Mexico', phone: '52' },
    { code: 'MY', label: 'Malaysia', phone: '60' },
    { code: 'MZ', label: 'Mozambique', phone: '258' },
    { code: 'NA', label: 'Namibia', phone: '264' },
    { code: 'NC', label: 'New Caledonia', phone: '687' },
    { code: 'NE', label: 'Niger', phone: '227' },
    { code: 'NF', label: 'Norfolk Island', phone: '672' },
    { code: 'NG', label: 'Nigeria', phone: '234' },
    { code: 'NI', label: 'Nicaragua', phone: '505' },
    { code: 'NL', label: 'Netherlands', phone: '31' },
    { code: 'NO', label: 'Norway', phone: '47' },
    { code: 'NP', label: 'Nepal', phone: '977' },
    { code: 'NR', label: 'Nauru', phone: '674' },
    { code: 'NU', label: 'Niue', phone: '683' },
    { code: 'NZ', label: 'New Zealand', phone: '64' },
    { code: 'OM', label: 'Oman', phone: '968' },
    { code: 'PA', label: 'Panama', phone: '507' },
    { code: 'PE', label: 'Peru', phone: '51' },
    { code: 'PF', label: 'French Polynesia', phone: '689' },
    { code: 'PG', label: 'Papua New Guinea', phone: '675' },
    { code: 'PH', label: 'Philippines', phone: '63' },
    { code: 'PK', label: 'Pakistan', phone: '92' },
    { code: 'PL', label: 'Poland', phone: '48' },
    {
        code: 'PM',
        label: 'Saint Pierre and Miquelon',
        phone: '508',
    },
    { code: 'PN', label: 'Pitcairn', phone: '870' },
    { code: 'PR', label: 'Puerto Rico', phone: '1' },
    {
        code: 'PS',
        label: 'Palestine, State of',
        phone: '970',
    },
    { code: 'PT', label: 'Portugal', phone: '351' },
    { code: 'PW', label: 'Palau', phone: '680' },
    { code: 'PY', label: 'Paraguay', phone: '595' },
    { code: 'QA', label: 'Qatar', phone: '974' },
    { code: 'RE', label: 'Reunion', phone: '262' },
    { code: 'RO', label: 'Romania', phone: '40' },
    { code: 'RS', label: 'Serbia', phone: '381' },
    { code: 'RU', label: 'Russian Federation', phone: '7' },
    { code: 'RW', label: 'Rwanda', phone: '250' },
    { code: 'SA', label: 'Saudi Arabia', phone: '966' },
    { code: 'SB', label: 'Solomon Islands', phone: '677' },
    { code: 'SC', label: 'Seychelles', phone: '248' },
    { code: 'SD', label: 'Sudan', phone: '249' },
    { code: 'SE', label: 'Sweden', phone: '46' },
    { code: 'SG', label: 'Singapore', phone: '65' },
    { code: 'SH', label: 'Saint Helena', phone: '290' },
    { code: 'SI', label: 'Slovenia', phone: '386' },
    {
        code: 'SJ',
        label: 'Svalbard and Jan Mayen',
        phone: '47',
    },
    { code: 'SK', label: 'Slovakia', phone: '421' },
    { code: 'SL', label: 'Sierra Leone', phone: '232' },
    { code: 'SM', label: 'San Marino', phone: '378' },
    { code: 'SN', label: 'Senegal', phone: '221' },
    { code: 'SO', label: 'Somalia', phone: '252' },
    { code: 'SR', label: 'Suriname', phone: '597' },
    { code: 'SS', label: 'South Sudan', phone: '211' },
    {
        code: 'ST',
        label: 'Sao Tome and Principe',
        phone: '239',
    },
    { code: 'SV', label: 'El Salvador', phone: '503' },
    {
        code: 'SX',
        label: 'Sint Maarten (Dutch part)',
        phone: '1-721',
    },
    {
        code: 'SY',
        label: 'Syrian Arab Republic',
        phone: '963',
    },
    { code: 'SZ', label: 'Swaziland', phone: '268' },
    {
        code: 'TC',
        label: 'Turks and Caicos Islands',
        phone: '1-649',
    },
    { code: 'TD', label: 'Chad', phone: '235' },
    {
        code: 'TF',
        label: 'French Southern Territories',
        phone: '262',
    },
    { code: 'TG', label: 'Togo', phone: '228' },
    { code: 'TH', label: 'Thailand', phone: '66' },
    { code: 'TJ', label: 'Tajikistan', phone: '992' },
    { code: 'TK', label: 'Tokelau', phone: '690' },
    { code: 'TL', label: 'Timor-Leste', phone: '670' },
    { code: 'TM', label: 'Turkmenistan', phone: '993' },
    { code: 'TN', label: 'Tunisia', phone: '216' },
    { code: 'TO', label: 'Tonga', phone: '676' },
    { code: 'TR', label: 'Turkey', phone: '90' },
    {
        code: 'TT',
        label: 'Trinidad and Tobago',
        phone: '1-868',
    },
    { code: 'TV', label: 'Tuvalu', phone: '688' },
    {
        code: 'TW',
        label: 'Taiwan',
        phone: '886',
    },
    {
        code: 'TZ',
        label: 'United Republic of Tanzania',
        phone: '255',
    },
    { code: 'UA', label: 'Ukraine', phone: '380' },
    { code: 'UG', label: 'Uganda', phone: '256' },
    {
        code: 'US',
        label: 'United States',
        phone: '1',
        suggested: true,
    },
    { code: 'UY', label: 'Uruguay', phone: '598' },
    { code: 'UZ', label: 'Uzbekistan', phone: '998' },
    {
        code: 'VA',
        label: 'Holy See (Vatican City State)',
        phone: '379',
    },
    {
        code: 'VC',
        label: 'Saint Vincent and the Grenadines',
        phone: '1-784',
    },
    { code: 'VE', label: 'Venezuela', phone: '58' },
    {
        code: 'VG',
        label: 'British Virgin Islands',
        phone: '1-284',
    },
    {
        code: 'VI',
        label: 'US Virgin Islands',
        phone: '1-340',
    },
    { code: 'VN', label: 'Vietnam', phone: '84' },
    { code: 'VU', label: 'Vanuatu', phone: '678' },
    { code: 'WF', label: 'Wallis and Futuna', phone: '681' },
    { code: 'WS', label: 'Samoa', phone: '685' },
    { code: 'XK', label: 'Kosovo', phone: '383' },
    { code: 'YE', label: 'Yemen', phone: '967' },
    { code: 'YT', label: 'Mayotte', phone: '262' },
    { code: 'ZA', label: 'South Africa', phone: '27' },
    { code: 'ZM', label: 'Zambia', phone: '260' },
    { code: 'ZW', label: 'Zimbabwe', phone: '263' },
];

export const RepatriationFormSimple = () => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { repatriations } = useSelector(state => state.repatriation);
    const { llegaRepatriations } = useSelector(state => state.llegaRepatriation);
    const [queryParameters] = useSearchParams();
    const [birthday, setBirthday] = React.useState('');
    const [company, setCompany] = useState(1);
    const [refered, setRefered] = useState(1);
    const [seller, setSeller] = useState([]);
    const [showPayment, setShowPayment] = useState(false);
    const [checked, setChecked] = useState(false);
    const { companies } = useSelector(state => state.companie);
    const { repCountries } = useSelector(state => state.repCountry);
    const { sellers } = useSelector(state => state.seller);
    const [sellersByCompany, setSellersByCompany] = useState([]);
    const [plan, setPlan] = useState(1);
    const [merchant, setMerchant] = useState('');
    const [token, setToken] = useState('');
    const [disableCompanie, setDisableCompanie] = useState(false);
    const { errorMessage } = useSelector(state => state.auth);
    const [showLlegaMarket, setShowLlegaMarket] = useState(true);
    const [showSellers, setShowSellers] = useState(false);
    const [edit, setEdit] = useState(false);
    const [repFormId, setFormId] = useState('');
    const [update, setUpdate] = useState(false);
    const [deleteRepForm, setDeleteRepForm] = useState(false);
    const [responseData, setResponseData] = useState(null);
    const [loading, setLoading] = useState(false);
    const [isOpen, setIsOpen] = useState(false);
    const [voucher, setVoucher] = useState({});
    const [selectedOriginCountry, setSelectedOriginCountry] = useState(countries[230]);
    const [selectedDestinationCountry, setSelectedDestinationCountry] = useState(countries[89]);
    const [correoComercio, setCorreoComercio] = useState('');
    const [nameComercio, setNameComercio] = useState('');
    const [moneda, setMoneda] = useState('D');

    const openModal = () => {
        setIsOpen(true);
    }

    useEffect(() => {
        dispatch(startLoadingAllCompanies());
        dispatch(startLoadingAllRepCountries());
        dispatch(startLoadingAllSellers());
        dispatch(startLoadingAllRepatriations());
        dispatch(startLoadingAllLlegaRepatriations());
        setValue('countryOrigin', countries[230])
        setValue('countryDestination', countries[89])
    }, [])

    const generatePDFBlob = async (DocumentComponent) => {
        const blob = await ReactPDF.renderToStream(<DocumentComponent />);
        return new Blob([blob], { type: 'application/pdf' });
    };

    const blobToBase64 = (blob) => {
        return new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.onloadend = () => resolve(reader.result);
            reader.onerror = reject;
            reader.readAsDataURL(blob);
        });
    };

    useEffect(() => {
        setPlan(queryParameters.get("id"))
        let tmpMerchant = queryParameters.get("merchantID");
        if (tmpMerchant) {
            setMerchant(tmpMerchant);
        } else {
            setMerchant('827');
        }
        setMoneda(queryParameters.get('currency'));
    }, [queryParameters])

    useEffect(() => {

    }, [selectedOriginCountry])

    useEffect(() => {

    }, [selectedDestinationCountry])

    const handleChangeOriginCountry = (event, newValue) => {
        setSelectedOriginCountry(newValue);
    };

    const handleChangeDestinationCountry = (event, newValue) => {
        setSelectedDestinationCountry(newValue);
    };

    const { register, handleSubmit, setValue, control, formState: { errors } } = useForm({
        defaultValues: {
            displayName: "",
            phone: "",
            areaCode: "",
            email: "",
            identification: "",
            birthday: "",
            addressOrigin: "",
            cityOrigin: "",
            stateOrigin: "",
            countryOrigin: "",
            contactOrigin: "",
            areaCodeOrigin: "",
            phoneContactOrigin: "",
            address: "",
            cityholdDestination: "",
            departmentDestination: "",
            contactDestination: "",
            areaCodeDestination: "",
            phoneContactDestination: "",
            seller: "",
            userLlega: ""
        }
    })

    useEffect(() => {
        if (merchant && companies.length > 0 && repCountries.length > 0 && sellers.length > 0) {
            let companyTmp = companies.filter(x => x.merchantId === merchant);
            if (companyTmp.length < 1) {
                showErrorMerchantId();
                navigate('/contact');
                return;
            }
            let nameComercioTmp = companyTmp[0]?.name;
            let correoComercio = companyTmp[0]?.email;
            let tokenTmp = companyTmp[0].token;
            if (correoComercio?.length > 1) {
                setCorreoComercio(correoComercio);
            }

            if (nameComercioTmp?.length > 1) {
                setNameComercio(nameComercioTmp);
            }
            if (tokenTmp?.length > 1) {
                setToken(tokenTmp);
            }

            let repCountrieTmp = repCountries.filter(x => x.companyId === companyTmp[0]?.id);
            let tmpSellers = sellers.filter(x => x.companyId === repCountrieTmp[0]?.companyId);

            if (repCountrieTmp?.length >= 1) {
                setDisableCompanie(true);
                setCompany(repCountrieTmp[0].id);
            }
            if (tmpSellers.length > 0) {
                setSellersByCompany(tmpSellers);
                setShowSellers(true);
            } else {
                setSellersByCompany([]);
                setShowSellers(false);
            }
        } else {
            let companyTmp = companies.filter(x => x.name === 'Llega Market');
            let correoComercio = companyTmp[0]?.email;

            let repCountrieTmp = repCountries.filter(x => x.companyId === companyTmp[0]?.id);
            let tmpSellers = sellers.filter(x => x.companyId === repCountrieTmp[0]?.companyId);
            if (repCountrieTmp.length >= 1) {
                setDisableCompanie(true);
                setCompany(repCountrieTmp[0].id);
            }

            if (tmpSellers.length > 0) {
                setSellersByCompany(tmpSellers);
                setShowSellers(true);
            } else {
                setSellersByCompany([]);
                setShowSellers(false);
            }
        }
    }, [companies, merchant, repCountries, sellers])

    useEffect(() => {
        if (window.scrollY > 300) {
            window.scrollTo({ top: 0, behavior: 'smooth' });
        }
    }, [])

    const checkContractStatus = async (contracts, origin) => {
        let api_url_consulta = 'llegamarket/consultarepatriacion';
        let merchantId = merchant;
        let tokenTmp = token;
        if (origin === 'LlegaExpress') {
            merchantId = '827';
            tokenTmp = 'fKaDylJeBTi61v88';
        }
        const promises = contracts.map(contract =>
            apiInstance.post(api_url_consulta, { MerchantID: merchantId, Token: tokenTmp, PolicyNumber: contract.policyNo })
        );

        const responses = await Promise.all(promises);
        return responses.map(response => response.data.AgreementStatus);
    };


    // const onSubmit = async (data) => {
    //     console.log('data', data);

    //     sendEmailWithPDF(data);

    // }

    const agreementNumber = Math.floor(Math.random() * 1000000);
    const address = 'Miami FL, 34938 29TH';
    const city = 'Miami';
    const state = 'Florida';
    const postalCode = '01016';
    const cardInfo1 = 'AGREEMENT # 12345';
    const name = 'JUAN MARTINEZ';
    const LetterDocument = ({ data }) => (
        <Document>
            <Page style={styles.page}>
                <View>
                    <Image src='../assets/llega2.png' style={styles.logo} />
                </View>
                <View style={styles.header}>
                    <Text>Fecha: {new Date().toLocaleDateString()}</Text>
                    <Text>Afiliación #: {data?.policyNo}</Text>
                </View>
                <Text style={styles.textTitle}>SR. / SRA.</Text>
                <Text style={styles.textTitle}>{data?.displayName}</Text>
                <Text style={styles.textTitle}>{data?.addressOrigin}</Text>
                <Text style={styles.textTitle}>{data?.cityOrigin}, {data?.stateOrigin}, {data?.areaCode}</Text>
                <Text style={styles.text}></Text>
                <Text style={styles.text}>Estimado(a) Afiliado(a):</Text>
                <Text style={styles.text}>
                    Queremos darle una cordial bienvenida al <Text style={styles.boldText}>Plan de Previsión Funerario Internacional Repatriación Llega</Text>, donde usted y su familia contarán con el respaldo, ayuda y apoyo del grupo empresarial de mayor reconocimiento y respaldo a nivel nacional e internacional en la industria funeraria.
                </Text>
                <Text style={styles.text}>
                    Repatriación Llega le proporcionará la tranquilidad necesaria en los momentos más difíciles de la vida. Contamos con la única red internacional de agencias funerarias más extensa del mercado y la ponemos a su disposición para poder garantizarle un servicio funerario digno con los más altos estándares de excelencia y calidad.
                </Text>
                <Text style={styles.text}>
                    Adjunto a esta carta le incluimos su tarjeta de afiliación, en ella encontrará los números de teléfono del Departamento de Asistencia Inmediata para su mayor conveniencia.
                </Text>
                <Text style={styles.text}>
                    En caso de cualquier duda o consulta puede llamarnos al departamento de atención al cliente al (786) 484-0757 y un asesor de familia les dará respuesta a todas sus inquietudes. Para reportar un fallecimiento comuníquese con nuestro Centro Internacional de Asistencia Inmediata al 1(877) 398-4413 disponible las 24 horas del día los 365 días del año.
                </Text>
                <Text style={styles.textTitle}>Agradecemos su confianza al elegirnos</Text>
                <Text style={styles.text}></Text>
                <Text style={styles.textTitle}>Cordial saludo.</Text>
                <Text style={styles.textTitle}>REPATRACIONES LLEGA</Text>
                <Text style={styles.textTitle}>Customer Service</Text>

                {/* <View style={styles.footer}>
                    <View style={styles.card}>
                        <Text>{cardInfo1}</Text>
                    </View>
                    <View style={styles.card}>
                        <Text>{cardInfo2}</Text>
                    </View>
                </View> */}

                <View style={styles.divider} />

                <View style={styles.footer}>
                    <View style={styles.cardImage}>
                        <Image src='../assets/CardFront2.png' style={styles.cardImage} />
                        <Text style={styles.cardText}>{data?.displayName}</Text>
                        <Text style={styles.agreementText}>{data?.policyNo}</Text>
                    </View>
                    <Image src='../assets/cardBack1.png' style={styles.cardImage} />
                </View>

                {/* <View style={styles.footer}>
                    <Image src='../assets/cardFront.png' style={styles.cardImage} />
                    <Image src='../assets/cardBack.png' style={styles.cardImage} />
                </View> */}
            </Page>
        </Document>
    );

    const onSubmit = async (data) => {
        if (checked) {
            let tmpPlan = plans.filter(x => x.value == plan);
            let amount = tmpPlan[0].amount.toString();

            if (moneda === 'Q') {
                amount = tmpPlan[0].amountQ.toString();
            }
            else {
                amount = tmpPlan[0].amount.toString();
            }

            let companyTmp = repCountries.filter(x => x.id === company[0]);

            let sellerTmp = sellers.filter(x => x.id === seller[0]);

            let companyName = '';
            let emailComercio = '';
            let sellerName = '';
            if (company === 1) {
                companyName = "LLega Market";
            }

            if (companyTmp.length > 0) {
                companyName = companyTmp[0]?.companyName;
            }

            if (sellerTmp.length > 0) {
                sellerName = sellerTmp[0]?.sellerName;
            }


            const {
                displayName,
                identification,
                email,
                phone,
                areaCode,
                address,
                addressOrigin,
                cityOrigin,
                stateOrigin,
                contactOrigin,
                areaCodeOrigin,
                phoneContactOrigin,
                countryDestination,
                cityholdDestination,
                departmentDestination,
                contactDestination,
                areaCodeDestination,
                phoneContactDestination,
                userLlega
            } = data;

            let planDescription = "";

            if (plan == 1) {
                planDescription = "Mensual";
            }

            if (plan == 2) {
                planDescription = "Anual";
            }

            if (plan == 3) {
                planDescription = "Anual Plus";
            }

            let PolicyNo = uuidv4();
            let AuthNo = '00000';

            const newData = {
                displayName,
                identification,
                email,
                phone,
                areaCode,
                address,
                countryDestination: selectedDestinationCountry.label,
                cityholdDestination,
                departmentDestination,
                contactDestination,
                areaCodeDestination,
                phoneContactDestination,
                addressOrigin,
                cityOrigin,
                stateOrigin,
                countryOrigin: selectedOriginCountry.label,
                contactOrigin,
                areaCodeOrigin,
                phoneContactOrigin,
                birthday: moment(birthday.$d).format("DD/MM/YYYY"),
                companyId: company,
                companyName: nameComercio,
                refered: refered,
                seller: seller,
                sellerName: sellerName,
                userLlega: 'PostPago',
                policyNo: PolicyNo,
                authNo: AuthNo,
                plan: planDescription,
                amount: amount,
                acceptTerms: checked
            }

            dispatch(setActiveRepatriation({ newData }));
            if (newData) {
                onSaveNewRepCountry(newData);
            }




        } else {
            showErrorConditions();
            setLoading(false);
        }

    }

    const onSubmit1 = async (data) => {
        if (checked) {
            // try {
            setLoading(true);
            let contractStatuses = [];
            let merchantID = merchant;
            let tmpPlan = plans.filter(x => x.value == plan);
            let amount = 0;
            if (moneda === 'Q') {
                amount = tmpPlan[0].amountQ.toString();
            }
            else {
                amount = tmpPlan[0].amount.toString();
            }

            let repatriationTmp = repatriations.filter(x => x.userLlega === data.userLlega);
            let llegaRepatriationTmp = llegaRepatriations.filter(x => x.telefono === data.userLlega);

            if (repatriationTmp.length > 0) {
                const repatriationStatuses = await checkContractStatus(repatriationTmp, 'LlegaMarket');
                contractStatuses = contractStatuses.concat(repatriationStatuses);
            }

            if (llegaRepatriationTmp.length > 0) {
                const llegaRepatriationStatuses = await checkContractStatus(llegaRepatriationTmp, 'LlegaExpress');
                contractStatuses = contractStatuses.concat(llegaRepatriationStatuses);
            }

            if (contractStatuses.includes('Active')) {
                Swal.fire({
                    title: `"El usuario llega ya tiene un contrato activo, ¿Deseas ser redirigido a la sección de pago?"`,
                    showDenyButton: true,
                    confirmButtonText: "Sí",
                    denyButtonText: `No`
                }).then((result) => {
                    if (result.isConfirmed) {
                        navigate('/payment');
                    } else if (result.isDenied) {
                        return;
                    }
                });
                return;
            }

            const hasCancelledContract = contractStatuses.includes('Cancelled');

            if (hasCancelledContract || contractStatuses.length === 0) {

                let api_url = 'llegamarket/crearepatriacion';
                let body = {
                    MerchantID: merchantID,
                    Token: token,
                    UserID: data.userLlega,
                    Password: data.passLlega,
                    Total: amount
                }

                let response = await apiInstance.post(api_url, body);
                let result = response.data;


                let PolicyNo = '';
                let AuthNo = '';

                if (result) {
                    PolicyNo = result.PolicyNo;
                    AuthNo = result.AuthNo;
                }

                if (result.ErrorCode == '0') {
                    let companyTmp = repCountries.filter(x => x.id === company[0]);

                    let sellerTmp = sellers.filter(x => x.id === seller[0]);

                    let companyName = '';
                    let emailComercio = '';
                    let sellerName = '';
                    if (company === 1) {
                        companyName = "LLega Market";
                    }

                    if (companyTmp.length > 0) {
                        companyName = companyTmp[0]?.companyName;
                    }

                    if (sellerTmp.length > 0) {
                        sellerName = sellerTmp[0]?.sellerName;
                    }

                    const {
                        displayName,
                        identification,
                        email,
                        phone,
                        areaCode,
                        address,
                        addressOrigin,
                        cityOrigin,
                        stateOrigin,
                        contactOrigin,
                        areaCodeOrigin,
                        phoneContactOrigin,
                        countryDestination,
                        cityholdDestination,
                        departmentDestination,
                        contactDestination,
                        areaCodeDestination,
                        phoneContactDestination,
                        userLlega
                    } = data;

                    let planDescription = "";

                    if (plan == 1) {
                        planDescription = "Mensual";
                    }

                    if (plan == 2) {
                        planDescription = "Anual";
                    }

                    if (plan == 3) {
                        planDescription = "Anual Plus";
                    }

                    const newData = {
                        displayName,
                        identification,
                        email,
                        phone,
                        areaCode,
                        address,
                        countryDestination: selectedDestinationCountry.label,
                        cityholdDestination,
                        departmentDestination,
                        contactDestination,
                        areaCodeDestination,
                        phoneContactDestination,
                        addressOrigin,
                        cityOrigin,
                        stateOrigin,
                        countryOrigin: selectedOriginCountry.label,
                        contactOrigin,
                        areaCodeOrigin,
                        phoneContactOrigin,
                        birthday: moment(birthday.$d).format("DD/MM/YYYY"),
                        companyId: company,
                        companyName: nameComercio,
                        refered: refered,
                        seller: seller,
                        sellerName: sellerName,
                        userLlega: userLlega,
                        policyNo: result.PolicyNo,
                        authNo: result.AuthNo,
                        plan: planDescription,
                        amount: amount,
                        acceptTerms: checked
                    }

                    dispatch(setActiveRepatriation({ newData }));
                    if (newData) {
                        onSaveNewRepCountry(newData);
                    }

                    // Aca guardar el pago
                    const newPayment = {
                        ReferenceNo: result.PolicyNo, //'000',
                        ProductID: result.PolicyNo,
                        Total: amount,
                        Last4DigitCardNumber: '0000',
                        ResponseCode: 'APR',
                        ResponseMsg: 'APPROVED',
                        AuthNo: result.AuthNo,
                        userLlega: userLlega
                    }

                    setVoucher(newPayment);

                    dispatch(setActivePayment({ newPayment }));
                    if (newPayment) {
                        onSaveNewPayment(newPayment);
                    }
                } else {
                    if (result.ErrorCode == 16) {
                        Swal.fire({
                            title: "El Contrato ya existe para tu usuario Llega, deseas ser redirigido a la sección de pago de contrato?",
                            showDenyButton: true,
                            confirmButtonText: "Sí",
                            denyButtonText: `No`
                        }).then((result) => {
                            /* Read more about isConfirmed, isDenied below */
                            if (result.isConfirmed) {
                                navigate('/payment');
                            } else if (result.isDenied) {
                                // navigate('repatriation')
                                setLoading(false);
                            }
                        });
                    } else if (result.ErrorCode == 14) {
                        setLoading(false);
                        showErrorInvalidUser();
                    } else if (result.ErrorCode == 51) {
                        setLoading(false);
                        showErrorFounds();
                    } else if (result.ErrorCode == 27) {
                        setLoading(false);
                        showErrorToken();
                    } else if (result.ErrorCode == 55) {
                        setLoading(false);
                        showInvalidPin();
                    }
                    else {
                        setLoading(false);
                        showErrorService();
                    }
                    setLoading(false);
                }

                setLoading(false);
            }
            // } catch (error) {
            //     console.log('error', error);
            //     setLoading(false);
            // }
        } else {
            showErrorConditions();
            setLoading(false);
        }
    }

    const onSaveNewPayment = (data) => {
        dispatch(startNewPayment(data));
    }

    function sendEmail(data) {
        const {
            displayName,
            email,
            address,
            addressOrigin,
            policyNo
        } = data;

        const newData = {
            displayName,
            email,
            address,
            addressOrigin,
            policyNo,
            subject: "Terminos y condiciones"
        }
        emailjs.send('service_k1iecnp', 'template_30hejqt', newData, 'srjso40pBJ9jqwxHz')
            .then(function (response) {
                limpiar();
            }, function (error) {
                showError();
            });
    }

    const sendEmailWithPDF = async (data) => {
        const pdfBlob = await pdf(
            <LetterDocument
                data={data}
            />).toBlob();
        // const pdfBlob = await generatePDFBlob(MyDocument);
        const pdfBase64 = await blobToBase64(pdfBlob);

        const blob = await pdf(<LetterDocument />).toBlob();
        const formData = new FormData();


        const {
            displayName,
            email,
            address,
            addressOrigin,
            policyNo
        } = data;

        const subject = "Bienvenido al Servicio de Repatriación Llega";

        const emailParams = {
            displayName,
            email,
            address,
            addressOrigin,
            policyNo,
            file: pdfBase64.split(',')[1],
            subject
        }

        const newDataComerce = {
            displayName,
            email: correoComercio,
            address,
            addressOrigin,
            policyNo,
            subject,
            file: pdfBase64.split(',')[1],
        }

        emailjs.send(
            'service_k1iecnp',
            'template_smrzb32',
            emailParams,
            'srjso40pBJ9jqwxHz'
        )
            .then((response) => {
                console.log('Email sent successfully!', response.status, response.text);
            })
            .catch((err) => {
                console.error('Failed to send email:', err);
            });

        emailjs.send(
            'service_k1iecnp',
            'template_smrzb32',
            newDataComerce,
            'srjso40pBJ9jqwxHz'
        )
            .then((response) => {
                console.log('Email sent successfully!', response.status, response.text);
            })
            .catch((err) => {
                console.error('Failed to send email:', err);
            });
    };

    // function sendWelcomeEmail(data) {
    //     const {
    //         displayName,
    //         email,
    //         address,
    //         addressOrigin,
    //         policyNo
    //     } = data;
    //     let correos = email;
    //     // if (correoComercio.length > 1) {
    //     //     correos = email + ';' + correoComercio;
    //     // }

    //     const newData = {
    //         displayName,
    //         email,
    //         address,
    //         addressOrigin,
    //         policyNo,
    //         subject: "Bienvenido al Servicio de Repatriación Llega"
    //     }

    //     const newDataComerce = {
    //         displayName,
    //         email: correoComercio,
    //         address,
    //         addressOrigin,
    //         policyNo,
    //         subject: "Bienvenido al Servicio de Repatriación Llega"
    //     }

    //     emailjs.send('service_k1iecnp', 'template_smrzb32', newData, 'srjso40pBJ9jqwxHz')
    //         .then(function (response) {
    //             // limpiar();
    //         }, function (error) {
    //             // showError();
    //             console.log('error', error);
    //         });

    //     emailjs.send('service_k1iecnp', 'template_smrzb32', newDataComerce, 'srjso40pBJ9jqwxHz')
    //         .then(function (response) {
    //             limpiar();
    //         }, function (error) {
    //             // showError();
    //             console.log('error', error);
    //         });

    // }

    const onSaveNewRepCountry = (data) => {
        dispatch(startNewRepatriation(data));
        if (!errorMessage) {
            // showSuccess();
            Swal.fire('Info', 'Se ha creado el contrato No. ' + data.policyNo + ', recibirás un correo con la información de tú contrato.', 'success');
            sendEmail(data);
            sendEmailWithPDF(data);
            // sendWelcomeEmail(data);
            limpiar();
            setShowPayment(true);
        } else {
            showError();
        }
    }

    const showSuccess = () => {
        Swal.fire('Info', 'Información enviada correctamente', 'success');
    };

    const showErrorMerchantId = () => {
        Swal.fire('Error', 'No se encontró este comercio, solicita vender repatriaciones al correo procesos@llega.com', 'error');
    };

    const showErrorConditions = () => {
        Swal.fire('Error', 'Debe aceptar los términos y condiciones para continuar', 'error');
    };

    const showError = () => {
        Swal.fire('Error en el envío', 'Error', 'error');
    };

    const showErrorService = () => {
        Swal.fire('Error', 'Error al crear la afiliación, intenta nuevamente.', 'error');
    };

    const showErrorResponse = (msgError) => {
        Swal.fire('Error', msgError, 'error');
    };

    const showErrorInvalidUser = () => {
        Swal.fire('Error', 'El usuario Llega ingresado no existe.', 'error');
    };

    const showInvalidPin = () => {
        Swal.fire('Error', 'El pin ingresado es incorrecto.', 'error');
    };

    const showErrorFounds = () => {
        Swal.fire('Error', 'Fondos insuficientes para realizar la transacción.', 'error');
    }

    const showErrorToken = () => {
        Swal.fire('Error', 'El token del comercio no es válido para la operación.', 'error');
    }

    const showErrorPolicy = () => {
        Swal.fire('Error', 'El contrato para este usuario Llega ya existe.', 'error');
    };

    const limpiar = () => {
        setValue('displayName', "");
        setValue('phone', "");
        setValue('areaCode', "");
        setValue('email', "");
        setValue('identification', "");
        setValue('birthday', "");
        setValue('addressOrigin', "");
        setValue('cityOrigin', "");
        setValue('stateOrigin', "");
        setValue('countryOrigin', "");
        setValue('contactOrigin', "");
        setValue('areaCodeOrigin', "");
        setValue('phoneContactOrigin', "");
        setValue('address', "");
        setValue('cityholdDestination', "");
        setValue('departmentDestination', "");
        setValue('contactDestination', "");
        setValue('areaCodeDestination', "");
        setValue('phoneContactDestination', "");
        setValue('seller', "");
        setValue('userLlega', "");
    }

    const handleChangeCondition = () => {
        setChecked(!checked);
    }

    const handleChangeCompany = (event) => {


        const {
            target: { value },
        } = event;

        let tmpSellers = [];
        let tmpCompanie = repCountries.filter(x => x.id === value);

        if (value === 1) {
            setShowSellers(false);
        } else {
            if (tmpCompanie?.length > 0) {
                tmpSellers = sellers.filter(x => x.companyId === tmpCompanie[0].companyId);
            }

            if (tmpSellers?.length > 0) {
                setSellersByCompany(tmpSellers);
                setShowSellers(true);
            } else {
                setSellersByCompany([]);
                setShowSellers(false);
            }

        }
        setCompany(
            typeof value === 'string' ? value.split(',') : value,
        );
    };

    const handleChangePlan = (event) => {
        const {
            target: { value },
        } = event;
        setPlan(value);
    };


    const handleChangeRefered = (event) => {
        const {
            target: { value },
        } = event;
        // if (value == 1) {
        //     setShowLlegaMarket(true);
        // } else {
        //     setShowLlegaMarket(false);
        // }
        setRefered(
            typeof value === 'string' ? value.split(',') : value,
        );
    };

    useEffect(() => {

    }, [showLlegaMarket])

    useEffect(() => {

    }, [showSellers])

    const handleChangeSeller = (event) => {
        const {
            target: { value },
        } = event;
        setSeller(
            typeof value === 'string' ? value.split(',') : value,
        );
    };

    const handleKeyDown = (event) => {
        // Permitir solo números (0-9), retroceso y borrar
        if (
            !(
                event.key === 'Backspace' ||
                event.key === 'Delete' ||
                event.key === 'ArrowLeft' ||
                event.key === 'ArrowRight' ||
                event.key === 'ArrowUp' ||
                event.key === 'ArrowDown' ||
                (event.key >= '0' && event.key <= '9')
            )
        ) {
            event.preventDefault();
        }
    };

    return (
        <>

            {/* <div>
                <PDFDownloadLink document={<LetterDocument />} fileName="letter.pdf">
                    {({ blob, url, loading, error }) => (loading ? 'Generando documento...' : 'Descargar PDF')}
                </PDFDownloadLink>
                <button onClick={sendWelcomeEmail1}>Enviar PDF por Email</button>
            </div> */}
            {!showPayment &&
                <>

                    <div className='w-full min-h-[500px] pt-10 bg-white pb-12'>
                        <div className='flex justify-center place-content-center items-center'>
                            <div className='lg:w-6/12 bg-[#303030] rounded-3xl ml-4 mr-4'>
                                <div className='w-full flex flex-row pt-8 pl-4 pr-4'>
                                    <p className="w-full text-center text-white text-2xl lg:text-3xl font-extrabold leading-10">{t('repatriation.formTitle')}</p>
                                </div>

                                {/* <div className='w-full pt-8 lg:pt-16 pl-4 lg:pl-8 pr-4 lg:pr-8'>
                                    <div className='w-full'>
                                        <p className="text-white text-left text-xl font-semibold leading-10">{t('repatriation.name')}*</p>
                                    </div>
                                    <div className='w-full'>
                                        <input name='name' className="w-full h-12 bg-white rounded-full px-4" {...register('displayName', { required: true })}></input>
                                    </div>
                                </div> */}

                                <div className='w-full lg:flex lg:flex-row pt-4 pl-4 lg:pl-8 pr-4 lg:pr-8'>

                                    <div className='w-full lg:w-1/2 lg:pr-2'>
                                        <div className='w-full'>
                                            <p className="text-white text-left text-xl font-semibold leading-10">{t('repatriation.name')}*</p>
                                        </div>
                                        <div className='w-full pt-2'>
                                            <input name='name' className="w-full h-12 bg-white rounded-full px-4" {...register('displayName', { required: true })}></input>
                                        </div>
                                    </div>

                                    <div className='w-full lg:w-1/2 lg:pl-2 pt-2'>
                                        <div className='w-full'>
                                            <p className="text-white text-left text-xl font-semibold leading-10">{t('repatriation.email')}*</p>
                                        </div>
                                        <div className='w-full '>
                                            <input name='email' className="w-full h-12 bg-white rounded-full px-4" {...register('email', { required: true })}></input>
                                        </div>
                                    </div>
                                </div>

                                <div className='w-full lg:flex lg:flex-row pt-4 pl-4 lg:pl-8 pr-4 lg:pr-8'>

                                    <div className='w-full lg:w-1/2 lg:pr-2 '>
                                        <div className='w-full'>
                                            <p className="text-white text-left text-xl font-semibold leading-10">{t('repatriation.identification')}*</p>
                                        </div>
                                        <div className='w-full pt-2'>
                                            <input name='identification' className="w-full h-14 bg-white rounded-full px-4" {...register('identification', { required: true })}></input>
                                        </div>
                                    </div>

                                    <div className='w-full lg:w-1/2 lg:pl-2'>
                                        <div className='w-full'>
                                            <p className="text-white text-left text-xl font-semibold leading-10">{t('repatriation.birthday')}*</p>
                                        </div>
                                        <div className='w-full '>
                                            <LocalizationProvider dateAdapter={AdapterDayjs} className='rounded-full ' >
                                                <DemoContainer components={['DatePicker']} sx={{ border: 'none' }}>
                                                    <DatePicker value={birthday} onChange={(newValue) => setBirthday(newValue)} className=" w-full bg-white rounded-full"
                                                        sx={{
                                                            '& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline': { border: 'none' }
                                                            // '& .MuiOutlinedInput-root:hoover .MuiOutlinedInput-notchedOutline': { border: 'none' },
                                                            // '& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': { border: 'none' }
                                                        }}

                                                    />
                                                </DemoContainer>
                                            </LocalizationProvider>
                                        </div>
                                    </div>
                                </div>

                                <div className='w-full lg:flex lg:flex-row pt-4 pl-4 lg:pl-8 pr-4 lg:pr-8'>

                                    <div className='w-full lg:w-2/6 lg:pr-2'>
                                        <div className='w-full'>
                                            <p className="text-white text-left text-xl font-semibold leading-10">{t('repatriation.areaCode')}*</p>
                                        </div>
                                        <div className='w-full'>
                                            <input name='areaCode' maxLength={5} className="w-full h-12 bg-white rounded-full px-4" {...register('areaCode', { required: true })} onKeyDown={handleKeyDown}></input>
                                        </div>
                                    </div>

                                    <div className='w-full lg:w-4/6 lg:pl-2'>
                                        <div className='w-full'>
                                            <p className="text-white text-left text-xl font-semibold leading-10">{t('repatriation.phone')}*</p>
                                        </div>
                                        <div className='w-full '>
                                            <input name='phone' maxLength={12} className="w-full h-12 bg-white rounded-full px-4" {...register('phone', { required: true })} onKeyDown={handleKeyDown}></input>
                                        </div>
                                    </div>

                                </div>


                                {/* <div className='w-full lg:flex lg:flex-row pt-4 pl-4 lg:pl-8 pr-4 lg:pr-8'>

                                    <div className='w-full lg:w-1/2 lg:pr-2'>
                                        <div className='w-full'>
                                            <p className="text-white text-left text-xl font-semibold leading-10">{t('repatriation.email')}*</p>
                                        </div>
                                        <div className='w-full'>

                                        </div>
                                    </div>

                                    <div className='w-full lg:w-1/2 lg:pl-2'>
                                        <div className='w-full'>
                                            <p className="text-white text-left text-xl font-semibold leading-10">{t('repatriation.phone')}*</p>
                                        </div>
                                        <div className='w-full '>
                                            <input name='phone' className="w-full h-12 bg-white rounded-full px-4" {...register('phone', { required: true })}></input>
                                        </div>
                                    </div>

                                </div> */}


                                <div className='w-full lg:flex lg:flex-row pt-4 pl-4 lg:pl-8 pr-4 lg:pr-8'>

                                    <div className='w-full lg:w-1/2 lg:pr-2'>
                                        <div className='w-full'>
                                            <p className="text-white text-left text-xl font-semibold leading-10">{t('repatriation.commerce')}*</p>
                                        </div>
                                        <div className='w-full'>
                                            <FormControl className='w-full'>
                                                <Select
                                                    labelId="comercio-label"
                                                    id="comercio"
                                                    label=''
                                                    placeholder={t('repatriation.commerce')}
                                                    defaultValue=""
                                                    inputProps={{ 'aria-label': 'Without label' }}
                                                    onChange={handleChangeCompany}
                                                    value={company}
                                                    className="w-full h-12 bg-white"
                                                    sx={{ borderRadius: 10 }}
                                                // disabled={disableCompanie}
                                                >
                                                    {repCountries.filter(x => x.status !== 'Inactivo').map((item) => (
                                                        <MenuItem key={item.id} value={item.id}>
                                                            {item.companyName}
                                                        </MenuItem>
                                                    ))}
                                                </Select>
                                            </FormControl>
                                        </div>
                                    </div>

                                    <div className='w-full lg:w-1/2 lg:pl-2'>
                                        <div className='w-full'>
                                            <p className="text-white text-left text-xl font-semibold leading-10">{t('repatriation.vendor')}*</p>
                                        </div>
                                        <div className='w-full '>
                                            <FormControl className='w-full rounded-full'>
                                                <Select
                                                    labelId="vendedor-label"
                                                    id="vendedor"
                                                    label=''
                                                    placeholder={t('repatriation.vendor')}
                                                    defaultValue={t('repatriation.selectVendor')}
                                                    inputProps={{ 'aria-label': 'Without label' }}
                                                    onChange={handleChangeSeller}
                                                    value={seller}
                                                    className="w-full h-12 bg-white"
                                                    sx={{ borderRadius: 10 }}
                                                    disabled={!showSellers}
                                                >
                                                    {showSellers && sellersByCompany.filter(x => x.status !== 'Inactivo').map((item) => (
                                                        <MenuItem key={item.id} value={item.id}>
                                                            {item.sellerName}
                                                        </MenuItem>
                                                    ))}
                                                </Select>
                                            </FormControl>
                                        </div>
                                    </div>

                                </div>

                                <hr className='mt-6 ml-4 lg:ml-8 mr-4 lg:mr-8' />

                                <div className='w-full pt-4 pl-4 lg:pl-8 pr-4 lg:pr-8'>
                                    <div className='w-full'>
                                        <p className="text-white text-left text-xl font-semibold leading-10">{t('repatriation.addressOrigin')}*</p>
                                    </div>
                                    <div className='w-full'>
                                        <input name='addressOrigin' className="w-full h-12 bg-white rounded-full px-4" {...register('addressOrigin', { required: true })}></input>
                                    </div>
                                </div>

                                <div className='w-full lg:flex lg:flex-row pt-4 pl-4 lg:pl-8 pr-4 lg:pr-8'>

                                    <div className='w-full lg:w-1/2 lg:pr-2'>
                                        <div className='w-full'>
                                            <p className="text-white text-left text-xl font-semibold leading-10">{t('repatriation.city')}*</p>
                                        </div>
                                        <div className='w-full'>
                                            <input name='cityOrigin' className="w-full h-12 bg-white rounded-full px-4" {...register('cityOrigin', { required: true })}></input>
                                        </div>
                                    </div>

                                    <div className='w-full lg:w-1/2 lg:pl-2'>
                                        <div className='w-full'>
                                            <p className="text-white text-left text-xl font-semibold leading-10">{t('repatriation.state')}*</p>
                                        </div>
                                        <div className='w-full '>
                                            <input name='stateOrigin' className="w-full h-12 bg-white rounded-full px-4" {...register('stateOrigin', { required: true })}></input>
                                        </div>
                                    </div>

                                </div>

                                <div className='w-full lg:flex lg:flex-row pt-4 pl-4 lg:pl-8 pr-4 lg:pr-8'>

                                    <div className='w-full lg:w-1/2 lg:pr-2'>
                                        <div className='w-full'>
                                            <p className="text-white text-left text-xl font-semibold leading-10">{t('repatriation.countryOrigin')}*</p>
                                        </div>
                                        <div className='w-full'>
                                            {/* <input name='countryOrigin' className="w-full h-12 bg-white rounded-full px-4" {...register('countryOrigin', { required: true })}></input> */}
                                            <Autocomplete
                                                id="country-select-demo"
                                                // sx={{ width: 300 }}
                                                value={selectedOriginCountry}
                                                onChange={handleChangeOriginCountry}
                                                options={countries}
                                                autoHighlight
                                                getOptionLabel={(option) => option.label}
                                                className="w-full h-12 bg-white"
                                                sx={{ borderRadius: 10, '& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline': { border: 'none' } }}
                                                renderOption={(props, option) => (
                                                    <Box component="li" sx={{ '& > img': { mr: 2, flexShrink: 0 } }} {...props}>
                                                        <img
                                                            loading="lazy"
                                                            width="20"
                                                            srcSet={`https://flagcdn.com/w40/${option.code.toLowerCase()}.png 2x`}
                                                            src={`https://flagcdn.com/w20/${option.code.toLowerCase()}.png`}
                                                            alt=""
                                                        />
                                                        {/* {option.label} */}
                                                        {option.label} ({option.code}) +{option.phone}
                                                    </Box>
                                                )}
                                                renderInput={(params) => (
                                                    <TextField
                                                        {...params}

                                                        // label="Choose a country"                                                        
                                                        inputProps={{
                                                            ...params.inputProps,
                                                            autoComplete: 'new-password', // disable autocomplete and autofill
                                                        }}
                                                        {...register('countryOrigin', { required: true })}
                                                    />
                                                )}
                                            />
                                        </div>
                                    </div>

                                    <div className='w-full lg:w-1/2 lg:pl-2'>
                                        <div className='w-full'>
                                            <p className="text-white text-left text-xl font-semibold leading-10">{t('repatriation.contact')}*</p>
                                        </div>
                                        <div className='w-full '>
                                            <input name='contactOrigin' className="w-full h-12 bg-white rounded-full px-4" {...register('contactOrigin', { required: true })}></input>
                                        </div>
                                    </div>

                                </div>

                                <div className='w-full lg:flex lg:flex-row pt-4 pl-4 lg:pl-8 pr-4 lg:pr-8'>

                                    <div className='w-full lg:w-2/6 lg:pr-2'>
                                        <div className='w-full'>
                                            <p className="text-white text-left text-xl font-semibold leading-10">{t('repatriation.areaCodeOrigin')}*</p>
                                        </div>
                                        <div className='w-full'>
                                            <input name='areaCodeOrigin' maxLength={5} className="w-full h-12 bg-white rounded-full px-4" {...register('areaCodeOrigin', { required: true })} onKeyDown={handleKeyDown}></input>
                                        </div>
                                    </div>

                                    <div className='w-full lg:w-4/6 lg:pl-2'>
                                        <div className='w-full'>
                                            <p className="text-white text-left text-xl font-semibold leading-10">{t('repatriation.phoneContactOrigin')}*</p>
                                        </div>
                                        <div className='w-full '>
                                            <input name='phoneContactOrigin' maxLength={12} className="w-full h-12 bg-white rounded-full px-4" {...register('phoneContactOrigin', { required: true })} onKeyDown={handleKeyDown}></input>
                                        </div>
                                    </div>

                                </div>


                                <hr className='mt-6 ml-4 lg:ml-8 mr-4 lg:mr-8' />

                                <div className='w-full pt-4 pl-4 lg:pl-8 pr-4 lg:pr-8'>
                                    <div className='w-full'>
                                        <p className="text-white text-left text-xl font-semibold leading-10">{t('repatriation.addressDestiny')}*</p>
                                    </div>
                                    <div className='w-full'>
                                        <input name='address' className="w-full h-12 bg-white rounded-full px-4" {...register('address', { required: true })}></input>
                                    </div>
                                </div>

                                <div className='w-full lg:flex lg:flex-row pt-4 pl-4 lg:pl-8 pr-4 lg:pr-8'>

                                    <div className='w-full lg:w-1/2 lg:pr-2'>
                                        <div className='w-full'>
                                            <p className="text-white text-left text-xl font-semibold leading-10">{t('repatriation.cityholdDestination')}*</p>
                                        </div>
                                        <div className='w-full'>
                                            <input name='cityholdDestination' className="w-full h-12 bg-white rounded-full px-4" {...register('cityholdDestination', { required: true })}></input>
                                        </div>
                                    </div>

                                    <div className='w-full lg:w-1/2 lg:pl-2'>
                                        <div className='w-full'>
                                            <p className="text-white text-left text-xl font-semibold leading-10">{t('repatriation.departmentDestination')}*</p>
                                        </div>
                                        <div className='w-full '>
                                            <input name='departmentDestination' className="w-full h-12 bg-white rounded-full px-4" {...register('departmentDestination', { required: true })}></input>
                                        </div>
                                    </div>

                                </div>

                                <div className='w-full lg:flex lg:flex-row pt-4 pl-4 lg:pl-8 pr-4 lg:pr-8'>

                                    <div className='w-full lg:w-1/2 lg:pr-2'>
                                        <div className='w-full'>
                                            <p className="text-white text-left text-xl font-semibold leading-10">{t('repatriation.countryDestination')}*</p>
                                        </div>
                                        <div className='w-full'>
                                            {/* <input name='countryOrigin' className="w-full h-12 bg-white rounded-full px-4" {...register('countryOrigin', { required: true })}></input> */}
                                            <Autocomplete
                                                id="country-select-demo"
                                                // sx={{ width: 300 }}
                                                value={selectedDestinationCountry}
                                                onChange={handleChangeDestinationCountry}
                                                options={countries}
                                                autoHighlight
                                                getOptionLabel={(option) => option.label}
                                                // defaultValue='US'
                                                className="w-full h-12 bg-white"
                                                sx={{ borderRadius: 10, '& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline': { border: 'none' } }}
                                                renderOption={(props, option) => (
                                                    <Box component="li" sx={{ '& > img': { mr: 2, flexShrink: 0 } }} {...props}>
                                                        <img
                                                            loading="lazy"
                                                            width="20"
                                                            srcSet={`https://flagcdn.com/w40/${option.code.toLowerCase()}.png 2x`}
                                                            src={`https://flagcdn.com/w20/${option.code.toLowerCase()}.png`}
                                                            alt=""
                                                        />
                                                        {option.label} ({option.code}) +{option.phone}
                                                    </Box>
                                                )}
                                                renderInput={(params) => (
                                                    <TextField
                                                        {...params}
                                                        // label="Choose a country"
                                                        inputProps={{
                                                            ...params.inputProps,
                                                            autoComplete: 'new-password', // disable autocomplete and autofill
                                                        }}
                                                        {...register('countryDestination', { required: true })}
                                                    />
                                                )}
                                            />
                                        </div>
                                    </div>

                                    <div className='w-full lg:w-1/2 lg:pl-2'>
                                        <div className='w-full'>
                                            <p className="text-white text-left text-xl font-semibold leading-10">{t('repatriation.contactDestination')}*</p>
                                        </div>
                                        <div className='w-full '>
                                            <input name='contactDestination' className="w-full h-12 bg-white rounded-full px-4" {...register('contactDestination', { required: true })}></input>
                                        </div>
                                    </div>

                                </div>

                                <div className='w-full lg:flex lg:flex-row pt-4 pl-4 lg:pl-8 pr-4 lg:pr-8'>

                                    <div className='w-full lg:w-2/6 lg:pr-2'>
                                        <div className='w-full'>
                                            <p className="text-white text-left text-xl font-semibold leading-10">{t('repatriation.areaCodeDestination')}*</p>
                                        </div>
                                        <div className='w-full'>
                                            <input name='areaCodeDestination' maxLength={5} className="w-full h-12 bg-white rounded-full px-4" {...register('areaCodeDestination', { required: true })} onKeyDown={handleKeyDown}></input>
                                        </div>
                                    </div>

                                    <div className='w-full lg:w-4/6 lg:pl-2'>
                                        <div className='w-full'>
                                            <p className="text-white text-left text-xl font-semibold leading-10">{t('repatriation.phoneContactDestination')}*</p>
                                        </div>
                                        <div className='w-full '>
                                            <input name='phoneContactDestination' maxLength={12} className="w-full h-12 bg-white rounded-full px-4" {...register('phoneContactDestination', { required: true })} onKeyDown={handleKeyDown}></input>
                                        </div>
                                    </div>

                                </div>

                                <hr className='mt-6 ml-4 lg:ml-8 mr-4 lg:mr-8' />

                                <div className='w-full pt-4 pl-4 lg:pl-8 pr-4 lg:pr-8'>
                                    <div className='w-full'>
                                        <p className="text-white text-left text-xl font-semibold leading-10">{t('repatriation.plan')}*</p>
                                    </div>
                                    <div className='w-full'>
                                        <FormControl className='w-full'>
                                            <Select
                                                labelId="plan-label"
                                                id="Plan"
                                                label=''
                                                placeholder={t('repatriation.plan')}
                                                defaultValue=""
                                                inputProps={{ 'aria-label': 'Without label' }}
                                                onChange={handleChangePlan}
                                                value={plan}
                                                className="w-full h-12 bg-white"
                                                sx={{ borderRadius: 10 }}
                                            >
                                                {moneda === 'Q' &&
                                                    plans.map((item) => (
                                                        <MenuItem key={item.value} value={item.value}>
                                                            {item.nameQ}
                                                        </MenuItem>
                                                    ))
                                                }
                                                {moneda !== 'Q' &&
                                                    plans.map((item) => (
                                                        <MenuItem key={item.value} value={item.value}>
                                                            {item.name}
                                                        </MenuItem>
                                                    ))
                                                }
                                            </Select>
                                        </FormControl>
                                    </div>
                                </div>

                                {/* <hr className='mt-6 ml-4 lg:ml-8 mr-4 lg:mr-8' />


                                <div className='w-full lg:flex lg:flex-row pt-4 pl-4 lg:pl-8 pr-4 lg:pr-8'>
                                    <div className='w-full lg:w-1/2 lg:pr-2'>
                                        <div className='w-full'>
                                            <p className="text-white text-left text-xl font-semibold leading-10">{t('repatriation.userLlega')}*</p>
                                        </div>
                                        <div className='w-full'>
                                            <input name='phone' className="w-full h-12 bg-white rounded-full px-4" {...register('userLlega', { required: true })}></input>
                                        </div>
                                    </div>

                                    <div className='w-full lg:w-1/2 lg:pl-2'>
                                        <div className='w-full'>
                                            <p className="text-white text-left text-xl font-semibold leading-10">{t('repatriation.passLlega')}*</p>
                                        </div>
                                        <div className='w-full '>
                                            <input name='pass' type='password' className="w-full h-12 bg-white rounded-full px-4" {...register('passLlega', { required: true })}></input>
                                        </div>
                                    </div>
                                </div> */}

                                <div className='w-full'>
                                    <ModalVideo channel='custom' isOpen={isOpen} onClose={() => setIsOpen(false)} url='https://firebasestorage.googleapis.com/v0/b/llega-market.appspot.com/o/conocenos%2Fvideos%2F1704392923480-Llega%20Usuario%20USA.mp4?alt=media&token=3edb33e7-0728-4df0-b1d0-6d28ab3892f6' />
                                    <button className='text-white pt-2' onClick={openModal}><u>¿Cómo crear mi usuario llega si no tengo?</u></button>
                                </div>

                                <div className='w-full flex flex-row pt-6 place-content-center'>
                                    <Checkbox
                                        checked={checked}
                                        onChange={handleChangeCondition} />
                                    <p className='text-white pt-2'>{t('repatriation.accept')} <a href='../assets/files/Terminos y Condiciones Repatriacion Llega.pdf' alt='Ver terminos y condiciones' target='_blank'><u>{t('repatriation.terms')}</u></a></p>
                                </div>
                                {!loading &&
                                    <div className='w-full flex flex-row pt-12 justify-center pb-10'>
                                        <button type='button' className="w-80 h-12 bg-cyan-300 rounded-full" onClick={handleSubmit(onSubmit)}>{t('repatriation.acquire')}</button>
                                    </div>
                                }
                                {loading &&
                                    <div className='pt-10 pb-10 place-content-center justify-center'>
                                        <Box sx={{ display: 'flex w-full' }}>
                                            <CircularProgress />
                                        </Box>
                                    </div>
                                }


                            </div>

                        </div>
                    </div>

                </>
            }
            {
                showPayment &&
                <>
                    <RepatriationPricing />
                </>
            }
        </>
    )
}
