import { query, collection, getDocs, where } from "firebase/firestore/lite";
import { FirebaseDB } from "../firebase/config";


export const loadCompanies = async (uid = '') => {
    if (!uid) throw new Error('El UID del usuario no existe');

    const q = query(
        collection(FirebaseDB, 'companie')
    );

    const docs = await getDocs(q);

    const companies = [];
    docs.forEach(doc => {
        companies.push({ id: doc.id, ...doc.data() });
    });
    return companies;
}

export const loadAllCompanies = async () => {

    const q = query(
        collection(FirebaseDB, 'companie')
    );

    const docs = await getDocs(q);

    const companies = [];
    docs.forEach(doc => {
        companies.push({ id: doc.id, ...doc.data() });
    });
    return companies;
}

export const loadCompanie = async (uid = '') => {
    if (!uid) throw new Error('El UID del usuario no existe');

    const q = query(
        collection(FirebaseDB, 'companie'),
        where('uid', '==', uid)
    );

    const docs = await getDocs(q);

    const companie = [];
    docs.forEach(doc => {
        companie.push({ id: doc.id, ...doc.data() });
    })

    return companie;
}