import { collection, count, deleteDoc, doc, setDoc } from "firebase/firestore/lite";
import { FirebaseDB } from "../../firebase/config";
import { addNewEmptyReferred, deleteReferredById, savingNewReferred, setActiveReferred, setReferred, setReferreds, setSaving, updateReferred } from "./referredSlice";
import { loadAllReferreds, loadReferred, loadReferreds } from "../../helpers/loadReferred";


export const startNewClearReferred = (referred) => {
    return async (dispatch, getState) => {
        dispatch(savingNewReferred());
        const { uid } = getState().auth;

        const {
            companyId,
            companyName,
            refCompanyId,
            refCompanyName
        } = referred;

        const newReferred = {
            uid,
            companyId,
            companyName,
            refCompanyId,
            refCompanyName,
            creationDate: new Date().getTime(),
            updateDate: new Date().getTime()
        }

        const newDoc = doc(collection(FirebaseDB, `referred`))
        await setDoc(newDoc, newReferred);
        newReferred.id = newDoc.id;
        dispatch(addNewEmptyReferred(newReferred));
        dispatch(setActiveReferred(newReferred));

    }
}

export const startNewReferred = (referred) => {
    return async (dispatch, getState) => {
        dispatch(savingNewReferred());

        const { uid } = getState().auth;
        const {
            companyId,
            companyName,
            refCompanyId,
            refCompanyName
        } = referred;

        const newReferred = {
            uid,
            companyId,
            companyName,
            refCompanyId,
            refCompanyName,
            creationDate: new Date().getTime(),
            updateDate: new Date().getTime()
        }

        const newDoc = doc(collection(FirebaseDB, `referred`));
        await setDoc(newDoc, newReferred);
        newReferred.id = newDoc.id;

        dispatch(addNewEmptyReferred(newReferred));
        dispatch(setActiveReferred(newReferred));

    }
}

export const startLoadingReferreds = () => {
    return async (dispatch, getState) => {
        const { uid } = getState().auth;

        if (!uid) throw new Error('El UID del usuario no existe');
        const referred = await loadReferreds(uid);
        dispatch(setReferreds(referred));
    }
}

export const startLoadingAllReferreds = () => {
    return async (dispatch, getState) => {
        // const { uid } = getState().auth;

        // if (!uid) throw new Error('El UID del usuario no existe');
        const referred = await loadAllReferreds();
        dispatch(setReferreds(referred));
    }
}

export const startLoadingReferred = () => {
    return async (dispatch, getState) => {
        const { uid } = getState().auth;

        if (!uid) throw new Error("El UID del usuario no existe");
        const referred = await loadReferred(uid);
        dispatch(setReferred(referred));
    }
}

export const startDeletingReferred = () => {
    return async (dispatch, getState) => {
        const { uid } = getState().auth;
        const { active: referred } = getState().referred;

        const docRef = doc(FirebaseDB, `referred/${referred.id}`);
        await deleteDoc(docRef);

        dispatch(deleteReferredById(referred.id));
    }
}

export const startSaveReferred = () => {
    return async (dispatch, getState) => {

        dispatch(setSaving());

        const { uid } = getState().auth;
        const { active: referred } = getState().referred;

        const referredToFireStore = { ...referred };
        delete referredToFireStore.id;

        const docRef = doc(FirebaseDB, `referred/${referred.id}`)
        await setDoc(docRef, referredToFireStore, { merge: true });

        dispatch(updateReferred(referred));
    }
}