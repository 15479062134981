import { query, collection, getDocs, where } from "firebase/firestore/lite";
import { FirebaseDB } from "../firebase/config";


export const loadSellers = async (uid = '') => {
    if (!uid) throw new Error('El UID del usuario no existe');

    const q = query(
        collection(FirebaseDB, 'seller')
    );

    const docs = await getDocs(q);

    const sellers = [];
    docs.forEach(doc => {
        sellers.push({ id: doc.id, ...doc.data() });
    });
    return sellers;
}

export const loadAllSellers = async () => {

    const q = query(
        collection(FirebaseDB, 'seller')
    );

    const docs = await getDocs(q);

    const sellers = [];
    docs.forEach(doc => {
        sellers.push({ id: doc.id, ...doc.data() });
    });
    return sellers;
}

export const loadSeller = async (uid = '') => {
    if (!uid) throw new Error('El UID del usuario no existe');

    const q = query(
        collection(FirebaseDB, 'seller'),
        where('uid', '==', uid)
    );

    const docs = await getDocs(q);

    const seller = [];
    docs.forEach(doc => {
        seller.push({ id: doc.id, ...doc.data() });
    })

    return seller;
}

