import { addNewEmptyLlegaRepatriation, deleteLlegaRepatriationById, savingNewLlegaRepatriation, setActiveLlegaRepatriation, setLlegaRepatriation, setLlegaRepatriations, setSaving, updateLlegaRepatriation } from "./llegaRepatriationSlice";
import { collection, count, deleteDoc, doc, setDoc } from "firebase/firestore/lite";
import { FirebaseDB } from "../../firebase/config";
import { loadAllLlegaRepatriations, loadLlegaRepatriation, loadLlegaRepatriations } from "../../helpers/loadLlegaRepatriations";
import moment from 'moment';

export const startNewClearLlegaRepatriation = (repatriation) => {
    return async (dispatch, getState) => {
        dispatch(savingNewLlegaRepatriation());

        const {
            displayName,
            identification,
            email,
            phone,
            areaCode,
            address,
            addressOrigin,
            birthday,
            companyId,
            companyName,
            refered,
            seller,
            sellerName,
            userLlega,
            policyNo,
            authNo,
            plan,
            amount,
            acceptTerms,
        } = repatriation

        const newRepatriation = {
            displayName,
            identification,
            email,
            phone,
            areaCode,
            address,
            addressOrigin,
            birthday,
            companyId,
            companyName,
            refered,
            seller,
            sellerName,
            userLlega,
            policyNo,
            authNo,
            plan,
            amount,
            acceptTerms,
            creationDate: moment(new Date().getTime()).format("DD/MM/YYYY"),
            updateDate: moment(new Date().getTime()).format("DD/MM/YYYY")
        }

        const newDoc = doc(collection(FirebaseDB, `llegaexpress`))
        await setDoc(newDoc, newRepatriation);
        newRepatriation.id = newDoc.id;
        dispatch(addNewEmptyLlegaRepatriation(newRepatriation));
        dispatch(setActiveLlegaRepatriation(newRepatriation));
    }
}

export const startNewLlegaRepatriation = (repatriation) => {
    return async (dispatch, getState) => {
        dispatch(savingNewLlegaRepatriation());

        const { uid } = getState().auth;
        const {
            displayName,
            identification,
            email,
            phone,
            areaCode,
            address,
            cityholdDestination,
            countryDestination,
            departmentDestination,
            contactDestination,
            areaCodeDestination,
            phoneContactDestination,
            addressOrigin,
            cityOrigin,
            stateOrigin,
            countryOrigin,
            contactOrigin,
            areaCodeOrigin,
            phoneContactOrigin,
            birthday,
            companyId,
            companyName,
            refered,
            seller,
            sellerName,
            userLlega,
            policyNo,
            authNo,
            plan,
            amount,
            acceptTerms
        } = repatriation

        const newRepatriation = {
            displayName,
            identification,
            email,
            phone,
            areaCode,
            address,
            cityholdDestination,
            countryDestination,
            departmentDestination,
            contactDestination,
            areaCodeDestination,
            phoneContactDestination,
            addressOrigin,
            cityOrigin,
            stateOrigin,
            countryOrigin,
            contactOrigin,
            areaCodeOrigin,
            phoneContactOrigin,
            birthday,
            companyId,
            companyName,
            refered,
            seller,
            sellerName,
            userLlega,
            policyNo,
            authNo,
            plan,
            amount,
            acceptTerms,
            creationDate: moment(new Date().getTime()).format("DD/MM/YYYY"),
            updateDate: moment(new Date().getTime()).format("DD/MM/YYYY")
        }

        const newDoc = doc(collection(FirebaseDB, `llegaexpress`));
        await setDoc(newDoc, newRepatriation);
        newRepatriation.id = newDoc.id;

        dispatch(addNewEmptyLlegaRepatriation(newRepatriation));
        dispatch(setActiveLlegaRepatriation(newRepatriation));
    }
}

export const startLoadingLlegaRepatriations = () => {
    return async (dispatch, getState) => {
        const { uid } = getState().auth;

        if (!uid) throw new Error('El UID del usuario no existe');
        const repatriations = await loadLlegaRepatriations(uid);
        dispatch(setLlegaRepatriations(repatriations));
    }
}

export const startLoadingAllLlegaRepatriations = () => {
    return async (dispatch, getState) => {

        const llegaRepatriations = await loadAllLlegaRepatriations();
        console.log('llegaRepatriations', llegaRepatriations);
        dispatch(setLlegaRepatriations(llegaRepatriations));
    }
}

export const startLoadingLlegaRepatriation = () => {
    return async (dispatch, getState) => {
        const { uid } = getState().auth;

        if (!uid) throw new Error("El UID del usuario no existe");
        const repatriation = await loadLlegaRepatriation(uid);
        dispatch(setLlegaRepatriation(repatriation));
    }
}

export const startDeletingLlegaRepatriation = () => {
    return async (dispatch, getState) => {
        const { uid } = getState().auth;
        const { active: llegaRepatriation } = getState().llegaRepatriation;

        const docRef = doc(FirebaseDB, `llegaexpress/${llegaRepatriation.id}`);
        await deleteDoc(docRef);

        dispatch(deleteLlegaRepatriationById(llegaRepatriation.id));
    }
}

export const startSaveLlegaRepatriation = () => {
    return async (dispatch, getState) => {

        dispatch(setSaving());

        const { uid } = getState().auth;
        const { active: llegaRepatriation } = getState().llegaRepatriation;

        const repatriationToFireStore = { ...llegaRepatriation };
        delete repatriationToFireStore.id;

        const docRef = doc(FirebaseDB, `llegaexpress/${llegaRepatriation.id}`)
        await setDoc(docRef, repatriationToFireStore, { merge: true });

        dispatch(updateLlegaRepatriation(llegaRepatriation));
    }
}