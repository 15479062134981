import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    page: 0,
    isLoading: false,
    messageSaved: '',
    isSaving: false,
    countries: [],
    country: [],
    active: null
}

export const countrySlice = createSlice({
    name: 'country',
    initialState,
    reducers: {
        savingNewCountry: (state) => {
            state.isSaving = true;
        },
        addNewEmptyCountry: (state, action) => {
            const newCountry = action.payload;
            state.countries.push(newCountry);
            state.isSaving = false;
        },
        setActiveCountry: (state, action) => {
            state.active = action.payload;
            state.messageSaved = '';
        },
        setCountries: (state, action) => {
            state.countries = action.payload;
        },
        setCountry: (state, action) => {
            state.country = action.payload;
        },
        setSaving: (state) => {
            state.isSaving = true;
            state.messageSaved = '';
        },
        updateCountry: (state, action) => {
            state.isSaving = false;
            state.countries = state.countries.map(country => {
                if (country.id === action.payload.id) {
                    return action.payload;
                }
                return country;
            })
            state.messageSaved = `${action.payload.title}, actualizada correctamente`
        },
        deleteCountryById: (state, action) => {

        }
    }
})

export const {
    savingNewCountry,
    addNewEmptyCountry,
    setActiveCountry,
    setCountries,
    setCountry,
    setSaving,
    updateCountry,
    deleteCountryById
} = countrySlice.actions