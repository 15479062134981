import { query, collection, getDocs, where, orderBy } from "firebase/firestore/lite";
import { FirebaseDB } from "../firebase/config";


export const loadVideos = async () => {

    const q = query(
        collection(FirebaseDB, 'video'),
        orderBy("sorting", 'asc')
    );

    const docs = await getDocs(q);
    const videos = [];
    docs.forEach(doc => {
        videos.push({ id: doc.id, ...doc.data() });
    });
    return videos;
}

export const loadAllVideos = async () => {

    const q = query(
        collection(FirebaseDB, 'video')
    );

    const docs = await getDocs(q);
    const videos = [];
    docs.forEach(doc => {
        videos.push({ id: doc.id, ...doc.data() });
    });
    return videos;
}

export const loadVideo = async (uid = '') => {
    if (!uid) throw new Error('El UID del usuario no existe');

    const q = query(
        collection(FirebaseDB, 'video'),
        where('uid', '==', uid)
    );

    const docs = await getDocs(q);

    const video = [];
    docs.forEach(doc => {
        video.push({ id: doc.id, ...doc.data() });
    })

    return video;
}

