import { collection, count, deleteDoc, doc, setDoc } from "firebase/firestore/lite";
import { FirebaseDB } from "../../firebase/config";
import { loadAllRepatriations, loadRepatriation, loadRepatriations } from "../../helpers/loadRepatriations";
import moment from 'moment';
import { addNewEmptyPayment, deletePaymentById, savingNewPayment, setActivePayment, setPayment, setPayments, setSaving, updatePayment } from "./paymentSlice";
import { loadAllPayments, loadPayment, loadPayments } from "../../helpers/loadPayments";

export const startNewClearPayment = (payment) => {
    return async (dispatch, getState) => {
        dispatch(savingNewPayment());

        const {
            ReferenceNo,
            ProductID,
            Total,
            Last4DigitCardNumber,
            ResponseCode,
            ResponseMsg,
            AuthNo,
            userLlega
        } = payment

        const newPayment = {
            ReferenceNo,
            ProductID,
            Total,
            Last4DigitCardNumber,
            ResponseCode,
            ResponseMsg,
            authNo: AuthNo,
            userLlega,
            creationDate: moment(new Date().getTime()).format("DD/MM/YYYY"),
            updateDate: moment(new Date().getTime()).format("DD/MM/YYYY")
        }

        const newDoc = doc(collection(FirebaseDB, `payment`))
        await setDoc(newDoc, newPayment);
        newPayment.id = newDoc.id;
        dispatch(addNewEmptyPayment(newPayment));
        dispatch(setActivePayment(newPayment));
    }
}

export const startNewPayment = (payment) => {
    return async (dispatch, getState) => {
        dispatch(savingNewPayment());

        // const { uid } = getState().auth;
        const {
            ReferenceNo,
            ProductID,
            Total,
            Last4DigitCardNumber,
            ResponseCode,
            ResponseMsg,
            AuthNo,
            userLlega
        } = payment

        const newPayment = {
            ReferenceNo,
            ProductID,
            Total,
            Last4DigitCardNumber,
            ResponseCode,
            ResponseMsg,
            AuthNo,
            userLlega,
            creationDate: moment(new Date().getTime()).format("DD/MM/YYYY"),
            updateDate: moment(new Date().getTime()).format("DD/MM/YYYY")
        }

        const newDoc = doc(collection(FirebaseDB, `payment`));
        await setDoc(newDoc, newPayment);
        newPayment.id = newDoc.id;

        dispatch(addNewEmptyPayment(newPayment));
        dispatch(setActivePayment(newPayment));
    }
}

export const startLoadingPayments = () => {
    return async (dispatch, getState) => {
        const { uid } = getState().auth;

        if (!uid) throw new Error('El UID del usuario no existe');
        const payments = await loadPayments(uid);
        dispatch(setPayments(payments));
    }
}

export const startLoadingAllPayments = () => {
    return async (dispatch, getState) => {

        const payments = await loadAllPayments();
        dispatch(setPayments(payments));
    }
}

export const startLoadingPayment = () => {
    return async (dispatch, getState) => {
        const { uid } = getState().auth;

        if (!uid) throw new Error("El UID del usuario no existe");
        const payment = await loadPayment(uid);
        dispatch(setPayment(payment));
    }
}

export const startDeletingPayment = () => {
    return async (dispatch, getState) => {
        const { uid } = getState().auth;
        const { active: payment } = getState().payment;

        const docRef = doc(FirebaseDB, `payment/${payment.id}`);
        await deleteDoc(docRef);

        dispatch(deletePaymentById(payment.id));
    }
}

export const startSavePayment = () => {
    return async (dispatch, getState) => {

        dispatch(setSaving());

        const { uid } = getState().auth;
        const { active: payment } = getState().payment;

        const paymentToFireStore = { ...payment };
        delete paymentToFireStore.id;

        const docRef = doc(FirebaseDB, `payment/${payment.id}`)
        await setDoc(docRef, paymentToFireStore, { merge: true });

        dispatch(updatePayment(payment));
    }
}