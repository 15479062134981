import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    page: 0,
    isLoading: false,
    messageSaved: '',
    isSaving: false,
    referreds: [],
    referred: [],
    active: null
}

export const referredSlice = createSlice({
    name: 'referred',
    initialState,
    reducers: {
        savingNewReferred: (state) => {
            state.isSaving = true;
        },
        addNewEmptyReferred: (state, action) => {
            const newReferred = action.payload;
            state.referred.push(newReferred);
            state.isSaving = false;
        },
        setActiveReferred: (state, action) => {
            state.active = action.payload;
            state.messageSaved = '';
        },
        setReferreds: (state, action) => {
            state.referreds = action.payload;
        },
        setReferred: (state, action) => {
            state.referred = action.payload;
        },
        setSaving: (state) => {
            state.isSaving = true;
            state.messageSaved = '';
        },
        updateReferred: (state, action) => {
            state.isSaving = false;
            state.referreds = state.referreds.map(referred => {
                if (referred.id === action.payload.id) {
                    return action.payload;
                }
                return referred;
            })
            state.messageSaved = `${action.payload.title}, actualizada correctamente`
        },
        deleteReferredById: (state, action) => {

        }
    }
})

export const {
    savingNewReferred,
    addNewEmptyReferred,
    setActiveReferred,
    setReferreds,
    setReferred,
    setSaving,
    updateReferred,
    deleteReferredById
} = referredSlice.actions