import React, { useEffect, useState } from 'react'
import Swal from 'sweetalert2';
import { useForm } from 'react-hook-form';
import { FormControl, InputLabel, MenuItem, Select, TextField } from '@mui/material';
import { setActiveCountry } from '../store/countries/countrySlice';
import { startDeletingCountry, startLoadingCountries, startNewCountry, startSaveCountry } from '../store/countries/thunks';
import { useDispatch, useSelector } from 'react-redux';
import { DataGrid } from '@mui/x-data-grid';

const state = [
    { name: 'Activo' },
    { name: 'Inactivo' }
]

export const Country = () => {
    const dispatch = useDispatch();
    const [status, setStatus] = useState('');
    const { countries } = useSelector(state => state.country);
    const { errorMessage } = useSelector(state => state.auth);
    const [edit, setEdit] = useState(false);
    const [countryId, setCountryId] = useState('');
    const [updateCountries, setUpdateCountries] = useState(false);
    const [deleteCountry, setDeleteCountry] = useState(false);

    useEffect(() => {
        if (updateCountries) {
            dispatch(startLoadingCountries());
        }
    }, [updateCountries])

    useEffect(() => {
        if (deleteCountry) {
            dispatch(startLoadingCountries());
        }
    }, [deleteCountry])

    const onDelete = (idCountry) => {
        let tmpCountry = countries.filter(x => x.id === idCountry);
        const { id, countryName, status } = tmpCountry[0];
        dispatch(setActiveCountry({ id, countryName, status }))
        setDeleteCountry(true);
        dispatch(startDeletingCountry(id));
        if (!errorMessage) {
            showDeleteSuccess();
            onClear();
        }
    }

    const columns = [
        { field: 'countryName', headerName: 'Nombre', width: 200 },
        // { field: 'description', headerName: 'Descripción', width: 250 },
        { field: 'status', headerName: 'Estado', width: 200 },
        { field: 'id', headerName: 'Opciones', width: 250, renderCell: (params) => <><button className='w-16 h-8 bg-blue-800 text-white rounded-full' onClick={() => onEdit(params.value)}>Editar</button><button className='w-16 h-8 bg-red-800 text-white ml-2 rounded-full' onClick={() => onDelete(params.value)}>Eliminar</button></> }
    ]

    const onEdit = (idCountry) => {
        setEdit(true);
        setEdit((state) => {
            return state;
        });

        let tmpCountry = countries.filter(x => x.id === idCountry);
        const { id, countryName, status } = tmpCountry[0];
        dispatch(setActiveCountry({ id, countryName, status }))
        setCountryId(id);
        setCountryId((state) => {
            return state;
        })
        setValue('displayName', countryName);
        setStatus(status);
    }

    const { register, handleSubmit, setValue, control, formState: { errors } } = useForm({
        defaultValues: {
            displayName: "",
            description: "",
            state: ""
        }
    });

    const onSubmit = (data) => {
        if (edit) {
            const newData = {
                id: countryId,
                countryName: data.displayName,
                status: status
            }
            dispatch(setActiveCountry(newData));
            if (newData) {
                onUpdateRegister(newData);
                if (!errorMessage) {
                    showUpdateSuccess();
                    onClear();
                    setUpdateCountries(true);
                }
            }
        } else {
            const newData = {
                countryName: data.displayName,
                status: status
            }

            dispatch(setActiveCountry(newData));
            if (newData) {
                showSuccess();
                onSaveNewCountry(newData);
            }
        }
    }

    const onUpdateRegister = () => {
        dispatch(startSaveCountry());
    }

    const onSaveNewCountry = (data) => {
        dispatch(startNewCountry(data));
        if (!errorMessage) {
            showSuccess();
            onClear();
        } else {
            showError();
        }
    }

    const showDeleteSuccess = () => {
        Swal.fire('El país fué eliminado correctamente', "Eliminado", 'success');
    }

    const showSuccess = () => {
        Swal.fire('País creado correctamente', 'Guardado', 'success');
    };

    const showUpdateSuccess = () => {
        Swal.fire('País actualizado correctamente', 'Guardado', 'success');
    };

    const showError = () => {
        Swal.fire('El País no fué creado', 'Error', 'error');
    };

    const handleChangeStatus = (event) => {
        const {
            target: { value },
        } = event;
        setStatus(
            value,
        );
    }

    const onClear = () => {
        setValue('displayName', "");
        setStatus('');
        dispatch(setActiveCountry({}));
        setEdit(false);
    }

    return (
        <div className='flex flex-col bg-white w-full place-items-center h-[870px]'>
            <div className='w-full pt-10 pl-32 pr-32'>
                <form onSubmit={handleSubmit(onSubmit)}>
                    <div className='flex flex-row w-full '>
                        <div className='w-full'>
                            <TextField
                                id='displayName'
                                className='w-full'
                                placeholder='Nombre'
                                {...register("displayName")}
                            />
                        </div>
                    </div>
                    <div className='flex flex-row w-full pt-4'>
                        <FormControl className='w-full'>
                            <InputLabel>Estado</InputLabel>
                            <Select
                                placeholder='Estado'
                                label='Estado'
                                value={status}
                                onChange={handleChangeStatus}
                            >
                                {state.map((item) => (
                                    <MenuItem key={item.name} value={item.name}>
                                        {item.name}
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    </div>

                    <div className='flex flex-row pt-10 justify-end w-full'>
                        <div className='w-1/4'><button type="button" className='w-full h-10 bg-red-700 text-white rounded-full font-medium' onClick={() => onClear()}>Limpiar</button></div>
                        <div className='w-1/4 pl-2'><button type="submit" className='w-full h-10 bg-sky-900 text-white rounded-full font-medium'>Guardar</button></div>

                    </div>
                </form>
            </div>

            <div className='flex flex-row w-full pl-32 pr-32 pt-10'>
                <DataGrid
                    rows={countries}
                    columns={columns}
                    initialState={{
                        pagination: {
                            paginationModel: { page: 0, pageSize: 5 },
                        },
                    }}
                    pageSizeOptions={[5, 10]}
                />
            </div>
        </div>
    )
}
