import React, { useState, useEffect, useRef } from 'react'
import { FirebaseStorage } from '../firebase/config';
import { Box, CircularProgress, FormControl, IconButton, InputLabel, MenuItem, Modal, Select, TextField, Typography } from '@mui/material'
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { setActiveCompanie } from '../store/Companies/companiesSlice';
import { startDeletingCompanie, startLoadingCompanies, startNewCompanie, startSaveCompanie } from '../store/Companies/thunks';
import { getDownloadURL, ref, uploadBytes } from 'firebase/storage';
import Swal from 'sweetalert2';
import { DataGrid } from '@mui/x-data-grid';
import { Check, CloseOutlined, ContentPaste, CopyAll } from '@mui/icons-material';
import SyntaxHighlighter from 'react-syntax-highlighter';
import { atomOneDark } from 'react-syntax-highlighter/dist/esm/styles/hljs';
import { dark } from 'react-syntax-highlighter/dist/esm/styles/prism';
const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    // width: 900,
    bgcolor: 'background.paper',
    // border: '2px solid #000',
    // boxShadow: 12,
};

export const Companies = () => {
    const dispatch = useDispatch();
    const [category, setCategory] = useState('');
    const [open, setOpen] = React.useState(false);
    const inputRef = useRef(null);
    const { active, categories } = useSelector(state => state.categorie);
    const { companies } = useSelector(state => state.companie);
    const { active: activeCountry, countries } = useSelector(state => state.country);
    const { status, errorMessage } = useSelector(state => state.auth);
    const [urlImage, setUrlImage] = useState('');
    const [file, setFile] = useState("");
    const [edit, setEdit] = useState(false);
    const [deleteCompanie, setDeleteCompanie] = useState(false);
    const [imageSaved, setImageSaved] = useState(false);
    const [idCompanie, setIdCompanie] = useState('');
    const [updateCompanies, setUpdateCompanies] = useState(false);
    const [selectedCountry, setSelectedCountry] = useState('');
    const [saving, setSaving] = useState(false);
    const [copy, setCopy] = useState(false);
    const [code, setCode] = useState(`<div></div>`)
    const [link1, setLink1] = useState('');
    const [link2, setLink2] = useState('');
    // const codeString = `<form action=''https://gpspay.io/spa/xpay/securew/ipay.asp'' method='post' name=''form1'' target=''_blank''> <br />" +
    //     "<input name='MerchantID' type='hidden' id='MerchantID' value='827' />  " +
    //     "<select name='Total' id='Total'><option value='8'> $8.00 (mensual) </option><option value=' 90'> $90.00 (anual) </option><option value=' 100'> $100.00 (anual plus)** </option></select> <br />  " +
    //     "<input name='Description' id='Description' type='hidden' value='Repatriaciones USD' />  " +
    //     "<input type='submit' name='checkouticard1' value='' style='background-image: url(https://gpspay.io/spa/xmma/images/checkouticard.png); border: 0px; height: 42px; width: 145px;' />" +
    //     "</form>`;

    const columns = [
        { field: 'urlImage', headerName: 'Imagen', width: 70, renderCell: (params) => <img className='w-8 h-8' src={params.value} /> },
        { field: 'name', headerName: 'Nombre', width: 200 },
        { field: 'description', headerName: 'Descripción', width: 250 },
        { field: 'email', headerName: 'Correo', width: 300 },
        { field: 'merchantId', headerName: 'MerchantId', width: 150 },
        { field: 'token', headerName: 'Token', width: 250 },
        { field: 'category', headerName: 'Categoría', width: 200 },
        { field: 'id', headerName: 'Opciones', width: 250, renderCell: (params) => <><button className='w-16 h-8 bg-blue-500 text-white mr-2 rounded-full' onClick={() => handleOpen(params.value)}>Botones</button><button className='w-16 h-8 bg-blue-800 text-white rounded-full' onClick={() => onEdit(params.value)}>Editar</button><button className='w-16 h-8 bg-red-800 text-white ml-2 rounded-full' onClick={() => onDelete(params.value)}>Eliminar</button></> }
    ]

    const { register, handleSubmit, setValue, control, formState: { errors } } = useForm({
        defaultValues: {
            name: "",
            description: "",
            urlWebsite: "",
            email: "",
            merchantId: "",
            token: ""
        }
    });

    // useEffect(() => {

    // }, [active])

    useEffect(() => {
        if (deleteCompanie) {
            dispatch(startLoadingCompanies());
        }
    }, [deleteCompanie])

    useEffect(() => {
        if (updateCompanies) {
            dispatch(startLoadingCompanies());
        }
    }, [updateCompanies])

    const onDelete = (idCompany) => {
        setDeleteCompanie(true);
        dispatch(startDeletingCompanie(idCompany));
        if (!errorMessage) {
            showDeleteSuccess();
            onClear();
            setUpdateCompanies(true);
        }
    }

    const showDeleteSuccess = () => {
        Swal.fire('El comercio fué eliminado correctamente', "Eliminado", 'success');
    }

    const onEdit = (idCompany) => {
        setEdit(true);
        setEdit((state) => {
            return state;
        });

        let tmpCategory = '';
        let tmpCompanie = companies.filter(x => x.id === idCompany);
        const { id, name, description, uid, urlImage, category, urlWebsite, email, merchantId, token } = tmpCompanie[0];
        tmpCategory = category[0];
        dispatch(setActiveCompanie({ id, name, description, uid, urlImage, category: tmpCategory, email, merchantId, token }))
        setIdCompanie(id);
        setIdCompanie((state) => {
            return state;
        })
        setValue('name', name);
        setValue('description', description);
        setValue('urlWebsite', urlWebsite);
        setValue('email', email);
        setValue('merchantId', merchantId);
        setValue('token', token)
        setCategory(category);
        setUrlImage(urlImage);

    }

    const showSuccess = () => {
        Swal.fire('Comercio creado correctamente', 'Guardado', 'success');
    };

    const onSubmit = async (data) => {
        setSaving(true);
        const {
            name,
            description,
            urlWebsite,
            email,
            merchantId,
            token
        } = data;

        let urlTmpImage = '';
        if (file) {
            urlTmpImage = await saveImage();
        }

        if (edit) {
            setTimeout(() => {
                const newData = {
                    id: idCompanie,
                    name: name,
                    description: description,
                    urlWebsite: urlWebsite,
                    email: email,
                    merchantId: merchantId,
                    token: token,
                    // urlImage: urlTmpImage,
                    category: category,
                    country: selectedCountry
                }
                dispatch(setActiveCompanie(newData));
                if (newData) {
                    onUpdateRegister(newData);
                    if (!errorMessage) {
                        showSuccess();
                        onClear();
                        setUpdateCompanies(true);
                    }
                }
            }, 4000);
        } else {
            setTimeout(() => {
                const newData = {
                    name: name,
                    description: description,
                    urlWebsite: urlWebsite,
                    email: email,
                    merchantId: merchantId,
                    token: token,
                    urlImage: urlTmpImage,
                    category: category,
                    country: selectedCountry
                }

                dispatch(setActiveCompanie(newData));
                if (newData) {
                    onUpdateCompanie(newData);
                }
            }, 2000);
        }
    }

    const onUpdateRegister = () => {
        dispatch(startSaveCompanie());
        setSaving(false);
        setUpdateCompanies(true);
    }

    const onUpdateCompanie = (data) => {
        if (imageSaved) {
            setTimeout(() => {
                dispatch(startNewCompanie(data));
                setSaving(false);
            }, 3000)
            if (!errorMessage) {
                showSuccess();
                onClear();
                setUpdateCompanies(true);
                setSaving(false);
            }
        }
    }

    // useEffect(() => {
    //     saveVideo();
    // }, [file])

    useEffect(() => {
        setImageSaved(true);
    }, [urlImage])

    const handleChangeCategory = (event) => {
        const {
            target: { value },
        } = event;
        setCategory(
            typeof value === 'string' ? value.split(',') : value,
        );
    }

    const handleChangeCountry = (event) => {
        const {
            target: { value },
        } = event;
        setSelectedCountry(
            value,
        );
        setSelectedCountry((state) => {
            return state;
        })
    }

    const onClear = () => {
        setValue('name', "");
        setValue('description', "");
        setValue('urlWebsite', '');
        setValue('email', '');
        setValue('merchantId', '');
        setValue('token', '');
        setCategory('');
        setUrlImage('');
        setFile('');
        inputRef.current.value = null;
    }

    const handleOpen = (idCompany) => {
        setOpen(true);
        let tmpCompanie = companies.filter(x => x.id === idCompany);
        const { id, name, description, uid, urlImage, category, urlWebsite, email, merchantId, token } = tmpCompanie[0];

        //         setCode(`<div style="display: flex; padding-right: 20px;">
        //   <a href="https://llegamarket.com/#/repatriationform?id=1&merchantID=${merchantId}">
        //     <img src="https://llegamarket.com/assets/images/repatriations/aC1.png" alt="Imagen 1" width="200" height="200" >
        //   </a>
        //     <a href="https://llegamarket.com/#/payment">
        //         <img src="https://llegamarket.com/assets/images/repatriations/pC1.png" alt="Imagen 2" width="200" height="200">
        //     </a>
        // </div>`)

        setCode(`<div style="display: flex; padding-right: 20px; justify-content: center; margin-top: 20px;">
    <a href="https://llegamarket.com/#/repatriationform?id=1&merchantID=${merchantId}" target="_blank">
        <img src="https://llegamarket.com/assets/images/repatriations/aR1.png" alt="Imagen 1" width="440" height="220" style="margin-right: 10px;">
    </a>
    <a href="https://llegamarket.com/#/payment" target="_blank">
        <img src="https://llegamarket.com/assets/images/repatriations/pR3.png" alt="Imagen 2" width="440" height="220 style="margin-left: 10px;">
    </a>
</div>`)

        setLink1(`https://llegamarket.com/#/repatriationform?id=1&merchantID=${merchantId}`);
        setLink2('https://llegamarket.com/#/payment');
    };

    const handleClose = () => {
        setOpen(false);
    };

    async function saveImage() {

        const storageRef = ref(FirebaseStorage, `images/companies/${Date.now()}-${file.name}`);
        let promise = new Promise((resolve, reject) => {
            uploadBytes(storageRef, file).then((snapshot) => {
                getDownloadURL(snapshot.ref).then((url) => {
                    resolve(url)
                })
            })
        })
        let result = await promise;
        setUrlImage(result);
        setUrlImage((state) => {
            return state;
        })
        return result;

    }

    return (
        <div className='flex flex-col bg-white w-full place-items-center min-h-[1050px]'>
            <div className='w-full pt-10 pl-32 pr-32'>
                <form onSubmit={handleSubmit(onSubmit)}>
                    <div className='flex flex-row w-full '>
                        <div className='w-full'>
                            <TextField
                                id='name'
                                className='w-full'
                                placeholder='Nombre'
                                {...register("name")}
                            />
                        </div>
                    </div>
                    <div className='flex flex-row w-full pt-4'>
                        <div className='w-full'>
                            <TextField
                                multiline
                                rows={4}
                                placeholder='Descripción'
                                className='w-full'
                                {...register("description")}
                            />
                        </div>
                    </div>
                    <div className='flex flex-row w-full pt-4'>
                        <div className='w-full'>
                            <TextField
                                placeholder='Url Website'
                                className='w-full'
                                {...register("urlWebsite")}
                            />
                        </div>
                    </div>
                    <div className='flex flex-row w-full pt-4 justify-between'>
                        <div className='w-1/2 pr-2'>
                            <TextField
                                placeholder='Email'
                                className='w-full'
                                {...register("email")}
                            />
                        </div>
                        <div className='w-1/2'>
                            <TextField
                                placeholder='Merchant ID'
                                className='w-full'
                                {...register("merchantId")}
                            />
                        </div>
                    </div>
                    <div className='flex flex-row w-full pt-4 justify-between'>
                        <div className='w-1/2 pr-2'>
                            <TextField
                                placeholder='Token'
                                className='w-full'
                                {...register("token")}
                            />
                        </div>
                        <div className='w-1/2'>
                            <FormControl className='w-full'>
                                <InputLabel>Categoría</InputLabel>
                                <Select
                                    placeholder='Categoría'
                                    label='Categoría'
                                    value={category}
                                    onChange={handleChangeCategory}
                                >
                                    {categories.map((item) => (
                                        <MenuItem key={item.name} value={item.name}>
                                            {item.name}
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        </div>
                    </div>
                    <div className='flex flex-row w-full pt-4'>
                        <FormControl className='w-full'>
                            <InputLabel >Pais</InputLabel>
                            <Select
                                label='Pais'
                                placeholder='Pais'
                                value={selectedCountry}
                                onChange={handleChangeCountry}
                            >
                                {countries.map((item) => (
                                    <MenuItem key={item.countryName} value={item.id}>{item.countryName}</MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    </div>
                    <div className='w-full pt-4'>
                        <div className='pt-2 flex flex-row w-full'>
                            <input
                                className="block w-full text-sm text-gray-900 border border-gray-300 rounded-lg cursor-pointer bg-gray-50 dark:text-gray-400 focus:outline-none dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400" aria-describedby="file_input_help" id="file_input"
                                type="file"
                                // onChange={handleChangeFile} 
                                onChange={event => setFile(event.target.files[0])}
                                disabled={urlImage}
                                ref={inputRef} />
                        </div>
                        {urlImage &&
                            <div className='pt-2 flex flex-row w-full justify-center'>
                                <img className='w-16 h-16' src={urlImage} alt='' />
                            </div>
                        }
                    </div>
                    {!saving &&
                        <div className='flex flex-row pt-10 justify-end w-full'>
                            <div className='w-1/4'><button type="button" className='w-full h-10 bg-red-700 text-white rounded-full font-medium' onClick={() => onClear()}>Limpiar</button></div>
                            <div className='w-1/4 pl-2'><button type="submit" className='w-full h-10 bg-sky-900 text-white rounded-full font-medium'>Guardar</button></div>
                        </div>
                    }
                    {saving &&
                        <div className='pt-20 place-content-center justify-center'>
                            <Box sx={{ display: 'flex' }}>
                                <CircularProgress />
                            </Box>
                        </div>
                    }
                </form>
            </div>
            <div className='flex flex-row w-full pl-32 pr-32 pt-10 pb-20'>
                <DataGrid
                    rows={companies}
                    columns={columns}
                    initialState={{
                        pagination: {
                            paginationModel: { page: 0, pageSize: 5 },
                        },
                    }}
                    pageSizeOptions={[5, 10]}
                />
            </div>
            <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={style} className='w-[370px] md:w-[400px] lg:w-[900px] h-[400px]'>
                    <div className='w-full h-full flex flex-row'>
                        <IconButton aria-label="close"
                            onClick={handleClose}
                            sx={{
                                position: "absolute",
                                right: 8,
                                top: 8,
                                color: (theme) => theme.palette.grey[500]
                            }}>
                            <CloseOutlined />
                        </IconButton>

                        <div className='lg:w-3/5 w-full border'>
                            <div className='w-full flex flex-row text-center justify-center pt-4'>
                                <Typography gutterBottom variant="h5" component="div" fontSize='1.25rem' className='text-center'>
                                    Código botones Web
                                </Typography>
                            </div>
                            <div className='max-w-xl min-w-[20rem] max-h-[24rem] bg-[#3a404d] rounded-md overflow-hidden mt-4 ml-2 mr-2'>
                                <div className='flex justify-between px-4 text-white text-xs items-center'>
                                    <p className='text-sm'>Codigo Boton Web</p>
                                    {copy ? (
                                        <button className='py-1 inline-flex items-center gap-1'>
                                            <span className='text-base mt-1'>
                                                <Check />
                                            </span>
                                            Copiado
                                        </button>
                                    ) : (
                                        <button className='py-1 inline-flex items-center gap-1' onClick={() => {
                                            navigator.clipboard.writeText(code);
                                            setCopy(true);
                                            setTimeout(() => {
                                                setCopy(false);
                                            }, 3000);
                                        }}>
                                            <span className='text-base mt-1'>
                                                <ContentPaste />
                                            </span>
                                            Copiar código
                                        </button>
                                    )}

                                </div>
                                <SyntaxHighlighter language="html" style={atomOneDark}
                                    customStyle={{
                                        padding: "20px",
                                        fontSize: "10px"
                                    }}
                                    wrapLongLines={true}
                                >
                                    {code}
                                </SyntaxHighlighter>
                            </div>
                        </div>
                        <div className='w-2/5 hidden lg:block pt-20 bord p-4'
                        // style={{ backgroundImage: 'url("../assets/carousel/Rectangle189.png")' }}
                        >
                            <div className='w-full flex flex-row'>
                                <p className='text-center text-lg font-bold'>Link para Crear Repatriación </p>
                            </div>
                            <div className='w-full flex flex-row'>
                                <a className=' text-blue-700' href={link1}>{link1}</a>
                            </div>
                            <br />
                            <div className='w-full flex flex-row'>
                                <p className='text-center text-lg font-bold'>Link para Pago de Repatriación </p>
                            </div>
                            <div className='w-full flex flex-row'>
                                <a className=' text-blue-700' href={link2}>{link2}</a>
                            </div>
                        </div>
                    </div>
                </Box>
            </Modal>
        </div>
    )
}
