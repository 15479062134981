import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    page: 0,
    isLoading: false,
    messageSaved: '',
    isSaving: false,
    sellers: [],
    seller: [],
    active: null
}

export const sellerSlice = createSlice({
    name: 'seller',
    initialState,
    reducers: {
        savingNewSeller: (state) => {
            state.isSaving = true;
        },
        addNewEmptySeller: (state, action) => {
            const newSeller = action.payload;
            state.sellers.push(newSeller);
            state.isSaving = false;
        },
        setActiveSeller: (state, action) => {
            state.active = action.payload;
            state.messageSaved = '';
        },
        setSellers: (state, action) => {
            state.sellers = action.payload;
        },
        setSeller: (state, action) => {
            state.seller = action.payload;
        },
        setSaving: (state) => {
            state.isSaving = true;
            state.messageSaved = '';
        },
        updateSeller: (state, action) => {
            state.isSaving = false;
            state.sellers = state.sellers.map(seller => {
                if (seller.id === action.payload.id) {
                    return action.payload;
                }
                return seller;
            })
            state.messageSaved = `${action.payload.title}, actualizada correctamente`
        },
        deleteSellerById: (state, action) => {

        }
    }
})

export const {
    savingNewSeller,
    addNewEmptySeller,
    setActiveSeller,
    setSellers,
    setSeller,
    setSaving,
    updateSeller,
    deleteSellerById
} = sellerSlice.actions