import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    page: 0,
    isLoading: false,
    messageSaved: '',
    isSaving: false,
    partners: [],
    partner: [],
    active: null
}

export const partnerSlice = createSlice({
    name: 'partner',
    initialState,
    reducers: {
        savingNewPartner: (state) => {
            state.isSaving = true;
        },
        addNewEmptyPartner: (state, action) => {
            const newPartner = action.payload;
            state.partners.push(newPartner);
            state.isSaving = false;
        },
        setActivePartner: (state, action) => {
            state.active = action.payload;
            state.messageSaved = '';
        },
        setPartners: (state, action) => {
            state.partners = action.payload;
        },
        setPartner: (state, action) => {
            state.partner = action.payload;
        },
        setSaving: (state) => {
            state.isSaving = true;
            state.messageSaved = '';
        },
        updatePartner: (state, action) => {
            state.isSaving = false;
            state.partners = state.partners.map(partner => {
                if (partner.id === action.payload.id) {
                    return action.payload;
                }
                return partner;
            })
            state.messageSaved = `${action.payload.title}, actualizada correctamente`
        },
        deletePartnerById: (state, action) => {

        }
    }
})

export const {
    savingNewPartner,
    addNewEmptyPartner,
    setActivePartner,
    setPartners,
    setPartner,
    setSaving,
    updatePartner,
    deletePartnerById
} = partnerSlice.actions