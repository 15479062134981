import React, { useEffect, useState } from 'react'
import { RegisterLlegaMarket } from './RegisterLlegaMarket'
import { PictureAsPdf } from '@mui/icons-material';
import { useTranslation } from 'react-i18next'
import { Accordion, AccordionDetails, AccordionSummary, Typography } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

export const Questions = () => {
    const { t } = useTranslation();
    const [question, setQuestion] = useState(false);
    const [question2, setQuestion2] = useState(false);
    const [question3, setQuestion3] = useState(false);
    const [question4, setQuestion4] = useState(false);
    const [question5, setQuestion5] = useState(false);
    const [question6, setQuestion6] = useState(false);
    const [question7, setQuestion7] = useState(false);
    const [question8, setQuestion8] = useState(false);

    useEffect(() => {
        if (window.scrollY > 300) {
            window.scrollTo({ top: 0, behavior: 'smooth' });
        }
    }, [])

    const openQuestion = () => {
        setQuestion((prev) => !prev);
        setQuestion2(false);
        setQuestion3(false);
        setQuestion4(false);
        setQuestion5(false);
        setQuestion6(false);
        setQuestion7(false);
        setQuestion8(false);
    }

    const openQuestion2 = () => {
        setQuestion(false);
        setQuestion2((prev) => !prev);
        setQuestion3(false);
        setQuestion4(false);
        setQuestion5(false);
        setQuestion6(false);
        setQuestion7(false);
        setQuestion8(false);
    }

    const openQuestion3 = () => {
        setQuestion(false);
        setQuestion2(false);
        setQuestion3((prev) => !prev);
        setQuestion4(false);
        setQuestion5(false);
        setQuestion6(false);
        setQuestion7(false);
        setQuestion8(false);
    }

    const openQuestion4 = () => {
        setQuestion(false);
        setQuestion2(false);
        setQuestion3(false);
        setQuestion4((prev) => !prev);
        setQuestion5(false);
        setQuestion6(false);
        setQuestion7(false);
        setQuestion8(false);
    }

    const openQuestion5 = () => {
        setQuestion(false);
        setQuestion2(false);
        setQuestion3(false);
        setQuestion4(false);
        setQuestion5((prev) => !prev);
        setQuestion6(false);
        setQuestion7(false);
        setQuestion8(false);
    }

    const openQuestion6 = () => {
        setQuestion(false);
        setQuestion2(false);
        setQuestion3(false);
        setQuestion4(false);
        setQuestion5(false);
        setQuestion6((prev) => !prev);
        setQuestion7(false);
        setQuestion8(false);
    }

    const openQuestion7 = () => {
        setQuestion(false);
        setQuestion2(false);
        setQuestion3(false);
        setQuestion4(false);
        setQuestion5(false);
        setQuestion6(false);
        setQuestion7((prev) => !prev);
        setQuestion8(false);
    }

    const openQuestion8 = () => {
        setQuestion(false);
        setQuestion2(false);
        setQuestion3(false);
        setQuestion4(false);
        setQuestion5(false);
        setQuestion6(false);
        setQuestion7(false);
        setQuestion8((prev) => !prev);
    }

    return (
        <>
            <div className='w-full bg-principal-blue min-h-[100px] pb-20'>
                <div className='w-full flex flex-row pt-20 pl-2 pr-2'>
                    <p className="w-full text-white text-3xl md:text-5xl font-bold leading-normal">{t('questions.welcome')}</p>
                </div>
                <div className='w-full flex flex-row pt-11'>
                    <p className="w-full text-white text-xl md:text-5xl font-medium leading-normal">{t('questions.question')}</p>
                </div>
            </div>
            <div className='w-full bg-white min-h-[290px] pl-2 md:pl-14 pr-2 md:pr-14 pt-32 pb-32'>
                <div className='w-full flex flex-wrap pl-2 md:pl-12'>


                    <div className='w-full md:w-1/2 justify-center text-center'>
                        <Accordion className='bg-[#645EC2]' expanded={question}
                            sx={{ background: '#645EC2' }}
                        >
                            <AccordionSummary sx={{}} onClick={() => openQuestion()}
                                expandIcon={<ExpandMoreIcon sx={{ color: '#6EEBE6' }} />}
                            >
                                <Typography sx={{ color: '#6EEBE6' }} >{t('questions.question1')}</Typography>
                            </AccordionSummary>
                            <AccordionDetails sx={{ minHeight: '200px' }}>
                                <Typography className='text-white pl-4 pr-4 justify-start text-left'>
                                    {t('questions.response1')}
                                </Typography>
                            </AccordionDetails>
                        </Accordion>
                    </div>
                    <div className='w-full md:w-1/2 justify-center text-center'>
                        <Accordion expanded={question2}
                            sx={{ background: '#6EEBE6' }}
                        >
                            <AccordionSummary
                                expandIcon={<ExpandMoreIcon sx={{ color: '#4F4170' }} />}
                                onClick={() => openQuestion2()}
                            >
                                <Typography>{t('questions.question2')}</Typography>
                            </AccordionSummary>
                            <AccordionDetails sx={{ minHeight: '200px' }}>
                                <Typography className='pl-4 pr-4 justify-start text-left'>
                                    {t('questions.response2_1')}
                                    <br /><br />
                                    {t('questions.response2_2')}
                                </Typography>
                            </AccordionDetails>
                        </Accordion>
                    </div>
                    <div className='w-full md:w-1/2 justify-center text-center'>
                        <Accordion expanded={question3}
                            sx={{ background: '#6EEBE6' }}
                        >
                            <AccordionSummary
                                expandIcon={<ExpandMoreIcon sx={{ color: '#4F4170' }} />}
                                onClick={() => openQuestion3()}
                            >
                                <Typography>{t('questions.question3')}</Typography>
                            </AccordionSummary>
                            <AccordionDetails sx={{ minHeight: '200px' }}>
                                <Typography className='pl-4 pr-4 justify-start text-left'>
                                    {t('questions.response3')}
                                </Typography>
                            </AccordionDetails>
                        </Accordion>
                    </div>
                    <div className='w-full md:w-1/2 justify-center text-center'>
                        <Accordion className='bg-[#645EC2]' expanded={question4}
                            sx={{ background: '#645EC2' }}

                        >
                            <AccordionSummary
                                expandIcon={<ExpandMoreIcon sx={{ color: '#6EEBE6' }} />}
                                onClick={() => openQuestion4()}
                            >
                                <Typography sx={{ color: '#6EEBE6' }} >{t('questions.question4')}</Typography>
                            </AccordionSummary>
                            <AccordionDetails sx={{ minHeight: '200px' }}>
                                <Typography className='text-white pl-4 pr-4 justify-start text-center'>
                                    <span className="text-white text-sm md:text-lg font-bold leading-none">{t('questions.response4_1')}</span><br />
                                    <span className="text-blue-900 text-sm font-normal leading-none"><a href='https://play.google.com/store/apps/details?id=com.ypayme.appllegagt' target="_blank">https://play.google.com/store/apps</a><br /><br /></span>
                                    <span className="text-white text-sm md:text-lg font-bold leading-none">{t('questions.response4_2')}<br /></span>
                                    <span className="text-blue-900 text-sm font-normal leading-none"><a href='https://apps.apple.com/gt/app/llega/id1604889620' target="_blank">https://apps.apple.com/gt/app/llega/id1604889620</a></span>
                                </Typography>
                            </AccordionDetails>
                        </Accordion>
                    </div>
                    <div className='w-full md:w-1/2 justify-center text-center'>
                        <Accordion className='bg-[#645EC2]' expanded={question5}
                            sx={{ background: '#645EC2' }}
                        >
                            <AccordionSummary
                                expandIcon={<ExpandMoreIcon sx={{ color: '#6EEBE6' }} />}
                                onClick={() => openQuestion5()}
                            >
                                <Typography sx={{ color: '#6EEBE6' }} >{t('questions.question5')}</Typography>
                            </AccordionSummary>
                            <AccordionDetails sx={{ minHeight: '200px' }}>
                                <Typography className='text-white pl-4 pr-4 justify-start text-left'>
                                    <div className='w-full flex flex-row pt-4 text-left pb-2'>
                                        {/* <p className="w-full text-blue-950 text-sm font-normal leading-6">Descargue el instructivo en PDF <a href='../assets/files/Instructivo Registrar Comercios LLMT.pdf' target="_blank"><span className='text-blue-600 text-base'>aquí</span></a> O escriba al correo electrónico comercial@llega.com</p> */}
                                        <p className="w-full text-white text-base  md:text-lg font-normal leading-6">{t('questions.response5')}</p>
                                    </div>
                                    <div className='w-full flex flex-row pt-2 justify-center pb-7'>
                                        <p className=" text-white text-sm font-normal leading-6">{t('questions.download')}  </p><a href='../assets/files/1 Instructivo Registrar Comercios GUA LLMT.pdf' target="_blank"><PictureAsPdf className='w-4 h-4' style={{ color: 'white' }} color='primary' /></a>
                                    </div>
                                </Typography>
                            </AccordionDetails>
                        </Accordion>
                    </div>
                    <div className='w-full md:w-1/2 justify-center text-center'>
                        <Accordion expanded={question6}
                            sx={{ background: '#6EEBE6' }}
                        >
                            <AccordionSummary
                                expandIcon={<ExpandMoreIcon sx={{ color: '#4F4170' }} />}
                                onClick={() => openQuestion6()}
                            >
                                <Typography>{t('questions.question6')}</Typography>
                            </AccordionSummary>
                            <AccordionDetails sx={{ minHeight: '200px' }}>
                                <Typography className='pl-4 pr-4 justify-start text-left'>
                                    <div className='w-full flex flex-row pt-4 text-left pb-2'>
                                        {/* <p className="w-full text-white text-sm font-normal leading-6">Descargue el instructivo en PDF <a href='../assets/files/Instructivo Registro desde Guatemala.pdf' target="_blank"><span className='text-blue-300 text-base'>aquí</span></a>. O escriba al correo electrónico procesos@llega.com</p> */}
                                        <p className="w-full  text-base  md:text-lg font-normal leading-6">{t('questions.response6')}</p>
                                    </div>
                                    <div className='w-full flex flex-row pt-2 justify-center pb-2'>
                                        <p className=" text-sm font-normal leading-6">{t('questions.download')}  </p><a href='../assets/files/2 Instructivo Registro desde Guatemala.pdf' target="_blank"><PictureAsPdf className='w-4 h-4' /></a>
                                    </div>
                                </Typography>
                            </AccordionDetails>
                        </Accordion>
                    </div>


                    <div className='w-full md:w-1/2 justify-center text-center'>
                        <Accordion expanded={question7}
                            sx={{ background: '#6EEBE6' }}
                        >
                            <AccordionSummary
                                expandIcon={<ExpandMoreIcon sx={{ color: '#4F4170' }} />}
                                onClick={() => openQuestion7()}
                            >
                                <Typography>{t('questions.question7')}</Typography>
                            </AccordionSummary>
                            <AccordionDetails sx={{ minHeight: '200px' }}>
                                <Typography className='pl-4 pr-4 justify-start text-left'>
                                    <p className="w-full text-base  md:text-md font-normal leading-6">{t('questions.response7_1')}<br />
                                        {t('questions.response7_2')}<br />
                                        {t('questions.response7_3')}<br />
                                        {t('questions.response7_4')}<br />
                                        {t('questions.response7_5')}<br />
                                        {/* {t('questions.response7_6')} */}
                                    </p>
                                </Typography>
                            </AccordionDetails>
                        </Accordion>
                    </div>
                    <div className='w-full md:w-1/2 justify-center text-center'>
                        <Accordion className='bg-[#645EC2]' expanded={question8}
                            sx={{ background: '#645EC2' }}
                        >
                            <AccordionSummary
                                expandIcon={<ExpandMoreIcon sx={{ color: '#6EEBE6' }} />}
                                onClick={() => openQuestion8()}
                            >
                                <Typography sx={{ color: '#6EEBE6' }} >{t('questions.question8')}</Typography>
                            </AccordionSummary>
                            <AccordionDetails sx={{ minHeight: '200px' }}>
                                <Typography className='text-white pl-4 pr-4 justify-start text-left'>
                                    <div className='w-full flex flex-row pt-4 text-left pb-2'>
                                        <p className="w-full  text-base md:text-lg font-normal leading-6">{t('questions.response8')}</p>
                                    </div>
                                    <div className='w-full flex flex-row pt-2 justify-center pb-6'>
                                        <p className="  text-sm font-normal leading-6">{t('questions.download')}  </p><a href='../assets/files/1 Instructivo Registro desde USA.pdf' target="_blank"><PictureAsPdf className='w-4 h-4' style={{ color: 'white' }} color='primary' /></a>
                                    </div>
                                </Typography>
                            </AccordionDetails>
                        </Accordion>
                    </div>

                    {/* <div className='w-full md:w-1/2 justify-center text-center'>
                        <div className='w-full md:w-11/12 h-full bg-[#6EEBE6] min-h-[270px] pb-12 text-center pl-2 pr-2 md:pl-10 md:pr-10 lg:pl-12 lg:pr-12 xl:pl-28 xl:pr-28 2xl:pl-28 2xl:pr-28 '>
                            <div className='w-full flex flex-row pt-10 text-left'>
                                <p className="w-full text-blue-950 text-xl font-bold text-left uppercase leading-none tracking-wide">¿Qué es Llega Market?</p>
                            </div>
                            <div className='w-full flex flex-row pt-4 text-left pb-2'>
                                <p className="w-full text-blue-950 text-base md:text-lg text-left font-normal leading-6">Llega Market es el centro comercial virtual, más grande de Guatemala, que te brinda acceso a miles de comercios alrededor del país.</p>
                            </div>

                        </div>
                    </div>
                    <div className='w-full md:w-1/2'>
                        <div className='w-full md:w-11/12 h-full bg-[#645EC2] min-h-[270px] pb-12 pl-2 pr-2 md:pl-10 md:pr-10 lg:pl-12 lg:pr-12 xl:pl-28 xl:pr-28 2xl:pl-28 2xl:pr-28'>
                            <div className='w-full flex flex-row pt-10 text-left'>
                                <p className="w-full text-white text-xl font-bold uppercase tracking-wide">¿Qué beneficios me ofrece Llega Market?</p>
                            </div>
                            <div className='w-full flex flex-row pt-4 text-left pb-2'>
                                <p className="w-full text-white text-base md:text-lg text-left font-normal  leading-none">a) Llega Market te facilita las compras desde el extranjero y Guatemala, a través de su billetera electrónica Llega.<br /><br />b) Acortamos distancias entre los comercios afiliados y sus clientes en el extranjero y en toda la república de Guatemala.</p>
                            </div>

                        </div>
                    </div> */}

                    {/* <div className='w-full md:w-1/2'>
                        <div className='w-full md:w-11/12 h-full bg-[#645EC2] min-h-[270px] pb-12 pl-2 pr-2 md:pl-10 md:pr-10 lg:pl-12 lg:pr-12 xl:pl-28 xl:pr-28 2xl:pl-28 2xl:pr-28'>
                            <div className='w-full flex flex-row pt-12 text-left'>
                                <p className="w-full text-white text-xl font-bold uppercase tracking-wide">¿Qué es la billetera electrónica Llega?</p>
                            </div>
                            <div className='w-full flex flex-row pt-6 text-left pb-4'>
                                <p className="w-full text-white text-base md:text-lg text-left font-normal leading-6">
                                    Es el medio de pago electrónico que puedes utilizar en todos los comercios que encontrarás en el centro comercial virtual Llega Market.</p>
                            </div>
                        </div>
                    </div>
                    <div className='w-full md:w-1/2  justify-center text-center'>
                        <div className='w-full md:w-11/12 h-full bg-[#6EEBE6] min-h-[260px] text-center pl-2 pr-2 md:pl-10 md:pr-10 lg:pl-12 lg:pr-12 xl:pl-28 xl:pr-28 2xl:pl-28 2xl:pr-28'>
                            <div className='w-full flex flex-row pt-12'>
                                <p className="w-full text-blue-950 text-xl font-bold text-left uppercase leading-none tracking-wide">¿Cómo descargo la aplicación Llega en mi celular?</p>
                            </div>
                            <div className='w-full flex flex-row pt-4 text-left pb-5'>
                                <p className="w-full">
                                    <span className="text-blue-950 text-sm md:text-lg font-bold leading-none">Play Store:</span><br />
                                    <span className="text-blue-600 text-sm font-normal leading-none"><a href='https://play.google.com/store/apps/details?id=com.ypayme.appllegagt'>https://play.google.com/store/apps</a><br /><br /></span>
                                    <span className="text-blue-950 text-sm md:text-lg font-bold leading-none">Apple Store:<br /></span>
                                    <span className="text-blue-600 text-sm font-normal leading-none">https://apps.apple.com/gt/app/llega</span>
                                </p>
                            </div>
                        </div>
                    </div> */}

                    {/* <div className='w-full md:w-1/2  justify-center text-center'>
                        <div className='w-full md:w-11/12 h-full bg-[#6EEBE6] min-h-[260px] text-center pl-2 pr-2 md:pl-10 md:pr-10 lg:pl-12 lg:pr-12 xl:pl-28 xl:pr-28 2xl:pl-28 2xl:pr-28'>
                            <div className='w-full flex flex-row pt-10'>
                                <p className="w-full text-blue-950 text-xl text-left font-bold uppercase leading-snug tracking-wide">¿Cómo afiliar mi comercio a Llega Market?</p>
                            </div>
                            <div className='w-full flex flex-row pt-4 text-left pb-2'>
                                <p className="w-full text-blue-950 text-base  md:text-lg font-normal leading-6">Descargue el instructivo en el icono de PDF. O escriba al correo electrónico comercial@llega.com</p>
                            </div>
                            <div className='w-full flex flex-row pt-2 justify-center pb-7'>
                                <p className=" text-blue-950 text-sm font-normal leading-6">Descargar:  </p><a href='../assets/files/1 Instructivo Registrar Comercios GUA LLMT.pdf' target="_blank"><PictureAsPdf className='w-4 h-4' color='primary' /></a>
                            </div>
                        </div>
                    </div>
                    <div className='w-full md:w-1/2'>
                        <div className='w-full md:w-11/12 h-full bg-[#645EC2] min-h-[260px] pb-12 pl-2 pr-2 md:pl-10 md:pr-10 lg:pl-12 lg:pr-12 xl:pl-28 xl:pr-28 2xl:pl-28 2xl:pr-28'>
                            <div className='w-full flex flex-row pt-10 text-left'>
                                <p className="w-full text-white text-xl font-bold uppercase leading-snug tracking-wide">¿Cómo me registro en Llega Guatemala?</p>
                            </div>
                            <div className='w-full flex flex-row pt-4 text-left pb-2'>
                                <p className="w-full text-white text-base  md:text-lg font-normal leading-6">Descargue el instructivo en PDF. O escriba al correo electrónico procesos@llega.com</p>
                            </div>
                            <div className='w-full flex flex-row pt-2 justify-center pb-2'>
                                <p className=" text-white text-sm font-normal leading-6">Descargar:  </p><a href='../assets/files/2 Instructivo Registro desde Guatemala.pdf' target="_blank"><PictureAsPdf className='w-4 h-4' style={{ color: 'white' }} /></a>
                            </div>
                        </div>
                    </div> */}

                    {/* <div className='w-ful md:w-1/2'>
                        <div className='w-full md:w-11/12 h-full bg-[#645EC2] min-h-[260px] pb-12 pl-2 pr-2 md:pl-10 md:pr-10 lg:pl-12 lg:pr-12 xl:pl-28 xl:pr-28 2xl:pl-28 2xl:pr-28'>
                            <div className='w-full flex flex-row pt-12 text-left'>
                                <p className="w-full text-white text-xl font-bold uppercase leading-snug tracking-wide">¿Qué métodos de pago existen?</p>
                            </div>
                            <div className='w-full flex flex-row pt-2 text-left pb-2'>
                                <p className="w-full text-white text-base  md:text-lg font-normal leading-6">Botón de pago Llega Guatemala<br />
                                    Botón de pago Llega USA<br />
                                    Código QR de pago Llega Guatemala por correo electrónico o presencial<br />
                                    Código QR de pago Llega USA por correo electrónico<br />
                                    Link de pago Llega Guatemala por WhatsApp o correo electrónico<br />
                                    Link de pago Llega USA por WhatsApp o correo electrónico.</p>
                            </div>
                        </div>
                    </div>
                    <div className='w-full md:w-1/2  justify-center text-center'>
                        <div className='w-full md:w-11/12 h-full bg-[#6EEBE6] min-h-[260px] text-center pl-2 pr-2 md:pl-10 md:pr-10 lg:pl-12 lg:pr-12 xl:pl-28 xl:pr-28 2xl:pl-28 2xl:pr-28'>
                            <div className='w-full flex flex-row pt-10 c'>
                                <p className="w-full text-blue-950 text-xl font-bold uppercase leading-snug tracking-wide">¿Cómo me registro en Llega Estados Unidos?</p>
                            </div>
                            <div className='w-full flex flex-row pt-4 text-left pb-2'>                                
                                <p className="w-full text-blue-950 text-base md:text-lg font-normal leading-6">Descargue el instructivo en PDF. O escriba al correo electrónico procesos@llega.com.</p>
                            </div>
                            <div className='w-full flex flex-row pt-2 justify-center pb-6'>
                                <p className=" text-blue-950 text-sm font-normal leading-6">Descargar:  </p><a href='../assets/files/1 Instructivo Registro desde USA.pdf' target="_blank"><PictureAsPdf className='w-4 h-4' color='primary' /></a>
                            </div>
                        </div>
                    </div> */}
                </div>
            </div>
            <RegisterLlegaMarket />
        </>
    )
}
