import { query, collection, getDocs, where } from "firebase/firestore/lite";
import { FirebaseDB } from "../firebase/config";

export const loadPayments = async (uid = '') => {
    if (!uid) throw new Error('El UID del usuario no existe');

    const q = query(
        collection(FirebaseDB, 'payment')
    );

    const docs = await getDocs(q);
    const payments = [];
    docs.forEach(doc => {
        payments.push({ id: doc.id, ...doc.data() });
    });
    return payments;
}

export const loadAllPayments = async () => {

    const q = query(
        collection(FirebaseDB, 'payment')
    );

    const docs = await getDocs(q);
    const payments = [];
    docs.forEach(doc => {
        payments.push({ id: doc.id, ...doc.data() });
    });
    return payments;
}

export const loadPayment = async (uid = '') => {
    if (!uid) throw new Error('El UID del usuario no existe');

    const q = query(
        collection(FirebaseDB, 'payment'),
        where('uid', '==', uid)
    );

    const docs = await getDocs(q);

    const payment = [];
    docs.forEach(doc => {
        payment.push({ id: doc.id, ...doc.data() });
    })

    return payment;
}

