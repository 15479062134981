import { Card, CardContent, CardMedia, Typography } from '@mui/material'
import React, { useState } from 'react'
import ModalVideo from 'react-modal-video'
import { useTranslation } from 'react-i18next'

function VideoCard({ urlImage, title, description, urlVideo, type }) {
    const [isOpen, setIsOpen] = useState(false);

    const openModal = () => {
        setIsOpen(true);
    }

    return (
        <>

            <div className='rounded-2xl relative w-full overflow-hidden bg-cover bg-no-repeat text-center place-content-center'
                style={{ backgroundImage: `url("${urlImage}")`, height: '300px' }}>
                <div className="absolute bottom-0 left-0 right-0 top-0 h-full w-full overflow-hidden bg-fixed"
                >
                    <div className="flex h-full items-center justify-center">
                        <div className="text-white">
                            <ModalVideo channel='custom' isOpen={isOpen} onClose={() => setIsOpen(false)} url={urlVideo} />
                            <button className='' onClick={openModal}>
                                <img className='w-32 h-32 opacity-40 hover:opacity-80 hover:rounded-full' src='../assets/images/play-video.png' />
                            </button>
                        </div>
                    </div>
                </div>
            </div>
            <div className='max-w-[360px]'>
                <div className='w-full flex flex-row text-center justify-center pt-4'>
                    <Typography gutterBottom variant="h5" component="div" fontSize='1.25rem' className='text-center'>
                        {title}
                    </Typography>
                </div>
                <div className='w-full flex flex-row text-center justify-center'>
                    <Typography variant="body2" color="text.secondary" fontSize='0.813rem'>
                        {description}
                    </Typography>
                </div>
            </div>

        </>
    )
}

export default VideoCard;