import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    page: 0,
    isLoading: false,
    messageSaved: '',
    isSaving: false,
    repCountries: [],
    repCountry: [],
    active: null
}

export const repCountrySlice = createSlice({
    name: 'repCountry',
    initialState,
    reducers: {
        savingNewRepCountry: (state) => {
            state.isSaving = true;
        },
        addNewEmptyRepCountry: (state, action) => {
            const newRepCountry = action.payload;
            state.repCountries.push(newRepCountry);
            state.isSaving = false;
        },
        setActiveRepCountry: (state, action) => {
            state.active = action.payload;
            state.messageSaved = '';
        },
        setRepCountries: (state, action) => {
            state.repCountries = action.payload;
        },
        setRepCountry: (state, action) => {
            state.repCountry = action.payload;
        },
        setSaving: (state) => {
            state.isSaving = true;
            state.messageSaved = '';
        },
        updateRepCountry: (state, action) => {
            state.isSaving = false;
            state.repCountries = state.repCountries.map(repCountry => {
                if (repCountry.id === action.payload.id) {
                    return action.payload;
                }
                return repCountry;
            })
            state.messageSaved = `${action.payload.title}, actualizada correctamente`
        },
        deleteRepCountryById: (state, action) => {

        }
    }
})

export const {
    savingNewRepCountry,
    addNewEmptyRepCountry,
    setActiveRepCountry,
    setRepCountries,
    setRepCountry,
    setSaving,
    updateRepCountry,
    deleteRepCountryById
} = repCountrySlice.actions