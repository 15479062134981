import { collection, count, deleteDoc, doc, setDoc } from "firebase/firestore/lite";
import { FirebaseDB } from "../../firebase/config";
import { loadAllCountries, loadCountries, loadCountry } from "../../helpers/loadCountries";
import { addNewEmptySeller, deleteSellerById, savingNewSeller, setActiveSeller, setSaving, setSeller, setSellers, updateSeller } from "./sellerSlice";
import { loadAllSellers, loadSeller, loadSellers } from "../../helpers/loadSellers";


export const startNewClearSeller = (seller) => {
    return async (dispatch, getState) => {
        dispatch(savingNewSeller());
        const { uid } = getState().auth;

        const {
            sellerName,
            companyId,
            companyName,
            status
        } = seller;

        const newSeller = {
            uid,
            sellerName,
            companyId,
            companyName,
            status,
            creationDate: new Date().getTime(),
            updateDate: new Date().getTime()
        }

        const newDoc = doc(collection(FirebaseDB, `seller`))
        await setDoc(newDoc, newSeller);
        newSeller.id = newDoc.id;
        dispatch(addNewEmptySeller(newSeller));
        dispatch(setActiveSeller(newSeller));
    }
}

export const startNewSeller = (seller) => {
    return async (dispatch, getState) => {
        dispatch(savingNewSeller());

        const { uid } = getState().auth;
        const {
            sellerName,
            companyId,
            companyName,
            status,
        } = seller;

        const newSeller = {
            uid,
            sellerName,
            companyId,
            companyName,
            status,
            creationDate: new Date().getTime(),
            updateDate: new Date().getTime()
        }

        const newDoc = doc(collection(FirebaseDB, `seller`));
        await setDoc(newDoc, newSeller);
        newSeller.id = newDoc.id;

        dispatch(addNewEmptySeller(newSeller));
        dispatch(setActiveSeller(newSeller));
    }
}

export const startLoadingSellers = () => {
    return async (dispatch, getState) => {
        const { uid } = getState().auth;

        if (!uid) throw new Error('El UID del usuario no existe');
        const sellers = await loadSellers(uid);
        dispatch(setSellers(sellers));
    }
}

export const startLoadingAllSellers = () => {
    return async (dispatch, getState) => {
        // const { uid } = getState().auth;

        // if (!uid) throw new Error('El UID del usuario no existe');
        const sellers = await loadAllSellers();
        dispatch(setSellers(sellers));
    }
}

export const startLoadingSeller = () => {
    return async (dispatch, getState) => {
        const { uid } = getState().auth;

        if (!uid) throw new Error("El UID del usuario no existe");
        const seller = await loadSeller(uid);
        dispatch(setSeller(seller));
    }
}

export const startDeletingSeller = () => {
    return async (dispatch, getState) => {
        const { uid } = getState().auth;
        const { active: seller } = getState().seller;

        const docRef = doc(FirebaseDB, `seller/${seller.id}`);
        await deleteDoc(docRef);

        dispatch(deleteSellerById(seller.id));
    }
}

export const startSaveSeller = () => {
    return async (dispatch, getState) => {

        dispatch(setSaving());

        const { uid } = getState().auth;
        const { active: seller } = getState().seller;

        const sellerToFireStore = { ...seller };
        delete sellerToFireStore.id;

        const docRef = doc(FirebaseDB, `seller/${seller.id}`)
        await setDoc(docRef, sellerToFireStore, { merge: true });

        dispatch(updateSeller(seller));
    }
}